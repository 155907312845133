import * as s from 'superstruct';

// WARNING! in case you need to change max length, it requires altering column in pg
export const USER_FIELDS_LENGTH = {
    EMAIL: 128,
    FIRST_NAME: 128,
    LAST_NAME: 128,
    COMPANY_NAME: 128,
    PHONE: 32,
    USER_TYPE: 32,
    STREET: 128,
    APARTMENT: 128,
    CITY: 64,
    STATE: 64,
    ZIPCODE: 5, // keep in mind in postgres 16
    SOURCE: 128,
    BUYER_TYPE_CODE: 64,
    BUSINESS_ROLE_TYPE_CODE: 64,
    PASSWORD: 1028, // no password in pg but we need to restrict length on UI
};

export const CreateUserInfoValidationSchema = s.type({
    firstName: s.size(s.string(), 1, USER_FIELDS_LENGTH.FIRST_NAME),
    lastName: s.size(s.string(), 1, USER_FIELDS_LENGTH.LAST_NAME),
    email: s.size(s.string(), 1, USER_FIELDS_LENGTH.EMAIL),
    phone: s.size(s.string(), 1, USER_FIELDS_LENGTH.PHONE),
    phoneVerifiedAt: s.optional(s.nullable(s.string())),
    phoneVerificationDate: s.optional(s.nullable(s.string())),
    phoneVerificationRequestId: s.optional(s.nullable(s.string())),
    phoneVerificationFor: s.optional(s.nullable(s.string())),
    companyName: s.optional(s.size(s.string(), 0, USER_FIELDS_LENGTH.COMPANY_NAME)),
    bidderNumber: s.optional(s.size(s.number(), 1, Infinity)),
    zipcode: s.size(s.string(), USER_FIELDS_LENGTH.ZIPCODE, USER_FIELDS_LENGTH.ZIPCODE),
    userType: s.size(s.string(), 1, USER_FIELDS_LENGTH.USER_TYPE),
    source: s.optional(s.size(s.string(), 0, USER_FIELDS_LENGTH.SOURCE)),
    isSMSNotificationsEnabled: s.boolean(),
    isToastNotificationsEnabled: s.boolean(),
    buyerTypeCode: s.optional(s.size(s.string(), 0, USER_FIELDS_LENGTH.BUYER_TYPE_CODE)),
    businessRoleTypeCode: s.optional(s.size(s.string(), 0, USER_FIELDS_LENGTH.BUSINESS_ROLE_TYPE_CODE)),
});

export const UpdateUserInfoValidationSchema = s.assign(
    s.partial(CreateUserInfoValidationSchema),
    s.type({
        uid: s.string(),
        isExemptFromSalesTax: s.optional(s.boolean()),
        isTrusted: s.optional(s.boolean()),
        isTrustedOverride: s.optional(s.nullable(s.boolean())),
        hasMultiBidAccess: s.optional(s.boolean()),
        settings: s.optional(
            s.object({
                address: s.optional(
                    s.object({
                        city: s.size(s.string(), 1, USER_FIELDS_LENGTH.CITY),
                        state: s.size(s.string(), 1, USER_FIELDS_LENGTH.STATE),
                        street: s.size(s.string(), 1, USER_FIELDS_LENGTH.STREET),
                        apartment: s.optional(s.size(s.string(), 0, USER_FIELDS_LENGTH.APARTMENT)),
                    })
                ),
                paymentInfo: s.optional(
                    s.object({
                        paymentProvider: s.optional(s.string()),
                        paymentCustomerId: s.optional(s.string()),
                    })
                ),
            })
        ),
        emailVerifiedAt: s.optional(s.string()),
        phoneVerificationBypass: s.optional(s.nullable(s.boolean())),
    })
);
