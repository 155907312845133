import {SettingOutlined} from '@ant-design/icons';
import {Alert, Checkbox, Col, List, Row, Typography} from 'antd';
import React, {FC} from 'react';
import {PersistedNotificationFactory} from '../notifications/PersistedNotificationFactory';
import {Observer} from 'mobx-react';
import {EventType} from '@joyrideautos/ui-models/src/types/events/Event';
import {cast, IDisposer, Instance, types} from 'mobx-state-tree';
import {observer} from 'mobx-react-lite';
import {Link, Router, useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes, UserProfileSettingsHashEnum} from '../../Routes';
import {Only} from '@joyrideautos/ui-common/src/components/Only';
import {when} from 'mobx';
import {QueueObject} from '@joyrideautos/auction-core/src/types/GDriveTypes';
import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import styles from './NotificationsMenu.module.less';
import {NotificationState} from '@joyrideautos/auction-core/src/types/events/notifications';

const {Text} = Typography;

export type NotificationsMenuPropsType = {
    presenter: NotificationMenuPresenterType;
};

export const QueueEntry = RootStoreAwareViewModel.named('QueueEntry')
    .props({
        isFolderFlow: types.maybe(types.boolean),
        path: types.maybe(types.string),
        sellerId: types.maybe(types.string),
        auctionId: types.maybe(types.string),
        regionId: types.maybe(types.string),
        timestamp: types.maybe(types.number),
        uid: types.maybe(types.string),
        toReplaceExisting: types.maybe(types.boolean),
    })
    .views((self) => {
        return {
            get sellerName() {
                if (self.sellerId) {
                    const seller = self.sellersStore.findSeller(self.sellerId);
                    return seller?.name;
                }
                return '';
            },
            get auctionName() {
                if (self.auctionId && self.regionId) {
                    const auction = self.auctionsStore.findAuction(self.regionId, self.auctionId);
                    return auction?.title;
                }
                return '';
            },
            get gDrivePath() {
                return 'Google Drive'; // TODO (future): Check if we can implement folders path here
            },
        };
    });

export const NotificationMenuPresenter = BaseViewModel.named('NotificationMenuModel')
    .props({
        queueEntries: types.array(QueueEntry),
    })
    .volatile(() => ({
        factory: new PersistedNotificationFactory(),
        disposers: [] as IDisposer[],
    }))
    .views((self) => {
        return {
            get events(): EventType[] {
                return self.eventsStore.lastUnreadPersistedNotifications.values;
            },
            get eventsNumber(): number {
                return self.eventsStore.unreadPersistedNotificationsCount.value ?? 0;
            },
            get hasSellerOrManagerRole() {
                return !!(this.userId && self.sellerRoleStore.hasSellerRoleForAnyCompany(this.userId));
            },
            get userId() {
                return self.authUser?.uid;
            },
            toSettings(ev: EventType, router: Router) {
                const settings = self.factory.createSettings(ev, {router});
                if (settings) {
                    settings.description = (
                        <>
                            {settings.description}
                            <div className={styles['radion-button']}>
                                <Checkbox
                                    className={styles['radion-button-style']}
                                    checked={!ev.isUnread()}
                                    onChange={(e) => {
                                        if (this.userId) {
                                            e.target.checked
                                                ? ev.markReadAndSave(this.userId)
                                                : ev.markUnreadAndSave(this.userId);
                                        }
                                    }}
                                />
                            </div>
                        </>
                    );
                }
                return settings;
            },
            get vehiclesLeft() {
                return self.mediaStore.vehiclesLeft;
            },
            get mediaLeft() {
                return self.mediaStore.mediaLeft;
            },
            get progressVisible() {
                return this.vehiclesLeft > 0 || self.queueEntries.length > 0;
            },
        };
    })
    .actions((self) => ({
        setQueueEntries(queueEntries: QueueObject[]) {
            self.queueEntries = cast(queueEntries);
        },
    }))
    .actions((self) => {
        return {
            markReadAndSave(ev: EventType) {
                if (self.userId) {
                    ev.markReadAndSave(self.userId);
                }
            },
            markAllRead() {
                return self.rootStore.eventsService.updateStateForAllUserPersistedNotifications({
                    from: NotificationState.POPPED,
                    to: NotificationState.READ,
                });
            },
            afterCreate() {
                self.disposers.push(
                    when(
                        () => self.hasSellerOrManagerRole,
                        () => {
                            self.disposers.push(
                                self.rootStore.googleDriveService.subscribeGoogleDriveQueue(
                                    self.userId!,
                                    (queueEntries: QueueObject[]) => {
                                        self.setQueueEntries(queueEntries);
                                    }
                                )
                            );
                        }
                    )
                );
            },
            beforeDestroy() {
                self.disposers.forEach((d) => d());
            },
        };
    });

export interface NotificationMenuPresenterType extends Instance<typeof NotificationMenuPresenter> {}

export const NotificationsMenu: FC<NotificationsMenuPropsType> = observer(({presenter}) => {
    const router = useRouter();
    return (
        <div className={styles['drop-notifications']}>
            <Row className={styles['top']}>
                <Col span={12}>
                    <Text strong>Notifications</Text>
                </Col>
                <Col span={12}>
                    <div className="contentLink">
                        <div className="list horizontal text-right">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={styles['item']} onClick={presenter.markAllRead}>
                                Mark all as read
                            </a>
                            <Link
                                className={styles['item']}
                                to={Routes.PROFILE_SETTINGS({
                                    hash: UserProfileSettingsHashEnum.NOTIFICATION_PREFERENCES,
                                })}>
                                <SettingOutlined />
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
            <List
                dataSource={presenter.events}
                renderItem={(ev: EventType) => {
                    return (
                        <Observer>
                            {() => {
                                const settings = presenter.toSettings(ev, router);
                                return (
                                    <Only when={!!settings}>
                                        <List.Item
                                            onClick={(e: any) => {
                                                if (e.target.className.includes('ant-checkbox-input')) {
                                                    return;
                                                }
                                                settings!.onClick && settings!.onClick();
                                                presenter.markReadAndSave(ev);
                                            }}>
                                            <Alert {...settings!} showIcon />
                                        </List.Item>
                                    </Only>
                                );
                            }}
                        </Observer>
                    );
                }}
            />
            <Row className={styles['bottom']}>
                <Col span={24}>
                    <Link to={Routes.NOTIFICATIONS()}>View all notifications</Link>
                </Col>
            </Row>
        </div>
    );
});
