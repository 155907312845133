class LRUCache<T> {
    private cache: Map<string, T>;
    private order: Array<string> = [];
    constructor(private size: number) {
        this.cache = new Map();
    }

    get(key: string): T | undefined {
        const value = this.cache.get(key);
        if (!value) {
            return undefined;
        }
        if (value && this.head !== key) {
            this.moveToHead(key);
        }
        return value;
    }

    set(key: string, value: T): void {
        if (this.cache.has(key)) {
            this.moveToHead(key);
            return;
        }
        if (this.cache.size === this.size) {
            this.delete(this.tail);
        }
        this.cache.set(key, value);
        this.order.push(key);
    }

    delete(key: string): void {
        this.cache.delete(key);
        const index = this.order.indexOf(key);
        if (index >= 0) {
            this.order.splice(index, 1);
        }
    }

    has(key: string): boolean {
        return this.cache.has(key);
    }

    private get tail(): string {
        return this.order[0];
    }

    private get head(): string {
        return this.order[this.order.length - 1];
    }

    private set head(key: string) {
        this.order[this.order.length - 1] = key;
    }

    private moveToHead(key: string): void {
        const index = this.order.indexOf(key);
        if (index >= 0) {
            const last = this.head;
            this.head = this.order[index];
            this.order[index] = last;
        }
    }

    description(): void {
        console.log(JSON.stringify(this.order, null, 2));
    }
}

export default LRUCache;
