import React, {FC} from 'react';
import {FrownFilled} from '@ant-design/icons';
import {Button} from 'antd';
import {observer} from 'mobx-react-lite';
import {Link} from '@joyrideautos/web-common-components/src/router/Router';
import {FailedDepositAlertViewModel} from '@components/alerts/FailedDepositAlertViewModel';
import type {HasViewModel} from '@joyrideautos/ui-models/src/types';
import {MyVehiclesAuctionPageHashEnum, Routes} from '../../Routes';
import Alert from '@joyrideautos/web-common-components/src/components/alert/Alert';

export interface FailedDepositAlertProps extends HasViewModel<typeof FailedDepositAlertViewModel> {}

const FailedDepositAlert: FC<FailedDepositAlertProps> = observer((props) => {
    const payDepositEnabled = props.viewModel.globalConfigStore.isPayDepositEnabled;
    return (
        <Alert
            className="alert-fail"
            message={
                <>
                    <span className="xs-block">
                        <strong>
                            <FrownFilled /> Account suspended.
                        </strong>
                    </span>

                    <span className="xs-block"> Pay outstanding deposits to continue bidding. </span>
                    <span className="xs-block">
                        {payDepositEnabled && (
                            <Button danger>
                                <Link
                                    to={Routes.MY_VEHICLES_AUCTIONS({
                                        hash: MyVehiclesAuctionPageHashEnum.FAILED_DEPOSITS,
                                    })}>
                                    View My Vehicles
                                </Link>
                            </Button>
                        )}
                    </span>
                </>
            }
            type="error"
        />
    );
});

export default FailedDepositAlert;
