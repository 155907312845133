import Queue from './Queue';

export default class AsyncQueue<T> extends Queue<T> {
    private inProgress = false;

    checkQueue(): void {
        this.print();
        if (this.inProgress) {
            return;
        }
        this.inProgress = true;
        (async () => {
            while (this.queue.length > 0) {
                await this.beforeProcess(this.peek());
                await this.process(this.peek());
                await this.afterProcess(this.dequeue());
            }
            this.inProgress = false;
        })().catch((e) => console.log(e));
    }

    async beforeProcess(item: T | undefined): Promise<void> {
        this.logger?.log('before process', this.stringify(item));
    }

    async afterProcess(item: T | undefined): Promise<void> {
        this.logger?.log('after process', this.stringify(item));
    }

    // Should be overridden in subclasses
    async process(item: T | undefined): Promise<void> {
        this.logger?.log('process', this.stringify(item));
    }
}
