export type Unsubscribe = () => void;
export type Subscribe = () => Unsubscribe | undefined;

const counters: Record<string, number> = {};
const unsubscribers: Record<string, Unsubscribe | undefined> = {};

const logger = {
    log(...args: any) {
        //console.log('[subscriptions]', args);
    },
};

export const makeSubscriber = (key: string, subsribe: Subscribe) => {
    if (typeof counters[key] === 'undefined') {
        counters[key] = 0;
    }

    return (): (() => number) => {
        counters[key]++;
        if (counters[key] === 1) {
            logger.log('subscribe', key);
            unsubscribers[key] = subsribe();
        } else {
            logger.log('increase counter', key, counters[key], typeof unsubscribers[key]);
        }
        return () => {
            if (counters[key] === 0) {
                return 0;
            }
            counters[key]--;
            logger.log(`decrease counter`, key, counters[key], typeof unsubscribers[key]);
            if (counters[key] === 0 && unsubscribers[key]) {
                logger.log('unsubscribe. there are no subscribers for', key);
                unsubscribers[key]!();
                unsubscribers[key] = undefined;
            }
            return counters[key];
        };
    };
};

export const makeSubscribers = (name: string, subscribers: {[key: string]: Subscribe}) => {
    return () => {
        const unsubscribers = Object.keys(subscribers).map((key) =>
            makeSubscriber(`${key}-${name}`, subscribers[key])()
        );
        return () => {
            unsubscribers.forEach((u) => u());
        };
    };
};

export const makeUnsubscriber = () => {
    let _disposer: (() => void) | undefined;
    return (disposer?: () => void) => {
        _disposer && _disposer();
        _disposer = disposer;
    };
};
