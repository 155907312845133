import {ONE_MIN, ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import type {AuctionType} from '../../types/auction/Auction';
import type {ItemType} from '../../types/item/Item';
import {WinningBidInfo} from '../../types/WinningBid';
import type {WinningBidContainerType} from '../../stores/WinningBidStore';
import {Timer} from './Timer';
import {ClockModelType} from '../../utils/Clock';
import type {BidService} from '@joyrideautos/ui-services/src/services/BidService';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';

export const CHECK_ITEM_BIDDING_ENDED_INTERVAL = ONE_SEC;

export const END_BIDDING_INTERMISSION_TIME_BETWEEN_SELLERS_IN_MS = 60 * ONE_SEC;
export const ENDED_RECENTLY_TIME_IN_MS = 5 * ONE_MIN;
export const BIDDING_SHOWING_RESULT_TIME_IN_MS = 2 * ONE_SEC;
export const BIDDING_CHECKING_RESULT_TIME_IN_MS = 2 * ONE_SEC;

export type SequenceAuctionSMPresenterType = {
    auction: AuctionType;
    startItemKey: string | undefined;
    currentItem: ItemType | undefined;
    lastWinningBid: WinningBidInfo | undefined;
    makeNextItem: () => ItemType | undefined;
    currentWinningBid: WinningBidInfo | undefined;
    previousWinningBid: WinningBidInfo | undefined;
    countDownTimer: Timer;
    winningBidContainer: WinningBidContainerType | undefined;
    startPreBidTime: number;
    startAuctionTime: number;
    startAuctionRemainder: number;
    startAuctionRemainderInMs: number;
    timeUntilAuctionStart: number;
    timeUntilAuctionStartInMs: number;
    timeUntilItemBidEndsInMs: number;
    isEmpty: boolean;
    endBiddingIntermissionTimeInMs: number;
    clock: ClockModelType;
};

export type ListingAuctionSMPresenterType = {
    auction: AuctionType;
    countDownTimer: Timer;
    winningBidContainer: WinningBidContainerType | undefined;
    startAuctionTime: number;
    startAuctionRemainder: number;
    startAuctionRemainderInMs: number;
    endAuctionTime: number;
    earliestItemEndTime: number;
    earliestWinningBid: WinningBidInfo | undefined;
    isEmpty: boolean;
    clock: ClockModelType;
    areAllResultsEnded: boolean;
};

export interface SequenceAuctionStateMachineContext {
    presenter: SequenceAuctionSMPresenterType;
    services: {
        bidService: BidService;
    };
    logger?: Logger;
}

export interface ListingAuctionStateMachineContext {
    presenter: ListingAuctionSMPresenterType;
    services: {
        bidService: BidService;
    };
    logger?: Logger;
}

export enum SequenceAuctionStateEnum {
    BEFORE_PRE_BID = 'beforePreBid', // Auction is not started, bids are not allowed
    PRE_BID = 'preBid', // Auction is not started, bids are allowed
    BEFORE_START = 'beforeStart', // Auction start countdown activated
    BIDDING = 'bidding', // Bidding on certain item is in progress
    AFTER_BIDDING = 'afterBidding', // Countdown timer for bidding is finished but we are waiting for the server to determine whether bidding if finished or bidding time is extended
    BIDDING_RESULT = 'biddingResult', // Showing bidding result (won, sold, unsold) for the last item to the user
    BEFORE_BIDDING = 'beforeBidding', // Showing intermission dialog with countdown between items
    ENDED_RECENTLY = 'endedRecently', // Auction is ended recently
    ENDED = 'ended', // Auction is ended long time ago
    PAUSED = 'paused', // Auction is on pause (countdown timer stopped, bids are accepted)
    SUSPENDED = 'suspended', // Auction is suspended (countdown timer stopped, bids are not accepted)
    ERROR = 'error', // An unexpected error caught
    EMPTY = 'empty', // Auction has no items.
}

export enum ListingAuctionStateEnum {
    BEFORE_START = 'beforeStart', // Auction is not started, bids are not allowed
    BIDDING = 'bidding', // Bidding on all items is in progress
    AFTER_BIDDING = 'afterBidding', // Countdown timer for bidding is finished but we are waiting for the server to determine whether bidding if finished or bidding time is extended
    ENDED_RECENTLY = 'endedRecently', // Auction is ended recently
    ENDED = 'ended', // Auction is ended long time ago
    SUSPENDED = 'suspended', // Auction is suspended (countdown timer stopped, bids are not accepted)
    ERROR = 'error', // An unexpected error caught
    EMPTY = 'empty', // Auction has no items.
}

export interface SequenceAuctionStateMachineSchema {
    states: {[key in SequenceAuctionStateEnum]: any};
}

export interface ListingAuctionStateMachineSchema {
    states: {[key in ListingAuctionStateEnum]: any};
}

export type SequenceAuctionStateMachineEvent =
    // beforePreBid
    | {type: 'BEFORE_PREBID_NEXT'}
    | {type: 'BEFORE_PREBID_CHECK_EMPTY'}
    | {type: 'BEFORE_PREBID_ERROR'}
    // preBid
    | {type: 'PREBID_NEXT'}
    // beforeStart
    | {type: 'BEFORE_START_NEXT'}
    // bidding
    | {type: 'BIDDING_END'}
    | {type: 'BIDDING_CHECK_IF_ENDED'}
    // afterBidding
    | {type: 'AFTER_BIDDING_END'}
    | {type: 'AFTER_BIDDING_START'}
    // biddingResult
    | {type: 'BIDDING_RESULT_NEXT'}
    // beforeBidding
    | {type: 'BEFORE_BIDDING_START'}
    // endedRecently
    | {type: 'ENDED_RECENTLY_AUCTION_END'}
    | {type: 'ENDED_RECENTLY_NEXT'}
    // ended
    | {type: 'ENDED_NEXT'}
    // paused/suspended
    | {type: 'PAUSE'}
    | {type: 'SUSPEND'}
    | {type: 'RESUME'}
    // error
    | {type: 'ERROR'};

export type ListingAuctionStateMachineEvent =
    | {type: 'SUSPEND'}
    | {type: 'RESUME'}
    | {type: 'START_BIDDING'}
    | {type: 'END_BIDDING'}
    | {type: 'CHECK_BIDDING_ENDED'}
    | {type: 'END_AUCTION'}
    | {type: 'ENDED_RECENTLY'};
