export default class RoutingService<RequestHandler extends string> {
    constructor(
        public urlByHandler: {[key in RequestHandler]: string | undefined},
        public fallbackHandler: RequestHandler,
        // handlersByRoute contains only overrides; other routes will be handled by the default request handler
        public handlersByRoute: {[key: string]: RequestHandler} = {}
    ) {}

    getUrlForRoute(route: string) {
        const handler = this.getRequestHandlerForRoute(route);
        const handlerURL = this.getRequestHandlerUrl(handler);

        if (!handlerURL) {
            throw new Error(`Url for handler ${handler} and route ${route} is not configured`);
        }

        // TODO (Future): join the urls with special libs to make it safer in case of trailing slash is present
        return `${handlerURL}/${route}`;
    }

    getRequestHandlerForRoute(route: string): RequestHandler {
        const expectedHandlerForRoute = this.handlersByRoute[route] || this.getDefaultRequestHandlerFromEnv();

        return expectedHandlerForRoute && this.isRequestHandlerEnabled(expectedHandlerForRoute)
            ? expectedHandlerForRoute
            : this.fallbackHandler;
    }

    isRequestHandlerEnabled(handler: RequestHandler) {
        return !!this.getRequestHandlerUrl(handler);
    }

    getRequestHandlerUrl(handler: RequestHandler): string | undefined {
        return this.urlByHandler[handler];
    }

    getDefaultRequestHandlerFromEnv(): RequestHandler | undefined {
        const handler = process.env.REACT_APP_DEFAULT_REQUEST_HANDLER as RequestHandler | undefined;
        const expectedValues = Object.keys(this.urlByHandler);
        if (handler && !expectedValues.includes(handler)) {
            throw new Error(
                `Can not detect request handler name. Expected one of [${expectedValues}], but got ${handler} in env variable REACT_APP_DEFAULT_REQUEST_HANDLER`
            );
        }
        return handler;
    }
}
