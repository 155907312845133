import {HomeOutlined} from '@ant-design/icons';
import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';

export const createHomeMenu = () => {
    const homeMenu = {
        label: <NavLink to={Routes.HOME()}>Home</NavLink>,
        key: 'home',
        icon: <HomeOutlined />,
    };

    return homeMenu;
};
