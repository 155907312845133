const VALIDATION_PATTERN = /^[2-9]\d{9}$/;
const ALLOWED_SYMBOLS_PATTERN = /[-: \\.x()]/g;
const PARSE_PATTERN = /\D/g;

type Nullable<T> = T | null | undefined;

export function format(phoneNumber: Nullable<string>): Nullable<string> {
    const value = parse(phoneNumber);
    let result = value;

    if (value) {
        if (value.length === 10) {
            result = `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(6)}`;
        } else if (value.length === 11) {
            result = `${value.substring(0, 1)}-${value.substring(1, 4)}-${value.substring(4, 7)}-${value.substring(7)}`;
        }
    }

    return result;
}

export function applyRegionCountryCodeIfNeeded(
    phoneNumber: Nullable<string>,
    regionCountryCode: string | undefined | null
): Nullable<string> {
    const value = parse(phoneNumber);
    let result = value;

    if (value && value.length <= 10 && regionCountryCode) {
        result = `${regionCountryCode}${value}`;
    }

    return result;
}

export function parse(phoneNumber: Nullable<string>): Nullable<string> {
    return phoneNumber ? phoneNumber.replace(PARSE_PATTERN, '') : phoneNumber;
}

export function isValid(phoneNumber: string) {
    const value = phoneNumber.replace(ALLOWED_SYMBOLS_PATTERN, '');
    return Boolean(value.match(VALIDATION_PATTERN));
}
