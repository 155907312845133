import {getRoot, IAnyStateTreeNode, Instance, SnapshotIn, types} from 'mobx-state-tree';
import type {ItemType} from './item/Item';
import {ItemReferenceForAuction, PersistedItemReference} from './item/Refs';
import moment from 'moment';
import {
    BidRejectionReasonEnum as BidRejectionReason,
    BidTypeEnum,
    Bid as BidDto,
    BidInfo as BidInfoDto,
} from '@joyrideautos/auction-core/src/types/Bid';
import {ItemPath} from '@joyrideautos/auction-core/src/types/Item';
import {ClockModelType} from '../utils/Clock';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';

const PENDING_KEY = 'pending';

export const BidRejectionReasonEnum = types.enumeration('BidRejectionReason', [
    BidRejectionReason.NO_ACCESS,
    BidRejectionReason.NO_MANAGER_BIDS,
    BidRejectionReason.BIDDING_NOT_STARTED,
    BidRejectionReason.BIDDING_ENDED,
    BidRejectionReason.WRONG_AMOUNT,
    BidRejectionReason.AUCTION_SUSPENDED,
    BidRejectionReason.MANAGER_REJECTED,
    BidRejectionReason.OUTBID_BY_AUTOBID,
    BidRejectionReason.INTERNAL_ERROR,
    BidRejectionReason.FEES_EXCEED_TOTAL_BID,
    BidRejectionReason.ALREADY_WINNING,
    BidRejectionReason.BIDDING_LIMIT,
]);

export const ItemBidderNumber = types.model('ItemBidderNumber', {
    value: types.number,
    userName: types.string,
    sellerUserName: types.maybe(types.string),
    email: types.maybe(types.string),
    byManager: types.boolean,
});

export const BidInfo = types.model('BidInfo', {
    userId: types.string,
    timestamp: types.string,
    bidderNumber: types.maybe(ItemBidderNumber),
    accepted: types.maybeNull(types.boolean),
    acceptedBy: types.maybe(types.string),
    acceptedAt: types.maybe(types.string),
    rejectedBy: types.maybe(types.string),
    rejectedAt: types.maybe(types.string),
    reason: types.maybe(BidRejectionReasonEnum),
});

export interface BidInfoType extends Instance<typeof BidInfo> {}

export const BID_REJECTION_REASON_MESSAGES: {[reason in BidRejectionReason]: string} = {
    [BidRejectionReason.NO_ACCESS]: 'No access',
    [BidRejectionReason.NO_MANAGER_BIDS]: 'No manager bids',
    [BidRejectionReason.BIDDING_NOT_STARTED]: 'Not started',
    [BidRejectionReason.BIDDING_ENDED]: 'Ended',
    [BidRejectionReason.WRONG_AMOUNT]: 'Wrong amount',
    [BidRejectionReason.AUCTION_SUSPENDED]: 'Auction suspended',
    [BidRejectionReason.MANAGER_REJECTED]: 'Manager Rejected',
    [BidRejectionReason.OUTBID_BY_AUTOBID]: 'Outbid by auto bid',
    [BidRejectionReason.INTERNAL_ERROR]: 'Server error',
    [BidRejectionReason.FEES_EXCEED_TOTAL_BID]: 'Fees exceed total bid',
    [BidRejectionReason.ALREADY_WINNING]: 'Already Winning',
    [BidRejectionReason.BIDDING_LIMIT]: 'Bidding limit',
};

export const Bid = types
    .model('Bid', {
        key: types.identifier,
        regionId: types.string,
        auctionId: types.string,
        uid: types.string,
        item: types.maybe(ItemReferenceForAuction()),
        persistedItem: types.maybe(PersistedItemReference()),
        amount: types.number,
        autobidId: types.maybe(types.string),
        bidInfo: types.maybe(BidInfo),
        paddleNumber: types.maybe(types.string),
        type: types.maybe(types.enumeration(Object.values(BidTypeEnum))),
        createdAt: types.maybe(types.number),
    })
    .views((self) => ({
        get rootStore() {
            return getRoot(self) as any;
        },
        get clock(): ClockModelType {
            return this.rootStore.clock;
        },
    }))
    .actions((self) => {
        return {
            setBidInfo(bidInfo: BidInfoType) {
                self.bidInfo = bidInfo;
            },
        };
    })
    .views((self) => ({
        get isPending() {
            return !self.bidInfo || self.bidInfo.accepted === undefined;
        },
        timeAgo(now?: number) {
            return self.bidInfo
                ? moment
                      .duration(moment(now || Date.now()).diff(moment(self.clock.parseDate(self.bidInfo.timestamp))))
                      .asMilliseconds()
                : NaN;
        },
        get rejectionReason() {
            return self.bidInfo && self.bidInfo.reason && BID_REJECTION_REASON_MESSAGES[self.bidInfo.reason];
        },
    }));

export function createPendingBid(amount: number, uid: string, item: ItemPath) {
    const now = Date.now();
    return {
        key: PENDING_KEY,
        item: item.itemId, // mst reference
        uid,
        regionId: item.regionId,
        auctionId: item.auctionId,
        amount,
        bidInfo: {
            timestamp: new Date(now).toISOString(),
            userId: uid,
        },
        createdAt: now,
    };
}

export interface BidType extends Instance<typeof Bid> {}

export interface SnapshotInBidType extends SnapshotIn<typeof Bid> {}

export const BidReference = types.reference(Bid, {
    get(identifier: string, parent: IAnyStateTreeNode & {item: ItemType}): any {
        const item = parent.itemDataAsync;
        if (item) {
            const bidsStore = ((parent.rootStore || getRoot(parent)) as any).bidsStore;
            let bidsContainer = bidsStore.containerFor(item);
            if (!bidsContainer) {
                bidsStore.addContainersForItems([item]);
                bidsContainer = bidsStore.containerFor(item);
                bidsContainer.setItem(item);
            }
            return bidsContainer.getBid(identifier);
        }
    },

    set(value: BidType): string {
        return value.key;
    },
});

export function fromBidDto(bid: BidDto, isManager: boolean): SnapshotInBidType {
    if (bid.bidInfo?.bidderNumber && !isManager) {
        delete bid.bidInfo.bidderNumber.email;
    }
    return cast<SnapshotInBidType>(bid);
}

export function mapFromBidDto(dtos: Map<string, BidDto>, isManager: boolean): Map<string, SnapshotInBidType> {
    return Array.from(dtos.keys()).reduce<Map<string, SnapshotInBidType>>((bids, key) => {
        bids.set(key, fromBidDto(dtos.get(key)!, isManager));
        return bids;
    }, new Map());
}

export function fromBidInfoDto(bidInfo: BidInfoDto, isManager: boolean): BidInfoType {
    if (bidInfo.bidderNumber && !isManager) {
        delete bidInfo.bidderNumber.email;
    }
    return bidInfo as BidInfoType;
}
