import {Event} from '@joyrideautos/ui-models/src/types/events/Event';
import {NotificationState} from '@joyrideautos/auction-core/src/types/events/notifications';
import {subscribeAsyncModels} from '@joyrideautos/ui-models/src/SubscribeAsyncModels';
import {mapPersistedNotificationDtoToModel} from './mappers';

export const createLastPersistedNotificationsViewModel = (state: NotificationState, limit: number) =>
    subscribeAsyncModels.array(Event, (self, events, disposers) =>
        self.authUserStore.subscribeToUserChanged(({before, after}) => {
            if (after.user?.isAnonymous) {
                events.onReset();
                return;
            }
            const isChanged = before.user?.uid !== after.user?.uid;
            if (!after.user?.uid) {
                events.onReset();
            } else if (isChanged) {
                events.onReset();
                disposers.push(
                    self.rootStore.eventsService.subscribeToPersistedNotifications(
                        after.user?.uid,
                        state,
                        (notifications) => {
                            events.onValues(
                                notifications.map(mapPersistedNotificationDtoToModel).filter(Event.is.bind(Event))
                            );
                        },
                        limit
                    )
                );
            }
        })
    );
