import {Notification} from '@joyrideautos/auction-core/src/types/events/notifications';
import {subscribeAsyncModels} from '@joyrideautos/ui-models/src/SubscribeAsyncModels';
import {fetchAsyncModels} from '@joyrideautos/ui-models/src/FetchAsyncModels';
import {when} from 'mobx';

export const createSubscribeToPersistedNotificationsCountViewModel = (
    filters?: {
        name: keyof Notification;
        value: Notification[keyof Notification] | Notification[keyof Notification][];
    }[],
    limit?: number
) =>
    subscribeAsyncModels.primitives<number>(0, (self, events, disposers) =>
        self.authUserStore.subscribeToUserChanged(({before, after}) => {
            if (after.user?.isAnonymous) {
                events.onReset();
                return;
            }
            const isChanged = before.user?.uid !== after.user?.uid;
            if (!after.user?.uid) {
                events.onReset();
            } else if (isChanged) {
                events.onReset();
                disposers.push(
                    self.rootStore.eventsService.subscribeToPersistedNotificationsCount(
                        after.user?.uid,
                        {
                            filters: filters ?? [],
                            limit,
                        },
                        events.onValue
                    )
                );
            }
        })
    );

export const createFetchPersistedNotificationsCountViewModel = (
    filters?: {
        name: keyof Notification;
        value: Notification[keyof Notification] | Notification[keyof Notification][];
    }[]
) =>
    fetchAsyncModels.primitives<number>(0, (self) => ({
        action: () =>
            when(() => !!self.authUserStore.userInfo).then(() =>
                self.rootStore.eventsService.fetchPersistedNotificationsCount(
                    self.authUserStore.userInfo!.uid,
                    filters ?? []
                )
            ),
    }));
