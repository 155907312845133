import {types} from 'mobx-state-tree';
import {AddressState, AddressStateType} from '@joyrideautos/ui-models/src/types/UserInfo';
import {LoadingStatus} from '@joyrideautos/ui-models/src/utils/LoadingStatus';
import ValidationBuilder, {
    declarativeValidator,
    requiredFieldValidator,
} from '@joyrideautos/ui-models/src/smartFields/validators/Validators';
import {InputFieldModel} from '@joyrideautos/ui-models/src/smartFields/models/InputFieldModel';
import {DropdownModelFactory, withDropdownStatus} from '@joyrideautos/ui-models/src/smartFields/models/DropdownModel';
import {renderAddressState} from '@joyrideautos/ui-models/src/smartFields/renderDropdownItems';
import stripeJs from '@stripe/stripe-js';
import {CardNumberElement} from '@stripe/react-stripe-js';
import {getStripeErrorMessage} from '@joyrideautos/auction-core/src/utils/stripeHelpers';
import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {PAYMENT_FIELDS_LENGTH} from '@joyrideautos/auction-core/src/types/Payments';

export const StripeCardFormViewModel = BaseViewModel.named('PaymentFormViewModel')
    .props({
        paymentMethodName: types.optional(types.string, ''),
        billingName: types.optional(types.string, ''),
        billingAddress1: types.optional(types.string, ''),
        billingCity: types.optional(types.string, ''),
        billingState: types.maybe(AddressState),
        zipcode: types.optional(types.string, ''),
    })
    .views((self) => ({
        get addressStates(): Promise<AddressStateType[]> {
            return self.rootStore.regionsService.fetchAllStates().then((allStates) =>
                Object.keys(allStates).map((code) => ({
                    code: code,
                    name: allStates[code],
                }))
            );
        },
    }))
    .volatile(() => ({
        billingStateStatus: new LoadingStatus(),
        paymentMethodNameValidator: declarativeValidator(
            `required|less_than:${PAYMENT_FIELDS_LENGTH.PAYMENT_METHOD_NAME}`
        ),
        billingNameValidator: declarativeValidator('required'),
        billingAddress1Validator: declarativeValidator('required'),
        billingCityValidator: declarativeValidator('required'),
        billingStateValidator: declarativeValidator('required'),
        zipcodeValidator: requiredFieldValidator('Zipcode', 'Required'),
        replaceCardId: undefined as string | undefined,
    }))
    .volatile((self) => ({
        paymentMethodNameConnector: new InputFieldModel(self.paymentMethodNameValidator, true),
        billingNameConnector: new InputFieldModel(self.billingNameValidator, true),
        billingAddress1Connector: new InputFieldModel(self.billingAddress1Validator, true),
        billingCityConnector: new InputFieldModel(self.billingCityValidator, true),
        billingStateConnector: new DropdownModelFactory<AddressStateType>(
            withDropdownStatus(self.addressStates),
            renderAddressState,
            'Error loading states',
            self.billingStateValidator,
            true
        ),
        zipcodeConnector: new InputFieldModel(self.zipcodeValidator, true),
    }))
    .actions((self) => {
        return {
            setPaymentMethodName(name?: string) {
                self.paymentMethodName = name || '';
            },

            setBillingName(name?: string) {
                self.billingName = name || '';
            },
            setBillingAddress1(addr1?: string) {
                self.billingAddress1 = addr1 || '';
            },
            setBillingCity(city?: string) {
                self.billingCity = city || '';
            },
            setBillingState(value?: AddressStateType) {
                self.billingState = value;
                self.billingStateStatus = new LoadingStatus();
            },
            setZipcode(code?: string) {
                self.zipcode = code || '';
            },
            resetForm() {
                this.setPaymentMethodName();
                self.paymentMethodNameConnector.reset();
                this.setBillingName();
                self.billingNameConnector.reset();
                this.setBillingAddress1();
                self.billingAddress1Connector.reset();
                this.setBillingCity();
                self.billingCityConnector.reset();
                this.setBillingState();
                self.billingStateConnector.reset();
                this.setZipcode();
                self.zipcodeConnector.reset();
            },
        };
    })
    .views((self) => {
        return {
            get paymentMethodNameModel() {
                return self.paymentMethodNameConnector.connect(self.paymentMethodName, self.setPaymentMethodName);
            },
            get billingNameModel() {
                return self.billingNameConnector.connect(self.billingName, self.setBillingName);
            },
            get billingAddress1Model() {
                return self.billingAddress1Connector.connect(self.billingAddress1, self.setBillingAddress1);
            },
            get billingCityModel() {
                return self.billingCityConnector.connect(self.billingCity, self.setBillingCity);
            },
            get billingStateModel() {
                return self.billingStateConnector.connect(self.billingState, self.setBillingState);
            },
            get zipcodeModel() {
                return self.zipcodeConnector.connect(self.zipcode, self.setZipcode);
            },
            get isValid() {
                return new ValidationBuilder()
                    .add(this.paymentMethodNameModel, self.paymentMethodNameValidator, self.paymentMethodName)
                    .add(this.billingNameModel, self.billingNameValidator, self.billingName)
                    .add(this.billingAddress1Model, self.billingAddress1Validator, self.billingAddress1)
                    .add(this.billingCityModel, self.billingCityValidator, self.billingCity)
                    .add(this.billingStateModel, self.billingStateValidator, self.billingState)
                    .add(this.zipcodeModel, self.zipcodeValidator, self.zipcode)
                    .validate(true);
            },
        };
    })
    .actions((self) => {
        let formHandler: (() => Promise<void>) | null = null;

        return {
            makeFormHandler(stripeJs: stripeJs.Stripe, elements: stripeJs.StripeElements) {
                formHandler = async () => {
                    if (!self.isValid) {
                        throw new Error('Please correct errors on the form');
                    }
                    const clientSecret: string | undefined =
                        await self.rootStore.paymentService.createPaymentMethodSetupIntent();
                    if (!clientSecret) {
                        throw new Error('Error adding card. Please try again');
                    }
                    const result = await stripeJs.confirmCardSetup(clientSecret, {
                        payment_method: {
                            card: elements.getElement(CardNumberElement)!,
                            billing_details: {
                                name: self.billingName,
                                address: {
                                    line1: self.billingAddress1,
                                    city: self.billingCity,
                                    state: self.billingState!.code,
                                    postal_code: self.zipcode,
                                },
                            },
                        },
                    });
                    if (result.error) {
                        console.error(result.error);
                        const stripeError = getStripeErrorMessage(result.error);
                        throw new Error(stripeError);
                    }
                    await self.rootStore.paymentService.addPaymentMethod({
                        paymentMethodId:
                            (result.setupIntent.payment_method as stripeJs.PaymentMethod)?.id ||
                            (result.setupIntent.payment_method as string),
                        paymentMethodName: self.paymentMethodName,
                    });
                };
            },
            saveForm(): Promise<void> {
                if (!formHandler) {
                    throw new Error('missing form handler, probably because of useStripeCardForm');
                }
                return formHandler();
            },
        };
    });
