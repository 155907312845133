import {action, computed, makeObservable, observable} from 'mobx';
import {authenticationErrors} from './errors';

export enum LoadingStatusEnum {
    NEW = 'New',
    IN_PROGRESS = 'In Progress',
    READY = 'Ready',
    ERROR = 'Error',
}

const loadingStatusEnumStringValues: {[key in LoadingStatusEnum]: string} = {
    [LoadingStatusEnum.NEW]: 'NEW',
    [LoadingStatusEnum.IN_PROGRESS]: 'IN_PROGRESS',
    [LoadingStatusEnum.READY]: 'READY',
    [LoadingStatusEnum.ERROR]: 'ERROR',
};

export class LoadingStatus {
    private name: string;

    constructor(status: LoadingStatusEnum = LoadingStatusEnum.NEW, name = '') {
        this.name = name;
        this.status = status;
        makeObservable(this);
    }

    @observable
    private status: LoadingStatusEnum;
    @observable
    private error = '';

    @action
    setNew() {
        if (!this.isNew) {
            this.status = LoadingStatusEnum.NEW;
            this.error = '';
        }
        return this;
    }

    @computed
    get isInProgress() {
        return this.status === LoadingStatusEnum.IN_PROGRESS;
    }

    @action
    setInProgress() {
        if (!this.isInProgress) {
            this.status = LoadingStatusEnum.IN_PROGRESS;
            this.error = '';
        }
        return this;
    }

    @computed
    get isReady() {
        return this.status === LoadingStatusEnum.READY;
    }

    @action
    setReady() {
        if (!this.isReady) {
            this.status = LoadingStatusEnum.READY;
            this.error = '';
        }
        return this;
    }

    @computed
    get isError() {
        return this.status === LoadingStatusEnum.ERROR;
    }

    @action
    setError(error: any) {
        if (this.error !== error) {
            this.status = LoadingStatusEnum.ERROR;
            this.error = error;
            console.error(error);
        }
        return this;
    }

    getError() {
        return this.error;
    }

    @computed
    get isNew() {
        return this.status === LoadingStatusEnum.NEW;
    }

    toString(): string {
        return `${this.name} - ${loadingStatusEnumStringValues[this.status]}`;
    }
}

export function withStatus(status: LoadingStatus) {
    return <T>(callback: (status: LoadingStatus) => Promise<T>): Promise<T> => {
        status.setInProgress();
        return callback(status)
            .then((data) => {
                status.setReady();
                return Promise.resolve(data);
            })
            .catch((error) => {
                const errorCode = error?.code;

                let errorMessage = '';
                if (errorCode && authenticationErrors[errorCode]) {
                    errorMessage = authenticationErrors[errorCode];
                } else {
                    errorMessage = error.response
                        ? typeof error.response.data === 'string'
                            ? error.response.data
                            : error.response.data.message || error.response.data.toString()
                        : error.details || error.message || error.toString();
                }
                status.setError(errorMessage);

                return Promise.reject(error);
            });
    };
}
