import React, {FC} from 'react';
import {Alert} from 'antd';

export interface SWNewVersionProps {}

const SWNewVersionAlert: FC<SWNewVersionProps> = () => {
    return (
        <Alert
            message={'New content is available and will be used when all tabs for this page are closed.'}
            type="warning"
            showIcon
        />
    );
};

export default SWNewVersionAlert;
