import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './i18n/en.json';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    resources: {
        en: {
            translation: en,
        },
    },
});

export default i18n;
