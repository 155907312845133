import React, {FC} from 'react';
import {Button} from 'antd';
import {observer} from 'mobx-react-lite';
import type {HasViewModel} from '@joyrideautos/ui-models/src/types';
import {CCHoldFailedAlertViewModel} from './CCHoldFailedAlertViewModel';
import {Routes} from '../../Routes';
import {useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import Alert from '@joyrideautos/web-common-components/src/components/alert/Alert';

export interface CCHoldFailedProps extends HasViewModel<typeof CCHoldFailedAlertViewModel> {}

const CCHoldFailedAlert: FC<CCHoldFailedProps> = observer(({viewModel: {auctionTitle, failedCcHoldAuction}}) => {
    const {push} = useRouter();
    return (
        <Alert
            className="alert-fail"
            message={
                <>
                    <strong>$100 hold failed </strong>
                    Complete hold authorization to continue bidding in <b>{auctionTitle}</b>
                    <span className="xs-block ml-5">
                        <Button
                            danger
                            type="primary"
                            onClick={() =>
                                push(
                                    Routes.AUCTION_OCCURRENCE({
                                        pathParams: {
                                            regionId: failedCcHoldAuction!.regionId,
                                            auctionId: failedCcHoldAuction!.auctionId,
                                        },
                                    })
                                )
                            }>
                            View auction here
                        </Button>
                    </span>
                </>
            }
            type="error"
            showIcon
        />
    );
});

export default CCHoldFailedAlert;
