import {CodeAndName, sorting} from '../../types/CodeAndName';
import BaseViewModel from '../../BaseViewModel';
import {cast, flow, isAlive, types} from 'mobx-state-tree';
import {LoadingStatus} from '../../utils/LoadingStatus';
import {CodeAndName as CodeAndNameDTO} from '@joyrideautos/auction-core/src/types/CodeAndName';
import {CancelableError, makeCancelable} from '@joyrideautos/auction-utils/src/cancelable';
import {logError} from '@joyrideautos/ui-logger/src/utils';

export const createRefsCodeAndName = <T extends {active?: boolean} = CodeAndNameDTO>(
    fetchRefs: (rootStore: any) => () => Promise<T[]>,
    mapper?: (value: T) => CodeAndNameDTO
) =>
    types.optional(
        BaseViewModel.named('RefsCodeAndName')
            .props({
                cache: types.map(CodeAndName),
            })
            .volatile(() => ({
                loadingStatus: new LoadingStatus(),
            }))
            .views((self) => ({
                get sorted() {
                    return Array.from(self.cache.values()).sort(sorting('name'));
                },
            }))
            .actions((self) => {
                const fetchCodeAndNameRefsCancelable = makeCancelable<T[]>('fetch refs');
                return {
                    loadAllRefs: flow(function* () {
                        const {isReady, isInProgress} = self.loadingStatus;
                        if (!isAlive(self) || isReady || isInProgress) {
                            return self.sorted;
                        }
                        try {
                            self.loadingStatus.setInProgress();
                            const allRefs: T[] = yield fetchCodeAndNameRefsCancelable(fetchRefs(self.rootStore), {
                                abort: () => !isAlive(self),
                            });
                            if (!isAlive(self)) {
                                return self.sorted;
                            }
                            self.cache = cast(
                                allRefs
                                    .filter(({active}) => active ?? true)
                                    .map((value) => (mapper ? mapper(value) : (value as unknown as CodeAndNameDTO)))
                                    .reduce<Record<string, CodeAndNameDTO>>((refs, ref) => {
                                        refs[ref.code] = ref;
                                        return refs;
                                    }, {})
                            );
                            self.loadingStatus.setReady();
                        } catch (e: any) {
                            if (e instanceof CancelableError) {
                                self.logger.log(e.message);
                            } else {
                                self.logger.error(e);
                                self.loadingStatus.setError(e.message);
                            }
                        }
                        return self.sorted;
                    }),
                };
            })
            .views((self) => ({
                get refs() {
                    if (self.loadingStatus.isNew) {
                        self.loadAllRefs().catch(logError());
                    }
                    return self.sorted;
                },
                get byCode() {
                    if (self.loadingStatus.isNew) {
                        self.loadAllRefs().catch(logError());
                    }
                    return self.cache;
                },
            })),
        {}
    );
