import {observable} from 'mobx';
import {destroy, IAnyModelType, IModelType, Instance, ModelProperties} from 'mobx-state-tree';
import {SingletonViewModelCache} from './SingletonViewModelCache';
import LoggerAwareViewModel from '../types/LoggerAwareViewModel';

const SingletonAwareViewModel = LoggerAwareViewModel.named('SingletonAwareViewModel')
    .volatile(() => ({
        singletonViewModels: observable.map<string, SingletonViewModelCache<Instance<IAnyModelType>>>(),
        cachedViewModels: observable.map<string, Instance<IAnyModelType>>(),
    }))
    .views((self) => ({
        getSingletonViewModel<T extends IModelType<ModelProperties, any, any, any>>(name: string) {
            return self.singletonViewModels.get(name)?.viewModel as T['Type'] | undefined;
        },
    }))
    .actions((self) => ({
        destroyViewModel<T extends IAnyModelType>(model: T) {
            const existingModel = self.cachedViewModels.get(model.name);
            if (existingModel) {
                requestAnimationFrame(() => {
                    destroy(existingModel);
                });
            }
        },
    }))
    .views((self) => {
        return {
            getOrCreateViewModel<T extends IAnyModelType>(
                model: T,
                snapshot?: T['CreationType'],
                env?: any,
                options?: {isSingleton?: boolean}
            ): T['Type'] {
                if (options?.isSingleton) {
                    const key = `${model.name}-${JSON.stringify(snapshot)}`;
                    let instance = self.cachedViewModels.get(key);
                    if (!instance) {
                        instance = model.create(snapshot, env);
                        self.cachedViewModels.set(key, instance);
                    }
                    return instance;
                } else {
                    self.destroyViewModel(model);
                    const newModel = model.create(snapshot, env);
                    self.cachedViewModels.set(model.name, newModel);
                    return newModel;
                }
            },
        };
    })
    .actions((self) => ({
        addSingletonViewModel(name: string, viewModel: Instance<IAnyModelType> | (() => Instance<IAnyModelType>)) {
            let holder = self.singletonViewModels.get(name);
            if (!holder) {
                holder = new SingletonViewModelCache<any>(name);
                self.singletonViewModels.set(name, holder);
            }
            if (typeof viewModel === 'function') {
                holder.addOrReplaceViewModel(viewModel);
            } else {
                holder.addOrReplaceViewModel(() => viewModel);
            }
        },
        removeSingletonViewModel(name: string) {
            const holder = self.singletonViewModels.get(name);
            if (!holder) {
                return;
            }
            self.singletonViewModels.delete(name);
            holder.destroy();
        },
        beforeDestroy() {
            self.singletonViewModels.forEach((holder) => holder.destroy());
            Array.from(self.cachedViewModels.values()).forEach((model) => requestAnimationFrame(() => destroy(model)));
        },
    }));

export default SingletonAwareViewModel;
