import {IDisposer, Instance, cast, types} from 'mobx-state-tree';
import {GlobalConfig} from '@joyrideautos/auction-core/src/types/GlobalConfig';
import {isAnonymous} from '@joyrideautos/auction-core/src/types/User';
import BaseStore from './BaseStore';
import {reaction, when} from 'mobx';
import primitives from '../Primitives';

const DEFAULT_DURATION_IN_SEC = 4;

const PayInFullConfigModel = types.model({
    passThroughFeePercent: types.maybe(types.number),
    balanceThresholdCents: types.maybe(
        types.model({
            vip: types.maybe(types.number),
            trusted: types.maybe(types.number),
            regular: types.maybe(types.number),
        })
    ),
});

export const GlobalConfigStore = BaseStore.named('globalConfigStore')
    .props({
        isPayDepositEnabled: primitives.boolean(false),
        shouldRefreshAfterRelease: primitives.boolean(false),
        notificationDurationSec: primitives.number(DEFAULT_DURATION_IN_SEC),
        isGateCodesEnabled: primitives.boolean(false),
        payments: types.maybe(PayInFullConfigModel),
    })
    .volatile(() => ({
        unsubscribeDisposer: null as null | IDisposer,
        disposers: [] as IDisposer[],
    }))
    .actions((self) => {
        return {
            updateFromGlobalConfig(globalConfig: GlobalConfig) {
                self.isPayDepositEnabled.setValue(globalConfig.payDepositEnabled ?? false);
                self.shouldRefreshAfterRelease.setValue(globalConfig.shouldRefreshAfterRelease ?? false);
                self.notificationDurationSec.setValue(globalConfig.notificationDurationSec ?? DEFAULT_DURATION_IN_SEC);
                self.isGateCodesEnabled.setValue(globalConfig.isGateCodesEnabled ?? false);
                self.payments = cast(globalConfig.payments);
            },
            subscribe() {
                self.unsubscribeDisposer = self.globalConfigService.subscribeToGlobalConfig(
                    (globalConfig: GlobalConfig) => {
                        this.updateFromGlobalConfig(globalConfig);
                    }
                );
            },
            unsubscribe() {
                self.unsubscribeDisposer && self.unsubscribeDisposer();
                self.unsubscribeDisposer = null;
            },
            afterCreate() {
                self.disposers.push(
                    reaction(
                        () => self.rootStore.authUserStore.userInfo,
                        (userInfo) => {
                            if (!isAnonymous(userInfo)) {
                                this.subscribe();
                            } else {
                                this.unsubscribe();
                            }
                        }
                    )
                );
                self.disposers.push(
                    when(
                        () => self.rootStore.authUserStore.userInfo,
                        () => {
                            const userInfo = self.rootStore.authUserStore.userInfo;
                            if (!isAnonymous(userInfo)) {
                                this.subscribe();
                            } else {
                                this.unsubscribe();
                            }
                        }
                    )
                );
            },
            beforeDestroy() {
                self.disposers.forEach((disposer) => disposer());
            },
        };
    });

export interface GlobalConfigStoreType extends Instance<typeof GlobalConfigStore> {}

export interface HasGlobalConfigStore {
    globalConfigStore: GlobalConfigStoreType;
}
