import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {ClockCircleOutlined} from '@ant-design/icons';
import {Progress} from 'antd';

const BLUE_TARGET = 20;
const ORANGE_TARGET = 10;
const VOLCANO_TARGET = 6;
const RED_TARGET = 1;

function colorForCountdown(countdown: number) {
    let color;
    if (countdown >= BLUE_TARGET) {
        color = 'blue';
    } else if (countdown >= ORANGE_TARGET && countdown < BLUE_TARGET) {
        color = 'orange';
    } else if (countdown >= VOLCANO_TARGET && countdown < ORANGE_TARGET) {
        color = 'volcano';
    } else if (countdown >= RED_TARGET && countdown < VOLCANO_TARGET) {
        color = 'red';
    } else {
        color = 'blue';
    }
    return color;
}

interface AuctionProgressProps {
    countdown: number;
    biddingProgress?: number;
}

export const AuctionProgress: FC<AuctionProgressProps> = observer(({countdown, biddingProgress}) => {
    const color = colorForCountdown(countdown);
    return (
        <>
            <div className={`card-remaining ${color}`}>
                <ClockCircleOutlined /> {countdown}s remaining
            </div>
            {biddingProgress !== undefined && (
                <div className={`progress-card ${color}`}>
                    <Progress percent={biddingProgress} showInfo={false} size="small" />
                </div>
            )}
        </>
    );
});
