import * as s from 'superstruct';
import {persistedItemStatuses} from '../ItemTypes';
import {UserNotificationPayloadValidation} from './notifications';
import {ItemInfoValidation} from '../validations/itemValidation';

type IconType = 'success' | 'info' | 'error' | 'warning';

export const VehicleStatusChangedPayloadValidation = s.assign(
    UserNotificationPayloadValidation,
    s.type({
        vehicleId: s.string(),
        statusFrom: s.enums(persistedItemStatuses),
        statusTo: s.enums(persistedItemStatuses),
        info: ItemInfoValidation,
    })
);

export type VehicleStatusChangedPayload = s.Infer<typeof VehicleStatusChangedPayloadValidation>;

export enum TransientEventTypeEnum {
    DEFAULT_SUCCESS = 'DEFAULT_SUCCESS',
    DEFAULT_INFO = 'DEFAULT_INFO',
    DEFAULT_WARNING = 'DEFAULT_WARNING',
    DEFAULT_ERROR = 'DEFAULT_ERROR',
    FAVORITE_REGION_ADDED = 'FAVORITE_REGION_ADDED',
    FAVORITE_REGION_REMOVED = 'FAVORITE_REGION_REMOVED',
    FAVORITE_AUCTION_SERIES_ADDED = 'FAVORITE_AUCTION_SERIES_ADDED',
    FAVORITE_AUCTION_SERIES_REMOVED = 'FAVORITE_AUCTION_SERIES_REMOVED',
    FAVORITE_AUCTION_ADDED = 'FAVORITE_AUCTION_ADDED',
    FAVORITE_AUCTION_REMOVED = 'FAVORITE_AUCTION_REMOVED',
    FAVORITE_VEHICLE_ADDED = 'FAVORITE_VEHICLE_ADDED',
    FAVORITE_VEHICLE_REMOVED = 'FAVORITE_VEHICLE_REMOVED',
    VEHICLE_STATUS_CHANGE = 'VEHICLE_STATUS_CHANGE',
}

export const allTransientEventTypeEnums = Object.values(TransientEventTypeEnum);

export type NotificationItem = {
    type: TransientEventTypeEnum;
    message?: string;
    description?: string;
    icon?: string | IconType;
    params?: Record<string, unknown>;
};
