import {Instance, types} from 'mobx-state-tree';

export const EventSubscriptionPayload = types.model('EventSubscriptionPayload', {
    regionId: types.maybe(types.string),
    auctionId: types.maybe(types.string),
    auctionSeriesId: types.maybe(types.string),
    startTime: types.maybe(types.number),
});

export const EventSubscriptionDto = types.model('EventSubscriptionDto', {
    uid: types.string,
    type: types.string,
    params: types.maybe(EventSubscriptionPayload),
});

export const EventSubscription = EventSubscriptionDto.named('EventSubscription').props({
    key: types.identifier,
});

export interface EventSubscriptionType extends Instance<typeof EventSubscription> {}

export interface EventSubscriptionDtoType extends Instance<typeof EventSubscriptionDto> {}

export enum EventSubscriptionTypeEnum {
    AUCTION_START = 'AUCTION_START', // TODO: replace with AUCTION_REMINDER
    AUCTION_REMINDER = 'AUCTION_REMINDER',
    FAVORITE_REGION = 'FAVORITE_REGION',
    FAVORITE_AUCTION_SERIES = 'FAVORITE_AUCTION_SERIES',
    FAVORITE_AUCTION = 'FAVORITE_AUCTION',
}
