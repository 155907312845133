import {BuyerFeeCalculator} from './types';
import {validateMoney} from './utils';
import {StepFunctionFee} from '../types/Seller';
import {Money} from '../types/Money';

type StepFunctionFeeWithMoney = {
    fee: Money;
    salePrice: Money;
};

export class StepFunctionBuyerFeeCalculator implements BuyerFeeCalculator {
    public prevFee = Money.zero;
    private sortedFeesArr: StepFunctionFeeWithMoney[] = [];
    /*
     * {
     *   fee1: [salePrice1, salePrice2],
     *   fee2: [salePrice2, salePrice3],
     *   fee3: [salePrice3, zero], - the last one
     * }
     * */
    private salesPriceRangeForFee: Map<Money, [Money, Money]> = new Map();

    private constructor(private stepFunctionFeeArr: StepFunctionFeeWithMoney[]) {
        this.sortedFeesArr = this.stepFunctionFeeArr
            .slice()
            .sort((a, b) => Money.getSorting(true)(a.salePrice, b.salePrice));
        const sorted = this.stepFunctionFeeArr.slice().sort((a, b) => Money.getSorting()(a.salePrice, b.salePrice));
        this.salesPriceRangeForFee = new Map<Money, [Money, Money]>();
        for (const [index, {fee, salePrice}] of sorted.entries()) {
            if (index === sorted.length - 1) {
                this.salesPriceRangeForFee.set(fee, [salePrice, Money.zero]);
            } else {
                const next = sorted[index + 1];
                this.salesPriceRangeForFee.set(fee, [salePrice, next.salePrice.minus(Money.fromCents(1))]);
            }
        }
    }

    static getInstance(stepFunctionFeeArr: StepFunctionFee[]) {
        return new StepFunctionBuyerFeeCalculator(
            stepFunctionFeeArr.map(({fee, salePrice}) => ({
                fee: Money.fromDollars(fee ?? 0),
                salePrice: Money.fromDollars(salePrice),
            }))
        );
    }

    calculate(amount: Money): Money {
        this.validate(amount);
        const index = this.sortedFeesArr.findIndex((sf) => amount.isGreaterThanOrEqual(sf.salePrice));

        if (index === -1) {
            this.prevFee = Money.zero;
            return Money.zero;
        } else if (index === this.sortedFeesArr.length - 1) {
            this.prevFee = this.sortedFeesArr[index].fee;
            return this.sortedFeesArr[index].fee;
        }
        this.prevFee = this.sortedFeesArr[index + 1].fee;
        return this.sortedFeesArr[index].fee;
    }

    checkIfSalePriceIsInRange(salePrice: Money, fee: Money): boolean {
        const [from, to] = this.salesPriceRangeForFee.get(fee) ?? [Money.zero, Money.zero];
        if (to.isZero()) {
            return salePrice.isGreaterThanOrEqual(from);
        }
        return salePrice.isGreaterThanOrEqual(from) && salePrice.isLessThanOrEqual(to);
    }

    validate(amount: Money) {
        if (!isFinite(amount.cents) || amount.cents < 0) {
            throw Error(`Wrong argument: amount ${amount}`);
        }
        this.stepFunctionFeeArr.forEach(({fee}) => {
            validateMoney(fee, 'amountInCent');
        });
    }

    get percent(): number {
        throw new Error('Is not applicable for StepFunctionBuyerFeeCalculator');
    }
}
