export default {
    required: 'Required field',
    size: 'Should contain of :size characters',
    email: 'Should be a valid email',
    url: 'Should be a valid URL',
    phoneNumber: 'Should be a valid phone number XXX-XXX-XXXX or XXXXXXXXXX',
    integer: 'Should be an integer number',
    startDateAfter: "Start Date can't be set after Event Start Date",
    startDateAfterEndDate: "Start Date can't be set after End Date",
    eventStartDateBefore: "Event Start Date can't be set before Start Date",
    eventEndDateBefore: "End Date can't be set before Start Date",
    vin: 'The field must be from 14 to 17 alphanumeric characters length, or equal to `NO VIN`',
    nonStandardVin: 'The field must be from 1 to 20 alphanumeric characters length',
    vinArray: 'Each VIN must be from 1 to 17 characters length, or equal to `NO VIN`',
    lessThan: 'Should be less than :less_than characters',
    nonStandardVinArray: 'Each VIN must be from 1 to 20 characters length, or equal to `NO VIN`',
    passwordMinEightCharacters: 'Password must be longer than 8 characters and contain at least one digit.',
    newPassword: 'Password must be at least :new_password characters long.',
    passwordDontMatch: "Passwords don't match.",
    minAmount: 'The amount must be at least $:min_amount',
    noDuplicateVinsInArray: 'The input must not contain duplicate VIN values',
    decimal: `Number must have no more than :decimal decimals`,
};
