import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';

class BaseBridge {
    private callbacks = new Map<string, {resolve: (value?: any) => void; reject: (error: string) => void}>();

    constructor(protected logger?: Logger) {}

    makeResultFor(type: string) {
        return new Promise<any>((resolve, reject) => {
            this.callbacks.set(type, {resolve, reject});
        });
    }

    protected handleCommand(type: string, messageData: any) {
        const cb = this.callbacks.get(type);
        if (cb) {
            const {data, error} = messageData;
            if (error) {
                cb.reject(error.message);
            } else {
                cb.resolve(data);
            }
            this.callbacks.delete(type);
        }
    }
}

export default BaseBridge;
