import {CalendarOutlined} from '@ant-design/icons';
import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';

export const createAuctionsMenu = () => {
    const auctionsMenu = {
        label: <NavLink to={Routes.AUCTIONS()}>Auctions</NavLink>,
        key: 'auctions',
        icon: <CalendarOutlined />,
    };

    return auctionsMenu;
};
