export async function waitOnline(): Promise<void> {
    if (navigator.onLine) {
        return;
    }
    return new Promise<void>((resolve) => {
        function handleOnline() {
            if (navigator.onLine) {
                console.log('connection changed to online');
                resolve();
                (navigator as any).connection.removeEventListener('change', handleOnline);
            } else {
                console.log('connection changed to offline');
            }
        }
        (navigator as any).connection.addEventListener('change', handleOnline);
    });
}
