import {Loading} from '@joyrideautos/web-common-components/src/components/loading/Loading';
import {useAuthorization} from '@joyrideautos/web-common-components/src/layout/withAuthorization';
import {Col, Row} from 'antd';
import {FC, PropsWithChildren} from 'react';
import {Redirect} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../Routes';

interface AuthProps extends PropsWithChildren {
    allowAnonymous?: boolean;
}

const Auth: FC<AuthProps> = ({allowAnonymous, children}) => {
    const {isUserAuthorized} = useAuthorization(allowAnonymous);

    if (isUserAuthorized == null) {
        return (
            <Row align="middle" className="reset-margin" justify="center" style={{width: '100%', height: '100%'}}>
                <Col>
                    <Loading />
                </Col>
            </Row>
        );
    }

    if (!isUserAuthorized) {
        return <Redirect to={Routes.SIGN_IN()} />;
    }
    return <>{children}</>;
};

export default Auth;
