import React, {MouseEventHandler, PropsWithChildren, useCallback} from 'react';
import {Redirect as RouterRedirect} from 'react-router';
import {
    useHistory,
    Link as RouterLink,
    useLocation,
    useRouteMatch,
    NavLink as RouterNavLink,
    generatePath,
    useParams,
    matchPath,
} from 'react-router-dom';
import {ExtractRouteParams} from 'react-router';
import {History} from 'history';
import {sanitizeObject, isEmpty} from '@joyrideautos/auction-utils/src/objectUtils';
import {useRootStore} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';

export type Router<Params extends {[K in keyof Params]?: string} = any> = {
    navigateTo: (link: string) => void;
    replace: (link: string, state?: any) => void;
    push: (link: string, state?: any) => void;
    location: any;
    match: any;
    open: (url: string) => void;
    openInPopup: (url: string, windowName: string, size: {width: number; height: number}) => void;
    goBack: () => void;
    listen: (listener: any) => () => void;
    getSearchParam: (name: string) => string | null;
    getHash: () => string | undefined;
    updateRoute: (link: string, options?: any) => void;
    params: Params;
    matchPath: (pathname: string, props: any) => any;
};

type Params = Record<string, string | string[] | number | boolean>;

type RouteOptions<T extends string, URL_PARAMS, HASH extends string = string> = {
    pathParams?: ExtractRouteParams<T>;
    urlParams?: URL_PARAMS;
    hash?: HASH | null;
};

export const makeRoute =
    <T extends string = string>(pathname: T) =>
    <URL_PARAMS = Params,>(options?: {urlParams?: URL_PARAMS; hash?: string | null}) =>
        toPath(pathname, {...options, pathParams: undefined});

export const makeRouteWithParams =
    <T extends string = string>(pathname: T) =>
    <URL_PARAMS = Params,>(options: {
        pathParams: ExtractRouteParams<T>;
        urlParams?: URL_PARAMS;
        hash?: string | null;
    }) =>
        toPath(pathname, options);

export const updateRoute = <URL_PARAMS, HASH extends string, T extends string = string>(
    link: T,
    options?: RouteOptions<T, URL_PARAMS, HASH>
) => {
    const path = toPath(link, options);
    window.history.replaceState({link, options}, window.document.title, path);
};

function toPath<T extends string, URL_PARAMS, HASH extends string>(
    pathname: T,
    options?: RouteOptions<T, URL_PARAMS, HASH>
) {
    if (!options || (isEmpty(options.pathParams) && !(options.hash || options.urlParams))) {
        return pathname;
    }

    const {urlParams, pathParams, hash} = options || {};
    try {
        const filledPath = generatePath(pathname, pathParams);
        const urlSearchParams = stringifySearchParams(urlParams);
        const fullPathWithUrlParams = urlSearchParams.toString()
            ? `${filledPath}?${urlSearchParams.toString()}`
            : filledPath;
        return hash ? `${fullPathWithUrlParams}#${hash}` : fullPathWithUrlParams;
    } catch (e: any) {
        console.log('failed to generate path', e.message);
        return '/home';
    }
}

function stringifySearchParams(urlParams?: any) {
    const urlSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(sanitizeObject(urlParams || {}))) {
        urlSearchParams.append(key, String(value));
    }
    return urlSearchParams.toString();
}

export const navigateTo =
    (history: History) =>
    <T extends string>(link: T) => {};
export const replace =
    (history: History) =>
    <T extends string>(link: T, state?: any) => {
        history.replace(link, state);
    };
export const push =
    (history: History) =>
    <T extends string>(link: T, state?: any) => {
        history.push(link, state);
    };

export function openInNewTab<T extends string>(url: T) {
    window.open(url, '_blank');
}

export function open<T extends string>(url: T) {
    window.open(url);
}

export function openInPopup<T extends string>(url: T, windowName: string, size: {width: number; height: number}) {
    window.open(url, windowName, `width=${size.width},height=${size.height}`);
}

interface RedirectProps<T extends string = string> {
    to: T;
    push?: boolean;
}

export function Redirect<T extends string>({to}: PropsWithChildren<RedirectProps<T>>) {
    return <RouterRedirect to={{pathname: to}} />;
}

interface LinkProps<T extends string = string> {
    to: T;
    className?: string;
    analyticsId?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export function Link<T extends string>({to, className, onClick, children}: PropsWithChildren<LinkProps<T>>) {
    return (
        <RouterLink to={to} className={className} onClick={onClick}>
            {children}
        </RouterLink>
    );
}

export function NavLink<T extends string>({
    to,
    className,
    analyticsId,
    onClick,
    children,
}: PropsWithChildren<LinkProps<T>>) {
    return (
        <RouterNavLink to={to} data-analyticsid={analyticsId} className={className} onClick={onClick}>
            {children}
        </RouterNavLink>
    );
}

export const useRouter = <Params extends {[K in keyof Params]?: string}>(): Router<Params> => {
    const history = useHistory();
    const location = useLocation<Params>();
    const match = useRouteMatch<Params>();
    const params = useParams<Params>();
    const searchParams = new URLSearchParams(location.search);
    const rootStore = useRootStore();
    const getHash = useCallback(() => {
        const hash = window.history.state?.options?.hash;
        if (hash) {
            return hash;
        }
        return location.hash ? location.hash.slice(1) : undefined;
    }, [location.hash]);

    return {
        navigateTo: navigateTo(history),
        replace: replace(history),
        push: push(history),
        location,
        match,
        open,
        openInPopup,
        goBack: history.goBack,
        listen: history.listen,
        getSearchParam: (name: string) => searchParams.get(name),
        getHash,
        updateRoute: <URL_PARAMS, HASH extends string, T extends string = string>(
            link: T,
            options?: RouteOptions<T, URL_PARAMS, HASH>
        ) => {
            const path = toPath(link, options);
            (rootStore as any).presentationStore.routeModel.search.setValue(stringifySearchParams(options?.urlParams));
            (rootStore as any).presentationStore.routeModel.hash.setValue(options?.hash);
            window.history.replaceState({link, options}, window.document.title, path);
        },
        params,
        matchPath,
    };
};
