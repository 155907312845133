import React, {FC} from 'react';
import * as Modal from './PDFModal';
import {PDFModalViewModelType} from '@joyrideautos/ui-models/src/presentation/pdfModal/PDFModalViewModel';
import {observer} from 'mobx-react-lite';

interface PDFModalProps {
    viewModel: PDFModalViewModelType;
}

export const PDFModal: FC<PDFModalProps> = observer(({viewModel}) => {
    const {isModalVisible, hideModal, modalTitle, documentLoadingStatus, downloadDocument, documentDownloadUrl} =
        viewModel;
    return (
        <Modal.PDFModal
            open={isModalVisible}
            onOk={hideModal}
            onCancel={hideModal}
            title={modalTitle || ''}
            onDownload={downloadDocument}
            loading={documentLoadingStatus.isInProgress}
            url={documentDownloadUrl}
        />
    );
});
