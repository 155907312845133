import {WithOptionalKey} from '@joyrideautos/auction-core/src/types/common';
import {TransientEventTypeEnum} from '@joyrideautos/auction-core/src/types/events/transient';
import {EventSnapshotIn} from '@joyrideautos/ui-models/src/types/events/Event';
import {NotificationKindEnum} from '@joyrideautos/ui-models/src/types/events/Notification';
import {NotificationItemType} from '@joyrideautos/ui-models/src/types/events/TransientEvent';
import {WithKey} from '@joyrideautos/ui-services/src/firebase/types';
import {Notification} from '@joyrideautos/auction-core/src/types/events/notifications';

export function mapTransientNotificationToModel(
    notificationItem: WithOptionalKey<NotificationItemType> | WithOptionalKey<TransientEventTypeEnum>
) {
    const isEnum = TransientEventTypeEnum[notificationItem as TransientEventTypeEnum];
    return isEnum
        ? ({
              type: notificationItem,
              params: {type: notificationItem},
              key: notificationItem.key ?? notificationItem,
          } as any) // TODO: (future) resolve type
        : ({
              ...(notificationItem as NotificationItemType),
              key: notificationItem.key ?? (notificationItem as NotificationItemType).type,
              params: {
                  ...(notificationItem as NotificationItemType).params,
                  type: (notificationItem as NotificationItemType).type,
              },
          } as any); // TODO: (future) resolve type
}

export function mapPersistedNotificationDtoToModel(notification: WithKey<Notification>): EventSnapshotIn {
    const {params, ...rest} = notification;
    return {
        kind: NotificationKindEnum.PERSISTED,
        ...rest,
        params: params as any,
    };
}
