import * as engine from 'store/src/store-engine';
import * as sessionStorage from 'store/storages/sessionStorage';
import * as localStorage from 'store/storages/localStorage';
import {Storage} from '@joyrideautos/ui-models/src/sessionStores/Storage';

export const sessionStore = engine.createStore([sessionStorage], []);
export const localStorageStore = engine.createStore([localStorage], []);

export class AsyncSessionStorage<T> implements Storage<T> {
    async get(key: string, optionalDefaultValue: T | undefined): Promise<T | null> {
        return sessionStore.get(key) ?? optionalDefaultValue ?? null;
    }

    async set(key: string, value: T): Promise<void> {
        sessionStore.set(key, value);
    }

    async remove(key: string): Promise<void> {
        return sessionStore.remove(key);
    }
}
