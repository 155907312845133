import {Instance, types} from 'mobx-state-tree';
import {AudioNotificationEnum, AudioQueue} from './AudioQueue';

export const SoundStore = types
    .model('SoundStore', {
        isMuted: true,
        isBrowserWithSound: true,
    })
    .volatile(() => ({
        audioQueue: new AudioQueue(),
    }))
    .views((self) => {
        return {
            get isSoundMuted() {
                return self.isMuted || !self.audioQueue.hasContext();
            },
            get canBrowserPlaySound() {
                return self.isBrowserWithSound;
            },
        };
    })

    .actions((self) => {
        return {
            toggleSound() {
                self.audioQueue.createContext();
                this.setSound(!self.isMuted);
            },
            // we want to play sounds not parallel but in queue
            playSound(sound: AudioNotificationEnum) {
                if (self.isMuted) {
                    return;
                }
                self.audioQueue.playSound(sound);
            },
            setSound(isMuted: boolean) {
                self.isMuted = isMuted;
            },
            setMutedForOldBrowser() {
                self.isBrowserWithSound = false;
                self.isMuted = true;
            },
            afterCreate() {
                self.audioQueue
                    .testAudio()
                    .then((canPlay) => {
                        if (!canPlay) {
                            this.setMutedForOldBrowser();
                        }
                    })
                    .catch((e) => console.log(e));
            },
        };
    });

export interface SoundStoreType extends Instance<typeof SoundStore> {}
