import {AWSCredentials} from '@joyrideautos/ui-services/src/aws/types';
import {createRPCService} from '@joyrideautos/web-firebase-client/src/services/rpcService/createRPCService';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {Auth} from '@joyrideautos/ui-services/src/firebase/Auth';
import {ONE_MIN} from '@joyrideautos/auction-utils/src/dateTimeUtils';

export class AWSCredentialsProvider {
    private credentials?: AWSCredentials;
    private fetchCredentialsPromise?: Promise<AWSCredentials>;

    constructor(private auth: Auth, private logger?: Logger) {}

    private async fetchCredentials(): Promise<AWSCredentials> {
        this.logger?.log('fetch credentials');
        return await createRPCService(this.auth).call(FeReqRouteEnum.AWS_GET_OPEN_ID_TOKEN_FOR_DEVELOPER_IDENTITY);
    }

    get isCredentialsValid() {
        this.logger?.log('check if credentials are valid', this.credentials?.tokenExpiresAt);
        if (!this.credentials) {
            return false;
        }
        const {tokenExpiresAt} = this.credentials;
        const now = Date.now();
        return Date.parse(tokenExpiresAt) - now >= ONE_MIN;
    }

    async getCredentials(): Promise<AWSCredentials> {
        if (this.credentials && this.isCredentialsValid) {
            return this.credentials;
        }
        try {
            this.logger?.log('get credentials', this.fetchCredentialsPromise);
            if (this.fetchCredentialsPromise) {
                this.logger?.log('wait for credentials');
            } else {
                this.fetchCredentialsPromise = this.fetchCredentials();
            }
            this.credentials = await this.fetchCredentialsPromise;
            this.logger?.warn('got credentials', this.credentials, this.fetchCredentialsPromise);
            this.fetchCredentialsPromise = undefined;
            return this.credentials;
        } catch (e: any) {
            this.logger?.error('error fetching credentials', e.message);
            this.fetchCredentialsPromise = undefined;
            throw e;
        }
    }
}

let provider: AWSCredentialsProvider | undefined;
export const getCredentialsProvider = (auth: Auth, logger?: Logger) => {
    if (!provider) {
        provider = new AWSCredentialsProvider(auth, logger);
    }
    return provider;
};
