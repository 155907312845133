import {applySnapshot, getRoot, IDisposer, Instance, types} from 'mobx-state-tree';
import {keys, when} from 'mobx';

import {
    UploadMedia,
    InProgressMediaInfo,
    SWUploadEventTypeEnum,
} from '@joyrideautos/ui-services/src/services/mediaService/types';
import {Progress} from '@joyrideautos/ui-services/src/aws/types';
import {NotificationEnum} from '@joyrideautos/auction-core/src/types/events/notifications';
import EventFactory from '../types/events/EventFactory';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import BaseStore from './BaseStore';
import type {EventsService} from '@joyrideautos/ui-services/src/services/EventsService';
import {logError} from '@joyrideautos/ui-logger/src/utils';

export const SAVED_IN_DB_MEDIA_PROGRESS = 10; // percents, when a record were saved in the db (firestore) for a media
export const WAITING_WEBHOOK_MEDIA_PROGRESS = 10; // percents

const showFailedUploadEvent = (services: {eventsService: EventsService}) => (itemKey: string, sellerId: string) => {
    services.eventsService
        .savePersistedNotifications({
            notifications: [
                EventFactory.createNewNotificationDto<any>(NotificationEnum.UPLOAD_FAILED, {
                    itemKey,
                    sellerId,
                }),
            ],
        })
        .catch(logError())
        .finally(() => {
            console.log('send upload failed event', itemKey, sellerId);
        });
};

function calculateInProgressMedia(data: {[itemKey: string]: InProgressMediaInfo[]}): {[itemKey: string]: number} {
    return Object.keys(data).reduce((result, itemKey) => {
        result[itemKey] = data[itemKey].length;
        return result;
    }, {} as {[itemKey: string]: number});
}

function calculateMediaLeft(uploadingMedia: Map<string, number>) {
    return Array.from(uploadingMedia.keys()).reduce((mediaLeft, key) => {
        return mediaLeft + (uploadingMedia.get(key) || 0);
    }, 0);
}

type MediaUploadListener = (idx: number, progress: number | undefined) => void;

export const MediaStore = BaseStore.named('MediaStore')
    .props({
        // inProgressMedia: {[itemKey: string]: mediaInProgresCount}
        inProgressMedia: types.map(types.number),
    })
    .volatile(() => ({
        disposers: [] as IDisposer[],
        // TODO: (Future) save an array of listeners instead of one listener
        listeners: new Map<string, MediaUploadListener>(),
    }))
    .views((self) => {
        const rootStore = getRoot<any>(self);
        return {
            get authUserId(): string | undefined {
                const {authUserStore} = rootStore;
                if (!authUserStore.userInfo) {
                    return undefined;
                }
                const {uid} = authUserStore.userInfo;
                return uid;
            },
            get hasSellerRole() {
                return rootStore.sellerRoleStore.hasSellerRoleForAnyCompany();
            },
            get vehiclesLeft() {
                return keys(self.inProgressMedia).length;
            },
            get mediaLeft() {
                return calculateMediaLeft(self.inProgressMedia);
            },
        };
    })
    .actions((self) => {
        function calculateProgress(progress: Progress | undefined) {
            const uploadToS3MaxProgress = 100 - SAVED_IN_DB_MEDIA_PROGRESS - WAITING_WEBHOOK_MEDIA_PROGRESS;
            // SAVED_IN_DB_MEDIA_PROGRESS + uploadToS3Progress + WAITING_WEBHOOK_MEDIA_PROGRESS;
            return progress
                ? SAVED_IN_DB_MEDIA_PROGRESS + Math.floor((progress.loaded / progress.total) * uploadToS3MaxProgress)
                : undefined;
        }

        return {
            addMediaUploadListener(itemKey: string, listener: MediaUploadListener) {
                self.listeners.set(itemKey, listener);
            },
            removeMediaUploadListener(itemKey: string) {
                self.listeners.delete(itemKey);
            },
            async uploadMedia(data: UploadMedia) {
                if (!self.authUserId) {
                    throw new Error('trying to upload media for anonymous user');
                }
                try {
                    try {
                        const ready = await self.uploadMediaService.checkSWisReady();
                        if (ready) {
                            this.incrementUploadingMediaForItem(data.itemKey, data.params.length);
                            const credentials = await self.awsCredentialsProvider.getCredentials();
                            await self.uploadMediaService.uploadMedia(self.authUserId, data, credentials);
                        } else {
                            await self.mediaService.uploadMediaToS3(data);
                        }
                    } catch (e: any) {
                        self.logger.log('uploadMedia error', e.message);
                        this.incrementUploadingMediaForItem(data.itemKey, -data.params.length);
                        try {
                            await self.mediaService.removeItemMedia(
                                data.itemKey,
                                data.params.map((el) => el.index)
                            );
                        } catch (e: any) {
                            self.logger.error('removing media error', e.message);
                        }
                    }
                } catch (e: any) {
                    self.logger.log('create loading media error', e.message);
                }
            },
            setInProgressMedia(value: {[itemKey: string]: number}) {
                applySnapshot(self.inProgressMedia, value);
            },
            clearInProgressMedia() {
                self.inProgressMedia.clear();
            },
            incrementUploadingMediaForItem(itemKey: string, uploadingMedia: number) {
                const current = self.inProgressMedia.get(itemKey) || 0;
                if (current + uploadingMedia > 0) {
                    self.inProgressMedia.set(itemKey, current + uploadingMedia);
                } else {
                    self.inProgressMedia.delete(itemKey);
                }
            },
            async checkMediaInProgress() {
                try {
                    const isReady = await self.uploadMediaService.checkSWisReady();
                    if (!isReady) {
                        self.logger.log('service worker is not ready');
                        return;
                    }

                    const credentials = await self.awsCredentialsProvider.getCredentials();
                    if (!credentials) {
                        self.logger.log('missing aws credentials');
                        return;
                    }

                    const result = await self.uploadMediaService.getUnloadedMedia(self.authUserId!, credentials);

                    if (!result) {
                        return false;
                    }
                    if (Object.keys(result).length === 0) {
                        this.clearInProgressMedia();
                        return false;
                    }
                    this.setInProgressMedia(calculateInProgressMedia(result));
                    Object.keys(result).forEach((itemKey) => {
                        const listener = self.listeners.get(itemKey);
                        if (listener) {
                            result[itemKey].forEach(({index, progress}) => {
                                listener(index, calculateProgress(progress));
                            });
                        }
                    });
                } catch (e: any) {
                    self.logger.log('error of getting unfinished resources', e.message);
                }
            },
            async cancelUploading(itemKey: string, index: number) {
                await self.uploadMediaService.cancelUpload(self.authUserId!, itemKey, index);
                await self.mediaService.removeItemMedia(itemKey, [index]);
            },
            afterCreate() {
                self.disposers.push(
                    when(
                        () => !!self.authUserId && self.hasSellerRole && self.uploadMediaService.isSWSupported,
                        () => {
                            this.checkMediaInProgress().catch(logError('checkMediaInProgress'));
                            self.disposers.push(
                                self.firebaseService.subscribeToConnectionChanged(
                                    (connected) => {
                                        if (connected) {
                                            self.awsCredentialsProvider
                                                .getCredentials()
                                                .then((credentials) =>
                                                    self.uploadMediaService.checkUpload(self.authUserId!, credentials)
                                                )
                                                .catch((e) => {
                                                    self.logger.log('error of checking upload', e.message);
                                                });
                                        }
                                    },
                                    {skipFirst: true}
                                )
                            );
                        }
                    )
                );
                self.disposers.push(
                    self.uploadMediaService.subscribeToEvents(async (error, data, postMessage) => {
                        self.logger.log('got a message', data?.type, error, data);

                        switch (data?.type) {
                            case SWUploadEventTypeEnum.RESIZE_IMAGE: {
                                const {binary} = data;
                                self.logger.log('binary', binary?.length);
                                if (binary) {
                                    try {
                                        const resizedBinary = await self.mediaService.resizeImageIfNeeded(binary);
                                        self.logger.log('resizedBinary', resizedBinary.length);
                                        postMessage({
                                            type: data.type,
                                            data: {data: {binary: resizedBinary}},
                                            messageType: 'command',
                                        });
                                    } catch (e) {
                                        self.logger.error('failed to resize the image', e);
                                        // sending back the `original` image
                                        postMessage({
                                            type: data.type,
                                            data: {data: {binary}},
                                            messageType: 'command',
                                        });
                                    }
                                } else {
                                    postMessage({
                                        type: data.type,
                                        data: {data: {error: `cannot find binary for key: ${data.binaryKey}`}},
                                        messageType: 'command',
                                    });
                                }

                                return;
                            }
                            case SWUploadEventTypeEnum.UPLOADED_ONE_SUCCESS: {
                                this.incrementUploadingMediaForItem(data.itemKey, -1);
                                return;
                            }
                            case SWUploadEventTypeEnum.UPLOADED_ONE_FAILED: {
                                if (
                                    error &&
                                    error.params?.itemKey &&
                                    error.params?.sellerId &&
                                    typeof error.params?.index !== 'undefined'
                                ) {
                                    const {
                                        message,
                                        params: {itemKey, sellerId, index},
                                    } = error;
                                    self.logger.log('upload media error: ', message);
                                    showFailedUploadEvent({eventsService: self.eventsService})(itemKey, sellerId);
                                    this.checkMediaInProgress()
                                        .then((loadingMediaExists) => {
                                            if (!loadingMediaExists) {
                                                return self.mediaService.removeItemMedia(itemKey, [index]);
                                            }
                                        })
                                        .catch((e) => console.log(e));
                                }
                                return;
                            }
                            case SWUploadEventTypeEnum.UPLOAD_PROGRESS: {
                                const {itemKey, index, progress} = cast<InProgressMediaInfo>(data);
                                const listener = self.listeners.get(itemKey);
                                listener && listener(index, calculateProgress(progress));
                            }
                        }
                        if (
                            error &&
                            error.params?.itemKey &&
                            error.params?.sellerId &&
                            typeof error.params?.index !== 'undefined'
                        ) {
                            const {
                                message,
                                params: {itemKey, sellerId, index},
                            } = error;
                            self.logger.log('upload media error: ', message);
                            this.incrementUploadingMediaForItem(itemKey, -1);
                            showFailedUploadEvent({eventsService: self.eventsService})(itemKey, sellerId);
                            await self.mediaService.removeItemMedia(itemKey, [index]);
                        }
                    })
                );
            },
            beforeDestroy() {
                self.logger.log('before destroy', self.toString());
                self.disposers.forEach((d) => d());
            },
        };
    });

export interface MediaStoreType extends Instance<typeof MediaStore> {}

export interface HasMediaStore {
    mediaStore: MediaStoreType;
}
