import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import memoize from 'lodash/memoize';

export {debounce, throttle, memoize};

// We need to keep it as a function because some parts of common module are imported in tests, so executed by Node.js
//  and there are no window.location
export function isLocalHost(): boolean {
    if (!globalThis?.location?.hostname) {
        return true;
    }
    return ['localhost', '0.0.0.0'].includes(globalThis?.location?.hostname);
}

export const DEMO_PROJECT_ID = 'demo-project';

export function isEmulatorDemoProject(projectId: string) {
    return projectId === DEMO_PROJECT_ID;
}

export function createSingletonFactory<T, Params = any>(factory: (params: Params) => T) {
    let value: T;
    return (params: Params = {} as Params) => {
        if (!value) {
            value = factory(params);
        }
        return value;
    };
}

export function lazyAsync<T>(factory: () => Promise<T>) {
    let value: T;
    return async () => {
        if (!value) {
            value = await factory();
        }
        return value;
    };
}
