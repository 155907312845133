import {IDisposer, Instance, onPatch, onSnapshot, types} from 'mobx-state-tree';
import {ItemsStoreFactory} from '@joyrideautos/ui-models/src/stores/ItemsStoreFactory';
import {BidsStore} from '@joyrideautos/ui-models/src/stores/BidsStore';
import {Scheduler} from '@joyrideautos/ui-common/src/lib/Scheduler';
import {EventsStore} from './EventsStore';
import {SoundStore} from './SoundStore';
import makeLogger, {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import {makeInspectable} from '@joyrideautos/ui-models/src/utils/mobx-utils';
import CommonRootStore from '@joyrideautos/ui-models/src/stores/rootStore/RootStore';
import {UIServicesAwareViewModel} from '@joyrideautos/ui-models/src/common/UIServicesAwareViewModel';
import * as Fullstory from '@fullstory/browser';
import {createFullstoryService} from '@joyrideautos/ui-services/src/services/FullstoryService';
import {createSocketWrapper} from '@joyrideautos/ui-models/src/common/SocketWrapper';
import {createUploadMediaService} from '@joyrideautos/aws-client/src/services/uploadMediaSW/window/UploadMediaService';
import {createClock} from '@joyrideautos/ui-models/src/utils/Clock';
import {createSellerSessionStorage} from '@joyrideautos/ui-models/src/sessionStores/SellerSessionStorage';
import {AsyncSessionStorage, localStorageStore} from '@joyrideautos/web-common-components/src/store';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {createBrowserHistory} from 'history';
import {createFERoutingService} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {getAppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import PresentationStore from '@joyrideautos/web-common-components/src/models/stores/PresentationStore';
import {getFirebaseServicesFactory} from '@joyrideautos/web-common-components/src/FirebaseServicesFactory';
import {LanguageStore} from './LanguageStore';

const appConfig = getAppConfig();
const history = createBrowserHistory();

const sellerSessionStorage = createSellerSessionStorage(
    new AsyncSessionStorage(),
    makeLogger({loggerStatusEnabled: appConfig.commonConfig.loggerStatusEnabled})(
        'SellerSessionStorage',
        LoggerStatusEnum.DISABLED
    )
);

const firebaseServicesFactory = getFirebaseServicesFactory(appConfig, history);

export const RootStore = types
    .compose(CommonRootStore, UIServicesAwareViewModel)
    .named('RootStore')
    .props({
        presentationStore: PresentationStore,
        soundStore: SoundStore,
        bidsStore: BidsStore,
        eventsStore: EventsStore,
        languageStore: LanguageStore,
        isSWNewVersionAvailable: false,
    })
    .volatile((self) => ({
        // Sometimes getRoot(self) will return intermediary stores if they are not in direct hierarchy from RootStore
        // (e.g. CachedPersistedItemsStore) so we define "rootStore" property everywhere to be compatible and can use
        // getRoot(self).rootStore despite what is actual result of getRoot(self)
        rootStore: self,
        scheduler: new Scheduler(),
        itemsStoreFactory: new ItemsStoreFactory(self),
        disposers: [] as IDisposer[],
        auctionsDisposers: [] as IDisposer[],
        regionsDisposers: [] as IDisposer[],
        appConfig: getAppConfig(),
    }))
    .views((self) => ({
        get history() {
            return history;
        },
        get sessionStore() {
            return new AsyncSessionStorage();
        },
        get localStorageStore() {
            return localStorageStore;
        },
        get sellerSessionStorage() {
            return sellerSessionStorage;
        },
        getLogger(name: string, status: LoggerStatusEnum = LoggerStatusEnum.ENABLED) {
            return makeLogger(self.sentryService, {loggerStatusEnabled: appConfig.commonConfig.loggerStatusEnabled})(
                name,
                status
            );
        },
        get logger() {
            return this.getLogger('RootStore', LoggerStatusEnum.DISABLED);
        },
        t(key: string) {
            return self.languageStore.t(key);
        },
    }))
    .actions((self) => {
        return {
            setSWNewVersionAvailable(value: boolean) {
                self.isSWNewVersionAvailable = value;
            },
            afterCreate: function () {
                self.logger.log('afterCreate', self.toString());
                self.disposers.push(
                    onSnapshot(self.regionsStore.regionsById, (snap) => {
                        self.logger.log('self.regionsStore.regionsById', snap);
                        self.auctionsDisposers.forEach((d) => d());
                        self.auctionsDisposers.push(self.auctionSeriesStore.subscribe(self.regionsStore.regions));
                        self.auctionsDisposers.push(self.auctionsStore.subscribe(self.regionsStore.regions));
                        self.auctionsDisposers.push(self.auctionsStore.platformFeeSchedules.subscribe());
                        self.auctionsDisposers.push(self.auctionsStore.subscribeToBidIncrements());
                    })
                );
                self.disposers.push(
                    onPatch(self.authUserStore, (patch) => {
                        self.logger.log('authUserStore, onPatch', patch);
                        if (patch.op === 'replace' && patch.path === '/userInfo') {
                            // unsubscribe listeners
                            self.sellerRoleStore.unsubscribe();
                            self.paymentsStore.unsubscribe();
                            self.sellersStore.unsubscribe();
                            self.biddersStatuses.unsubscribe();
                            self.auctionsDisposers.forEach((d) => d());
                            self.regionsDisposers.forEach((d) => d());

                            if (self.authUserStore.userInfo) {
                                self.regionsDisposers.push(self.regionsStore.subscribe());
                                self.featureFlagStore.loadUser(self.authUserStore.userInfo);
                                self.sellersStore.subscribe();

                                if (!self.authUserStore.userInfo.isAnonymous) {
                                    // TODO: (future) move to authUserStore
                                    self.favoritesStore.load().catch(logError());
                                    self.wonItemsStore.loadUserWonItems();
                                    self.managerRoleStore.load().catch(logError());
                                    self.sellerRoleStore.subscribe();
                                    self.sellerRoleStore.load().catch(logError());
                                    self.sellersStore
                                        .fetchSellersForPreapprovedUser(self.authUserStore.userInfo.uid, false)
                                        .catch(logError());
                                    self.biddersStatuses.load(self.authUserStore.userInfo.uid).catch(logError());
                                    self.eventsStore.subscribeToEvents(self.authUserStore.userInfo.uid);
                                    self.paymentsStore.subscribe(self.authUserStore.userInfo.uid);
                                }
                            } else {
                                self.featureFlagStore.loadUser();
                            }
                        }
                    })
                );
            },
            beforeDestroy: function () {
                self.logger.log('beforeDestroy', self.toString());
                self.scheduler.cancelAll();
                self.disposers.forEach((d) => d());
            },
        };
    });

const clockService = firebaseServicesFactory.createClockService();
const authUserService = firebaseServicesFactory.createAuthUserService();

export const rootStore = RootStore.create(
    {
        authUserStore: {
            geoLocationStore: {},
        },
        featureFlagStore: {},
        regionsStore: {},
        favoritesStore: {},
        soundStore: {},
        auctionSeriesStore: {},
        auctionsStore: {},
        itemsStore: {},
        wonItemsStore: {},
        sellersStore: {},
        winningBidStore: {},
        biddersStatuses: {},
        managerRoleStore: {},
        sellerRoleStore: {},
        liveAuctionStateStore: {},
        bidsStore: {},
        eventsStore: {},
        paymentsStore: {},
        mediaStore: {},
        ccHoldStore: {},
        userStore: {},
        refsStore: {},
        presentationStore: {},
        globalConfigStore: {},
        appStore: {},
        languageStore: {},
    },
    {
        services: {
            ...firebaseServicesFactory.createAllServices(),
            itemsService: firebaseServicesFactory.createItemsService(),
            bidsService: firebaseServicesFactory.createBidService(),
            wonItemService: firebaseServicesFactory.createWonItemsService(),
            whiteListService: firebaseServicesFactory.createWhiteListService(),
            userService: firebaseServicesFactory.createUsersService(),
            biddersService: firebaseServicesFactory.createBiddersService(),
            sellersService: firebaseServicesFactory.createSellersService(),
            sellerRoleService: firebaseServicesFactory.createSellerRoleService(),
            managerRoleService: firebaseServicesFactory.createManagerRoleService(),
            regionsService: firebaseServicesFactory.createRegionsService(),
            refsService: firebaseServicesFactory.createRefsService(),
            itemPaymentService: firebaseServicesFactory.createItemPaymentService(),
            paymentService: firebaseServicesFactory.createPaymentService(),
            globalConfigService: firebaseServicesFactory.createGlobalConfigService(),
            releaseVersionService: firebaseServicesFactory.createReleaseVersionService(),
            favoritesService: firebaseServicesFactory.createFavoritesService(),
            firebaseTraceService: firebaseServicesFactory.createFirebaseTraceService(),
            userBansService: firebaseServicesFactory.createUserBansService(),
            auctionService: firebaseServicesFactory.createAuctionService(),
            adminRoleService: firebaseServicesFactory.createAdminRoleService(),
            authUserService,
            sentryService: firebaseServicesFactory.sentryService,
            fullstoryService: createFullstoryService(Fullstory, {
                publicAuctionUrl: appConfig.commonConfig.publicAuctionUrl,
            }),
            analyticsService: firebaseServicesFactory.createAnalyticsService(),
            clockService: firebaseServicesFactory.createClockService(),
            storageService: firebaseServicesFactory.createStorageService(),
            eventsService: firebaseServicesFactory.createEventsService(),
            searchService: firebaseServicesFactory.createSearchService(),
            mediaService: firebaseServicesFactory.createMediaService(),
            uploadMediaService: createUploadMediaService({appConfig: appConfig.toJSON()}),
            phoneValidationService: firebaseServicesFactory.createPhoneValidationService(),
            awsCredentialsProvider: firebaseServicesFactory.getCredentialsProvider(),
            firebaseService: firebaseServicesFactory.createFirebaseService(),
            googleDriveService: firebaseServicesFactory.createGoogleDriveService(),
            recaptchaService: firebaseServicesFactory.createRecaptchaService(),
            platformFeeScheduleService: firebaseServicesFactory.createPlatformFeeScheduleService(),
        },
        clock: createClock({
            clockService,
            logger: makeLogger(firebaseServicesFactory.sentryService, {
                loggerStatusEnabled: appConfig.commonConfig.loggerStatusEnabled,
            })('Clock', LoggerStatusEnum.DISABLED),
        }),
        socketWrapper: createSocketWrapper(
            authUserService,
            createFERoutingService({
                projectId: appConfig.firebaseConfig.projectId,
                functionUrl: appConfig.emulatorsConfig.functionsUrl,
                microservices: appConfig.microservicesConfig,
            }),
            firebaseServicesFactory.sentryService,
            makeLogger(firebaseServicesFactory.sentryService, {
                loggerStatusEnabled: appConfig.commonConfig.loggerStatusEnabled,
            })('socketWrapper', LoggerStatusEnum.DISABLED)
        ),
    }
);

export interface RootStoreType extends Instance<typeof RootStore> {}

if (process.env.NODE_ENV === 'development') {
    makeInspectable(rootStore);
}
