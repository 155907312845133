import {BannedUser, User} from '@joyrideautos/auction-core/src/types/User';
import {BaseService} from './BaseService';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {IsUserBannedRPCResData} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/bidders/isUserBannedReqTypes';

export class UserBansService extends BaseService {
    subscribeToCheckIfCurrentUserIsGloballyBanned(
        uid: string | null,
        listener: (result: BannedUser | null) => void
    ): () => void {
        if (!uid) {
            listener(null);
            return () => {};
        }

        return this.firebase.firestore.subscribeToCollection<BannedUser>(
            this.firebase.firestore.collectionRef(`/users/${uid}/userBans`),
            (result) => {
                const doc = result && Object.values(result).find((x) => x.sellerId === 'ALL');
                doc ? listener(doc) : listener(null);
            }
        );
    }

    subscribeToCheckIfCurrentUserIsBannedForSeller(
        uid: string | null,
        sellerId: string,
        listener: (result: BannedUser | null) => void
    ): () => void {
        if (!uid) {
            listener(null);
            return () => {};
        }

        return this.firebase.firestore.subscribeToCollection<BannedUser>(
            this.firebase.firestore.collectionRef(`/users/${uid}/userBans`),
            (result) => {
                const doc = result && Object.values(result).find((x) => x.sellerId === sellerId);
                doc ? listener(doc) : listener(null);
            }
        );
    }

    async banUsers(uids: string[], sellerId: string): Promise<User[]> {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_USER_BAN_USERS)<
            {
                uids: string[];
                sellerId: string;
            },
            User[]
        >({uids, sellerId});
    }

    async banUserForSellers(uid: string, sellers: string[], banUntil?: string): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_BAN_USER_FOR_SELLERS)({
            uid,
            sellers,
            banUntil,
        });
    }

    async isUserBanned(uid: string, sellersIds: string[]): Promise<IsUserBannedRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_IS_USER_BANNED)({
            uid,
            sellersIds,
        });
    }

    async banUserAsSeller(uid: string, sellerId: string): Promise<void> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_BAN_USER_AS_SELLER)({
            uid,
            sellerId,
        });
    }

    async unbanUserForSellers(uid: string, sellers: string[]): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_UNBAN_USERS_FOR_SELLERS)({uid, sellers});
    }

    async findSellersForBannedUser(uid: string): Promise<string[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_FIND_SELLERS_FOR_BANNED_USER)({
            uid,
        });
    }

    async banUsersForAuctionSeller(uids: string[], sellerId: string): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_BAN_USERS_FOR_AUCTION_SELLER)({
            uids,
            sellerId,
        });
    }
}
