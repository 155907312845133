import * as s from 'superstruct';

export const PositiveNumber = s.min(s.number(), 0);

export const PositiveNumberNotLessThan1800 = s.min(s.integer(), 1800);
export const UnknownYearNumber = s.literal(0);

const DATE_STRING_REGEXP = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(([-+](\d{2}):(\d{2})|Z)?)$/;
export const DateStringValidation = s.pattern(s.string(), DATE_STRING_REGEXP);

export const ID_VALIDATION_REGEX = /^([\w_-])+$/;
export const idValidation = s.pattern(s.string(), ID_VALIDATION_REGEX);

export const CoordinatesValidation = s.type({
    lat: s.number(),
    lon: s.number(),
});
