import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';

export const createSignInMenu = () => {
    const signInMenu = {
        label: <NavLink to={Routes.SIGN_IN()}>Sign In</NavLink>,
        key: 'sign-in',
        style: {marginInlineStart: 'auto'},
    };
    return signInMenu;
};
