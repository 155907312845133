import {action, computed, makeObservable, observable} from 'mobx';
import {Storage} from './Storage';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {LoadingStatusEnum} from '../utils/LoadingStatus';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';

export const SESSION_STORE_KEY = 'SELLER_SESSION_STORE';

type SellerSessionStorageValue = {
    sellerId: string | null;
};

export class SellerSessionStorage {
    private _sellerId = observable.box<string | null>(null);
    private _status = observable.box(LoadingStatusEnum.NEW);

    constructor(private sessionStore: Storage<SellerSessionStorageValue>, private logger?: Logger) {
        makeObservable(this, {
            sellerId: computed,
            setStatus: action,
            updateSellerId: action,
            clear: action,
            status: computed,
        });
    }

    get status() {
        return this._status.get();
    }

    get isReady() {
        return this.status === LoadingStatusEnum.READY;
    }

    get sellerId() {
        this.logger?.log('get sellerId', {status: this.status, sellerId: this._sellerId.get()});
        if (this.status === LoadingStatusEnum.NEW) {
            this.logger?.log("fetch seller from session's storage");
            this.setStatus(LoadingStatusEnum.IN_PROGRESS);
            this.sessionStore
                .get(SESSION_STORE_KEY)
                .then((value) => {
                    this.logger?.log('sellerId from sessionStore:', {sellerId: value?.sellerId});
                    this.updateSellerId(value?.sellerId ?? null);
                    this.setStatus(LoadingStatusEnum.READY);
                })
                .catch(logError('SellerSessionStorage.getInitialSellerId'));
        }
        if (this.status === LoadingStatusEnum.READY) {
            return this._sellerId.get();
        }
    }

    setSellerId(sellerId: string | null) {
        this.logger?.log('set sellerId', {sellerId});
        this.updateSellerId(sellerId);
        this.sessionStore.set(SESSION_STORE_KEY, {sellerId}).catch(logError('SellerSessionStorage.setSellerId'));
    }

    setStatus(status: LoadingStatusEnum) {
        this._status.set(status);
    }

    updateSellerId(sellerId: string | null) {
        this._sellerId.set(sellerId);
    }

    async clear() {
        this.logger?.log("clearing seller's session");
        this.setStatus(LoadingStatusEnum.NEW);
        this.updateSellerId(null);
        await this.sessionStore.remove(SESSION_STORE_KEY);
    }
}

export const createSellerSessionStorage = (sessionStore: Storage<SellerSessionStorageValue>, logger?: Logger) =>
    new SellerSessionStorage(sessionStore, logger);
