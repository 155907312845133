import {Query, WhereFilterOp} from '@joyrideautos/ui-services/src/firebase/Firestore';
import {
    DocumentSnapshot,
    Firestore,
    query,
    Query as FirebaseFirestoreQuery,
    where,
    startAt,
    startAfter,
    orderBy,
    limitToLast,
    limit,
    endBefore,
    endAt,
    collectionGroup,
} from 'firebase/firestore';

export class FirestoreCollectionGroupQuery<T> implements Query<T> {
    private _query: FirebaseFirestoreQuery<T>;

    constructor(private firestore: Firestore, path: string) {
        this._query = collectionGroup(this.firestore, path) as FirebaseFirestoreQuery<T>;
    }

    get query() {
        return this._query;
    }

    endAt(snapshot: DocumentSnapshot<T>): Query<T>;
    endAt(...fieldValues: any[]): Query<T>;
    endAt(...snapshot: any[]): Query<T> {
        this._query = query(this._query, endAt(...snapshot));
        return this;
    }

    endBefore(snapshot: DocumentSnapshot<T>): Query<T>;
    endBefore(...fieldValues: any[]): Query<T>;
    endBefore(...snapshot: any[]): Query<T> {
        this._query = query(this._query, endBefore(...snapshot));
        return this;
    }

    limit(value: number): Query<T> {
        this._query = query(this._query, limit(value));
        return this;
    }

    limitToLast(limit: number): Query<T> {
        this._query = query(this._query, limitToLast(limit));
        return this;
    }

    orderBy(fieldPath: string, directionStr?: 'asc' | 'desc'): Query<T> {
        this._query = query(this._query, orderBy(fieldPath as string, directionStr || 'asc'));
        return this;
    }

    startAfter(snapshot: DocumentSnapshot<T>): Query<T>;
    startAfter(...fieldValues: any[]): Query<T>;
    startAfter(...snapshot: any[]): Query<T> {
        this._query = query(this._query, startAfter(...snapshot));
        return this;
    }

    startAt(snapshot: DocumentSnapshot<T>): Query<T>;
    startAt(...fieldValues: any[]): Query<T>;
    startAt(...snapshot: any[]): Query<T> {
        this._query = query(this._query, startAt(...snapshot));
        return this;
    }

    where(fieldPath: string, opStr: WhereFilterOp, value: unknown): Query<T> {
        this._query = query(this._query, where(fieldPath as string, opStr, value));
        return this;
    }
}
