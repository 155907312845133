import _toUpper from 'lodash/toUpper';
import _toLower from 'lodash/toLower';
import capitalize from 'lodash/capitalize';
import {formatNumberWithCommas} from './numberWithCommas';
import {formatNumber} from './numberUtil';

export function replaceSpacesWithUnderscores(value: string | undefined): string | undefined {
    if (!value || value.length === 0) {
        return value;
    }
    return value.replace(/ /g, '_');
}

export function compareStrings(a?: string, b?: string, reverse = false) {
    if (a == null) {
        return reverse ? -1 : 1;
    }
    if (b == null) {
        return reverse ? 1 : -1;
    }
    const A = a.toUpperCase();
    const B = b.toUpperCase();

    let result = 0;
    if (A < B) {
        result = reverse ? 1 : -1;
    } else if (A > B) {
        result = reverse ? -1 : 1;
    }
    return result;
}

function toUpper(value: string | number | undefined | null, defaultValue = ''): string {
    if (value != null) {
        return _toUpper(String(value));
    }
    return _toUpper(defaultValue);
}

function toLower(value: string | number | undefined | null, defaultValue = ''): string {
    if (value != null) {
        return _toLower(String(value));
    }
    return _toLower(defaultValue);
}

export {toUpper, toLower, capitalize};

export const getSafeFileName = (name: string) => name.replaceAll(/[\s:]/g, '_');

export function isEqualString(str: string, options?: {caseSensitive: boolean}) {
    const caseSensitive = options?.caseSensitive ?? false;
    return (x: any) => {
        if (typeof x != 'string') {
            return false;
        }
        if (caseSensitive) {
            return x.trim() === str.trim();
        }
        return toUpper(x.trim()) === toUpper(str.trim());
    };
}

// https://en.wikipedia.org/wiki/Damerau%E2%80%93Levenshtein_distance
export function getStringAlignmentDistance(a: string) {
    return function (b: string) {
        Array.from(new Array(10));
        const dp = new Array(a.length + 1).fill(0).map(() => new Array(b.length + 1).fill(0));

        for (let i = 0; i <= a.length; i++) {
            dp[i][0] = i;
        }
        for (let j = 0; j <= b.length; j++) {
            dp[0][j] = j;
        }

        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                if (a[i - 1] === b[j - 1]) {
                    dp[i][j] = dp[i - 1][j - 1];
                } else {
                    dp[i][j] = 1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]);
                }
            }
        }

        return dp[a.length][b.length];
    };
}

export function safeToStringUpperCase<T>(value: T): string | T {
    if (value === undefined || value === null) {
        return value;
    }
    return String(value).toUpperCase();
}

export function safeToString<T>(value: T): string | T {
    if (value === undefined || value === null) {
        return value;
    }
    return String(value);
}

/**
 * @deprecated use String.prototype.reduceToMaxLength instead
 */
export function reduceString(str: string, length: number) {
    return str.length > length ? str.substring(0, length - 1) + '…' : str;
}

/** @example
 *   getPathsToSubFolders("My Drive/g_drive_folder_import/2023-06-16") ->
 *     ['My Drive', 'My Drive/g_drive_folder_import', 'My Drive/g_drive_folder_import/2023-06-16']
 */
export function getPathsToSubFolders(path: string) {
    return path.split('/').reduce((allPaths, foldderName) => {
        const prevPath = allPaths.at(-1);
        allPaths.push(prevPath ? [prevPath, foldderName].join('/') : foldderName);
        return allPaths;
    }, [] as string[]);
}

export function stringIncludesValue(s: undefined | string, value: string) {
    return (s || '').toLowerCase().includes(value.toLowerCase());
}

export const randomStringGenerator =
    (symbols: string[]) =>
    (length: number): string => {
        return Array.from(new Array(length)).reduce(
            (prev) => `${prev}${symbols[Math.floor(Math.random() * symbols.length)]}`,
            ''
        );
    };

export function formatAmount(value: number | null | undefined) {
    return `$${formatNumberWithCommas(formatNumber(value))}`;
}

export function pluralize(value: string, count: number, suffix = 's') {
    return `${value}${count !== 1 ? suffix : ''}`;
}
