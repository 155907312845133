import BaseRootStoreAwareViewModel from '@joyrideautos/ui-models/src/stores/rootStore/RootStoreAwareViewModel';
import {PresentationStoreType} from './stores/PresentationStore';

const RootStoreAwareViewModel = BaseRootStoreAwareViewModel.named('').views((self) => ({
    get presentationStore() {
        return self.getStoreByName<PresentationStoreType>('presentationStore');
    },
}));

export default RootStoreAwareViewModel;
