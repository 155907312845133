import {memo, useEffect, useState} from 'react';
import {notification} from 'antd';
import {autorun, reaction, when} from 'mobx';
import {PersistedNotificationFactory} from './PersistedNotificationFactory';
import {TransientEventNotificationFactory} from './TransientEventNotificationFactory';
import {useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import {useRootStore} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';
import {RootStoreType} from '@models/stores/types';
import {NotificationsQueue} from '@models/stores/NotificationsQueue';
import {IDisposer, getSnapshot, onSnapshot} from 'mobx-state-tree';
import {EventType} from '@joyrideautos/ui-models/src/types/events/Event';
import {ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import {NotificationKindEnum} from '@joyrideautos/ui-models/src/types/events/Notification';

const createNotificationDisposer = () => {
    let _disposer: IDisposer | null;
    return (disposer: IDisposer | null) => {
        if (_disposer) {
            _disposer();
        }
        _disposer = disposer;
    };
};

const createNotificationQueueFactory = () => {
    let _queue: NotificationsQueue;
    return (durationInSec: number) => {
        if (_queue) {
            _queue.clear();
        }
        _queue = NotificationsQueue.getInstance({
            waitInCurrentQueueDurationMs: (durationInSec + 3) * ONE_SEC,
            queueSize: 1,
        });
        return _queue;
    };
};

const notificationQueueFactory = createNotificationQueueFactory();

const NotificationComponent = memo(
    () => {
        const rootStore = useRootStore<RootStoreType>();
        const router = useRouter();

        const duration = rootStore.globalConfigStore.notificationDurationSec.value;

        const [queue, setQueue] = useState(() => notificationQueueFactory(duration));

        useEffect(() => {
            return onSnapshot(rootStore.globalConfigStore.notificationDurationSec, (snapshot) => {
                setQueue(notificationQueueFactory(snapshot.value));
            });
        }, [rootStore]);

        useEffect(() => {
            const {eventsStore, authUserStore, globalConfigStore} = rootStore;
            const disposers: (() => void)[] = [];
            const notificationDisposer = createNotificationDisposer();

            const notificationsListener = (uid: string) => (notifications: EventType[]) => {
                notifications.forEach((e) => {
                    let settings;
                    if (e.kind === NotificationKindEnum.TRANSIENT) {
                        settings = transientEventsNotificationFactory.createSettings(cast(e.type), e.params);
                        requestAnimationFrame(() => {
                            eventsStore.removeTransientEvents([e.key]);
                        });
                    } else if (e.kind === NotificationKindEnum.PERSISTED) {
                        settings = notificationFactory.createSettings(e, {router});
                        e.markPoppedAndSave(uid);
                    }
                    if (!settings) {
                        return null;
                    }
                    const {type = 'info', message = '', description = ''} = settings;
                    notification.open({
                        type,
                        message,
                        description,
                        duration: globalConfigStore.notificationDurationSec.value,
                    });
                });
            };

            disposers.push(
                when(
                    () => !!authUserStore.userInfo?.uid,
                    () => {
                        const uid = authUserStore.userInfo?.uid;
                        if (!uid) {
                            return;
                        }
                        const d = notificationsListener(uid);
                        notificationDisposer(queue.setListener(d));
                    }
                )
            );

            disposers.push(
                reaction(
                    () => authUserStore.userInfo?.uid,
                    (uid) => {
                        if (!uid) {
                            return;
                        }
                        const d = notificationsListener(uid);
                        notificationDisposer(queue.setListener(d));
                    }
                )
            );
            disposers.push(
                autorun(() => {
                    eventsStore.transientNotifications
                        .map((event) => getSnapshot(event))
                        .forEach((event) => queue.enqueue(event as any));
                })
            );

            disposers.push(
                autorun(() => {
                    eventsStore.popupNotifications.forEach((e) => queue.enqueue(e));
                })
            );

            return () => {
                notificationDisposer(null);
                disposers.forEach((d) => d());
                queue.clear();
            };
        }, [rootStore, queue, router]);

        return null;
    },
    () => true
);

const notificationFactory = new PersistedNotificationFactory();
const transientEventsNotificationFactory = new TransientEventNotificationFactory();

export default NotificationComponent;
