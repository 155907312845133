import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';

export type Nullable<T> = T | null;
export type Undefinable<T> = T | undefined;

export const loadCustomScript = (scriptUrl: string, handler: () => void) => {
    const exiting = Array.from(document.scripts).filter((script) => script.src === scriptUrl);
    if (exiting.length > 0) {
        handler();
        return;
    }
    const scriptElement = makeScriptElement(scriptUrl);
    document.getElementsByTagName('head')[0].appendChild(scriptElement);
    scriptElement.onload = handler;
};

export const loadCustomScriptWithPromise = (scriptUrl: string, attributes?: Record<string, string>): Promise<void> => {
    const exiting = Array.from(document.scripts).filter((script) => script.src.endsWith(scriptUrl));
    if (exiting.length > 0) {
        return Promise.resolve();
    }

    return new Promise<void>((resolve, _reject) => {
        const scriptElement = makeScriptElement(scriptUrl);
        document.getElementsByTagName('head')[0].appendChild(scriptElement);
        if (attributes) {
            for (const [key, value] of Object.entries(attributes)) {
                scriptElement.setAttribute(key, value);
            }
        }
        scriptElement.onload = () => {
            resolve();
        };
        scriptElement.onerror = (e: any) => {
            console.log('error', e);
        };
    });
};

export const makeScriptElement = (url: string) => {
    const scriptElement = document.createElement('script');
    scriptElement.src = url;
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    return scriptElement;
};

type sortingFactoryItemType = {[key: string]: any};

export const sortingFactory = (...orders: [string, SortingEnum][]) => {
    return (a: sortingFactoryItemType, b: sortingFactoryItemType) => {
        for (const [field, order] of orders) {
            const direction = order === SortingEnum.DESC ? -1 : 1;
            if (a[field] < b[field]) {
                return -direction;
            } else if (a[field] > b[field]) {
                return direction;
            }
        }
        return 0;
    };
};

export function encodeUserEmail(value: string) {
    return window.btoa(value);
}

export function decodeUserEmail(value: string) {
    return window.atob(value);
}

export function normalizeURL(urlOrPath: string): string {
    const url = !urlOrPath.startsWith('http') ? `https://${urlOrPath}` : urlOrPath;
    return url.endsWith('/') ? url.slice(0, -1) : url;
}

// https://stackoverflow.com/questions/57962986/react-how-to-programmatically-select-text-on-component-click
export function selectContents(el: any) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection()!;
    sel.removeAllRanges();
    sel.addRange(range);
}
