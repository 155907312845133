import {
    ListingAuctionStateEnum,
    SequenceAuctionStateEnum,
} from '@joyrideautos/ui-models/src/liveAuctionStateModel/statemachines/types';
import {Routes} from '../../Routes';

export function getLiveAuctionAlertOption(
    auctionState: SequenceAuctionStateEnum | ListingAuctionStateEnum,
    pagePath: string
): LiveAuctionAlertOptionEnum | undefined {
    const optionForTheState = liveAuctionAlertOptionsConfig[auctionState];
    if (typeof optionForTheState === 'object') {
        return optionForTheState[pagePath];
    }
    return optionForTheState; // = found string or undefined
}

export enum LiveAuctionAlertOptionEnum {
    DISCONNECTED = 'DISCONNECTED',
    BEFORE_PREBID__AUCTION_OCCURRENCE = 'BEFORE_PREBID__AUCTION_OCCURRENCE',
    BEFORE_PREBID__VEHICLE_PROFILE = 'BEFORE_PREBID__VEHICLE_PROFILE',
    PREBID__AUCTION_OCCURRENCE = 'PREBID__AUCTION_OCCURRENCE',
    PREBID__VEHICLE_PROFILE = 'PREBID__VEHICLE_PROFILE',
    BEFORE_START__VEHICLE_PROFILE = 'BEFORE_START__VEHICLE_PROFILE',
    BEFORE_START__AUCTION_OCCURRENCE = 'BEFORE_START__AUCTION_OCCURRENCE',
    BEFORE_BIDDING__AUCTION_OCCURRENCE = 'BEFORE_BIDDING__AUCTION_OCCURRENCE',
    BIDDING__AUCTION_OCCURRENCE = 'BIDDING__AUCTION_OCCURRENCE',
    BIDDING__VEHICLE_PROFILE = 'BIDDING__VEHICLE_PROFILE',
    ENDED__VEHICLE_PROFILE = 'ENDED__VEHICLE_PROFILE',
    ENDED__AUCTION_OCCURRENCE = 'ENDED__AUCTION_OCCURRENCE',
    AFTER_BIDDING = 'AFTER_BIDDING',
}

type liveAuctionAlertOptionsConfigType = {
    [state in SequenceAuctionStateEnum]?:
        | LiveAuctionAlertOptionEnum
        | {
              [route: string]: LiveAuctionAlertOptionEnum;
          };
};

export const liveAuctionAlertOptionsConfig: liveAuctionAlertOptionsConfigType = {
    beforePreBid: {
        [Routes.AUCTION_OCCURRENCE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.BEFORE_PREBID__AUCTION_OCCURRENCE,
        [Routes.AUCTION_INFO({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.BEFORE_PREBID__AUCTION_OCCURRENCE,
        [Routes.VEHICLE_PROFILE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
        })]: LiveAuctionAlertOptionEnum.BEFORE_PREBID__VEHICLE_PROFILE,
    },
    preBid: {
        [Routes.AUCTION_OCCURRENCE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.PREBID__AUCTION_OCCURRENCE,
        [Routes.AUCTION_INFO({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.PREBID__AUCTION_OCCURRENCE,
        [Routes.VEHICLE_PROFILE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
        })]: LiveAuctionAlertOptionEnum.PREBID__VEHICLE_PROFILE,
    },
    beforeStart: {
        [Routes.AUCTION_OCCURRENCE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.BEFORE_START__AUCTION_OCCURRENCE,
        [Routes.VEHICLE_PROFILE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
        })]: LiveAuctionAlertOptionEnum.BEFORE_START__VEHICLE_PROFILE,
    },
    beforeBidding: {
        [Routes.AUCTION_OCCURRENCE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.BEFORE_BIDDING__AUCTION_OCCURRENCE,
    },
    bidding: {
        [Routes.AUCTION_OCCURRENCE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.BIDDING__AUCTION_OCCURRENCE,
        [Routes.VEHICLE_PROFILE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
        })]: LiveAuctionAlertOptionEnum.BIDDING__VEHICLE_PROFILE,
    },
    endedRecently: {
        [Routes.AUCTION_OCCURRENCE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.ENDED__AUCTION_OCCURRENCE,
        [Routes.AUCTION_INFO({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.ENDED__AUCTION_OCCURRENCE,
        [Routes.VEHICLE_PROFILE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
        })]: LiveAuctionAlertOptionEnum.ENDED__VEHICLE_PROFILE,
    },
    ended: {
        [Routes.AUCTION_OCCURRENCE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.ENDED__AUCTION_OCCURRENCE,
        [Routes.AUCTION_INFO({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
        })]: LiveAuctionAlertOptionEnum.ENDED__AUCTION_OCCURRENCE,
        [Routes.VEHICLE_PROFILE({
            pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
        })]: LiveAuctionAlertOptionEnum.ENDED__VEHICLE_PROFILE,
    },
    afterBidding: LiveAuctionAlertOptionEnum.AFTER_BIDDING,
    biddingResult: LiveAuctionAlertOptionEnum.AFTER_BIDDING,
};
