import {GlobalConfig} from '@joyrideautos/auction-core/src/types/GlobalConfig';
import {BaseService} from './BaseService';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {UpdateGlobalConfigRPCReqData} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/admin/updateGlobalConfigReqDataTypes';

export class GlobalConfigService extends BaseService {
    subscribeToGlobalConfig(listener: (globalConfig: GlobalConfig) => void): () => void {
        return this.firebase.database.subscribeToSnapshot(`/globalConfig`, (configSnapshot) => {
            if (configSnapshot.exists()) {
                const config = configSnapshot.toJSON() as GlobalConfig | null;
                listener(config ?? {});
            }
        });
    }

    async updateGlobalConfig(data: UpdateGlobalConfigRPCReqData): Promise<void> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_UPDATE_GLOBAL_CONFIG)(data);
    }
}
