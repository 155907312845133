import React, {FC} from 'react';
import {CloseOutlined, DownloadOutlined} from '@ant-design/icons';
import {Alert, Button, Col, Modal, Row} from 'antd';
import {ModalProps} from 'antd/lib/modal';
import {PDFViewer} from '../../../components/PDFViewer';
import {Only} from '@joyrideautos/ui-common/src/components/Only';
import {Loading} from '@joyrideautos/web-common-components/src/components/loading/Loading';
import './PDFModal.less';

interface PDFModalProps extends ModalProps {
    onDownload: () => void;
    title: string;
    url?: string;
    loading: boolean;
    error?: string;
}

export const PDFModal: FC<PDFModalProps> = ({onDownload, title, url, loading, error, ...rest}) => {
    return (
        <Modal {...rest} className="modal-pdf" footer={null} closable={false}>
            <Only when={!!error}>
                <Alert type="error" message={error} />
            </Only>
            <Only when={loading}>
                <Loading />
            </Only>
            <Only when={!loading}>
                <>
                    <Row className="top-modal-tools container" align="middle" justify="space-between">
                        <Col span={12}>
                            <Button type="link" size="large" className="btn-close-modal" onClick={rest.onCancel}>
                                <CloseOutlined />
                            </Button>
                            <Button type="link" size="large" className="file-name">
                                {title}
                            </Button>
                        </Col>
                        <Col span={12} className="text-right">
                            <Button type="primary" icon={<DownloadOutlined />} size="large" onClick={onDownload}>
                                Download
                            </Button>
                        </Col>
                    </Row>
                    {url && <PDFViewer link={url} />}
                </>
            </Only>
        </Modal>
    );
};
