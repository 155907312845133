import {types} from 'mobx-state-tree';
import {NotificationFilterEnum} from '@models/stores/NotificationFilters';
import {createRecentOldestSortingViewModel} from '@joyrideautos/ui-models/src/RecentOldestSortingViewModel';
import {Event} from '@joyrideautos/ui-models/src/types/events/Event';
import {reaction} from 'mobx';
import {createFiltersViewModel} from '../../pages/notifications/FiltersViewModel';
import {mapPersistedNotificationDtoToEventType} from '@joyrideautos/ui-models/src/types/events/mappers';
import primitives from '@joyrideautos/ui-models/src/Primitives';
import {subscribeAsyncModels} from '@joyrideautos/ui-models/src/SubscribeAsyncModels';
import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {WithKey} from '@joyrideautos/ui-services/src/firebase/types';
import {filterUndef} from '@joyrideautos/auction-utils/src/objectUtils';
import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';
import {composeDisposers} from '@joyrideautos/ui-models/src/utils/mobxUtils';
import {
    Notification,
    NotificationPayload,
    NotificationState,
} from '@joyrideautos/auction-core/src/types/events/notifications';

const NOTIFICATION_PAGE_SIZE = 10;

const createIteratorKey = ({filter, sorting}: {filter?: NotificationState; sorting: SortingEnum}) =>
    `${filter ?? '.'}-${sorting}`;

const mapNotificationFilterToNotificationState = (filter: NotificationFilterEnum) =>
    ({
        [NotificationFilterEnum.ALL]: undefined,
        [NotificationFilterEnum.NEW]: NotificationState.NEW,
        [NotificationFilterEnum.UNREAD]: NotificationState.POPPED,
        [NotificationFilterEnum.READ]: NotificationState.READ,
        [NotificationFilterEnum.OUTBID]: undefined,
        [NotificationFilterEnum.RESERVE_NOT_MET]: undefined,
        [NotificationFilterEnum.AUCTION]: undefined,
        [NotificationFilterEnum.VEHICLE]: undefined,
        [NotificationFilterEnum.UPLOAD]: undefined,
        [NotificationFilterEnum.G_DRIVE_FINISH_SUCCESS]: undefined,
    }[filter]);

export const createPersistedNotificationsContainer = (filter?: {
    name: keyof Notification;
    value: Notification[keyof Notification] | Notification[keyof Notification][];
}) =>
    types.optional(
        BaseViewModel.named('PersistedNotificationsContainer')
            .props({
                page: primitives.number(1),
                hasMore: primitives.boolean(true),
                lastResetAt: primitives.string(new Date().toISOString()),
                filterViewModel: createFiltersViewModel(),
                sortingViewModel: createRecentOldestSortingViewModel(),
                notifications: subscribeAsyncModels.array(Event, (self: any, events) => {
                    let _sorting = self.sortingViewModel.value;
                    let _filter = self.notificationStateFilter;
                    self.createIterator();
                    return composeDisposers([
                        reaction(
                            () => ({page: self.page.value}),
                            () => self.load()
                        ),
                        reaction(
                            () => ({sorting: self.sortingViewModel.value, filter: self.notificationStateFilter}),
                            ({sorting, filter}) => {
                                if (_sorting !== sorting || _filter !== filter) {
                                    events.onReset();
                                    self.reset();
                                }
                                _sorting = sorting;
                                _filter = filter;
                            }
                        ),
                    ]);
                }),
            })
            .volatile(() => ({
                iterators: new Map<
                    string,
                    AsyncGenerator<WithKey<Notification<NotificationPayload>>[], any, unknown>
                >(),
            }))
            .views((self) => ({
                get uid() {
                    return self.authUser?.uid;
                },
                get notificationStateFilter() {
                    return mapNotificationFilterToNotificationState(self.filterViewModel.value);
                },
                get iteratorKey() {
                    return createIteratorKey({
                        filter: this.notificationStateFilter,
                        sorting: self.sortingViewModel.value,
                    });
                },
                get iterator() {
                    return self.iterators.get(this.iteratorKey);
                },
                get filteredNotifications() {
                    // When the user changes the state of notifications - filter out them instead of reload the whole collection
                    return self.notifications.values.filter(({state}) =>
                        this.notificationStateFilter ? state === this.notificationStateFilter : true
                    );
                },
            }))
            .actions((self) => ({
                createIterator() {
                    if (!self.uid) {
                        return;
                    }
                    if (self.iterators.has(self.iteratorKey)) {
                        self.iterators.delete(self.iteratorKey);
                    }
                    const iterator = self.rootStore.eventsService.getPersistedNotificationsIterator(self.uid, {
                        limit: NOTIFICATION_PAGE_SIZE,
                        filters: filterUndef([
                            self.notificationStateFilter && {name: 'state', value: self.notificationStateFilter},
                            filter,
                        ]),
                        sorting: self.sortingViewModel.value,
                    });
                    self.iterators.set(self.iteratorKey, iterator);
                },
                reset() {
                    self.page.reset();
                    self.hasMore.setValue(true);
                    self.lastResetAt.setValue(new Date().toISOString());
                    this.createIterator();
                },
            }))
            .actions((self) => ({
                load: async () => {
                    if (!self.iterator) {
                        return;
                    }
                    const result = await self.iterator.next();
                    if (!result.done) {
                        const notifications = result.value
                            .map(mapPersistedNotificationDtoToEventType)
                            .filter(Event.is.bind(Event));
                        self.notifications.addValues(notifications);
                        // It's possible that all notifications in the chunk are invalid
                        // in this case trying to fetch a new chunk.
                        if (result.value.length !== notifications.length) {
                            (self as any).load();
                        }
                    } else {
                        self.hasMore.setValue(false);
                    }
                },
            })),
        {}
    );
