import {COMPANY_NAME} from '../constants/Constants';

export const getStripeErrorMessage = (error?: any) => {
    if (error) {
        if (error.payment_method && error.payment_method.card && error.payment_method.card.funding === 'prepaid') {
            return `We're sorry, ${COMPANY_NAME} does not accept pre-paid cards of any kind as payment methods`;
        }
        if (error.decline_code && ['stolen_card', 'insufficient_funds', 'fraudulent'].includes(error.decline_code)) {
            return "We're sorry, the card you entered did not pass our verifications, please check with your bank or use another card";
        }
        if (error.decline_code === 'do_not_honor') {
            return `The credit card issuer has declined to honor this card, please check for any fraud alert texts or emails, allow transactions from ${COMPANY_NAME}, and try again`;
        }
        if (error.decline_code === 'generic_decline') {
            return "We're sorry, one or more of our automated rules has prevented you from adding this card. Please contact support or try again later";
        }
    }
    return 'We were unable to verify your card at this time, please check the details and try again';
};
