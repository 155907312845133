import * as s from 'superstruct';

export enum AuditEventTypeEnum {
    IMPERSONATE_TOKEN_CREATE = 'IMPERSONATE_TOKEN_CREATE',
    IMPERSONATE_SESSION_START = 'IMPERSONATE_SESSION_START',
    IMPERSONATE_SESSION_END = 'IMPERSONATE_SESSION_END',
}

export const ImpersonateSessionBaseEventPayloadValidation = s.type({
    adminUid: s.string(),
    uid: s.string(),
    sessionId: s.string(),
});

export const ImpersonateSessionStartEventPayloadValidation = s.assign(
    ImpersonateSessionBaseEventPayloadValidation,
    s.type({})
);
export type ImpersonateSessionStartEventPayload = s.Infer<typeof ImpersonateSessionStartEventPayloadValidation>;

export const ImpersonateSessionEndEventPayloadValidation = s.assign(
    ImpersonateSessionBaseEventPayloadValidation,
    s.type({})
);
export type ImpersonateSessionEndEventPayload = s.Infer<typeof ImpersonateSessionEndEventPayloadValidation>;

export const AuditEventValidation = s.type({
    type: s.enums(Object.values(AuditEventTypeEnum)),
    payload: s.union([ImpersonateSessionStartEventPayloadValidation, ImpersonateSessionEndEventPayloadValidation]),
});

export type AuditEvent = s.Infer<typeof AuditEventValidation>;
