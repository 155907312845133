import {action, IObservableValue, makeObservable, observable} from 'mobx';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';

export class Timer {
    private _timer: IObservableValue<number>;
    private _interval: NodeJS.Timeout | undefined;

    private endTime = 0;

    constructor(private logger?: Logger) {
        makeObservable(this, {
            update: action,
            reset: action,
        });
        this._timer = observable.box(0);
    }

    start(endTimeInFuture: number, callback?: () => void) {
        this.logger?.log('start');
        this.clear();
        this.endTime = endTimeInFuture;

        this.update(Math.round((this.endTime - Date.now()) / ONE_SEC));
        this._interval = setInterval(() => {
            this.update(Math.round((this.endTime - Date.now()) / ONE_SEC));

            if (this._timer.get() < 1) {
                this.clear();
                callback && callback();
            }
        }, 1000);
    }

    update(value: number) {
        this.logger?.log('update', value);
        this._timer.set(value);
    }

    get() {
        this.logger?.log('get');
        return this._timer.get();
    }

    reset() {
        this.logger?.log('reset');
        this.clear();
        this._timer.set(0);
    }

    clear() {
        this.logger?.log('clear');
        this._interval && clearInterval(this._interval);
    }
}
