import {getParent, IAnyType, Instance, isAlive, SnapshotIn, types} from 'mobx-state-tree';
import RootStoreAwareViewModel from './stores/rootStore/RootStoreAwareViewModel';
import {IDisposer} from 'mobx-utils';
import {LoadingStatus} from './utils/LoadingStatus';

interface RootStoreType extends Instance<typeof RootStoreAwareViewModel> {}

export const subscribeAsyncModels = {
    value: <T extends IAnyType>(
        model: T,
        handler: (self: any) => (
            events: {
                onValue: (value?: SnapshotIn<T['Type']> | null) => void;
                onError: (error: string) => void;
                onReset: () => void;
            },
            disposers: IDisposer[]
        ) => () => void
    ) =>
        types.optional(
            types
                .model('SubscribeAsyncModels.Value', {
                    value: types.maybe(model),
                })
                .volatile(() => ({
                    disposers: [] as IDisposer[],
                    loadingStatus: new LoadingStatus(),
                }))
                .views((self) => ({
                    get parent(): any {
                        return getParent(self);
                    },
                    get loading() {
                        return self.loadingStatus.isInProgress;
                    },
                }))
                .actions((self) => ({
                    setValue(value?: T['Type'] | null) {
                        if (!isAlive(self)) return;
                        self.value = value || undefined;
                        self.loadingStatus.setReady();
                    },
                    setError(error: string) {
                        if (!isAlive(self)) return;
                        self.loadingStatus.setError(error);
                    },
                    reset() {
                        if (!isAlive(self)) return;
                        self.disposers.forEach((d) => d());
                        self.disposers = [];
                        self.value = undefined;
                        self.loadingStatus.setNew();
                    },
                }))
                .actions((self) => {
                    let disposer: IDisposer | null = null;
                    return {
                        afterCreate() {
                            self.loadingStatus.setInProgress();
                            disposer = handler(self.parent)(
                                {
                                    onValue: (value) => {
                                        if (!isAlive(self)) return;
                                        self.setValue(value);
                                    },
                                    onError: (error) => {
                                        if (!isAlive(self)) return;
                                        self.setError(error);
                                    },
                                    onReset: () => {
                                        if (!isAlive(self)) return;
                                        self.reset();
                                    },
                                },
                                self.disposers
                            );
                        },
                        beforeDestroy() {
                            self.disposers.forEach((d) => d());
                            disposer && disposer();
                        },
                    };
                }),
            {value: undefined}
        ),
    primitives: <T extends string | number>(
        initialValue: T,
        handler: (
            self: RootStoreType,
            events: {
                onValue: (value: T | null) => void;
                onError: (error: string) => void;
                onReset: () => void;
            },
            disposers: IDisposer[]
        ) => () => void
    ) => {
        return types.optional(
            types
                .model('SubscribeAsyncModels.Primitives')
                .volatile(() => ({
                    value: initialValue as typeof initialValue | null,
                    disposers: [] as IDisposer[],
                    loading: new LoadingStatus(),
                }))
                .views((self) => ({
                    get parent(): RootStoreType {
                        return getParent(self);
                    },
                }))
                .actions((self) => ({
                    setValue(value: typeof initialValue | null) {
                        if (!isAlive(self)) return;
                        self.value = value;
                        self.loading.setReady();
                    },
                    setError(error: string) {
                        if (!isAlive(self)) return;
                        self.loading.setError(error);
                    },
                    reset() {
                        if (!isAlive(self)) return;
                        self.disposers.forEach((d) => d());
                        self.disposers = [];
                        self.value = null;
                    },
                }))
                .actions((self) => {
                    let disposer: IDisposer | null = null;
                    return {
                        afterCreate() {
                            self.loading.setInProgress();
                            disposer = handler(
                                self.parent,
                                {
                                    onValue: (value) => {
                                        if (!isAlive(self)) return;
                                        self.setValue(value);
                                    },
                                    onError: (error) => {
                                        if (!isAlive(self)) return;
                                        self.setError(error);
                                    },
                                    onReset: () => {
                                        if (!isAlive(self)) return;
                                        self.reset();
                                    },
                                },
                                self.disposers
                            );
                        },
                        beforeDestroy() {
                            self.disposers.forEach((d) => d());
                            disposer && disposer();
                        },
                    };
                }),
            {}
        );
    },
    array: <T extends IAnyType>(
        model: T,
        handler: (
            self: RootStoreType,
            events: {
                onValues: (value: SnapshotIn<T['Type']>[]) => void;
                onError: (error: string) => void;
                onReset: () => void;
            },
            disposers: IDisposer[]
        ) => () => void
    ) => {
        return types.optional(
            types
                .model('AsyncModels.Array', {
                    _value: types.array(model),
                })
                .volatile(() => ({
                    disposers: [] as IDisposer[],
                    loadingStatus: new LoadingStatus(),
                }))
                .views((self) => ({
                    get parent(): RootStoreType {
                        return getParent(self);
                    },
                    get values() {
                        return self._value.slice();
                    },
                    get loading() {
                        return self.loadingStatus.isInProgress;
                    },
                }))
                .actions((self) => ({
                    setValues(values: SnapshotIn<typeof model['Type']>[]) {
                        if (!isAlive(self)) return;
                        self._value.replace(values);
                        self.loadingStatus.setReady();
                    },
                    addValues(values: SnapshotIn<typeof model['Type']>[]) {
                        if (!isAlive(self)) return;
                        self._value.push(...values);
                        self.loadingStatus.setReady();
                    },
                    setError(error: string) {
                        if (!isAlive(self)) return;
                        self.loadingStatus.setError(error);
                    },
                    reset() {
                        if (!isAlive(self)) return;
                        self.disposers.forEach((d) => d());
                        self.disposers = [];
                        self._value.clear();
                        self.loadingStatus.setInProgress();
                    },
                }))
                .actions((self) => {
                    let disposer: IDisposer | null = null;
                    return {
                        afterCreate() {
                            self.loadingStatus.setInProgress();
                            disposer = handler(
                                self.parent,
                                {
                                    onValues: (values) => {
                                        if (!isAlive(self)) return;
                                        self.setValues(values);
                                    },
                                    onError: (error) => {
                                        if (!isAlive(self)) return;
                                        self.setError(error);
                                    },
                                    onReset: () => {
                                        if (!isAlive(self)) return;
                                        self.reset();
                                    },
                                },
                                self.disposers
                            );
                        },
                        beforeDestroy() {
                            self.disposers.forEach((d) => d());
                            disposer && disposer();
                        },
                    };
                }),
            {}
        );
    },
};
