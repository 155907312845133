import Queue from '@joyrideautos/auction-core/src/utils/Queue';
import {QueueType} from '@joyrideautos/auction-core/src/utils/QueueType';
import {EventType} from '@joyrideautos/ui-models/src/types/events/Event';
import {ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';

const DEFAULT_QUEUE_SIZE = 5;
const WAIT_IN_CURRENT_QUEUE_DURATION = 6 * ONE_SEC;

export class NotificationsQueue implements QueueType<EventType> {
    private currentQueue = new Queue<EventType>();
    private nextQueue = new Queue<EventType>();
    private listeners: ((events: EventType[]) => void)[] = [];
    private isScheduled = false;
    private timeout?: NodeJS.Timeout;
    private keys = new Set<string>();
    private processedKeys = new Set<string>();

    private constructor(
        private waitInCurrentQueueDuration = WAIT_IN_CURRENT_QUEUE_DURATION,
        private queueSize = DEFAULT_QUEUE_SIZE
    ) {}

    static getInstance(params?: {waitInCurrentQueueDurationMs?: number; queueSize?: number}) {
        return new NotificationsQueue(params?.waitInCurrentQueueDurationMs, params?.queueSize);
    }

    setListener(listener?: (events: EventType[]) => void) {
        if (listener) {
            this.listeners.push(listener);
            this.scheduleDequeue();
        }
        return () => {
            this.listeners = this.listeners.filter((l) => l !== listener);
        };
    }

    enqueue(event: EventType): void {
        if (this.processedKeys.has(event.key)) {
            return;
        }
        this.processedKeys.add(event.key);
        if (this.currentQueue.size < this.queueSize) {
            this.enqueueAndSchedule(event);
        } else {
            this.nextQueue.enqueue(event);
        }
    }

    dequeue(): EventType | undefined {
        const event = this.currentQueue.dequeue();
        if (event) {
            this.keys.delete(event.key);
        }
        if (this.nextQueue.size > 0) {
            const nextEvent = this.nextQueue.dequeue()!;
            this.enqueueAndSchedule(nextEvent);
        }
        return event;
    }

    notify(event: EventType) {
        this.listeners.forEach((l) => l([event]));
    }

    get size(): number {
        return this.currentQueue.size + this.nextQueue.size;
    }

    peek() {
        return this.currentQueue.peek();
    }

    get peekAll() {
        return [...this.currentQueue.peekAll, ...this.nextQueue.peekAll];
    }

    clear() {
        this.currentQueue.clear();
        this.nextQueue.clear();
        clearTimeout(this.timeout);
        this.isScheduled = false;
    }

    print(): void {
        console.log({
            current: this.currentQueue.peekAll.map((item) => item.key),
            next: this.nextQueue.peekAll.map((item) => item.key),
        });
    }

    private enqueueAndSchedule(event: EventType) {
        if (this.keys.has(event.key)) {
            return;
        }
        this.keys.add(event.key);
        this.currentQueue.enqueue(event);
        this.scheduleDequeue();
    }

    private scheduleDequeue() {
        if (this.isScheduled) {
            return;
        }
        const processDequeue = () => {
            if (this.currentQueue.size > 0) {
                this.isScheduled = true;
                const events = this.currentQueue.peekAll;
                events.forEach((event) => this.notify(event));
                this.timeout = setTimeout(() => {
                    events.forEach(() => this.dequeue());
                    processDequeue();
                }, this.waitInCurrentQueueDuration);
            } else {
                this.isScheduled = false;
            }
        };
        processDequeue();
    }
}
