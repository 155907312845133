import {
    TransientEventTypeEnum,
    NotificationItem as NotificationItemDto,
} from '@joyrideautos/auction-core/src/types/events/transient';
import {Instance, types} from 'mobx-state-tree';
import {AuctionSeriesReferenceByKey} from '../auction/Refs';
import {ItemInfo} from '../item/ItemInfo';
import {PersistedItemStatus} from '../item/PersistedItem';
import {Notification, NotificationKind, NotificationKindEnum} from './Notification';

export const VehicleStatusChangedEventPayload = types.model('VehicleStatusChangedPayload', {
    type: types.string,
    userId: types.string,
    vehicleId: types.string,
    statusFrom: PersistedItemStatus,
    statusTo: PersistedItemStatus,
    info: ItemInfo,
});
export const PaymentMethodBannedEventPayloadEventModel = types.model('PaymentMethodBannedEventPayload', {
    type: types.string,
    userId: types.string,
    paymentMethodName: types.maybe(types.string),
    last4: types.maybe(types.string),
});

export const TransientEventPayload = types.model('TransientEventPayload', {
    type: types.string,
    tmpValue: types.maybe(types.string),
    message: types.maybe(types.string),
    description: types.maybe(types.string),
    auctionSeries: types.maybe(AuctionSeriesReferenceByKey),
    reason: types.maybe(types.string),
    userEmail: types.maybe(types.string),
});

export interface ITransientEventTypeParams extends Instance<typeof TransientEventPayload> {}

function transientEventsPayloadTypeDispatcher({type}: any) {
    // TODO (Future): implement a map of MST models by event types
    if (type === TransientEventTypeEnum.VEHICLE_STATUS_CHANGE) {
        return VehicleStatusChangedEventPayload;
    }
    return TransientEventPayload;
}

export const LocalTransientEventKeyPrefix = 'localTransientEvent_';

export const NotificationItem = Notification.named('NotificationItem').props({
    kind: types.optional(NotificationKind, NotificationKindEnum.TRANSIENT),
    key: types.identifier,
    type: types.string,
    message: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    icon: types.optional(types.string, ''),
    params: types.maybe(
        types.union(
            {dispatcher: transientEventsPayloadTypeDispatcher},
            TransientEventPayload,
            VehicleStatusChangedEventPayload
        )
    ),
});

export interface NotificationItemType extends Instance<typeof NotificationItem> {}

export function fromNotificationItemDto<T>(event: NotificationItemDto & T): NotificationItemType & T {
    const {params, ...rest} = event;
    const {userId, ...restParams} = params as any;
    return {
        ...rest,
        params: {
            ...restParams,
            userId,
        },
    } as NotificationItemType & T;
}
