import {getParent, getSnapshot, Instance, types, flow} from 'mobx-state-tree';
import {BaseItemType} from '../types/item/BaseItem';
import RootStoreType from './Shared';
import {LoadingStatus, withStatus} from '../utils/LoadingStatus';
import BaseStore from './BaseStore';

export const ManagerRoleStore = BaseStore.named('ManagerRoleStore')
    .props({
        managerRoleUsersByUser: types.map(types.map(types.boolean)),
        managerRoleUsersBySeller: types.map(types.map(types.boolean)),
    })
    .volatile(() => ({
        disposer: null as null | (() => void),
        loadingStatus: new LoadingStatus(),
    }))
    .views((self) => {
        return {
            get authUserStore() {
                return (getParent(self) as RootStoreType).authUserStore;
            },
            get auctionsStore() {
                return (getParent(self) as RootStoreType).auctionsStore;
            },
            getUserId() {
                const userInfo = this.authUserStore.userInfo;
                return userInfo && userInfo.uid;
            },
            getManagedSellerIds(uid?: string): string[] {
                const result: string[] = [];
                const userId = uid || this.getUserId();
                const sellers = userId && self.managerRoleUsersByUser.get(userId);
                if (sellers) {
                    sellers.forEach((bool, sellerId) => {
                        result.push(sellerId);
                    });
                }

                return result;
            },
            hasManagerRoleForCompany(sellerId: string, uid?: string) {
                const userId = uid || this.getUserId();
                const sellers = userId && self.managerRoleUsersByUser.get(userId);
                return Boolean(sellers && sellers.get(sellerId));
            },
            hasManagerRoleForAuction(regionId: string, auctionId: string) {
                const auction = this.auctionsStore.findAuction(regionId, auctionId);
                const sellers = auction && auction.sellers ? (getSnapshot(auction.sellers!) as any) : {};
                return Object.keys(sellers).findIndex((sellerId) => this.hasManagerRoleForCompany(sellerId)) >= 0;
            },
            hasManagerRoleForItem(item: BaseItemType, uid?: string) {
                const userId = uid || this.getUserId();
                return Boolean(userId && this.hasManagerRoleForCompany(item.sellerId));
            },
            getAssociatedUsers(sellerId: string) {
                const users = self.managerRoleUsersBySeller.get(sellerId);
                return users ? Array.from(users.keys()) : [];
            },
        };
    })
    .actions((self) => ({
        setSellers(uid: string, sellerIds: string[]) {
            const managers = self.managerRoleUsersByUser.set(uid, {}).get(uid)!;
            sellerIds.forEach((sellerId) => {
                managers.set(sellerId, true);
                const users =
                    self.managerRoleUsersBySeller.get(sellerId) ||
                    self.managerRoleUsersBySeller.set(sellerId, {}).get(sellerId)!;
                users.set(uid, true);
            });
        },
    }))
    .actions((self) => {
        return {
            subscribe: function (uid?: string) {
                const userId = uid || self.getUserId();

                if (userId) {
                    self.disposer && self.disposer();
                    self.loadingStatus.setInProgress();
                    self.disposer = self.managerRoleService.subscribeToManagerSellers(userId, (sellerIds: string[]) => {
                        self.setSellers(userId, sellerIds);
                        self.loadingStatus.setReady();
                    });
                }
            },
            load: flow(function* (uid?: string) {
                const userId = uid || self.getUserId();
                if (userId) {
                    const sellerIds = yield withStatus(self.loadingStatus)(() =>
                        self.managerRoleService.fetchManagerSellers(userId)
                    );
                    self.setSellers(userId, sellerIds);
                }
            }),
        };
    })
    .actions((self) => {
        return {
            beforeDestroy: function () {
                self.disposer && self.disposer();
            },
        };
    });

export interface ManagerRoleStoreType extends Instance<typeof ManagerRoleStore> {}

export interface HasManagerRoleStore {
    managerRoleStore: ManagerRoleStoreType;
}
