import {getEnv, Instance} from 'mobx-state-tree';
import {autorun} from 'mobx';
import {now} from 'mobx-utils';
import {isAnonymous} from '@joyrideautos/auction-core/src/types/User';
import type {ItemType} from '../../types/item/Item';
import AuctionAwareViewModel from '../../types/auction/AuctionAwareViewModel';
import {isSequenceAuction} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import {DEFAULT_VEHICLE_TRANSITION_INTERVAL_SEC} from '@joyrideautos/auction-core/src/types/AuctionSeries';

export const LiveAuctionAlertViewModel = AuctionAwareViewModel.named('LiveAuctionAlertViewModel')
    .props({
        isConnected: true,
    })
    .volatile(() => ({
        unsubscribers: [] as (() => void)[],
        disposers: [] as (() => void)[],
    }))
    .views((self) => {
        return {
            get path() {
                return getEnv(self).path;
            },
            get biddingProgress() {
                return self.liveAuctionStateModel?.biddingProgress || 0;
            },
            get isBiddingInProgress() {
                return Boolean(self.liveAuctionStateModel?.isBidding);
            },
            get auctionType() {
                const auction = self.auction;
                return auction ? auction.settings.auctionType : undefined;
            },
            get countdown(): number | undefined {
                return self.liveAuctionStateModel?.countDownTimer.get();
            },
            get currentBid(): number {
                const currentBid = self.liveAuctionStateModel?.currentWinningBid;
                return currentBid ? currentBid.amount : 0;
            },
            get vehicleDisplayName(): string {
                const currentItem = self.liveAuctionStateModel?.currentItem;
                return currentItem ? currentItem.itemTitle : '';
            },
            get vehiclesLeft(): number | undefined {
                return self.liveAuctionStateModel?.numberOfItemsLeftInAuction;
            },
            get timeToAuctionStart(): number {
                const startAuctionTime = self.liveAuctionStateModel?.startAuctionTime || 0;
                return Math.max(startAuctionTime - now(), 0);
            },
            get auctionStartTime(): Date | undefined {
                const startTimeString = isSequenceAuction(this.auctionType)
                    ? self.liveAuctionStateModel?.startAuctionTime
                    : self.liveAuctionStateModel?.startPreBidTime;
                return startTimeString ? new Date(startTimeString) : undefined;
            },
            get preBiddingStartTime(): Date | undefined {
                return self.liveAuctionStateModel?.startPreBidTime
                    ? new Date(self.liveAuctionStateModel.startPreBidTime)
                    : undefined;
            },
            get hasReminder(): boolean {
                return self.liveAuctionStateModel?.hasAuctionReminder() || false;
            },
            get vehicleTransitionInterval() {
                return (
                    self.auction?.settings.vehicleTransitionIntervalInSeconds ?? DEFAULT_VEHICLE_TRANSITION_INTERVAL_SEC
                );
            },
            get countDownInPercents(): number {
                const countDown = self.liveAuctionStateModel?.countDownTimer.get() || 0;
                const endBiddingIntermissionTime =
                    self.liveAuctionStateModel?.endBiddingIntermissionTime || this.vehicleTransitionInterval;
                return 100 - (100 * countDown) / endBiddingIntermissionTime;
            },
            get nextVehicle(): ItemType | undefined {
                return self.liveAuctionStateModel?.nextBiddingItem;
            },
            get isAnonymous(): boolean {
                return isAnonymous(self.authUserStore.userInfo);
            },
        };
    })
    .actions((self) => ({
        setReminder(): void {
            self.liveAuctionStateModel?.setAuctionReminder();
        },
        setIsConnected(isConnected: boolean) {
            self.isConnected = isConnected;
        },
    }))
    .actions((self) => {
        return {
            afterCreate() {
                self.disposers.push(
                    autorun(() => {
                        if (self.liveAuctionStateModel?.isLive) {
                            self.unsubscribers.push(
                                self.rootStore.firebaseService.subscribeToConnectionChanged(self.setIsConnected)
                            );
                        }
                    })
                );
            },
            beforeDestroy() {
                self.unsubscribers.forEach((unsubscriber) => unsubscriber());
                self.disposers.forEach((disposer) => disposer());
            },
        };
    });

export interface LiveAuctionAlertViewModelType extends Instance<typeof LiveAuctionAlertViewModel> {}
