import {flow, types} from 'mobx-state-tree';
import {observable} from 'mobx';
import i18n from '../../i18next.config';

export const LanguageStore = types
    .model('LanguageStore', {
        currentLanguage: types.optional(types.string, i18n.language),
    })
    .volatile(() => ({
        tFn: observable.box(i18n.t),
    }))
    .views((self) => ({
        t(key: string) {
            return self.tFn.get()(key);
        },
    }))
    .actions((self) => ({
        changeLanguage: flow(function* (language: string) {
            yield i18n.changeLanguage(language);
            self.currentLanguage = i18n.language;
            self.tFn.set(i18n.t);
        }),
    }));
