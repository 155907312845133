export interface Filter<T = any> {
    (event: T): boolean;
}

export const notFilter =
    <T>(filter: Filter<T>): Filter<T> =>
    (e) =>
        !filter(e);

export const andFilter =
    <T>(...filters: Filter<T>[]): Filter<T> =>
    (e) => {
        return filters.reduce((result: boolean, filter) => result && filter(e), true);
    };

export const orFilter =
    <T>(...filters: Filter<T>[]): Filter<T> =>
    (e) => {
        return filters.reduce((result: boolean, filter) => result || filter(e), false);
    };

export const trueFilter: Filter = () => true;
export const falseFilter: Filter = () => false;
