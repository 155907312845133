import AsyncQueue from './AsyncQueue';
import Logger from './logger/Logger';

type AsyncLoaderItem<T> = {key: string | symbol; loader: () => Promise<T>};

class AsyncLoaderQueue<T> extends AsyncQueue<AsyncLoaderItem<T>> {
    private processedKeys: Set<string | symbol> = new Set();

    constructor(private name: string, logger?: Logger) {
        super(logger);
    }

    enqueue(item: AsyncLoaderItem<T>) {
        if (this.processedKeys.has(item.key)) {
            return;
        }
        this.processedKeys.add(item.key);
        super.enqueue(item);
    }

    protected stringify(item: AsyncLoaderItem<T> | undefined): string {
        return `${this.name} ${item?.key.toString()}`;
    }

    async process(item: AsyncLoaderItem<T> | undefined): Promise<void> {
        if (!item) {
            return;
        }
        try {
            await item.loader();
        } catch (e: any) {
            this.logger?.log('error', e.message);
        }
    }
}

export default AsyncLoaderQueue;
