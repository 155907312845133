import differenceBy from 'lodash/differenceBy';
import partition from 'lodash/partition';
import flattenDepth from 'lodash/flattenDepth';
import lodashGroupBy from 'lodash/groupBy';
import lodashCountBy from 'lodash/countBy';
import without from 'lodash/without';
import first from 'lodash/first';
import range from 'lodash/range';
import {PrimitivesOrObjects} from './types';

export const windowedSlice = <T>(arr: T[], size: number) => {
    if (size <= 0) {
        throw Error(`Illegal argument: cannot split array into ${size}-length chunks`);
    }
    const newLen = Math.ceil(arr.length / size);
    return Array.from(Array(newLen).keys()).map((i) => {
        const ind = i * size;
        return arr.slice(ind, ind + size);
    });
};

export function difference<T>(a: readonly T[], b: readonly T[]) {
    return a.filter((value) => b.indexOf(value) < 0);
}

export function intersection<T>(a: T[], b: T[]) {
    const setB = new Set(b);
    return [...new Set(a)].filter((x) => setB.has(x));
}

export function flatMap<T, R>(a: T[], f: (v: T) => R[]) {
    return a.length ? a.map(f).reduce((xs, ys) => [...xs, ...ys]) : [];
}

export function flatten<T>(a: T[][]) {
    return a.length ? a.reduce((xs, ys) => [...xs, ...ys]) : [];
}

export function chunk<T>(array: T[], size: number): T[][] {
    const chunked_arr: T[][] = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

export function groupBy<T extends PrimitivesOrObjects, K, V extends PrimitivesOrObjects = T>(
    list: T[],
    keyGetter: (t: T) => K,
    valueGetter: (t: T) => V = (item) => item as unknown as V
): Map<K, V[]> {
    const map = new Map<K, V[]>();
    list.forEach((item) => {
        const key = keyGetter(item);
        const value = valueGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [value]);
        } else {
            collection.push(value);
        }
    });
    return map;
}

export function arrayToBoolObjMap(arr: (string | number)[]): Record<string, true> {
    return arr.reduce((res, val) => ({...res, [val]: true}), {});
}

export {differenceBy};
export {partition};
export {flattenDepth};
export {without};
export {lodashGroupBy};
export {first, range};
export {lodashCountBy};

export const selectRandomItemsFrom = <T>(items: T[], amount: number): T[] => {
    if (amount >= items.length) {
        return items;
    }

    const selectedItems: T[] = [];
    const selectedIdx = new Set<number>();

    const getUniqueRandomNumber = (upperBound: number): number => {
        const getRandomNumber = () => {
            return Math.floor(Math.random() * upperBound);
        };
        let idx = getRandomNumber();
        while (selectedIdx.has(idx)) {
            idx = getRandomNumber();
        }
        selectedIdx.add(idx);
        return idx;
    };

    for (let i = 0; i < amount; i++) {
        const idx = getUniqueRandomNumber(items.length);
        selectedItems.push(items[idx]);
    }
    return selectedItems;
};

export const lastNItems =
    (n: number) =>
    <T>(items: T[]) => {
        if (!items.length) {
            return [];
        }
        if (items.length <= n) {
            return items;
        }
        return items.slice(items.length - n - 1, items.length - 1);
    };

export const firstNItems =
    (n: number) =>
    <T>(items: T[]) => {
        if (!items.length) {
            return [];
        }
        if (items.length <= n) {
            return items;
        }
        return items.slice(0, n);
    };

export function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
    const copy = [...arr];
    const element = copy[fromIndex];
    copy.splice(fromIndex, 1);
    copy.splice(toIndex, 0, element);
    return copy;
}

export function unique<T>(arr: T | T[]): T[] {
    return [...new Set(Array.isArray(arr) ? arr : [arr])];
}

export function partitionArray<T>(arr: T[], predicate: (value: T) => boolean) {
    return arr.reduce(
        (acc, e) => {
            acc[predicate(e) ? 0 : 1].push(e);
            return acc;
        },
        [[] as T[], [] as T[]]
    );
}

export function findAndReplace(
    arr: (number | string)[],
    find: number | string,
    replace: number | string
): (number | string)[] {
    return arr.map((item) => (item === find ? replace : item));
}
