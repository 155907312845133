import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';
import {CarOutlined, CaretDownOutlined} from '@ant-design/icons';
import {isMobile} from 'react-device-detect';
import {Badge} from 'antd';

export const createMyVehiclesMenu = (badgeCount: number) => {
    const myVehiclesMenu = {
        label: <span>My Vehicles {!isMobile && <CaretDownOutlined />}</span>,
        key: 'my-vehicles',
        icon: <CarOutlined />,
        children: [
            {
                label: (
                    <NavLink to={Routes.MY_VEHICLES_AUCTIONS()} analyticsId="visit-my-vehicles-auctions">
                        My Auctions {badgeCount > 0 && <Badge className="my-vehicles-badge" count={badgeCount} />}
                    </NavLink>
                ),
                key: 'my-auctions',
            },
            {
                label: (
                    <NavLink to={Routes.MY_VEHICLES_BUY_NOW()} analyticsId="visit-my-vehicles-offers">
                        Offers/Buy Now
                    </NavLink>
                ),
                key: 'offers-buy-now',
            },
            {
                label: (
                    <NavLink to={Routes.MY_VEHICLES_FAVORITES()} analyticsId="visit-my-vehicles-favorites">
                        Favorites
                    </NavLink>
                ),
                key: 'favorites',
            },
        ],
    };
    return myVehiclesMenu;
};
