import {InputFieldStatus} from './Statutes';
import {action, makeObservable, observable} from 'mobx';
import {Validator} from '../validators/Validators';

type RadioGroupOnChange = (data?: string) => void;
export type RadioButtonValue = {key: string; value: string; label: string; extra?: string};

export interface IRadioGroupModel {
    status: InputFieldStatus;
    message?: string;
    validate: () => void;
    defaultValue?: string;
    values: RadioButtonValue[];
    onChange: RadioGroupOnChange;
}

export class RadioGroupModel {
    status: InputFieldStatus = 'normal';
    focused = false;
    changed = false;
    message: string | undefined = undefined;

    constructor(private options: RadioButtonValue[], public validator: Validator<string> = () => undefined) {
        makeObservable(this, {
            status: observable,
            message: observable,
            connect: action,
            setMessage: action,
        });
    }

    setMessage(value: string | undefined) {
        this.message = value;
    }

    shouldInvokeValidator = () => {
        return this.changed && !this.focused;
    };

    validate = (value: string | undefined, force = false) => {
        if (this.shouldInvokeValidator() || force) {
            this.setMessage(this.validator(value));
        }
    };

    connect = (
        x: string | undefined,
        onValueChanged: (value: string | undefined, key?: string) => void,
        key?: string
    ): IRadioGroupModel => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const factory = this;

        const obj = class implements IRadioGroupModel {
            get defaultValue(): string | undefined {
                return typeof x !== 'undefined' ? x : undefined;
            }

            get values() {
                return factory.options;
            }

            get status() {
                return factory.status;
            }

            onChange: RadioGroupOnChange = (data) => {
                onValueChanged(data, key);
                factory.changed = true;
            };

            get message() {
                return factory.message;
            }

            validate() {
                factory.validate(x, true);
            }

            onFocus: (event: React.SyntheticEvent<HTMLElement>) => void = () => {
                factory.focused = true;
                factory.validate(x);
            };

            onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
                factory.focused = false;
                factory.validate(x);
            };
        };
        return new obj();
    };
}
