import {cast, flow, getEnv, Instance, SnapshotIn, types} from 'mobx-state-tree';
import {WinningBid, WinningBidType, fromDto, mapFromDto} from '../types/WinningBid';
import {observable} from 'mobx';
import {LoadingStatus} from '../utils/LoadingStatus';
import {makeSubscriber} from '@joyrideautos/auction-utils/src/subscriptions';
import BaseStore from './BaseStore';
import type {BidService} from '@joyrideautos/ui-services/src/services/BidService';
import {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import {logError} from '@joyrideautos/ui-logger/src/utils';

function makeKey(regionId: string, auctionId: string) {
    return `${regionId}-${auctionId}`;
}

export const WinningBidContainer = types
    .model('WinningBidContainer')
    .props({
        regionId: types.string,
        auctionId: types.string,
        values: types.map(WinningBid),
    })
    .volatile(() => ({
        loadingStatus: new LoadingStatus(),
    }))
    .views((self) => ({
        get auctionPath() {
            return {
                regionId: self.regionId,
                auctionId: self.auctionId,
            };
        },
        get allWinningBids(): WinningBidType[] {
            return [...self.values.values()].filter(({deleted}) => !deleted);
        },
        getValue(itemKey: string): WinningBidType | undefined {
            return self.values.get(itemKey);
        },
        getUserWinningBids(uid: string): WinningBidType[] {
            return this.allWinningBids.filter((winningBid) => winningBid.uid === uid);
        },
        filterValues(
            filterFunc: (winningBid: WinningBidType, index: number, array: ReadonlyArray<WinningBidType>) => unknown
        ): WinningBidType[] {
            return this.allWinningBids.filter(filterFunc);
        },
        get length() {
            return this.allWinningBids.length;
        },
        get rootStore() {
            return getEnv(self).rootStore;
        },
        get logger() {
            return this.rootStore.getLogger(self.toString(), LoggerStatusEnum.ENABLED);
        },
        get bidService(): BidService {
            return getEnv(self).bidService;
        },
    }))
    .actions((self) => ({
        setValues: function (values: {[key: string]: WinningBidType}) {
            self.values = cast(values);
        },
        updateWinningBid(winningBid: SnapshotIn<typeof WinningBid>) {
            self.values.set(winningBid.itemId, winningBid);
        },
    }))
    .actions((self) => {
        const winningBidSubscribe = () => {
            if (self.loadingStatus.isReady) {
                let canceled = false;
                let unsubscribe: (() => void) | null = null;
                (async () => {
                    await self.bidService.checkAuctionBiddingEnded(self.regionId, self.auctionId);
                    if (canceled) {
                        return;
                    }
                    unsubscribe = self.bidService.subscribeToWinningBids(self.auctionPath, {
                        singleBidListener: (winningBid) => {
                            self.updateWinningBid(fromDto(winningBid));
                        },
                    });
                })().catch(logError('WinningBidContainer.subscribe'));
                return () => {
                    canceled = true;
                    unsubscribe && unsubscribe();
                };
            } else {
                self.loadingStatus.setInProgress();
                let canceled = false;
                let unsubscribe: (() => void) | null = null;
                // here we have IIFE which returns a promise (the function type, in this case, is: () => Promise<void>). and the 'no-floating-promises' rule catches this issue.
                (async () => {
                    await self.bidService.checkAuctionBiddingEnded(self.regionId, self.auctionId);
                    if (canceled) {
                        return;
                    }
                    unsubscribe = self.bidService.subscribeToWinningBids(self.auctionPath, {
                        allBidsListener: (bids) => {
                            if (!bids) {
                                self.loadingStatus.setReady();
                                return;
                            }
                            self.setValues(mapFromDto(bids));
                            self.loadingStatus.setReady();
                        },
                        singleBidListener: (winningBid) => {
                            self.updateWinningBid(fromDto(winningBid));
                        },
                    });
                })().catch(logError('WinningBidContainer.subscribe'));
                return () => {
                    self.logger.log('unsubscribe from winning bids');
                    canceled = true;
                    unsubscribe && unsubscribe();
                };
            }
        };
        return {
            subscribe() {
                return makeSubscriber(`WinningBidContainer-${self.regionId}-${self.auctionId}`, winningBidSubscribe)();
            },
            load: flow(function* () {
                if (self.loadingStatus.isReady) {
                    return;
                }
                try {
                    self.loadingStatus.setInProgress();
                    const bids = yield self.bidService.fetchWinningBids(self.regionId, self.auctionId);
                    self.setValues(bids);
                    self.loadingStatus.setReady();
                } catch (e) {
                    self.logger.log('failed to load winning bids', e);
                }
            }),
            async loadPersistedWinningBid(itemKey: string) {
                let winningBid = self.getValue(itemKey);
                if (!winningBid) {
                    self.loadingStatus.setInProgress();
                    winningBid = await self.rootStore.bidsService.fetchWinningBid(
                        self.regionId,
                        self.auctionId,
                        itemKey
                    );
                    winningBid && self.updateWinningBid(winningBid);
                    self.loadingStatus.setReady();
                }
                return winningBid;
            },
        };
    });

export interface WinningBidContainerType extends Instance<typeof WinningBidContainer> {}

export const WinningBidStore = BaseStore.named('WinningBidStore')
    .volatile(() => ({
        containers: observable.map<string, WinningBidContainerType>(),
    }))
    .actions((self) => ({
        setContainer(regionId: string, auctionId: string, container: WinningBidContainerType) {
            self.containers.set(makeKey(regionId, auctionId), container);
        },
        containerFor(regionId: string, auctionId: string): WinningBidContainerType {
            let container = self.containers.get(makeKey(regionId, auctionId));
            if (!container) {
                container = WinningBidContainer.create(
                    {regionId, auctionId},
                    {
                        rootStore: self.rootStore,
                        bidService: self.bidService,
                    }
                );
                this.setContainer(regionId, auctionId, container);
            }
            return container;
        },
    }))
    .views((self) => ({
        maybeContainerFor(regionId: string, auctionId: string): WinningBidContainerType | undefined {
            return self.containers.get(makeKey(regionId, auctionId));
        },
        containerForAsync(regionId: string, auctionId: string): WinningBidContainerType | undefined {
            const container = self.containers.get(makeKey(regionId, auctionId));
            if (!container) {
                requestAnimationFrame(() => self.containerFor(regionId, auctionId));
            }
            return container;
        },
    }));

export interface WinningBidStoreType extends Instance<typeof WinningBidStore> {}

export interface HasWinningBidStore {
    winningBidStore: WinningBidStoreType;
}
