export function promiseTimeout<T>(p: Promise<T>, ms: number): Promise<T> {
    const timeout = new Promise<T>((resolve, reject) => {
        const wait = setTimeout(() => {
            clearTimeout(wait);

            reject(`timeout in ${ms} ms.`);
        }, ms);
    });

    return Promise.race([p, timeout]);
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const runWithDelay = <T>(ms: number, cb: () => Promise<T>) =>
    new Promise<T>((resolve, reject) => setTimeout(() => cb().then(resolve).catch(reject), ms));

export const runNotFasterThan = async <T>(ms: number, job: Promise<T>): Promise<T> => {
    const before = Date.now();

    const res = await job;

    const duration = Date.now() - before;
    const timeToWait = Math.max(ms - duration, 0);

    if (timeToWait) {
        await delay(timeToWait);
    }

    return res;
};

export const promiseWithTimeout = <T>(timeoutMs: number, promise: () => Promise<T>, error: string | Error) => {
    let timeoutHandle: NodeJS.Timeout;
    const timeoutPromise = new Promise<never>((resolve, reject) => {
        timeoutHandle = setTimeout(() => reject(error), timeoutMs);
    });

    return Promise.race([promise(), timeoutPromise])
        .then((result) => {
            return result;
        })
        .finally(() => clearTimeout(timeoutHandle));
};
