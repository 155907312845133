import * as s from 'superstruct';
import {PersistedItemStatusEnum} from '../../../ItemTypes';
import {RPCAPIValidationError} from '../common';
import {ExistingSellerVehicleIdErrorMeta, InventoryErrorCodeEnum} from '../../../validations/itemValidation';

export interface ExistingSellerVehicleIdErrorRes extends RPCAPIValidationError<ExistingSellerVehicleIdErrorMeta> {}

export function isExistingSellerVehicleIdError(error: any): error is ExistingSellerVehicleIdErrorRes {
    return error?.details?.code === InventoryErrorCodeEnum.EXISTING_SELLER_VEHICLE_ID;
}

export const CloneVehicleDataRPCReqDataValidation = s.object({itemKey: s.string()});
export type CloneVehicleDataRPCReqData = s.Infer<typeof CloneVehicleDataRPCReqDataValidation>;

export type CloneVehicleDataRPCResData = {id: string; status: PersistedItemStatusEnum};
