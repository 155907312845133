import * as s from 'superstruct';

export enum NotificationEnum {
    WELCOME = 'WELCOME',
    BID_LOSS = 'BID_LOSS',
    BID_REJECT = 'BID_REJECT',
    ITEM_WON = 'ITEM_WON',
    ITEM_WON_EMAIL = 'ITEM_WON_EMAIL',
    ITEM_NOT_WON = 'ITEM_NOT_WON',
    VEHICLE_SOLD = 'VEHICLE_SOLD',
    ITEM_PAYMENT_SUCCESSFUL_EMAIL = 'ITEM_PAYMENT_SUCCESSFUL_EMAIL',
    ITEM_PAYMENT_SUCCESSFUL = 'ITEM_PAYMENT_SUCCESSFUL',
    ITEM_PAYMENT_FAILED_EMAIL = 'ITEM_PAYMENT_FAILED_EMAIL',
    ITEM_PAYMENT_FAILED = 'ITEM_PAYMENT_FAILED',
    AUCTION_START = 'AUCTION_START',
    AUCTION_WILL_START = 'AUCTION_WILL_START',
    AUCTION_SUMMARY = 'AUCTION_SUMMARY',
    AUCTION_ENDED = 'AUCTION_ENDED',
    FAVORITE_REGION_ADDED = 'FAVORITE_REGION_ADDED',
    FAVORITE_REGION_REMOVED = 'FAVORITE_REGION_REMOVED',
    FAVORITE_AUCTION_SERIES_ADDED = 'FAVORITE_AUCTION_SERIES_ADDED',
    FAVORITE_AUCTION_SERIES_REMOVED = 'FAVORITE_AUCTION_SERIES_REMOVED',
    FAVORITE_AUCTION_ADDED = 'FAVORITE_AUCTION_ADDED',
    FAVORITE_AUCTION_REMOVED = 'FAVORITE_AUCTION_REMOVED',
    FAVORITE_VEHICLE_ADDED = 'FAVORITE_VEHICLE_ADDED',
    FAVORITE_VEHICLE_REMOVED = 'FAVORITE_VEHICLE_REMOVED',
    USER_BANNED = 'USER_BANNED',
    USER_BAN_SUCCESS = 'USER_BAN_SUCCESS',
    USER_BAN_FAIL = 'USER_BAN_FAIL',
    USER_BANNED_GLOBALLY = 'USER_BANNED_GLOBALLY',
    PAYMENT_METHOD_BANNED = 'PAYMENT_METHOD_BANNED',
    VEHICLE_STATUS_CHANGE = 'VEHICLE_STATUS_CHANGE',
    BID_HOLD_FAILED_FOR_BUYER = 'BID_HOLD_FAILED_FOR_BUYER',
    BID_HOLD_SUCCESSFUL_FOR_BUYER = 'BID_HOLD_SUCCESSFUL_FOR_BUYER',
    G_DRIVE_START_SUCCESS = 'G_DRIVE_START_SUCCESS',
    G_DRIVE_START_ERROR = 'G_DRIVE_START_ERROR',
    G_DRIVE_FINISH_SUCCESS = 'G_DRIVE_FINISH_SUCCESS',
    G_DRIVE_FINISH_ERROR = 'G_DRIVE_FINISH_ERROR',
    UNSCHEDULE_VEHICLE = 'UNSCHEDULE_VEHICLE',
    RESERVE_NOT_MET = 'RESERVE_NOT_MET',
    UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
    UPLOAD_FAILED = 'UPLOAD_FAILED',
}

export const allNotificationTypes = Object.values(NotificationEnum);

export enum EventSubscriptionTypeEnum {
    AUCTION_START = 'AUCTION_START', // TODO: replace with AUCTION_REMINDER
    AUCTION_REMINDER = 'AUCTION_REMINDER',
    FAVORITE_REGION = 'FAVORITE_REGION',
    FAVORITE_AUCTION_SERIES = 'FAVORITE_AUCTION_SERIES',
    FAVORITE_AUCTION = 'FAVORITE_AUCTION',
}

export enum NotificationState {
    NEW = 1,
    POPPED = 1 << 1,
    READ = 1 << 2,
    SENT = 1 << 3,
}

export interface Notification<P = NotificationPayload> {
    params: P;
    type: NotificationEnum;
    state: NotificationState;
    timestamp?: number;
    eventDate?: string;
}

export interface EventSubscription {
    type: EventSubscriptionTypeEnum;
    uid: string;
    params: any;
}

// Payloads
// TODO: (Future) move all 'users' notification payload definitions to './user.ts'

export interface NotificationPayload {}

export const UserNotificationPayloadValidation = s.type({
    userId: s.string(),
});

export type UserPersistedNotificationPayload = s.Infer<typeof UserNotificationPayloadValidation>;
export type UserTransientNotificationPayload = s.Infer<typeof UserNotificationPayloadValidation>;

export const UserNotificationValidation = s.type({
    params: s.type({}),
    type: s.enums(allNotificationTypes),
    state: s.enums([NotificationState.NEW, NotificationState.POPPED, NotificationState.READ, NotificationState.SENT]),
    timestamp: s.optional(s.number()),
    eventDate: s.optional(s.string()),
});

export type UserNotificationPayload = s.Infer<typeof UserNotificationValidation>;

export type GetNotificationPayloadType<T> = T extends infer NotificationPayload ? NotificationPayload : never;

export const RegionNotificationPayloadValidation = s.type({
    regionId: s.string(),
});

export type RegionNotificationPayload = s.Infer<typeof RegionNotificationPayloadValidation>;

export const BroadcastAuctionNotificationPayloadValidation = s.assign(
    RegionNotificationPayloadValidation,
    s.type({
        auctionId: s.string(),
    })
);

export type BroadcastAuctionNotificationPayload = s.Infer<typeof BroadcastAuctionNotificationPayloadValidation>;

export const AuctionEndedNotificationPayloadValidation = BroadcastAuctionNotificationPayloadValidation;

export type AuctionEndedNotificationPayload = s.Infer<typeof AuctionEndedNotificationPayloadValidation>;
