// import {init, identify, shutdown, restart, setUserVars, anonymize} from '@fullstory/browser';

interface FullstoryConfig {
    publicAuctionUrl?: string;
}

export class FullstoryService {
    private fullStoryDebug = false;
    private fullstoryLoaded = false;
    private fullStoryOrgId: string | undefined = undefined;
    private pendingOps: {fn: (...a: any[]) => any; args: any[]}[] = [];

    constructor(private fullstory: any, private config: FullstoryConfig) {}

    setDebug(debug: boolean) {
        this.fullStoryDebug = debug;
    }

    init(orgId: string) {
        const hostWithoutSchema =
            this.config.publicAuctionUrl && this.config.publicAuctionUrl.replace(/^https?:\/\//, '');
        this.fullStoryOrgId = orgId;
        this.fullstory.init({
            orgId,
            devMode: process.env.NODE_ENV !== 'production',
            host: `${hostWithoutSchema}/fs-relay`,
            script: `${hostWithoutSchema}/fs-relay/s/fs.js`,
        });
        this.fullstoryLoaded = true;
        this.pendingOps.forEach(({fn, args}) => {
            this.fullStoryDebug && console.log(`pending fs: ${fn.name}(${JSON.stringify(args)})`);
            fn(...args);
        });
    }

    get identify() {
        return this.checkFullStory(this.fullstory.identify);
    }

    get anonymize() {
        return this.checkFullStory(this.fullstory.anonymize);
    }

    get shutdown() {
        return this.checkFullStory(this.fullstory.shutdown);
    }

    get restart() {
        return this.checkFullStory(this.fullstory.restart);
    }

    get setUserVars() {
        return this.checkFullStory(this.fullstory.setUserVars);
    }

    private checkFullStory = <T, A extends [any] | any[]>(
        fn: (...a: A) => T | undefined
    ): ((...args: A) => T | undefined) => {
        return (...args: A) => {
            if (!this.fullStoryOrgId) {
                return;
            }
            if (!this.fullstoryLoaded) {
                this.pendingOps.push({fn, args: args});
                return;
            }
            try {
                this.fullStoryDebug && console.log(`fs: ${fn.name}(${JSON.stringify(args)})`);
                return fn(...args);
            } catch (e) {
                console.warn('error calling fullstory api');
            }
        };
    };
}

export const createFullstoryService = (fullstory: any, config: FullstoryConfig) =>
    new FullstoryService(fullstory, config);
