import React, {FC, PropsWithChildren} from 'react';
import Header from './header/Header';
import Breadcrumb from '@joyrideautos/web-common-components/src/layout/Breadcrumb';
import {Layout} from 'antd';
import GlobalAlertContainer from '@components/alerts/GlobalAlertContainer';
import NotificationComponent from '@components/notifications/NotificationComponent';
import {ZendeskWidgetView} from '@components/ZendeskWidget';
import {observer, Observer} from 'mobx-react-lite';
import useScrollToTop from '@hooks/useScrollToTop';
import {MainLayoutResizeDetectionContextProvider} from './withMainLayoutResizeDetection';
import classNames from 'classnames';
import {useRootStore} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';
import {Routes} from '../Routes';
import {useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import {Only} from '@joyrideautos/ui-common/src/components/Only';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {RootStoreType} from '@models/stores/RootStore';
import ImpersonatedUserWarning from '@layouts/header/ImpersonateWarning';

interface PortalPageTemplateProps extends PropsWithChildren {}

const PageTemplate: FC<PortalPageTemplateProps> = observer(({children}) => {
    const {presentationStore, authUserStore} = useRootStore<RootStoreType>();
    useScrollToTop(presentationStore.needScrollToTop.value ?? true);
    const {replace} = useRouter();

    return (
        <div className="App">
            <NotificationComponent />
            <Observer>
                {() => (
                    <>
                        <ZendeskWidgetView visible={presentationStore.liveChatVisible.value} />
                        <MainLayoutResizeDetectionContextProvider
                            isFooterVisible={presentationStore.footerVisible.value}
                            renderHeader={() => (
                                <>
                                    <Only when={authUserStore.loadingStatus.isReady}>
                                        {() => (
                                            <Header
                                                userInfo={authUserStore.userInfo}
                                                signOut={() => {
                                                    replace(Routes.SIGN_IN());
                                                    authUserStore.signOut().catch(logError());
                                                }}
                                                hideMenu={false}
                                                top={
                                                    authUserStore.userInfo?.adminEmail ? (
                                                        <ImpersonatedUserWarning />
                                                    ) : null
                                                }
                                                footer={<GlobalAlertContainer />}
                                            />
                                        )}
                                    </Only>
                                </>
                            )}>
                            <Observer>
                                {() => (
                                    <Layout.Content
                                        className={classNames({
                                            main: !presentationStore.isMobile.value,
                                            [presentationStore.containerClassName.value]:
                                                !presentationStore.containerClassName.isEmpty,
                                        })}>
                                        <Observer>
                                            {() => {
                                                return (
                                                    <Breadcrumb
                                                        sections={presentationStore.breadcrumb?.sections ?? []}
                                                    />
                                                );
                                            }}
                                        </Observer>
                                        {children}
                                    </Layout.Content>
                                )}
                            </Observer>
                        </MainLayoutResizeDetectionContextProvider>
                    </>
                )}
            </Observer>
        </div>
    );
});

export default PageTemplate;
