import React from 'react';
import {ITransientEventTypeParams} from '@joyrideautos/ui-models/src/types/events/TransientEvent';
import {SettingsType} from './NotificationSettings';
import {NotificationTypeEnum} from '@joyrideautos/auction-core/src/constants/Constants';
import {
    TransientEventTypeEnum,
    VehicleStatusChangedPayload,
} from '@joyrideautos/auction-core/src/types/events/transient';

export class TransientEventNotificationFactory {
    private settingsTemplate: {
        [eventType in TransientEventTypeEnum]: {
            (params: any | undefined): SettingsType;
        };
    } = {
        [TransientEventTypeEnum.DEFAULT_SUCCESS]: (params: ITransientEventTypeParams) => ({
            message: params.message || 'Success!',
            description: params.description,
            type: NotificationTypeEnum.SUCCESS,
        }),
        [TransientEventTypeEnum.DEFAULT_ERROR]: (params: ITransientEventTypeParams) => ({
            message: params.message || 'Error!',
            description: params.description,
            type: NotificationTypeEnum.ERROR,
        }),
        [TransientEventTypeEnum.DEFAULT_WARNING]: (params: ITransientEventTypeParams) => ({
            message: params.message || 'Warning!',
            description: params.description,
            type: NotificationTypeEnum.WARNING,
        }),
        [TransientEventTypeEnum.DEFAULT_INFO]: (params: ITransientEventTypeParams) => ({
            message: params.message || 'Info:',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_REGION_ADDED]: (params: ITransientEventTypeParams) => ({
            message: 'Region Added to Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_REGION_REMOVED]: (params: ITransientEventTypeParams) => ({
            message: 'Region Removed from Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_AUCTION_SERIES_ADDED]: (params: ITransientEventTypeParams) => ({
            message: 'Auction Series Added to Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_AUCTION_SERIES_REMOVED]: (params: ITransientEventTypeParams) => ({
            message: 'Auction Series Removed from Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_AUCTION_ADDED]: (params: ITransientEventTypeParams) => ({
            message: 'Auction Added to Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_AUCTION_REMOVED]: (params: ITransientEventTypeParams) => ({
            message: 'Auction Removed from Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_VEHICLE_ADDED]: (params: ITransientEventTypeParams) => ({
            message: 'Vehicle Added to Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.FAVORITE_VEHICLE_REMOVED]: (params: ITransientEventTypeParams) => ({
            message: 'Vehicle Removed from Favorites',
            description: params.description,
            type: NotificationTypeEnum.INFO,
        }),
        [TransientEventTypeEnum.VEHICLE_STATUS_CHANGE]: (params: VehicleStatusChangedPayload) => ({
            message: 'Vehicle status changed',
            description: (
                <span>
                    <div>
                        {params.info.year} {params.info.make} {params.info.model}
                    </div>
                    <span className="your-bid">
                        <em>{params?.statusFrom.toUpperCase()}</em> → {params?.statusTo.toUpperCase()}
                    </span>
                </span>
            ),
            type: 'warning',
        }),
    };

    createSettings<DataType = any>(type: TransientEventTypeEnum, data: DataType): SettingsType | null {
        const factory = this.settingsTemplate[type];
        return factory ? factory(data) : null;
    }
}
