import {types} from 'mobx-state-tree';

export enum NotificationKindEnum {
    PERSISTED = 'persistentEvent',
    TRANSIENT = 'transientEvent',
}

export const NotificationKind = types.enumeration('NotificationKind', [
    NotificationKindEnum.PERSISTED,
    NotificationKindEnum.TRANSIENT,
]);

export const Notification = types.model('Notification', {
    kind: NotificationKind,
});
