import {COMPANY_NAME} from '../constants/Constants';
import {Timestamp} from './common';

export enum BidIncrementTypeEnum {
    JOYRIDE_STANDARD = 'JOYRIDE_STANDARD',
    ALWAYS_25 = 'ALWAYS_25',
}

export const bidIncrementTypeDescriptions = {
    [BidIncrementTypeEnum.JOYRIDE_STANDARD]: `${COMPANY_NAME} Standard`,
    [BidIncrementTypeEnum.ALWAYS_25]: 'Always $25',
};

export interface BidIncrementStep {
    from: number;
    to?: number;
    increment: number;
}

export interface CustomBidIncrement {
    editedBy?: string;
    editedAt?: Timestamp;
    createdBy?: string;
    createdAt?: Timestamp;
    name: string;
    steps?: BidIncrementStep[];
}

export const standartBidIncrementConfigs = {
    [BidIncrementTypeEnum.JOYRIDE_STANDARD]: [
        {
            from: 0,
            to: 1000,
            increment: 25,
        },
        {
            from: 1000,
            to: 5000,
            increment: 50,
        },
        {
            from: 5000,
            to: 25000,
            increment: 100,
        },
        {
            from: 25000,
            to: Infinity,
            increment: 500,
        },
    ],
    [BidIncrementTypeEnum.ALWAYS_25]: [
        {
            from: 0,
            to: Infinity,
            increment: 25,
        },
    ],
};

export const DEFAULT_BID_INCREMENT = 25;
export const DEFAULT_BID_INCREMENT_TYPE = BidIncrementTypeEnum.JOYRIDE_STANDARD;

export const isStandartBidIncrement = (name: string) => {
    return (
        name === bidIncrementTypeDescriptions[BidIncrementTypeEnum.JOYRIDE_STANDARD] ||
        name === bidIncrementTypeDescriptions[BidIncrementTypeEnum.ALWAYS_25]
    );
};
