import {EventType} from '@joyrideautos/ui-models/src/types/events/Event';
import {
    Notification,
    NotificationState,
    NotificationEnum,
} from '@joyrideautos/auction-core/src/types/events/notifications';
import {Filter} from '@joyrideautos/auction-utils/src/filters';

export enum NotificationFilterEnum {
    ALL = 'ALL',
    NEW = 'NEW',
    UNREAD = 'UNREAD',
    READ = 'READ',
    OUTBID = 'OUTBID',
    RESERVE_NOT_MET = 'RESERVE_NOT_MET',
    VEHICLE = 'VEHICLE',
    AUCTION = 'AUCTION',
    UPLOAD = 'UPLOAD',
    G_DRIVE_FINISH_SUCCESS = 'G_DRIVE_FINISH_SUCCESS',
}

export const allEventFilters = Object.values(NotificationFilterEnum);

export const toFilterLabel = (filter?: NotificationFilterEnum): string =>
    ({
        [NotificationFilterEnum.ALL]: 'All',
        [NotificationFilterEnum.NEW]: 'New',
        [NotificationFilterEnum.UNREAD]: 'Unread',
        [NotificationFilterEnum.READ]: 'Read',
        [NotificationFilterEnum.OUTBID]: 'Outbid',
        [NotificationFilterEnum.RESERVE_NOT_MET]: 'Reserve Not Met',
        [NotificationFilterEnum.VEHICLE]: 'Vehicle',
        [NotificationFilterEnum.AUCTION]: 'Auction',
        [NotificationFilterEnum.UPLOAD]: 'Upload',
        [NotificationFilterEnum.G_DRIVE_FINISH_SUCCESS]: 'GDrive',
    }[filter ?? NotificationFilterEnum.ALL]);

export const filterFactory: Record<NotificationFilterEnum, Filter<EventType>> = {
    [NotificationFilterEnum.ALL]: () => true,
    [NotificationFilterEnum.NEW]: ({state}) => !!(state & NotificationState.NEW),
    [NotificationFilterEnum.UNREAD]: ({state}) => !!(state & NotificationState.POPPED),
    [NotificationFilterEnum.READ]: ({state}) => !!(state & NotificationState.READ),
    [NotificationFilterEnum.OUTBID]: ({type}) => type === NotificationEnum.BID_LOSS,
    [NotificationFilterEnum.RESERVE_NOT_MET]: ({type}) => type === NotificationEnum.RESERVE_NOT_MET,
    [NotificationFilterEnum.VEHICLE]: ({type}) =>
        [
            NotificationEnum.BID_LOSS,
            NotificationEnum.ITEM_WON,
            NotificationEnum.RESERVE_NOT_MET,
            NotificationEnum.ITEM_PAYMENT_FAILED,
            NotificationEnum.ITEM_PAYMENT_SUCCESSFUL,
        ].includes(type),
    [NotificationFilterEnum.AUCTION]: ({type}) =>
        [
            NotificationEnum.AUCTION_START,
            NotificationEnum.AUCTION_ENDED,
            NotificationEnum.BID_HOLD_FAILED_FOR_BUYER,
            NotificationEnum.BID_HOLD_SUCCESSFUL_FOR_BUYER,
        ].includes(type),
    [NotificationFilterEnum.UPLOAD]: ({type}) =>
        [
            NotificationEnum.UPLOAD_SUCCESS,
            NotificationEnum.UPLOAD_FAILED,
            NotificationEnum.G_DRIVE_START_SUCCESS,
            NotificationEnum.G_DRIVE_START_ERROR,
            NotificationEnum.G_DRIVE_FINISH_SUCCESS,
            NotificationEnum.G_DRIVE_FINISH_ERROR,
        ].includes(type),
    [NotificationFilterEnum.G_DRIVE_FINISH_SUCCESS]: ({type}) =>
        [NotificationEnum.G_DRIVE_FINISH_SUCCESS].includes(type),
};

export const createNotificationTypeFilter: (filter: NotificationFilterEnum) =>
    | {
          name: keyof Notification;
          value: Notification[keyof Notification] | Notification[keyof Notification][];
      }
    | undefined = (filter) =>
    ({
        [NotificationFilterEnum.ALL]: undefined,
        [NotificationFilterEnum.NEW]: undefined,
        [NotificationFilterEnum.UNREAD]: undefined,
        [NotificationFilterEnum.READ]: undefined,
        [NotificationFilterEnum.OUTBID]: {
            name: 'type' as keyof Notification,
            value: NotificationEnum.BID_LOSS,
        },
        [NotificationFilterEnum.RESERVE_NOT_MET]: undefined,
        [NotificationFilterEnum.AUCTION]: {
            name: 'type' as keyof Notification,
            value: [
                NotificationEnum.AUCTION_START,
                NotificationEnum.AUCTION_ENDED,
                NotificationEnum.BID_HOLD_FAILED_FOR_BUYER,
                NotificationEnum.BID_HOLD_SUCCESSFUL_FOR_BUYER,
            ],
        },
        [NotificationFilterEnum.VEHICLE]: {
            name: 'type' as keyof Notification,
            value: [
                NotificationEnum.BID_LOSS,
                NotificationEnum.ITEM_WON,
                NotificationEnum.RESERVE_NOT_MET,
                NotificationEnum.ITEM_PAYMENT_FAILED,
                NotificationEnum.ITEM_PAYMENT_SUCCESSFUL,
            ],
        },
        [NotificationFilterEnum.UPLOAD]: {
            name: 'type' as keyof Notification,
            value: [
                NotificationEnum.UPLOAD_SUCCESS,
                NotificationEnum.UPLOAD_FAILED,
                NotificationEnum.G_DRIVE_START_SUCCESS,
                NotificationEnum.G_DRIVE_START_ERROR,
                NotificationEnum.G_DRIVE_FINISH_SUCCESS,
                NotificationEnum.G_DRIVE_FINISH_ERROR,
            ],
        },
        [NotificationFilterEnum.G_DRIVE_FINISH_SUCCESS]: {
            name: 'type' as keyof Notification,
            value: [NotificationEnum.G_DRIVE_START_SUCCESS],
        },
    }[filter]);
