import {flow, Instance, types, getSnapshot, getEnv, SnapshotIn, getRoot} from 'mobx-state-tree';
import {EventDto} from './EventDto';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {NotificationState} from '@joyrideautos/auction-core/src/types/events/notifications';
import type {EventsService} from '@joyrideautos/ui-services/src/services/EventsService';

export const Event = EventDto.named('Event')
    .props({
        key: types.identifier,
    })
    .views((self) => ({
        isUnread() {
            return self.state & (NotificationState.NEW | NotificationState.POPPED);
        },
        get eventsService(): EventsService {
            return getEnv(self).services?.eventsService || (getRoot(self) as any).rootStore?.eventsService;
        },
    }))
    .actions((self) => ({
        markPopped() {
            self.state = NotificationState.POPPED;
        },

        markRead() {
            self.state = NotificationState.READ;
        },

        markUnread() {
            self.state = NotificationState.POPPED;
        },

        markPoppedAndSave(uid: string) {
            this.markPopped();
            this.save(uid).catch(logError());
        },

        markReadAndSave(uid: string) {
            this.markRead();
            this.save(uid).catch(logError());
        },

        markUnreadAndSave(uid: string) {
            this.markUnread();
            this.save(uid).catch(logError());
        },

        save: flow(function* (userId?: string) {
            // TODO: (future) implement mapper model -> dto
            const {
                key,
                parentKey,
                kind,
                params: {type, ...restParams},
                ...rest
            } = getSnapshot<any>(self);
            yield self.eventsService.savePersistedNotifications({
                notifications: [
                    {
                        key,
                        ...rest,
                        params: restParams,
                    },
                ],
            });
        }),
    }));

export interface EventType extends Instance<typeof Event> {}

export interface EventSnapshotIn extends SnapshotIn<typeof Event> {}
