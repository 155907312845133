import {types, IDisposer} from 'mobx-state-tree';
import RootStoreAwareViewModel from './RootStoreAwareViewModel';
import SingletonAwareViewModel from '@joyrideautos/ui-models/src/common/SingletonAwareViewModel';
import {AuthUserType} from '@joyrideautos/ui-models/src/types/UserInfo';

const BaseViewModel = types
    .compose(RootStoreAwareViewModel, SingletonAwareViewModel)
    .named('BaseViewModel')
    .volatile(() => ({
        disposers: [] as IDisposer[],
    }))
    .views((self) => ({
        get authUser(): AuthUserType | undefined {
            return self.authUserStore.userInfo;
        },
        get isUserAuthenticated() {
            return this.authUser != null && !this.authUser.isAnonymous;
        },
    }))
    .actions((self) => ({
        afterCreate() {},
        beforeDestroy() {
            self.disposers.forEach((d) => d());
        },
    }));

export default BaseViewModel;
