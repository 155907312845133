import {Instance, types} from 'mobx-state-tree';
import {PaymentIntentHoldStatusEnum} from '@joyrideautos/auction-core/src/enums/PaymentIntentHoldStatus';
import {FirestoreTimestamp} from './item/FirestoreTimestamp';

export const CcHold = types.model('CcHold', {
    status: types.maybe(types.enumeration(Object.values(PaymentIntentHoldStatusEnum))),
    confirmed: types.maybe(FirestoreTimestamp),
    auctionEnded: types.maybe(types.boolean),
    // paymentIntent?: PaymentIntent; // TODO: (future) add this property if we need it
});

export interface CcHoldType extends Instance<typeof CcHold> {}
