import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {BidInfo} from './Bid';
import {ItemReferenceForAuction, PersistedItemReference} from './item/Refs';
import {AutoBid as AutoBidDto} from '@joyrideautos/auction-core/src/types/Bid';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';

const AutoBid = types.model('AutoBid', {
    key: types.identifier,
    bidInfo: types.maybe(BidInfo),
    regionId: types.string,
    auctionId: types.string,
    amount: types.number,
    item: types.maybe(ItemReferenceForAuction()),
    persistedItem: types.maybe(PersistedItemReference()),
    cancelledAt: types.maybe(types.string),
});

export default AutoBid;

export interface AutoBidType extends Instance<typeof AutoBid> {}

export interface SnapshotInAutoBidType extends SnapshotIn<typeof AutoBid> {}

export function fromAutobidDto(bid: AutoBidDto): SnapshotInAutoBidType {
    return cast<SnapshotInAutoBidType>(bid);
}

export function mapFromAutobidDto(dtos: Map<string, AutoBidDto>): Map<string, SnapshotInAutoBidType> {
    return Array.from(dtos.keys()).reduce<Map<string, SnapshotInAutoBidType>>((bids, key) => {
        bids.set(key, fromAutobidDto(dtos.get(key)!));
        return bids;
    }, new Map());
}
