export enum LogLevelEnum {
    ERROR = 'error',
    WARN = 'warn',
    INFO = 'info',
    DEBUG = 'debug',
}

export interface LoggerConfig {
    level: LogLevelEnum;
    whitelist?: string[];
    blacklist?: string[];
}

type Primitives = string | number | boolean | undefined | null;

interface InfoObject {
    message: string;
    [key: string | number]: any;
}

// TODO: (Future) consider to fix this type and allow to pass any object to the 'log' function
// currently the type doesn't allow to pass object like this: logger.log({key: 'value'})
// it's possible in all other loggers.
export type MsgOrInfoObj = Primitives | InfoObject;

interface LoggingMethod {
    (msgOrInfoObj: MsgOrInfoObj): void;
    (msgOrInfoObj: string, ...meta: any[]): void;
}

export default interface Logger {
    debug: LoggingMethod;
    log: LoggingMethod;
    info: LoggingMethod;
    warn: LoggingMethod;
    error: LoggingMethod;

    // Extra custom method
    critical: LoggingMethod;

    // For compatibility with console
    dir: (object: any) => any;
    time: (key: string) => any;
    timeEnd: (key: string) => any;

    level: LogLevelEnum;

    child(options: Record<string, unknown>): Logger;
}
