import {flow, getParent, getSnapshot, IDisposer, Instance, types} from 'mobx-state-tree';
import {BaseItemType} from '../types/item/BaseItem';
import RootStoreType from './Shared';
import {LoadingStatus, LoadingStatusEnum} from '../utils/LoadingStatus';
import {reaction, when} from 'mobx';
import BaseStore from './BaseStore';

export const SellerRoleStore = BaseStore.named('SellerRoleStore')
    .props({
        sellerRoleUsersByUser: types.map(types.map(types.boolean)),
        sellerRoleUsersBySeller: types.map(types.map(types.boolean)),
    })
    .volatile(() => ({
        unsubscribeDisposer: null as null | (() => void),
        disposers: [] as IDisposer[],
        loadingStatusMap: new Map<string, LoadingStatus>(),
    }))
    .views((self) => {
        return {
            get auctionsStore() {
                return (getParent(self) as RootStoreType).auctionsStore;
            },
            get managerRoleStore() {
                return (getParent(self) as RootStoreType).managerRoleStore;
            },
            get regionsStore() {
                return (getParent(self) as RootStoreType).regionsStore;
            },
            get sellersStore() {
                return (getParent(self) as RootStoreType).sellersStore;
            },
        };
    })
    .views((self) => {
        return {
            getUserId(): string | undefined {
                return self.authUserStore.userInfo?.uid;
            },
            getSellerCompanyIds(uid?: string): string[] {
                const result: Set<string> = new Set();
                const userId = uid || this.getUserId();
                const sellers = userId && self.sellerRoleUsersByUser.get(userId);
                if (sellers) {
                    sellers.forEach((bool, sellerId) => {
                        result.add(sellerId);
                    });
                }
                self.managerRoleStore.getManagedSellerIds(userId).forEach((id) => result.add(id));
                return [...result];
            },
            get sellerCompaniesCount() {
                return this.getSellerCompanyIds().length;
            },
            hasSellerRoleForAnyCompany(uid?: string, sellerIds?: string[]) {
                if ((sellerIds ?? []).length > 0) {
                    return sellerIds!.some((sellerId) => this.hasSellerRoleForCompany(sellerId, uid));
                } else {
                    const userId = uid || this.getUserId();
                    const companies = this.getSellerCompanyIds(userId);
                    return companies && companies.length > 0;
                }
            },
            hasSellerRoleForCompany(companyId: string, uid?: string) {
                const userId = uid || this.getUserId();
                const companies = userId && self.sellerRoleUsersByUser.get(userId);
                const sellerRoleResult = Boolean(companies && companies.get(companyId));
                return sellerRoleResult || self.managerRoleStore.hasManagerRoleForCompany(companyId, uid);
            },
            hasSellerRoleForAuction(regionId: string, auctionId: string, uid?: string) {
                const auction = self.auctionsStore.findAuction(regionId, auctionId);
                const sellers = auction && auction.sellers ? (getSnapshot(auction.sellers) as any) : {};
                const sellerRoleResult =
                    Object.keys(sellers).findIndex((sellerId) => this.hasSellerRoleForCompany(sellerId, uid)) >= 0;
                return sellerRoleResult || self.managerRoleStore.hasManagerRoleForAuction(regionId, auctionId);
            },
            hasSellerRoleForItem: function (item: BaseItemType, uid?: string) {
                const userId = uid || this.getUserId();
                const sellerRoleResult = Boolean(userId && this.hasSellerRoleForCompany(item.sellerId));
                return sellerRoleResult || self.managerRoleStore.hasManagerRoleForItem(item, userId);
            },
            hasSellerRoleForAnyItem: function (items: BaseItemType[], uid?: string) {
                const userId = uid || this.getUserId();
                return items.reduce((hasSellerRole, nextItem) => {
                    return hasSellerRole || this.hasSellerRoleForItem(nextItem, userId);
                }, false);
            },
            getAssociatedUsers(sellerId: string) {
                const users = self.sellerRoleUsersBySeller.get(sellerId);
                const sellerResult = users ? Array.from(users.keys()) : [];
                const sellerResultSet = new Set(sellerResult);
                const managerResult = self.managerRoleStore.getAssociatedUsers(sellerId);
                managerResult.forEach((uid) => sellerResultSet.add(uid));
                return [...sellerResultSet];
            },
            getSellerRoleUserRegions(uid?: string) {
                const userId = uid || this.getUserId();
                const companyIds = new Set<string>();
                const sellers = userId && self.sellerRoleUsersByUser.get(userId);
                if (sellers) {
                    sellers.forEach((_, sellerId) => {
                        companyIds.add(sellerId);
                    });
                }
                const managers = userId && self.managerRoleStore.managerRoleUsersByUser.get(userId);
                if (managers) {
                    managers.forEach((_, sellerId) => {
                        companyIds.add(sellerId);
                    });
                }
                const regions = new Set<string>();
                if (companyIds.size > 0) {
                    self.sellersStore.findSellers(Array.from(companyIds.values())).forEach((seller) => {
                        Array.from(seller.regions.keys()).forEach((regionId) => {
                            regions.add(regionId);
                        });
                    });
                }
                return [...regions];
            },
            getLoadingStatusForUser(userId: string): LoadingStatus {
                const loading = self.loadingStatusMap.get(userId);
                if (!loading) {
                    self.loadingStatusMap.set(userId, new LoadingStatus());
                }
                return self.loadingStatusMap.get(userId)!;
            },
        };
    })
    .actions((self) => ({
        setSellers(uid: string, sellerIds: string[]) {
            const sellers = self.sellerRoleUsersByUser.set(uid, {}).get(uid)!;
            sellerIds.forEach((sellerId) => {
                sellers.set(sellerId, true);
                const users =
                    self.sellerRoleUsersBySeller.get(sellerId) ||
                    self.sellerRoleUsersBySeller.set(sellerId, {}).get(sellerId)!;
                users.set(uid, true);
            });
        },
        removeSellers(uid: string) {
            const sellers = self.sellerRoleUsersByUser.get(uid);
            const sellerIds = sellers && Array.from(sellers.keys());
            sellerIds &&
                sellerIds.forEach((sellerId) => {
                    sellers && sellers.delete(sellerId);
                    const users =
                        self.sellerRoleUsersBySeller.get(sellerId) ||
                        self.sellerRoleUsersBySeller.set(sellerId, {}).get(sellerId)!;
                    users.delete(uid);
                });
            self.sellerRoleUsersByUser.delete(uid);
        },
        clear() {
            self.sellerRoleUsersByUser.clear();
            self.sellerRoleUsersBySeller.clear();
            self.loadingStatusMap.clear();
        },
    }))
    .actions((self) => {
        return {
            load: flow(function* (uid?: string) {
                const userId = uid || self.getUserId();
                if (!userId) {
                    return;
                }
                self.getLoadingStatusForUser(userId).setInProgress();
                const sellerIdByUid: {[uid: string]: string[]} | undefined =
                    yield self.sellerRoleService.fetchRoleSellers(userId);
                if (!sellerIdByUid) {
                    self.getLoadingStatusForUser(userId).setReady();
                    return;
                }
                const existingUids = Array.from(self.sellerRoleUsersByUser.keys());
                const newUids = Object.keys(sellerIdByUid);
                const toRemoveUids = existingUids.filter((uid) => !newUids.includes(uid));

                newUids.forEach((uid) => {
                    self.setSellers(uid, sellerIdByUid[uid]);
                });
                toRemoveUids.forEach((uid) => {
                    self.removeSellers(uid);
                });

                self.getLoadingStatusForUser(userId).setReady();
            }),
            subscribe(uid?: string) {
                const userId = uid || self.getUserId();
                if (userId) {
                    self.unsubscribeDisposer && self.unsubscribeDisposer();
                    self.getLoadingStatusForUser(userId).setInProgress();
                    self.unsubscribeDisposer = self.sellerRoleService.subscribeToRoleSellers(
                        userId,
                        (sellerIds: string[]) => {
                            self.setSellers(userId, sellerIds);
                            self.getLoadingStatusForUser(userId).setReady();
                        }
                    );
                }
            },
            unsubscribe() {
                self.unsubscribeDisposer && self.unsubscribeDisposer();
                self.unsubscribeDisposer = null;
            },
            updateAssociatedCompanyIdForLoggedInUser({
                currentCompanyId,
                sellerCompaniesCount,
            }: {
                currentCompanyId: string | null;
                sellerCompaniesCount: number;
            }) {
                if (sellerCompaniesCount > 0) {
                    if (!currentCompanyId) {
                        const companyIds = self.getSellerCompanyIds();
                        self.sellerSessionStorage.setSellerId(companyIds[0]);
                    }
                } else {
                    self.sellerSessionStorage.setSellerId(null);
                }
            },
        };
    })
    .actions((self) => {
        return {
            afterCreate() {
                self.disposers.push(
                    when(
                        () =>
                            Boolean(self.getUserId() && self.getLoadingStatusForUser(self.getUserId()!).isReady) &&
                            self.managerRoleStore.loadingStatus.isReady &&
                            self.sellerSessionStorage.status === LoadingStatusEnum.READY,
                        () => {
                            self.updateAssociatedCompanyIdForLoggedInUser({
                                currentCompanyId: self.sellerSessionStorage.sellerId,
                                sellerCompaniesCount: self.sellerCompaniesCount,
                            });
                            self.disposers.push(
                                reaction(
                                    () => ({
                                        currentCompanyId: self.sellerSessionStorage.sellerId,
                                        sellerCompaniesCount: self.sellerCompaniesCount,
                                    }),
                                    ({currentCompanyId, sellerCompaniesCount}) => {
                                        self.updateAssociatedCompanyIdForLoggedInUser({
                                            currentCompanyId,
                                            sellerCompaniesCount,
                                        });
                                    }
                                )
                            );
                        }
                    )
                );
            },
            beforeDestroy: function () {
                self.unsubscribeDisposer && self.unsubscribeDisposer();
                self.disposers.forEach((d) => d());
            },
        };
    });

export interface SellerRoleStoreType extends Instance<typeof SellerRoleStore> {}

export interface HasSellerRoleStore {
    sellerRoleStore: SellerRoleStoreType;
}
