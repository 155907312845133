import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';

export const UI_STORE_KEY = 'UI_LOCAL_STORE';
const UserPreferences = 'UserPreferences';

type UserPreferencesType = {
    showPhotoTips: boolean;
    zipCode?: string;
};

type PageSettings = {
    scrollY: number;
    itemKey?: string;
};

type Tokens = {
    'google-drive-access-token'?: string;
};

interface StorageValue {
    [uid: string]: {
        [key: string]: PageSettings | UserPreferencesType | Tokens;
    };
}

type UILocalStorageKeys = keyof PageSettings | keyof UserPreferencesType | keyof Tokens;

export class UILocalStorage {
    constructor(
        private uid: string,
        private stores: {
            sessionStore: any;
            localStorageStore: any;
        },
        private logger?: Logger
    ) {}

    setScrollY(key: string, scrollY: number) {
        this.setValue(key, 'scrollY', scrollY);
    }

    getScrollY(key: string) {
        return this.getValue<number>(key, 'scrollY');
    }

    setItemKey(key: string, itemKey?: string) {
        this.setValue(key, 'itemKey', itemKey);
    }

    getItemKey(key: string) {
        return this.getValue<string>(key, 'itemKey');
    }

    markPhotoTipsAsRead() {
        this.setValue(UserPreferences, 'showPhotoTips', false);
    }

    shouldShowPhotoTips(): boolean {
        return this.getValue(UserPreferences, 'showPhotoTips')!;
    }

    getZipCode(): string | undefined {
        return this.getValue(UserPreferences, 'zipCode')!;
    }

    setZipCode(zipCode?: string) {
        this.setValue(UserPreferences, 'zipCode', zipCode);
    }

    getGDriveAccessToken(): string | undefined {
        return this.getValue('tokens', 'google-drive-access-token')!;
    }

    setGDriveAccessToken(token: string | undefined) {
        this.setValue('tokens', 'google-drive-access-token', token);
    }

    clear(key: string) {
        const existing = this.stores.localStorageStore.get(UI_STORE_KEY, this.defaultValueForKey(key)) as StorageValue;
        this.stores.localStorageStore.set(UI_STORE_KEY, {
            ...existing,
            [this.uid]: {[key]: undefined},
        });
    }

    clearAll() {
        this.stores.sessionStore.remove(UI_STORE_KEY);
    }

    private defaultValueForKey(key: string) {
        return {[this.uid]: {UserPreferences: {showPhotoTips: true}, [key]: {scrollY: 0}}};
    }

    private setValue(key: string, propName: UILocalStorageKeys, value: any) {
        const existing = this.stores.localStorageStore.get(UI_STORE_KEY, this.defaultValueForKey(key)) as StorageValue;
        this.logger?.log('setValue', {existing, key, value, propName});
        this.stores.localStorageStore.set(UI_STORE_KEY, {
            ...existing,
            [this.uid]: {
                ...existing[this.uid],
                [key]: {
                    ...(existing[this.uid] ? existing[this.uid][key] : {}),
                    [propName]: value,
                },
            },
        });
    }

    private getValue<T>(key: string, propName: UILocalStorageKeys): T | undefined {
        const values = this.stores.localStorageStore.get(UI_STORE_KEY, this.defaultValueForKey(key));
        const value = values && values[this.uid];
        this.logger?.log('getValue', {values, key, propName, value});
        return value && value[key] && (value[key][propName] as T | undefined);
    }
}

export function getUILocalStorage(uid: string, stores: {sessionStore: any; localStorageStore: any}, logger?: Logger) {
    return new UILocalStorage(uid, stores, logger);
}
