import {
    BodyType,
    ColorDataType,
    DriveTrainType,
    EngineDataType,
    LicensePlaceStateType,
    MakeDataType,
    ModelDataType,
    TransmissionType,
    DocumentationTypeDataType,
    KeyStatusDataType,
    StartStatusDataType,
    BuyerType,
    CatalyticConverterDataType,
    SellerIntegrationType,
    BusinessRoleType,
} from '@joyrideautos/auction-core/src/types/Refs';
import {flatten} from '@joyrideautos/auction-utils/src/arrayUtils';
import {BaseService} from './BaseService';

export class RefsService extends BaseService {
    fetchEngineData() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<EngineDataType>('/refEngineTypes')
                .where('active', '==', true)
                .orderBy('sortOrder')
        );
    }

    fetchDrivetrainData() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<DriveTrainType>('/refDrivetrain')
                .where('active', '==', true)
                .orderBy('sortOrder')
        );
    }

    getBusinessTypes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<BuyerType>('/refBuyerType').orderBy('sortOrder', 'asc')
        );
    }

    getRoleTypes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<BusinessRoleType>('/refBusinessRoles')
        );
    }

    fetchStartStatuses(): Promise<string[]> {
        return this.firebase.firestore.fetchOnceArray<StartStatusDataType, string>(
            this.firebase.firestore.collectionRef('/refStartStatuses'),
            (snapshot) => snapshot.data().name || snapshot.data().code
        );
    }

    fetchLicensePlateStates() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<LicensePlaceStateType>('/refLicensePlateStates')
                .where('active', '==', true)
        );
    }

    fetchAllMakes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<MakeDataType>('/refMakes').where('active', '==', true).orderBy('make')
        );
    }

    async fetchAllModels() {
        const models = await this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<ModelDataType>('/refModels')
                .where('active', '==', true)
                .orderBy('makeId')
        );
        return models.filter(({makeCode}) => !!makeCode);
    }

    async fetchModels(makeName: string) {
        if (!makeName) {
            return [];
        }
        const makes = await this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<MakeDataType>('/refMakes')
                .where('active', '==', true)
                .where('make', '==', makeName)
        );
        const make = makes[0];

        if (make) {
            const models = await this.firebase.firestore.fetchOnceArray(
                this.firebase.firestore
                    .collectionRef<ModelDataType>('/refModels')
                    .where('makeId', '==', make.makeId)
                    .where('active', '==', true)
            );
            return models;
        }
        return [];
    }

    async fetchModelsForMakes(makeNames: string[]): Promise<ModelDataType[]> {
        if (makeNames.length === 0) {
            return [];
        }

        const result = await Promise.all(makeNames.map((make) => this.fetchModels(make)));
        return flatten(result);
    }

    fetchKeyStatuses() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<KeyStatusDataType>('/refKeyStatuses'),
            (snapshot) => ({
                code: snapshot.data().code,
                name: snapshot.data().name,
                key: snapshot.id,
            })
        );
    }

    fetchDocumentationTypes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<DocumentationTypeDataType>('/refDocumentationTypes')
        );
    }

    fetchColors() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<ColorDataType>('/refColors').where('active', '==', true)
        );
    }

    fetchTransmissions() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<TransmissionType>('/refTransmissions')
                .where('active', '==', true)
                .orderBy('sortOrder')
        );
    }

    fetchBody() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<BodyType>('/refBody').where('active', '==', true).orderBy('sortOrder')
        );
    }

    fetchCatalyticConverters() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<CatalyticConverterDataType>('/refCatalyticConverters'),
            (snapshot) => ({
                code: snapshot.data().code,
                name: snapshot.data().name,
                key: snapshot.id,
            })
        );
    }

    fetchSellerIntegrations = async () => {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<SellerIntegrationType>('/refSellerIntegrations')
        );
    };

    fetchFuelTypes() {
        return ['Gas', 'Natural Gas', 'Diesel', 'Electric', 'Hybrid'];
    }
}
