import React, {FC, ReactElement} from 'react';
import {AlertFilled, InfoCircleFilled} from '@ant-design/icons';
import {Button, Progress, Tag} from 'antd';
import {observer} from 'mobx-react-lite';
import {colorForCountdown} from '@joyrideautos/ui-common/src/utils/CountDownColorUtil';
import {
    formatDate,
    formatTime,
    formatTimeCountdown,
    LONG_DATE_AT_FORMAT,
} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {Routes} from '../../../Routes';
import {Only} from '@joyrideautos/ui-common/src/components/Only';
import {LiveAuctionAlertViewModel} from '@joyrideautos/ui-models/src/presentation/auctionView/LiveAuctionAlertViewModel';
import type {HasViewModel} from '@joyrideautos/ui-models/src/types';
import {AuctionProgress} from '@pageComponents/biddingCard/components/AuctionProgress';
import {isMobile} from 'react-device-detect';
import styles from './LiveAuctionAlert.module.less';
import {Link} from '@joyrideautos/web-common-components/src/router/Router';
import Alert from '@joyrideautos/web-common-components/src/components/alert/Alert';
import {getLiveAuctionAlertOption, LiveAuctionAlertOptionEnum} from '@components/alerts/liveAuctionAlertOptionsConfig';
import classNames from 'classnames';

export interface LiveAuctionAlertProps extends HasViewModel<typeof LiveAuctionAlertViewModel> {}

const BiddingStartsLabel: FC<{date?: Date | null}> = ({date}) => {
    if (!date) {
        return null;
    }
    return (
        <>
            Bidding starts <strong>{formatDate(date, LONG_DATE_AT_FORMAT)}</strong>.
        </>
    );
};

type AlertFactoryType = (props: LiveAuctionAlertProps) => ReactElement<any, any> | null;
type AlertsByOptionType = {[key in LiveAuctionAlertOptionEnum]: AlertFactoryType};

const alertsByOption: AlertsByOptionType = {
    [LiveAuctionAlertOptionEnum.BEFORE_PREBID__AUCTION_OCCURRENCE]: (props) => {
        const {preBiddingStartTime, hasReminder, isAnonymous} = props.viewModel;
        const message = (
            <span>
                <BiddingStartsLabel date={preBiddingStartTime} />
                <Only when={!hasReminder && !isAnonymous}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => props.viewModel.setReminder()}>
                        <strong> Set reminder</strong>
                    </a>
                </Only>
            </span>
        );
        return <Alert message={message} type="info" showIcon />;
    },
    [LiveAuctionAlertOptionEnum.BEFORE_PREBID__VEHICLE_PROFILE]: (props) => {
        const {preBiddingStartTime, hasReminder, isAnonymous} = props.viewModel;
        const message = (
            <span>
                <BiddingStartsLabel date={preBiddingStartTime} />
                <Only when={!hasReminder && !isAnonymous}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => props.viewModel.setReminder()}>
                        <strong> Set reminder</strong>
                    </a>
                </Only>
            </span>
        );
        return <Alert message={message} type="info" showIcon />;
    },
    [LiveAuctionAlertOptionEnum.PREBID__AUCTION_OCCURRENCE]: (props) => {
        const {auctionStartTime, hasReminder, isAnonymous} = props.viewModel;
        const message = (
            <span>
                <>
                    Auction starts <strong>{formatDate(auctionStartTime, LONG_DATE_AT_FORMAT)}</strong>.
                </>
                <Only when={!hasReminder && !isAnonymous}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => props.viewModel.setReminder()}>
                        <strong> Set reminder</strong>
                    </a>
                </Only>
            </span>
        );
        return <Alert message={message} type="info" showIcon />;
    },
    [LiveAuctionAlertOptionEnum.PREBID__VEHICLE_PROFILE]: (props) => {
        const {auctionStartTime, hasReminder = false, isAnonymous} = props.viewModel;
        const message = (
            <span>
                This vehicle is in an auction that starts{' '}
                <strong>{formatDate(auctionStartTime, LONG_DATE_AT_FORMAT)}</strong>.
                <Only when={!hasReminder && !isAnonymous}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => props.viewModel.setReminder()}>
                        <strong> Set reminder</strong>
                    </a>
                </Only>
            </span>
        );
        return <Alert message={message} type="info" showIcon />;
    },
    [LiveAuctionAlertOptionEnum.BEFORE_START__AUCTION_OCCURRENCE]: (props) => {
        const {auctionStartTime, hasReminder, isAnonymous} = props.viewModel;
        return (
            <Alert
                message={
                    <span>
                        Auction starts <strong>{formatDate(auctionStartTime, LONG_DATE_AT_FORMAT)}</strong>.
                        <Only when={!hasReminder && !isAnonymous}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a onClick={() => props.viewModel.setReminder()}>
                                <strong> Set reminder</strong>
                            </a>
                        </Only>
                    </span>
                }
                type="info"
                showIcon
            />
        );
    },
    [LiveAuctionAlertOptionEnum.BEFORE_START__VEHICLE_PROFILE]: (props) => {
        const {timeToAuctionStart, hasReminder, isAnonymous} = props.viewModel;
        return (
            <Alert
                message={
                    <span>
                        This vehicle is in an auction that starts in <strong>{formatTime(timeToAuctionStart)}</strong>.
                        <Only when={!hasReminder && !isAnonymous}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a onClick={() => props.viewModel.setReminder()}>
                                <strong> Set reminder</strong>
                            </a>
                        </Only>
                    </span>
                }
                type="info"
                showIcon
            />
        );
    },
    [LiveAuctionAlertOptionEnum.BIDDING__AUCTION_OCCURRENCE]: (props) => {
        const {vehicleDisplayName, countdown = 0, currentBid, biddingProgress, isBiddingInProgress} = props.viewModel;
        return (
            <>
                <Alert
                    message={
                        <span>
                            <strong>Live auction.</strong> {vehicleDisplayName}{' '}
                            {vehicleDisplayName && <strong> ${currentBid}</strong>}{' '}
                            <Tag className={colorForCountdown(countdown)}> {formatTimeCountdown(countdown)} </Tag>
                        </span>
                    }
                    className="alert-live"
                    type="info"
                    showIcon
                    icon={<AlertFilled />}
                />
                <Only when={isMobile && countdown !== undefined && isBiddingInProgress}>
                    <div className={classNames([styles['clear-timing'], 'card-box'])}>
                        <AuctionProgress countdown={countdown} biddingProgress={biddingProgress} />
                    </div>
                </Only>
            </>
        );
    },
    [LiveAuctionAlertOptionEnum.BIDDING__VEHICLE_PROFILE]: (props) => {
        const {
            vehicleDisplayName,
            countdown = 0,
            currentBid,
            regionId,
            auctionId,
            biddingProgress,
            isBiddingInProgress,
        } = props.viewModel;
        return (
            <>
                <Alert
                    message={
                        <span>
                            <strong>This vehicle is in a live auction happening now.</strong>{' '}
                            {vehicleDisplayName && (
                                <>
                                    Current vehicle: {vehicleDisplayName}
                                    <strong> ${currentBid}</strong>
                                </>
                            )}
                            <Tag className={colorForCountdown(countdown)} color={colorForCountdown(countdown)}>
                                {formatTimeCountdown(countdown)}
                            </Tag>
                            <Link to={Routes.AUCTION_OCCURRENCE({pathParams: {regionId, auctionId}})}>
                                <strong>View →</strong>
                            </Link>
                        </span>
                    }
                    className="alert-live"
                    type="info"
                    showIcon
                    icon={<AlertFilled />}
                />
                <Only when={isMobile && countdown !== undefined && isBiddingInProgress}>
                    <div className="clear-timing card-box">
                        <AuctionProgress countdown={countdown} biddingProgress={biddingProgress} />
                    </div>
                </Only>
            </>
        );
    },
    [LiveAuctionAlertOptionEnum.BEFORE_BIDDING__AUCTION_OCCURRENCE]: (props) => {
        const {countdown = 0, vehiclesLeft = 0, countDownInPercents} = props.viewModel;
        return (
            <>
                <Alert
                    message={
                        <span>
                            <strong>Live auction.</strong> {vehiclesLeft} vehicles left. Next vehicle in{' '}
                            <strong>{formatTimeCountdown(countdown)}</strong>
                        </span>
                    }
                    className="alert-live"
                    type="info"
                    showIcon
                    icon={<AlertFilled />}
                />
                <Progress className="blue" percent={countDownInPercents} showInfo={false} size="small" />
            </>
        );
    },
    [LiveAuctionAlertOptionEnum.ENDED__AUCTION_OCCURRENCE]: () => {
        return (
            <Alert
                message={
                    <span>
                        <strong>Auction completed</strong>{' '}
                    </span>
                }
                className="alert-completed"
                type="info"
                showIcon
                icon={<InfoCircleFilled />}
            />
        );
    },
    [LiveAuctionAlertOptionEnum.ENDED__VEHICLE_PROFILE]: () => {
        return (
            <Alert
                message={
                    <span>
                        <strong>This vehicle was in an auction that has completed.</strong>{' '}
                    </span>
                }
                className="alert-completed"
                type="info"
                showIcon
                icon={<InfoCircleFilled />}
            />
        );
    },
    [LiveAuctionAlertOptionEnum.AFTER_BIDDING]: (props) => {
        const {nextVehicle} = props.viewModel;
        return nextVehicle ? (
            <Alert
                message={`Live auction. Bidding ended. Next vehicle is ${nextVehicle.info.yearMakeModel}`}
                className="alert-live"
                type="info"
                showIcon
                icon={<AlertFilled />}
            />
        ) : (
            <Alert
                message={'Live auction. Bidding ended.'}
                className="alert-live"
                type="info"
                showIcon
                icon={<InfoCircleFilled />}
            />
        );
    },
    [LiveAuctionAlertOptionEnum.DISCONNECTED]: () => {
        return (
            <Alert
                message={
                    <>
                        <span>You disconnected from live auction.</span>
                        <Button type="link" onClick={() => window.location.reload()}>
                            Re-connect
                        </Button>
                    </>
                }
                type="error"
                showIcon
                icon={<InfoCircleFilled />}
            />
        );
    },
};

export const LiveAuctionAlert: FC<LiveAuctionAlertProps> = observer((props: LiveAuctionAlertProps) => {
    const {viewModel} = props;

    let visibleAlert;
    if (viewModel.auction?.settings?.isListing) {
        return null;
    }
    if (!viewModel.isConnected) {
        visibleAlert = LiveAuctionAlertOptionEnum.DISCONNECTED;
    }
    if (viewModel.auctionState) {
        visibleAlert = getLiveAuctionAlertOption(viewModel.auctionState, viewModel.path);
    }
    const AuctionStateAlertsFactory = visibleAlert && observer(alertsByOption[visibleAlert]);
    return AuctionStateAlertsFactory ? <AuctionStateAlertsFactory {...props} /> : null;
});
