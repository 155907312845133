import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {observable} from 'mobx';
import {IDisposer, Instance} from 'mobx-state-tree';

//TODO: (future) fix this type
type Router = any;

export interface BreadcrumbSectionType {
    label: string;
    icon?: React.ReactNode;
    to?: string;
    onClick?: (router: Router) => void;
}

export const BreadcrumbModel = BaseViewModel.named('BreadcrumbModel')
    .volatile(() => ({
        sections: observable([] as BreadcrumbSectionType[]),
        disposers: [] as IDisposer[],
    }))
    .actions((self) => ({
        replaceSections(sections: BreadcrumbSectionType[]) {
            self.sections.replace(sections);
        },
    }))
    .actions((self) => ({
        setSections(sections: BreadcrumbSectionType[]) {
            requestAnimationFrame(() => {
                self.replaceSections(sections);
            });
        },
    }))
    .actions((self) => ({
        beforeDestroy() {
            self.disposers.forEach((d) => d());
        },
    }));

export interface BreadcrumbModelType extends Instance<typeof BreadcrumbModel> {}
