import {StorageReference, UploadMetadata} from '../firebase/Storage';
import {BaseService} from './BaseService';

export class StorageService extends BaseService {
    getFileRef(path: string): StorageReference {
        return this.firebase.storage.ref(path);
    }

    async getDownloadUrl(path: string) {
        return this.firebase.storage.getDownloadURL(path);
    }

    downloadFile(name: string, url: string | Blob) {
        this.firebase.storage.downloadFile(name, url);
    }

    uploadToStorage(path: string, file: File, metadata?: UploadMetadata): Promise<{uploaded: boolean; error?: string}> {
        return new Promise((res, rej) => {
            const uploadTask = this.firebase.storage.uploadBytesResumable(path, file, metadata);
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => rej({error: error.message, uploaded: false}),
                async () => {
                    // DONT DELETE THIS CODE. it is used in development only, because there is no cloud storage local emulator
                    // await callUploadVehicleVideoTrigger(path, 'video/mp4', metadata && metadata.customMetadata);
                    res({uploaded: true});
                }
            );
        });
    }
}
