import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';

export const createSignUpMenu = () => {
    const signUpMenu = {
        label: <NavLink to={Routes.SIGN_UP()}>Sign Up</NavLink>,
        key: 'sign-up',
    };
    return signUpMenu;
};
