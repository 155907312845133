import {BaseService} from './BaseService';

export class AdminRoleService extends BaseService {
    async fetchRoleAdmin(): Promise<string[] | undefined> {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/userRoles/admins`);
        if (!snapshot.exists()) {
            return;
        }
        const result: string[] = [];
        snapshot.forEach((child) => {
            if (child.key) {
                result.push(child.key);
            }
        });
        return result;
    }
}
