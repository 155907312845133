import {SearchOutlined} from '@ant-design/icons';
import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';

export const createVehicleSearchMenu = () => {
    const vehicleSearchMenu = {
        label: <NavLink to={Routes.SEARCH()}>Vehicle Search</NavLink>,
        key: 'vehicle-search',
        icon: <SearchOutlined />,
    };

    return vehicleSearchMenu;
};
