export const BreadcrumbLabels = {
    HOME: 'Home',
    VEHICLES: 'Vehicles',
    FAVORITES: 'Favorites',
    RECEIPT: 'Receipt',
    AUCTION: 'Auction',
    AUCTIONS: 'All Auctions',
    ACCOUNT: 'Account',
    ACCOUNT_SETTINGS: 'Account Settings',
    MY_VEHICLES: 'My Vehicles',
    MY_INVENTORY: 'My Inventory',
    SEARCH: 'Search',
    BACK: 'Back',
    MY_VEHICLES_AUCTIONS: 'My Auctions',
    MY_VEHICLES_AUCTION_WON: 'Won',
};
