import React, {FC} from 'react';
import {Observer} from 'mobx-react-lite';

interface IfProps {
    when: boolean;
    children?: React.ReactNode | (() => React.ReactNode);
}

export const Only: FC<IfProps> = ({when, children}) => {
    if (process.env.NODE_ENV === 'development' && typeof children === 'undefined') {
        console.error('children', typeof children);
    }
    return (
        <Observer>
            {() => {
                return when ? <>{typeof children === 'function' ? children() : children}</> : <></>;
            }}
        </Observer>
    );
};
