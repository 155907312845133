import {AuctionsStoreType} from '../../../stores/AuctionsStore';
import {EventSubscriptionType, EventSubscriptionTypeEnum} from '../EventSubscription';
import {AuctionType} from '../../auction/Auction';

export class AuctionsFetchStrategy {
    private auctionsStore: AuctionsStoreType;

    constructor(auctionsStore: AuctionsStoreType) {
        this.auctionsStore = auctionsStore;
    }

    async fetchRegionAuctions(subscription: EventSubscriptionType) {
        const {regionId} = subscription.params!;
        return this.auctionsStore.fetchAuctionsForRegion(regionId!);
    }

    async fetchSeriesAuctions(subscription: EventSubscriptionType) {
        const {regionId, auctionSeriesId} = subscription.params!;
        return this.auctionsStore.fetchAuctionsForSeries(regionId!, auctionSeriesId!);
    }

    async fetchAuction(subscription: EventSubscriptionType) {
        const {regionId, auctionId} = subscription.params!;
        return this.auctionsStore.fetchMultipleAuctions([{regionId: regionId!, auctionId: auctionId!}]);
    }

    async fetchAuctions(subscription: EventSubscriptionType): Promise<AuctionType[]> {
        switch (subscription.type) {
            case EventSubscriptionTypeEnum.AUCTION_START:
            case EventSubscriptionTypeEnum.FAVORITE_AUCTION:
                return this.fetchAuction(subscription);
            case EventSubscriptionTypeEnum.FAVORITE_REGION:
                return this.fetchRegionAuctions(subscription);
            case EventSubscriptionTypeEnum.FAVORITE_AUCTION_SERIES:
                return this.fetchSeriesAuctions(subscription);
            default:
                throw Error(`Unsupported event type ${subscription.type}`);
        }
    }
}
