import * as s from 'superstruct';

export const WinningBidFeesValidation = s.type({
    adminFee: s.number(),
    bid: s.number(),
    buyerFee: s.number(),
    platformFee: s.number(),
    salePrice: s.number(),
    salesTax: s.optional(s.number()),
    total: s.optional(s.number()),
    deposit: s.optional(s.number()),
    storageTax: s.optional(s.number()),
    variableFee: s.optional(s.number()),
    vehicleKeyFee: s.optional(s.number()),
    vehicleOtherFixedFee: s.optional(s.number()),
});
export const WinningBidValidation = s.type({
    amount: s.optional(s.number()),
    start: s.string(),
    ended: s.optional(s.boolean()),
    deleted: s.optional(s.boolean()),
    skipped: s.optional(s.boolean()),
    isUserPreApproved: s.optional(s.boolean()),
    expiration: s.optional(s.string()),
    timestamp: s.optional(s.string()),
    autobid: s.optional(
        s.type({
            amount: s.number(),
            prevAmount: s.optional(s.number()),
            bidId: s.string(),
            prevBidId: s.optional(s.string()),
            timestamp: s.string(),
            uid: s.nullable(s.string()),
            prevUid: s.optional(s.string()),
        })
    ),
    fees: s.optional(WinningBidFeesValidation),
    bidId: s.optional(s.string()),
    uid: s.optional(s.string()),
    billingUid: s.optional(s.string()),
    reserveMet: s.optional(s.boolean()),
    reservePrice: s.optional(s.nullable(s.number())),
    payInFullAvailable: s.optional(s.boolean()),
    soldType: s.optional(s.string()),
});
