export type MediaResourceType = {
    width: number;
    height: number;
    contentType: string;
    bucket: string;
    name: string;
    url: string;
    idx: number | null;
    metadata: {
        sourceIdx: string;
        sourceURL?: string;
        sourceGdriveFileId?: string;
    };
};

export enum ItemPlaceholderEnum {
    EXTRA = 'extra',
    VIDEO = 'video',
    FRONT_PASSENGER_SIDE = 'front-passenger-side',
    FRONT_DRIVER_SIDE = 'front-driver-side',
    REAR_DRIVER_SIDE = 'rear-driver-side',
    REAR_PASSENGER_SIDE = 'rear-passenger-side',
    INTERIOR_DRIVER_SIDE = 'interior-driver-side',
    INTERIOR_BACK_SEAT = 'interior-back-seat',
    INTERIOR_ODOMETER = 'interior-odometer',
    MANUFACTURER_LABEL = 'manufacturer-label',
    INTERIOR_ENGINE = 'interior-engine',
    CATALYTIC_CONVENTER = 'catalytic-conventer',
    KEYS = 'vehicle-keys',
    OTHER = 'other',
    DAMAGE = 'damage',
}

export const DefaultItemPlaceholder = ItemPlaceholderEnum.EXTRA;
export const VideoItemPlaceholder = ItemPlaceholderEnum.VIDEO;

export type ItemPlaceholder = {
    type: ItemPlaceholderEnum;
    title: string;
};

export const ItemPlaceholdersByIdx: Record<number, ItemPlaceholder> = {
    0: {
        type: ItemPlaceholderEnum.FRONT_PASSENGER_SIDE,
        title: 'Front Right',
    },
    1: {
        type: ItemPlaceholderEnum.FRONT_DRIVER_SIDE,
        title: 'Front Left',
    },
    2: {
        type: ItemPlaceholderEnum.REAR_DRIVER_SIDE,
        title: 'Rear Left',
    },
    3: {
        type: ItemPlaceholderEnum.REAR_PASSENGER_SIDE,
        title: 'Rear Right',
    },
    4: {
        type: ItemPlaceholderEnum.INTERIOR_DRIVER_SIDE,
        title: 'Front Interior',
    },
    5: {
        type: ItemPlaceholderEnum.INTERIOR_BACK_SEAT,
        title: 'Rear Interior',
    },
    6: {
        type: ItemPlaceholderEnum.INTERIOR_ODOMETER,
        title: 'Odometer',
    },
    7: {
        type: ItemPlaceholderEnum.MANUFACTURER_LABEL,
        title: 'VIN Sticker',
    },
    8: {
        type: ItemPlaceholderEnum.INTERIOR_ENGINE,
        title: 'Engine Bay',
    },
    9: {
        type: ItemPlaceholderEnum.CATALYTIC_CONVENTER,
        title: 'Catalytic Converter',
    },
    10: {
        type: ItemPlaceholderEnum.KEYS,
        title: 'Keys',
    },
    11: {
        type: ItemPlaceholderEnum.OTHER,
        title: 'Other',
    },
};

export const ItemPlaceholdersByType: {[key in ItemPlaceholderEnum]: string} = {
    [ItemPlaceholderEnum.FRONT_PASSENGER_SIDE]: 'Front Right',
    [ItemPlaceholderEnum.FRONT_DRIVER_SIDE]: 'Front Left',
    [ItemPlaceholderEnum.REAR_DRIVER_SIDE]: 'Rear Right',
    [ItemPlaceholderEnum.REAR_PASSENGER_SIDE]: 'Rear Left',
    [ItemPlaceholderEnum.INTERIOR_DRIVER_SIDE]: 'Front Interior',
    [ItemPlaceholderEnum.INTERIOR_BACK_SEAT]: 'Rear Interior',
    [ItemPlaceholderEnum.INTERIOR_ODOMETER]: 'Odometer',
    [ItemPlaceholderEnum.MANUFACTURER_LABEL]: 'VIN Sticker',
    [ItemPlaceholderEnum.INTERIOR_ENGINE]: 'Engine Bay',
    [ItemPlaceholderEnum.CATALYTIC_CONVENTER]: 'Catalytic Converter',
    [ItemPlaceholderEnum.KEYS]: 'Keys',
    [ItemPlaceholderEnum.EXTRA]: 'Extra',
    [ItemPlaceholderEnum.VIDEO]: 'Video',
    [ItemPlaceholderEnum.DAMAGE]: 'Damage',
    [ItemPlaceholderEnum.OTHER]: 'Other',
};

export enum MediaTypeEnum {
    IMAGES = 'images',
    VIDEO = 'video',
}
