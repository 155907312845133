import React, {FC} from 'react';
import {Alert as AntAlert, AlertProps as AntAlertProps} from 'antd';
import './Alert.less';
import classNames from 'classnames';

export interface AlertProps extends AntAlertProps {}

const Alert: FC<AlertProps> = ({className, ...rest}) => {
    return <AntAlert className={classNames(className, 'js-alert')} {...rest} />;
};

export default Alert;
