import {MenuViewModelType} from '../MenuViewModel';
import {Routes} from '../../../Routes';
import {CaretDownOutlined, ShopOutlined, UserOutlined} from '@ant-design/icons';
import {isAnonymous, UserInfo} from '@joyrideautos/auction-core/src/types/User';
import {Badge, MenuProps} from 'antd';
import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {isMobile} from 'react-device-detect';
import {Observer} from 'mobx-react-lite';
import {getAppConfig} from '@joyrideautos/ui-services/src/AppConfig';

const appConfig = getAppConfig();

// TODO: (future) move to 'utils' module
type ArrayElementType<T> = T extends Array<infer U> ? U : T;

const getUserInitials = (userInfo?: UserInfo) => {
    return isAnonymous(userInfo) ? 'Anonymous' : userInfo!.firstName;
};

export const createCurrentUserMenu = (
    viewModel: MenuViewModelType,
    isEmailVerifiedStep: boolean,
    signOut?: (callback: () => void) => void
) => {
    const handleSignOut = () => {
        if (signOut) {
            signOut(() => {
                if (appConfig.commonConfig.publicUrlLanding) {
                    window.location.replace(appConfig.commonConfig.publicUrlLanding);
                } else {
                    window.location.href = Routes.LANDING();
                }
            });
        }
    };

    const currentUserMenu: ArrayElementType<MenuProps['items']> = {
        label: (
            <Observer>
                {() => (
                    <span>
                        {getUserInitials(viewModel.userInfo)} {!isMobile && <CaretDownOutlined />}
                        {!isMobile &&
                            viewModel.userInfo?.emailVerified &&
                            !viewModel.userInfo.paymentMethodProvided && <Badge status="success" />}
                    </span>
                )}
            </Observer>
        ),
        key: 'profile',
        children: [],
    };

    if (isMobile) {
        currentUserMenu.icon = <UserOutlined />;
    }

    if (viewModel.isMultiSeller && !isMobile) {
        currentUserMenu.children = [
            {
                label: (
                    <div className="header-menu-current-seller-name">
                        <ShopOutlined />
                        <span>{viewModel.currentSellerName}</span>
                    </div>
                ),
                key: 'current-seller-name',
                type: 'group',
                children: [{label: <NavLink to={Routes.CHANGE_SELLER()}>Change Seller</NavLink>, key: 'cahnge-seller'}],
            },
        ] as any;
    }
    if (viewModel.isMultiSeller && isMobile) {
        currentUserMenu.children = [
            {
                label: viewModel.currentSellerName,
                key: 'current-seller-name',
                icon: <ShopOutlined />,
                children: [{label: <NavLink to={Routes.CHANGE_SELLER()}>Change Seller</NavLink>, key: 'cahnge-seller'}],
            },
        ];
    }

    if (viewModel.isSeller) {
        currentUserMenu.children.push({type: 'divider'});
    }

    if (!isEmailVerifiedStep) {
        currentUserMenu.children.push({
            label: <NavLink to={Routes.PROFILE()}>View Profile</NavLink>,
            key: 'view-profile',
        });
    }

    currentUserMenu.children.push({
        label: (
            <NavLink
                to={'#'}
                onClick={(e) => {
                    e.preventDefault();
                    handleSignOut();
                }}>
                Sign Out
            </NavLink>
        ),
        key: 'sign-out',
    });

    return currentUserMenu;
};
