import React, {FC, PropsWithChildren} from 'react';
import {Col, Row} from 'antd';
import './GlobalAlert.less';

const GlobalAlert: FC<PropsWithChildren<any>> = (props) => {
    return (
        <div className="message-top">
            <Row>
                <Col span={24}>{props.children}</Col>
            </Row>
        </div>
    );
};
export default GlobalAlert;
