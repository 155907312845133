import {AuctionPath} from '@joyrideautos/auction-core/src/types/AuctionOccurrence';
import {NotificationState, NotificationEnum} from '@joyrideautos/auction-core/src/types/events/notifications';
import type {EventsService} from '@joyrideautos/ui-services/src/services/EventsService';

export const notifyAuctionIsAboutToStart =
    (services: {eventsService: EventsService}) =>
    ({regionId, auctionId}: AuctionPath, uid: string) => {
        return services.eventsService.savePersistedNotifications({
            notifications: [
                {
                    type: NotificationEnum.AUCTION_START,
                    state: NotificationState.NEW,
                    params: {userId: uid, regionId, auctionId: auctionId},
                },
            ],
        });
    };
