import {NotificationItem} from '../NotificationItem';

export const createNotificationMenu = () => {
    const notificationMenu = {
        label: <NotificationItem />,
        key: 'notification',
    };

    return notificationMenu;
};
