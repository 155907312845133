import {CarOutlined, CaretDownOutlined} from '@ant-design/icons';
import {NavLink} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';
import {isMobile} from 'react-device-detect';

export const createSellerToolsMenu = (options?: {isGateCodesEnabled?: boolean}) => {
    const children = [
        {
            label: <NavLink to={Routes.MY_INVENTORY()}>My Inventory</NavLink>,
            key: 'my-inventory',
        },
        {
            label: <NavLink to={Routes.MY_AUCTIONS()}>My Auctions</NavLink>,
            key: 'my auctions',
        },
    ];
    if (options?.isGateCodesEnabled) {
        children.push({
            label: <NavLink to={Routes.SELLER_RELEASE_FLOW_START()}>Release Vehicles</NavLink>,
            key: 'release vehicle',
        });
    }
    return {
        label: <span>Seller Tools {!isMobile && <CaretDownOutlined />}</span>,
        key: 'seller-tools',
        icon: <CarOutlined />,
        children,
    };
};
