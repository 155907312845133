import {getRoot, getSnapshot, Instance, types} from 'mobx-state-tree';
import {WonItem, WonItemType, fromDto} from '../types/WonItem';
import {LoadingStatus} from '../utils/LoadingStatus';
import {WonItem as WonItemDto} from '@joyrideautos/auction-core/src/types/WonItem';
import BaseStore from './BaseStore';

export const WonItemsStore = BaseStore.named('WonItemsStore')
    .props({
        wonItems: types.map(types.map(types.map(types.array(WonItem)))),
    })
    .volatile(() => ({
        disposers: {} as {[key: string]: () => void},
        loadingStatus: new LoadingStatus(),
    }))
    .views((self) => ({
        getUserWonItemsForAuction(regionId: string, auctionId: string): WonItemType[] {
            const wonItemsForRegion = self.wonItems.get(regionId);
            if (wonItemsForRegion) {
                const wonItemsForAuction = wonItemsForRegion.get(auctionId);
                if (wonItemsForAuction) {
                    const {authUserStore} = getRoot(self) as any;
                    const {userInfo} = authUserStore;
                    if (userInfo) {
                        return wonItemsForAuction.get(userInfo.uid) || [];
                    }
                }
            }

            return [];
        },
        getUserWonItemsForRegion(regionId: string): WonItemType[] {
            const wonItemsForRegion = self.wonItems.get(regionId);
            if (wonItemsForRegion) {
                const allAuctionIds = Object.keys(getSnapshot(wonItemsForRegion));
                return allAuctionIds.reduce((acc, auctionId) => {
                    return acc.concat(this.getUserWonItemsForAuction(regionId, auctionId));
                }, [] as WonItemType[]);
            }

            return [];
        },
        getUserWonItems(): WonItemType[] {
            const {authUserStore} = getRoot(self) as any;
            const {userInfo} = authUserStore;
            if (!userInfo) {
                return [];
            }

            const allWonItems = self.wonItems;
            return Array.from(allWonItems.keys()).reduce((acc, regionId) => {
                const regionWonItems = allWonItems.get(regionId);
                return regionWonItems
                    ? Array.from(regionWonItems.keys()).reduce((acc, auctionId) => {
                          const userWonItems = regionWonItems.get(auctionId);
                          return acc.concat(userWonItems ? userWonItems.get(userInfo.uid) || [] : []);
                      }, acc)
                    : [];
            }, [] as Array<WonItemType>);
        },
        getUserWonItemsGroupedByRegion(): {regionId: string; wonItems: WonItemType[]}[] {
            const allWonItems = getSnapshot(self.wonItems);

            return Object.keys(allWonItems).reduce((acc, regionId) => {
                const wonItemsForRegion = this.getUserWonItemsForRegion(regionId).sort((a, b) =>
                    a.regionId.localeCompare(b.regionId)
                );
                acc.push({
                    regionId,
                    wonItems: wonItemsForRegion,
                });

                return acc;
            }, [] as {regionId: string; wonItems: WonItemType[]}[]);
        },
        getUserWonItem(regionId: string, auctionId: string, itemId: string): WonItemType | undefined {
            const wonItemsForRegion = self.wonItems.get(regionId);
            if (wonItemsForRegion) {
                const wonItemsForAuction = wonItemsForRegion.get(auctionId);
                if (wonItemsForAuction) {
                    const {authUserStore} = getRoot(self) as any;
                    const {userInfo} = authUserStore;
                    if (userInfo) {
                        const wonItemsForUser = wonItemsForAuction.get(userInfo.uid) || [];
                        return wonItemsForUser.find((wonItem) => wonItem.itemId === itemId);
                    }
                }
            }
        },
        getWonItemsForAuction(regionId: string, auctionId: string): WonItemType[] {
            const wonItemsForRegion = self.wonItems.get(regionId);
            if (wonItemsForRegion) {
                const wonItemsForAuction = wonItemsForRegion.get(auctionId);
                if (wonItemsForAuction) {
                    return Object.keys(getSnapshot(wonItemsForAuction)).reduce((acc, userId) => {
                        return acc.concat(wonItemsForAuction.get(userId) || []);
                    }, [] as WonItemType[]);
                }
            }

            return [];
        },
        getWonItem(regionId: string, auctionId: string, itemId: string): WonItemType | undefined {
            const wonItemsForAuction = this.getWonItemsForAuction(regionId, auctionId);
            return wonItemsForAuction.find((wonItem) => wonItem.itemId === itemId);
        },
    }))
    .actions((self) => ({
        setWonItems(regionId: string, auctionId: string, userId: string, wonItems: WonItemType[]) {
            const wonItemsForRegion = self.wonItems.get(regionId) || self.wonItems.set(regionId, {}).get(regionId)!;
            const wonItemsForAuction =
                wonItemsForRegion.get(auctionId) || wonItemsForRegion.set(auctionId, {}).get(auctionId)!;
            wonItemsForAuction.set(userId, wonItems);
        },
    }))
    .actions((self) => {
        const {authUserStore, sellersStore} = getRoot(self) as any;
        const getUserInfo = () => {
            const {userInfo} = authUserStore;
            return userInfo;
        };

        return {
            loadWonItemsForAuction: function (
                regionId: string,
                auctionId: string,
                listener?: (wonItems: WonItemType[]) => void
            ) {
                const disposerKey = `${regionId}-${auctionId}`;

                const callback = () => listener && listener(self.getWonItemsForAuction(regionId, auctionId));

                if (!self.disposers[disposerKey]) {
                    self.disposers[disposerKey] = self.wonItemService.subscribeToWonItems(
                        regionId,
                        auctionId,
                        (wonItemsByUser: {[key: string]: WonItemDto[]}) => {
                            Object.entries(wonItemsByUser).forEach(([userId, wonItems]) => {
                                self.setWonItems(regionId, auctionId, userId, wonItems.map(fromDto));
                            });
                            callback();
                        }
                    );
                } else {
                    callback();
                }
            },
            loadUserWonItems: function () {
                const userInfo = getUserInfo();
                if (userInfo && !self.disposers[userInfo.uid]) {
                    self.loadingStatus.setInProgress();
                    self.disposers[userInfo.uid] = self.wonItemService.subscribeToUserWonAuctions(
                        userInfo.uid,
                        (wonAuctions: {regionId: string; auctionId: string}[]) => {
                            wonAuctions.forEach((wonAuction) => {
                                // TODO: (Future) don't subscribe to the ended auctions.
                                this.loadWonItemsForAuction(wonAuction.regionId, wonAuction.auctionId, () =>
                                    self.loadingStatus.setReady()
                                );
                            });
                        }
                    );
                }
            },
            fetchAllWonItemsForSeller(sellerId: string) {
                const seller = sellersStore.findSeller(sellerId);
                const promises =
                    seller &&
                    Object.keys(seller.regions).map((regionId) => {
                        return self.wonItemService.fetchWonItemsForSeller(sellerId, regionId);
                    });
                if (promises) {
                    Promise.all(promises)
                        .then((result) => {
                            console.log(result);
                        })
                        .catch((e) => console.log(e));
                }
                return [] as any[];
            },
            fetchCurrentUserWonItems() {
                return self.wonItemService.fetchCurrentUserWonItems();
            },
            unsubscribe() {
                Object.values(self.disposers).forEach((d) => d());
                self.disposers = {};
            },
            beforeDestroy() {
                Object.values(self.disposers).forEach((dispose) => dispose());
            },
        };
    });

export interface WonItemsStoreType extends Instance<typeof WonItemsStore> {}

export interface HasWonItemsStore {
    wonItemsStore: WonItemsStoreType;
}
