import {Unsubscribe} from '../types';
import {Firebase} from '../firebase/types';
import {AuthUser} from '../firebase/Auth';
import {UserInfo} from '@joyrideautos/auction-core/src/types/User';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {UILogger} from '@joyrideautos/ui-logger/src/Logger';
import {toError} from '../UIErrors';
import {AppConfig} from '../AppConfig';
import {PaymentMethod} from '@joyrideautos/auction-core/src/types/Payments';
import {parseJwt} from '../utils';

export class AuthUserService {
    constructor(private firebase: Firebase, private logger: UILogger, private config: AppConfig) {}

    async getIdToken() {
        return this.firebase.auth.currentUser?.getIdToken();
    }

    async signOut() {
        try {
            await this.firebase.auth.signOut();
            return true;
        } catch (e: any) {
            throw this.createError(e, 'Failed to sign out.');
        }
    }

    async signIn(email: string, password: string) {
        try {
            await this.firebase.auth.signInWithEmailAndPassword(email, password);
            return true;
        } catch (e: any) {
            this.logger.log('signIn error', e.message);
            throw this.createError(e, 'Failed to sign in.');
        }
    }

    async signInWithCustomToken(customToken: string) {
        try {
            await this.firebase.auth.signInWithCustomToken(customToken);
            return true;
        } catch (e: any) {
            this.logger.log('signInWithCustomToken error', e);
            throw this.createError(e, 'Failed to sign in using customToken.');
        }
    }

    async createUser(email: string, password: string): Promise<[UserInfo | null, boolean]> {
        try {
            const currentUser = this.firebase.auth.currentUser;

            if (currentUser && currentUser.isAnonymous) {
                const credential = this.firebase.auth.getEmailAndPasswordCredential(email, password);
                await this.firebase.auth.linkWithCredential(credential);
                const user = await this.firebase.auth.signInWithCredential(credential);
                return [await this.toUserInfo(user), false];
            }

            const user = await this.firebase.auth.createUserWithEmailAndPassword(email, password);
            return [await this.toUserInfo(user), true];
        } catch (e: any) {
            throw this.createError(e, 'Failed to create profile.');
        }
    }

    async reauthenticateUser(password: string) {
        try {
            const anUser = this.firebase.auth.currentUser;
            if (!anUser || !anUser.email) {
                return false;
            }

            const credential = this.firebase.auth.getEmailAndPasswordCredential(anUser.email, password);
            await this.firebase.auth.reauthenticateWithCredential(credential);
            return true;
        } catch (e: any) {
            throw this.createError(e, 'Failed to sign in.');
        }
    }

    async resetPassword(email: string): Promise<void> {
        await this.firebase.auth.sendPasswordResetEmail(
            email,
            `${this.config.commonConfig.publicAuctionUrl}/reset-password`
        );
    }

    async updateUserEmail(email: string) {
        try {
            const user = this.firebase.auth.currentUser;
            if (!user) {
                return false;
            }
            await this.firebase.auth.updateEmail(email.toLocaleLowerCase());
            return true;
        } catch (e: any) {
            throw this.createError(e, 'Failed to update email.');
        }
    }

    async updateUserProfile(profile: {displayName: string | null}) {
        try {
            const user = this.firebase.auth.currentUser;
            if (!user) {
                return false;
            }
            await this.firebase.auth.updateProfile(profile);
            return true;
        } catch (e: any) {
            throw this.createError(e, 'Failed to update profile.');
        }
    }

    onAuthUserListener(next: (userInfo: UserInfo) => void, fallback?: (anonymousUser: UserInfo) => void) {
        let isStopped = false;
        const unsubscribe = this.firebase.auth.onAuthStateChanged((authUser: AuthUser | null) => {
            if (isStopped) {
                return;
            }
            this.handleAuthentication(authUser, next, fallback).catch(logError('AuthUserService.onAuthUserListener'));
        });
        return () => {
            isStopped = true;
            unsubscribe();
        };
    }

    subscribeToPaymentMethods(uid: string, cb: (paymentMethods: PaymentMethod[]) => void): Unsubscribe {
        return this.firebase.firestore.subscribeToCollection<PaymentMethod>(
            this.firebase.firestore.collectionRef(`/users/${uid}/paymentMethods`),
            (pm) => {
                cb(pm ? Object.values(pm) : []);
            }
        );
    }

    private async handleAuthentication(
        authUser: AuthUser | null,
        next: (userInfo: UserInfo) => void,
        fallback?: (anonymousUser: UserInfo) => void
    ) {
        if (authUser && !authUser.isAnonymous) {
            const userInfo = await this.toUserInfo(authUser);
            const token = await this.firebase.auth.getIdTokenOfLoggedInUser();
            const extra = (token && parseJwt(token)) || {};
            next({
                ...userInfo,
                adminEmail: extra.adminEmail,
                adminUid: extra.adminUid,
                impersonatedSessionId: extra.sessionId,
                impersonatedUserEmail: extra.impersonatedUserEmail,
            });
        } else if (fallback) {
            const user = this.firebase.auth.currentUser;
            if (user?.isAnonymous) {
                fallback({
                    uid: user.uid,
                    email: null,
                    emailVerified: false,
                    phone: null,
                    phoneVerified: false,
                    isAnonymous: true,
                    providerData: user.providerData,
                    wonItems: [],
                    favorites: [],
                    settings: undefined,
                });
            } else {
                const user = await this.firebase.auth.signInAnonymously();
                fallback({
                    uid: user.uid,
                    email: null,
                    emailVerified: false,
                    phone: null,
                    phoneVerified: false,
                    isAnonymous: true,
                    providerData: user.providerData,
                    wonItems: [],
                    favorites: [],
                    settings: undefined,
                });
            }
        }
    }

    private async toUserInfo(user: AuthUser): Promise<UserInfo> {
        const dbUser = await this.firebase.database.fetchOnce<UserInfo>(`users/${user.uid}`);
        // TODO (Future): read paymentMethods from the corresponding Firestore collection instead of userInfo

        // merge auth and db user
        const mergedUserInfo: UserInfo = {
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            phone: dbUser?.phone,
            phoneVerifiedAt: dbUser?.phoneVerifiedAt,
            phoneVerificationDate: dbUser?.phoneVerificationDate,
            phoneVerificationRequestId: dbUser?.phoneVerificationRequestId,
            phoneVerificationFor: dbUser?.phoneVerificationFor,
            providerData: user.providerData,
            isAnonymous: user.isAnonymous,
            firstName: dbUser?.firstName,
            lastName: dbUser?.lastName,
            companyName: dbUser?.companyName,
            zipcode: dbUser?.zipcode,
            wonItems: [],
            settings: dbUser?.settings && {
                address: dbUser.settings && dbUser.settings.address,
            },
            favorites: [],
        };

        return mergedUserInfo;
    }

    private createError(e: any, message: string) {
        return toError(e.code) ?? message;
    }
}
