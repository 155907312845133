import {Performance as Service} from '@joyrideautos/ui-services/src/firebase/Performance';
import {trace, getPerformance, FirebasePerformance} from 'firebase/performance';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {FirebaseApp} from 'firebase/app';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';

class Performance implements Service {
    constructor(private performance?: FirebasePerformance, private logger?: Logger) {}

    trace(id: string) {
        if (!this.performance) {
            this.logger?.log('Performance is not available');
            return;
        }
        return trace(this.performance, id);
    }
}

export function createPerformance(firebaseApp: FirebaseApp, appConfig: AppConfig, logger?: Logger) {
    const performance = appConfig.isEmulatedEnv ? undefined : getPerformance(firebaseApp);
    return new Performance(performance, logger);
}
