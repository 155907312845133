import {EventSnapshotIn} from './Event';
import {Notification} from '@joyrideautos/auction-core/src/types/events/notifications';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {EventDtoParamsType} from './EventDto';
import {NotificationKindEnum} from './Notification';

export function mapPersistedNotificationDtoToEventType(event: WithKey<Notification>): EventSnapshotIn {
    return {
        kind: NotificationKindEnum.PERSISTED,
        ...event,
        params: {
            ...(event.params as EventDtoParamsType),
        },
    };
}
