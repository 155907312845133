import {Storage as Service, UploadMetadata} from '@joyrideautos/ui-services/src/firebase/Storage';
import {
    ref,
    getDownloadURL,
    getStorage,
    uploadBytesResumable,
    FirebaseStorage,
    connectStorageEmulator,
} from 'firebase/storage';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {isLocalHost} from '@joyrideautos/auction-utils/src/common';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import {FirebaseApp} from 'firebase/app';

class Storage implements Service {
    constructor(private storage: FirebaseStorage, private appConfig: AppConfig, private logger?: Logger) {
        if (this.appConfig.emulatorsConfig.storageUrl && isLocalHost()) {
            const storageUrl = new URL(this.appConfig.emulatorsConfig.storageUrl);
            const host = storageUrl.hostname;
            const port = parseInt(storageUrl.port || '8087');
            this.logger?.log(`        -- hitting local storage emulator: ${host}/${port}`);
            connectStorageEmulator(storage, host, port);
        }
    }

    ref(path: string) {
        return ref(this.storage, path);
    }

    async getDownloadURL(path: string) {
        return getDownloadURL(this.ref(path));
    }

    uploadBytesResumable(path: string, file: File, metadata?: UploadMetadata) {
        return uploadBytesResumable(this.ref(path), file, metadata);
    }

    downloadFile(name: string, url: string | Blob) {
        const link = document.createElement('a');
        link.href = typeof url === 'string' ? url : window.URL.createObjectURL(url);
        link.target = '_blank';
        link.download = name;
        document.body.appendChild(link); // Required for FF
        link.click();
    }
}

export function createStorage(firebaseApp: FirebaseApp, appConfig: AppConfig, logger?: Logger) {
    return new Storage(getStorage(firebaseApp), appConfig, logger);
}
