import React, {FC} from 'react';
import {COMPANY_NAME} from '@joyrideautos/auction-core/src/constants/Constants';
import {useTranslation} from 'react-i18next';

export const Copyright: FC = () => {
    const version = process.env.REACT_APP_BITBUCKET_TAG || process.env.REACT_APP_BITBUCKET_BUILD_NUMBER;
    const {t} = useTranslation();
    return (
        <span className="copyright">
            {version && (
                <span>
                    {t('footer.version')} {version} &nbsp;
                </span>
            )}{' '}
            &copy; 2020 - {new Date().getFullYear()} {COMPANY_NAME}
        </span>
    );
};
