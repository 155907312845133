import * as React from 'react';
import {action, autorun, makeObservable, observable} from 'mobx';
import {Validator} from '../validators/Validators';
import {InputFieldStatus} from './Statutes';

type InputOnChange = (data?: string) => void;
type TextAreaOnInput = (data?: string) => void;

export interface IModel {
    value?: string;
    message?: string;
    status: InputFieldStatus;
    validate: () => void;
    isValid: boolean;
    setMessage: (value: string) => void;
    resetMessage: () => void;
}

export interface IInputFieldModel extends IModel {
    onChange: InputOnChange;
    onBlur: (value?: string) => void;
    onFocus: React.FocusEventHandler<HTMLInputElement>;
}

export interface ITextAreaModel extends IModel {
    onInput: TextAreaOnInput;
    onBlur: React.FocusEventHandler<HTMLTextAreaElement>;
    onFocus: React.FocusEventHandler<HTMLTextAreaElement>;
}

export class InputFieldModel<T extends IModel> {
    status: InputFieldStatus = 'normal';
    focused = false;
    changed = false;
    message: string | undefined = undefined;
    localValue: string | null = null;

    constructor(
        public validator: Validator<string> = () => undefined,
        public updateOnChange: boolean = false,
        private selectOnFocus: boolean = false,
        public forceFormatOnChange: boolean = false,
        public updateOnBlur: boolean = true
    ) {
        makeObservable(this, {
            status: observable,
            message: observable,
            localValue: observable,
            connect: action,
            setLocalValue: action,
            _setLocalValue: action,
            setMessage: action,
        });
    }

    setLocalValue(value: string | undefined | null, markChanged: boolean) {
        this.localValue = value ?? null;
        this.validate(this.forceFormatOnChange); // Need to validate the value if it was changes somewhere outside
        if (markChanged) {
            this.changed = true;
        }
    }

    // this method is used as a setter to get rid of mobx warnings about using actions
    _setLocalValue(value: string | undefined | null) {
        this.localValue = value ?? null;
    }

    setMessage(value: string | undefined) {
        this.message = value;
    }

    reset = () => {
        this.status = 'normal';
        this.message = undefined;
    };

    shouldInvokeValidator = () => {
        return this.changed && !this.focused;
    };

    validate = (force = false) => {
        if (this.shouldInvokeValidator() || force) {
            this.setMessage(this.validator(this.localValue || ''));
        }
    };

    connect = (
        x: string | undefined | null,
        onValueChanged: (value: string | undefined, currentModel: IInputFieldModel) => void
    ): IInputFieldModel => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const factory = this;

        factory.setLocalValue(x, false);

        const obj = class implements IInputFieldModel {
            get value() {
                return factory.localValue || '';
            }

            get status() {
                return factory.status;
            }

            get message() {
                return factory.message;
            }

            get isValid() {
                return !factory.message;
            }

            validate() {
                factory.validate(true);
            }

            updateValue = (value: string | undefined | null, currentModel: IInputFieldModel) => {
                onValueChanged(!value ? undefined : value, currentModel);
            };

            onChange: InputOnChange = (data) => {
                factory.setLocalValue(data, true);
                // We need to validate the value first and then pass the model to the change listener so that it can understand if the value is valid
                factory.validate(factory.forceFormatOnChange);
                if (factory.updateOnChange) {
                    this.updateValue(data, this);
                }
            };

            onFocus: (event: React.SyntheticEvent<HTMLElement>) => void = (event) => {
                if (factory.selectOnFocus) {
                    (function inputElementSelectAllClosure(inputElement: HTMLInputElement) {
                        setTimeout(() => {
                            inputElement.setSelectionRange(0, inputElement.value.length);
                        }, 150);
                    })(event.target as HTMLInputElement);
                }

                factory.focused = true;
                factory.validate();
            };

            onBlur: (value?: string) => void = (value) => {
                factory.focused = false;
                // We need to validate the value first and then pass the model to the change listener so that it can understand if the value is valid
                if (factory.updateOnBlur) {
                    factory.validate();
                    this.updateValue(value, this);
                }
            };

            setMessage(value = '') {
                factory.message = value;
            }

            resetMessage() {
                factory.message = '';
            }
        };
        return new obj();
    };

    connectTextArea = (x: string | undefined | null, onValueChanged: (value?: string) => void): ITextAreaModel => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const factory = this;

        autorun((disposer) => {
            factory._setLocalValue(x);
            factory.validate(factory.forceFormatOnChange); // Need to validate the value if it was changes somewhere outside
            disposer.dispose();
        });

        const obj = class implements ITextAreaModel {
            get value() {
                return factory.localValue || '';
            }

            get status() {
                return factory.status;
            }

            get message() {
                return factory.message;
            }

            validate() {
                factory.validate(true);
            }

            get isValid() {
                return !factory.message;
            }

            updateValue = (value: string | undefined) => {
                onValueChanged(!value || value === '' ? undefined : value);
            };

            onInput: TextAreaOnInput = (data) => {
                factory.changed = true;
                factory._setLocalValue(data);
                factory.validate();
                if (factory.updateOnChange) {
                    this.updateValue(data);
                }
            };

            onFocus: (event: React.SyntheticEvent<HTMLTextAreaElement>) => void = () => {
                factory.focused = true;
                factory.validate();
            };

            onBlur: React.FocusEventHandler<HTMLTextAreaElement> = (event) => {
                factory.focused = false;
                factory.validate();
                this.updateValue(event.currentTarget.value);
            };

            setMessage(value = '') {
                factory.message = value;
            }

            resetMessage() {
                factory.message = '';
            }
        };

        return new obj();
    };
}
