import React, {ComponentClass, ComponentType, useEffect, useRef, useState} from 'react';
import {destroy, IAnyModelType} from 'mobx-state-tree';
import {HasViewModel} from '../types';
import {autorun} from 'mobx';

interface ComponentEnhancer<TInner, TOuter> {
    (component: ComponentType<TInner>): ComponentClass<TOuter>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function compose<TInner, TOuter>(...functions: Function[]): ComponentEnhancer<TInner, TOuter> {
    return functions.reduce(
        (a, b) =>
            (...args: any) =>
                a(b(...args))
    ) as any;
}

// It should be used only for common global components when otherwise we would need to store their viewModel in presenters of
//  several pages
export const withViewModel =
    <P extends IAnyModelType>(ViewModel: P['Type'], rootStore: any) =>
    (WrappedComponent: ComponentType<HasViewModel<P>>) =>
    (props: any) => {
        const [viewModel, setViewModel] = useState(null as P['Type'] | null);

        useEffect(() => {
            const disposer = autorun(() => {
                const {routeModel} = rootStore.presentationStore;
                if (routeModel) {
                    const {params, path, isExact} = routeModel;
                    if (isExact) {
                        const viewModel = ViewModel.create(undefined, {...params, path, rootStore});
                        requestAnimationFrame(() => {
                            setViewModel(viewModel);
                        });
                    }
                }
            });

            return () => {
                disposer();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        if (!viewModel) {
            return null;
        }

        return <WrappedComponent {...props} viewModel={viewModel} />;
    };

export const withSingletonViewModel = <P extends IAnyModelType, PropsType>(
    ViewModel: P['Type'],
    initialState: any = undefined,
    env: any = {}
) => {
    const viewModel = ViewModel.create(initialState, env);
    console.log('withSingletonViewModel. create', viewModel.toString());
    return (WrappedComponent: ComponentType<PropsType & HasViewModel<P>>): ComponentType<PropsType> =>
        (props) => {
            return <WrappedComponent {...props} viewModel={viewModel} />;
        };
};

export const useViewModelDisposer = <T extends IAnyModelType>(): [
    addViewModel: (key: string, viewModel: T['Type']) => void
] => {
    const holder = useRef<Map<string, T>>(new Map());

    useEffect(() => {
        return () => {
            const viewModels = Array.from(holder.current.values());
            viewModels.forEach((vm) => destroy(vm));
        };
    }, []);
    return [
        (key: string, viewModel: T) => {
            if (holder.current.get(key)) {
                destroy(holder.current.get(key));
            }
            holder.current.set(key, viewModel);
        },
    ];
};
