import * as React from 'react';
import {action, makeObservable, observable} from 'mobx';
import {Validator} from '../validators/Validators';
import {InputFieldStatus} from './Statutes';

type CheckboxOnChange = (data?: boolean) => void;

export interface ICheckboxModel {
    checked?: boolean;
    status: InputFieldStatus;
    message?: string;
    validate: () => void;
    onChange: CheckboxOnChange;
    onBlur: React.FocusEventHandler<HTMLInputElement>;
    onFocus: React.FocusEventHandler<HTMLInputElement>;
}

export class CheckboxModel {
    status: InputFieldStatus = 'normal';
    focused = false;
    changed = false;
    message: string | undefined = undefined;

    constructor(public validator: Validator<boolean> = () => undefined) {
        makeObservable(this, {
            status: observable,
            message: observable,
            connect: action,
            setMessage: action,
        });
    }

    setMessage(value: string | undefined) {
        this.message = value;
    }

    shouldInvokeValidator = () => {
        return this.changed && !this.focused;
    };

    validate = (value: boolean | undefined, force = false) => {
        if (this.shouldInvokeValidator() || force) {
            this.setMessage(this.validator(value));
        }
    };

    connect = (
        x: boolean | undefined,
        onValueChanged: (value: boolean | undefined, key?: string) => void,
        key?: string
    ): ICheckboxModel => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const factory = this;

        const obj = class implements ICheckboxModel {
            get checked() {
                return x;
            }

            get status() {
                return factory.status;
            }

            onChange: CheckboxOnChange = (data) => {
                onValueChanged(data, key);
                factory.changed = true;
                factory.validate(data);
            };

            get message() {
                return factory.message;
            }

            validate() {
                factory.validate(x, true);
            }

            // TODO (Future): fix - checkboxes don't have onFocus and onBlur events
            onFocus: (event: React.SyntheticEvent<HTMLElement>) => void = () => {
                factory.focused = true;
                factory.validate(x);
            };

            onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
                factory.focused = false;
                factory.validate(x);
            };
        };
        return new obj();
    };
}
