import {Button, Col, Row} from 'antd';
import React, {FC} from 'react';
import {InfoCircleFilled, LogoutOutlined} from '@ant-design/icons';
import {isMobile} from 'react-device-detect';
import {useRootStore} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';
import {RootStoreType} from '@models/stores/RootStore';
import styles from './ImpersonateWarning.module.less';
import {observer} from 'mobx-react-lite';

const ImpersonateWarning: FC = observer(() => {
    const {authUserStore} = useRootStore<RootStoreType>();

    return (
        <div className={styles['impersonate-container']}>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row align="middle" className={styles['impersonate-user']}>
                        <Col className={styles['impersonate-user-box']}>
                            <InfoCircleFilled />
                            <p>
                                <span>{authUserStore.userInfo?.adminEmail}</span> logged in as{' '}
                                <strong>{authUserStore.userInfo?.firstName}</strong> ({authUserStore.userInfo?.email})
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row align="middle" justify={isMobile ? 'center' : 'end'} className={styles['impersonate-logout']}>
                        <Col>
                            <Button type="primary" icon={<LogoutOutlined />} onClick={authUserStore.signOut}>
                                End Session
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
});

export default ImpersonateWarning;
