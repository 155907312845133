import {AuctionAccessType, AuctionFormatType} from '../types/auction/types';
import {RegionType} from '../types/Region';
import {LocationType, SellerType} from '../types/Seller';
import {CodeAndNameType} from '../types/CodeAndName';
import {Instance} from 'mobx-state-tree';
import VehicleModelMstModel from '../types/VehicleModelMstModel';
import Make from '../types/Make';

export const DEFAULT_OPTION = {key: 'default_option', text: '', value: undefined};

const auctionFormatOptions = [
    {key: 'SEQUENCE', text: 'Sequential', value: 'SEQUENCE'},
    {key: 'LISTING', text: 'Listing', value: 'LISTING'},
];

const auctionAccessOptions = [
    {key: 'PUBLIC', text: 'Public Auction', value: 'PUBLIC'},
    {key: 'RESTRICTED', text: 'Licensed Buyers Only', value: 'RESTRICTED'},
];

const DEFAULT_ITEM = {
    key: '',
    text: '',
    value: '',
};

export function renderEnumValue<T>(enumConst: T) {
    return (s?: keyof typeof enumConst) => {
        return s
            ? {
                  key: s,
                  text: enumConst[s],
                  value: s,
              }
            : DEFAULT_OPTION;
    };
}

export function renderStringValue(s?: string) {
    return s
        ? {
              key: s,
              text: s,
              value: s,
          }
        : DEFAULT_OPTION;
}

export function renderNumberValue(n?: number) {
    return n
        ? {
              key: String(n),
              text: String(n),
              value: String(n),
          }
        : DEFAULT_OPTION;
}

export function renderAddressState(s?: {code: string; name: string}) {
    return s
        ? {
              key: s.code,
              text: s.name,
              value: s.code,
          }
        : {key: 'default_option', text: '', value: undefined};
}

export function renderSellerLocation(s?: LocationType) {
    return s
        ? {
              key: s.id,
              text: s.description,
              value: s.id,
          }
        : DEFAULT_OPTION;
}

export function renderAuctionAccessItem(s?: AuctionAccessType) {
    return auctionAccessOptions.find((o) => o.key === s) || DEFAULT_ITEM;
}

export function renderAuctionFormatItem(s?: AuctionFormatType) {
    return auctionFormatOptions.find((o) => o.key === s) || DEFAULT_ITEM;
}

export function renderRegionItem(r?: RegionType) {
    return r
        ? {
              key: r.regionId,
              value: r.regionId,
              text: r.renderedDisplayName,
          }
        : DEFAULT_OPTION;
}

export function renderSeller(seller: SellerType | undefined) {
    return seller
        ? {
              key: seller.sellerId,
              value: seller.sellerId,
              text: seller.name,
          }
        : DEFAULT_OPTION;
}

export function renderItemMake(make: string | undefined) {
    return {
        key: `make-item-${make}`,
        value: make,
        text: make,
    };
}

export function renderCodeAndName(value?: CodeAndNameType) {
    return value?.code
        ? {
              key: value.code,
              text: value.name || value.code,
              value: value.code,
          }
        : value
        ? {
              key: 'default_option',
              text: value.name,
              value: undefined,
          }
        : DEFAULT_OPTION;
}

export function renderVehicleModel(value?: Instance<typeof VehicleModelMstModel>) {
    return value
        ? {
              key: value.modelId,
              text: value.modelName,
              value: `${value.modelName}:${value.modelId}`,
          }
        : DEFAULT_OPTION;
}

export function renderMake(value?: Instance<typeof Make>) {
    return value
        ? {
              key: value.makeCode,
              text: value.makeName,
              value: value.makeName,
          }
        : DEFAULT_OPTION;
}
