import React, {Component} from 'react';
import type {History} from 'history';
import {Router, Route, Switch} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ReactRouterGA from './analytics/GoogleAnalytics';
import '@joyrideautos/web-common-components/src/components/webComponents/defineCustomElements';
import PageTemplate from '@layouts/PageTemplate';
import {anonymousRoutes, authUserRoutes, renderNotFoundPage, staticRoutes} from './AppRoutes';
import {RootStoreType} from '@models/stores/RootStore';
import {
    defaultTheme, // Default theme
} from '@ant-design/compatible';
import {ConfigProvider} from 'antd';
import {autorun} from 'mobx';
import {CUSTOM_THEME} from './constants/constants';

const FORCE_REFRESH_TIMEOUT_MS = 2 * 60 * 60 * 1000;

export interface AppProps {
    history: History;
    rootStore: RootStoreType;
}

class App extends Component<AppProps> {
    disposers: (() => void)[] = [];

    setForceRefreshHistory() {
        const unregister = this.props.history.listen(() => {
            window.location.reload();
            unregister();
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.setForceRefreshHistory();
        }, FORCE_REFRESH_TIMEOUT_MS);
        this.disposers.push(
            autorun(() => {
                const shouldRefreshAfterRelease =
                    this.props.rootStore.globalConfigStore.shouldRefreshAfterRelease.value;
                this.props.rootStore.appStore.setReleaseVersionListener((version) => {
                    if (shouldRefreshAfterRelease) {
                        window.location.reload();
                    }
                });
            })
        );
    }

    componentWillUnmount() {
        this.disposers.forEach((disposer) => disposer());
    }

    render() {
        return (
            <ConfigProvider
                theme={{
                    ...defaultTheme,
                    token: CUSTOM_THEME,
                    components: {
                        Anchor: {},
                        Modal: {wireframe: true},
                    },
                }}>
                <Router history={this.props.history}>
                    <ReactRouterGA trackPathnameOnly={true} rootStore={this.props.rootStore}>
                        <Switch>
                            {staticRoutes.map(({path, render, exact}) => (
                                <Route key={path} exact={exact} path={path} render={render} />
                            ))}
                            <Route>
                                <PageTemplate>
                                    <Switch>
                                        {anonymousRoutes.map(({path, render, exact}) => (
                                            <Route key={path} exact={exact} path={path} render={render} />
                                        ))}
                                        {authUserRoutes.map(({path, render, exact}) => (
                                            <Route key={path} exact={exact} path={path} render={render} />
                                        ))}
                                        <Route render={renderNotFoundPage()} />
                                    </Switch>
                                </PageTemplate>
                            </Route>
                        </Switch>
                    </ReactRouterGA>
                </Router>
            </ConfigProvider>
        );
    }
}

export default Sentry.withProfiler(App);
