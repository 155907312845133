import {flow, isAlive, types} from 'mobx-state-tree';
import BaseViewModel from '../../BaseViewModel';
import {LoadingStatus} from '../../utils/LoadingStatus';
import {CancelableError, makeCancelable} from '@joyrideautos/auction-utils/src/cancelable';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {SellerIntegration, SellerIntegrationType, sorting} from '../../types/SellerIntegration';

const cancelable = makeCancelable<SellerIntegrationType[]>('fetch seller integration');

const RefsSellerIntegration = BaseViewModel.named('RefsMake')
    .props({
        cache: types.map(SellerIntegration),
    })
    .volatile(() => ({
        loadingStatus: new LoadingStatus(),
    }))
    .views((self) => ({
        get sorted() {
            return Array.from(self.cache.values()).sort(sorting('name'));
        },
    }))
    .actions((self) => ({
        loadAllRefs: flow(function* () {
            const {isReady, isInProgress} = self.loadingStatus;
            if (!isAlive(self) || isReady || isInProgress) {
                return self.sorted;
            }
            try {
                self.loadingStatus.setInProgress();
                const allMakes: SellerIntegrationType[] = yield cancelable(
                    self.rootStore.refsService.fetchSellerIntegrations.bind(self.rootStore.refsService),
                    {
                        abort: () => !isAlive(self),
                    }
                );
                if (!isAlive(self)) {
                    return self.sorted;
                }
                self.cache = cast(
                    allMakes.reduce<Record<string, SellerIntegrationType>>((sellerIntegrations, {id, name}) => {
                        sellerIntegrations[id] = {
                            id,
                            name,
                        };
                        return sellerIntegrations;
                    }, {})
                );
                self.loadingStatus.setReady();
            } catch (e: any) {
                if (e instanceof CancelableError) {
                    self.logger.log(e.message);
                } else {
                    self.logger.error(e);
                    self.loadingStatus.setError(e.message);
                }
            }
            return self.sorted;
        }),
    }))
    .views((self) => ({
        get refs() {
            if (self.loadingStatus.isNew) {
                self.loadAllRefs().catch(logError());
            }
            return self.sorted;
        },
    }));

export default RefsSellerIntegration;
