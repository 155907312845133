import {flow, types} from 'mobx-state-tree';
import {AddressState, AddressStateType} from '@joyrideautos/ui-models/src/types/UserInfo';
import {LoadingStatus, withStatus} from '@joyrideautos/ui-models/src/utils/LoadingStatus';
import {InputFieldModel} from '@joyrideautos/ui-models/src/smartFields/models/InputFieldModel';
import ValidationBuilder, {
    chainValidator,
    declarativeValidator,
    requiredFieldValidator,
} from '@joyrideautos/ui-models/src/smartFields/validators/Validators';
import {DropdownModelFactory, withDropdownStatus} from '@joyrideautos/ui-models/src/smartFields/models/DropdownModel';
import {renderAddressState} from '@joyrideautos/ui-models/src/smartFields/renderDropdownItems';
import {IReactionDisposer, when} from 'mobx';
import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {USER_FIELDS_LENGTH} from '@joyrideautos/auction-core/src/types/validations/userValidation';

const zipcodeValidator = declarativeValidator(`required|digits:${USER_FIELDS_LENGTH.ZIPCODE}`);
const cityValidator = chainValidator(
    requiredFieldValidator('City'),
    declarativeValidator(`less_than:${USER_FIELDS_LENGTH.CITY}`)
);
const stateValidator = chainValidator(
    requiredFieldValidator('State'),
    declarativeValidator(`less_than:${USER_FIELDS_LENGTH.STATE}`)
);
const streetValidator = chainValidator(
    requiredFieldValidator('Street'),
    declarativeValidator(`less_than:${USER_FIELDS_LENGTH.STREET}`)
);
const apartmentValidator = declarativeValidator(`less_than:${USER_FIELDS_LENGTH.APARTMENT}`);

export const AddressFormViewModel = RootStoreAwareViewModel.named('AddressFormViewModel')
    .props({
        zipcode: types.optional(types.string, ''),
        city: types.optional(types.string, ''),
        state: types.maybe(AddressState),
        street: types.optional(types.string, ''),
        apartment: types.optional(types.string, ''),
    })
    .volatile(() => ({
        status: new LoadingStatus(),
        disposers: [] as IReactionDisposer[],
    }))
    .views((self) => ({
        get addressStates(): Promise<AddressStateType[]> {
            return self.rootStore.regionsService.fetchAllStates().then((allStates) =>
                Object.keys(allStates).map((code) => ({
                    code: code,
                    name: allStates[code],
                }))
            );
        },
    }))
    .actions((self) => ({
        setZipcode(code?: string) {
            self.zipcode = code || '';
            self.status = new LoadingStatus();
        },
        setCity(value?: string) {
            self.city = value || '';
            self.status = new LoadingStatus();
        },
        setAddressState(value?: AddressStateType) {
            self.state = value;
            self.status = new LoadingStatus();
        },
        setStreet(value?: string) {
            self.street = value || '';
            self.status = new LoadingStatus();
        },
        setApartment(value?: string) {
            self.apartment = value || '';
            self.status = new LoadingStatus();
        },
    }))
    .views((self) => {
        const zipcodeConnector = new InputFieldModel(zipcodeValidator, true);
        const cityConnector = new InputFieldModel(cityValidator, true);
        const stateConnector = new DropdownModelFactory<AddressStateType>(
            withDropdownStatus(self.addressStates),
            renderAddressState
        );
        const streetConnector = new InputFieldModel(streetValidator, true);
        const apartmentConnector = new InputFieldModel(apartmentValidator, true);

        return {
            get userInfo() {
                return self.authUserStore.userInfo;
            },
            get zipcodeViewModel() {
                return zipcodeConnector.connect(self.zipcode, self.setZipcode);
            },
            get cityViewModel() {
                return cityConnector.connect(self.city, self.setCity);
            },
            get stateViewModel() {
                return stateConnector.connect(self.state, self.setAddressState);
            },
            get streetViewModel() {
                return streetConnector.connect(self.street, self.setStreet);
            },
            get apartmentViewModel() {
                return apartmentConnector.connect(self.apartment, self.setApartment);
            },
            get valuesChanged() {
                return this.userInfo
                    ? self.zipcode !== this.userInfo.zipcode ||
                          self.city !==
                              ((this.userInfo.settings &&
                                  this.userInfo.settings.address &&
                                  this.userInfo.settings.address.city) ||
                                  '') ||
                          (self.state &&
                              self.state.code !==
                                  ((this.userInfo.settings &&
                                      this.userInfo.settings.address &&
                                      this.userInfo.settings.address.state) ||
                                      '')) ||
                          self.street !==
                              ((this.userInfo.settings &&
                                  this.userInfo.settings.address &&
                                  this.userInfo.settings.address.street) ||
                                  '') ||
                          self.apartment !==
                              ((this.userInfo.settings &&
                                  this.userInfo.settings.address &&
                                  this.userInfo.settings.address.apartment) ||
                                  '')
                    : false;
            },
            get isValid() {
                return this.userInfo
                    ? new ValidationBuilder()
                          .add(this.zipcodeViewModel, zipcodeValidator, self.zipcode)
                          .add(this.cityViewModel, cityValidator, self.city)
                          .add(this.stateViewModel, stateValidator, self.state?.name)
                          .add(this.streetViewModel, streetValidator, self.street)
                          .add(this.apartmentViewModel, apartmentValidator, self.apartment)
                          .validate()
                    : true;
            },
        };
    })
    .actions((self) => {
        const update = flow(function* () {
            if (!self.valuesChanged) {
                return;
            }

            if (!self.isValid) {
                self.status.setError('Please correct errors on the form');
                return;
            }

            yield withStatus(self.status)(() =>
                self.authUserStore.updateAddress(self.zipcode, {
                    city: self.city,
                    state: self.state ? self.state.code : '',
                    street: self.street,
                    apartment: self.apartment,
                })
            );
        });

        return {
            afterCreate() {
                self.disposers.push(
                    when(
                        () => self.userInfo !== undefined,
                        () => {
                            const userInfo = self.userInfo!;

                            self.setZipcode(userInfo.zipcode);

                            const {settings} = userInfo;
                            self.setCity(settings && settings.address && settings.address.city);
                            self.rootStore.regionsService
                                .fetchAllStates()
                                .then((allStates) => {
                                    const state = settings && settings.address && settings.address.state;
                                    state && self.setAddressState({code: state, name: allStates[state]});
                                })
                                .catch(logError());
                            self.setStreet(settings && settings.address && settings.address.street);
                            self.setApartment(settings && settings.address && settings.address.apartment);
                        }
                    )
                );
            },
            beforeDestroy() {
                self.disposers.forEach((disposer) => disposer());
            },
            resetStatus() {
                self.status = new LoadingStatus();
            },
            update,
        };
    });
