import {DatabaseQuery as Query} from '@joyrideautos/ui-services/src/firebase/Database';
import {
    query,
    ref,
    orderByChild,
    orderByKey,
    orderByValue,
    equalTo,
    limitToFirst,
    limitToLast,
    startAfter,
    startAt,
    endAt,
    endBefore,
    Database,
    Query as FirebaseDatabaseQuery,
} from 'firebase/database';

export class DatabaseQuery implements Query {
    private _query: FirebaseDatabaseQuery;

    constructor(private database: Database, path: string | FirebaseDatabaseQuery) {
        this._query = typeof path === 'string' ? ref(this.database, path) : path;
    }

    get key() {
        return this._query.ref.key;
    }

    get query() {
        return this._query;
    }

    equalTo(value: string | number | boolean | null, key?: string): DatabaseQuery {
        this._query = query(this.query, equalTo(value, key));
        return this;
    }

    orderByChild(path: string) {
        this._query = query(this.query, orderByChild(path));
        return this;
    }

    orderByKey(): DatabaseQuery {
        this._query = query(this.query, orderByKey());
        return this;
    }

    orderByValue(): DatabaseQuery {
        this._query = query(this.query, orderByValue());
        return this;
    }

    limitToFirst(limit: number): DatabaseQuery {
        this._query = query(this.query, limitToFirst(limit));
        return this;
    }

    limitToLast(limit: number): DatabaseQuery {
        this._query = query(this.query, limitToLast(limit));
        return this;
    }

    startAt(value?: number | string | boolean | null, key?: string): DatabaseQuery {
        this._query = query(this.query, startAt(value, key));
        return this;
    }

    startAfter(value: number | string | boolean | null, key?: string): DatabaseQuery {
        this._query = query(this.query, startAfter(value, key));
        return this;
    }

    endAt(value: number | string | boolean | null, key?: string): DatabaseQuery {
        this._query = query(this.query, endAt(value, key));
        return this;
    }

    endBefore(value: number | string | boolean | null, key?: string): DatabaseQuery {
        this._query = query(this.query, endBefore(value, key));
        return this;
    }
}
