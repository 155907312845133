import {Seller, SellerType, mapFromDto, SellerCustomFieldType} from '../types/Seller';
import {flow, Instance, types, getSnapshot, cast, IDisposer} from 'mobx-state-tree';
import {keys, toJS} from 'mobx';
import {LoadingStatus} from '../utils/LoadingStatus';
import {DraftSeller, NewSellerCustomField, SellerLocation} from '@joyrideautos/auction-core/src/types/Seller';
import {intersection} from '@joyrideautos/auction-utils/src/arrayUtils';
import BaseStore from './BaseStore';

export const SellersStore = BaseStore.named('SellersStore')
    .props({
        sellers: types.map(Seller),
        sellersForPreapprovedUsers: types.map(types.array(types.reference(Seller))),
        sellersForBannedUsers: types.map(types.array(types.reference(Seller))),
    })
    .volatile(() => ({
        disposers: [] as IDisposer[],
        loadingStatus: new LoadingStatus(),
    }))
    .actions((self) => ({
        setSellers: function (sellers: {[sellerId: string]: SellerType}) {
            self.sellers.replace(sellers);
        },
    }))
    .views((self) => ({
        get allSellers(): SellerType[] {
            return Array.from(self.sellers.values());
        },
        sellersForRegion(...regionIds: string[]): SellerType[] {
            const result: SellerType[] = [];
            self.sellers.forEach((seller) => {
                const sellerRegionIds = [...keys(seller.regions)];
                if (intersection(sellerRegionIds, regionIds).length > 0) {
                    result.push(seller);
                }
            });

            return result;
        },
        findSellers(sellerIds: string[]): SellerType[] {
            return sellerIds.reduce((sellersArr, id) => {
                return sellersArr.concat(self.sellers.get(id) || []);
            }, [] as SellerType[]);
        },
        findSeller(sellerId: string): SellerType | undefined {
            return self.sellers.get(sellerId);
        },
        findLocation(sellerId: string, locationId: string) {
            return this.findSeller(sellerId)?.locations?.get(locationId);
        },
        sellersForPreapprovedUser(uid: string) {
            const sellers = self.sellersForPreapprovedUsers.get(uid);
            const isPreapprovedForAll = sellers && sellers.length === 0;
            return isPreapprovedForAll ? this.allSellers : sellers;
        },
        sellersForBannedUser(uid: string): SellerType[] | undefined {
            return self.sellersForBannedUsers.get(uid);
        },
        getCustomFieldsForSeller(sellerId: string): SellerCustomFieldType[] {
            return Array.from(this.findSeller(sellerId)?.customFields.values() ?? []).filter(
                ({deletedBy}) => !deletedBy
            );
        },
    }))
    .actions((self) => {
        return {
            fetchSellersForPreapprovedUser: flow(function* (uid: string, force = false) {
                if (!self.sellersForPreapprovedUsers.has(uid) || force) {
                    const sellers = yield self.sellersService.fetchSellersForPreapprovedUser(uid);
                    if (sellers) {
                        self.sellersForPreapprovedUsers.set(uid, sellers);
                    } else {
                        self.sellersForPreapprovedUsers.delete(uid);
                    }
                }
                return self.sellersForPreapprovedUsers.get(uid);
            }),
            fetchSellersForBannedUser: flow(function* (uid: string) {
                if (!self.sellersForBannedUsers.has(uid)) {
                    const sellers: string[] = yield self.userBansService.findSellersForBannedUser(uid);
                    if (sellers.length > 0) {
                        self.sellersForBannedUsers.set(uid, sellers);
                    }
                }
                return self.sellersForBannedUsers.get(uid);
            }),
            updateSellerRegions: flow(function* (sellerId: string, regions: string[]) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    const sellerInfo = getSnapshot(seller);
                    yield self.sellersService.updateSellerRegions(sellerId, regions);
                    const newRegions: {[key: string]: boolean} = {};
                    regions.forEach((region) => {
                        newRegions[region] = true;
                    });
                    self.sellers.set(sellerId, {
                        ...sellerInfo,
                        regions: newRegions,
                    });
                }
            }),
            updateSellerGoogleFolderId: flow(function* (sellerId: string, folderId: string) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    const sellerInfo = getSnapshot(seller);
                    yield self.sellersService.updateSellerGoogleFolderId(sellerId, folderId);
                    self.sellers.set(sellerId, {
                        ...sellerInfo,
                        googleFolderId: folderId,
                    });
                }
            }),
            updateSellerAddress: flow(function* (
                sellerId: string,
                name: string,
                phoneNumber: string,
                emailAddress: string,
                internalEmailAddress: string
            ) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    const sellerInfo = getSnapshot(seller);
                    yield self.sellersService.updateSellerAddress(
                        sellerId,
                        name,
                        phoneNumber,
                        emailAddress,
                        internalEmailAddress
                    );
                    self.sellers.set(sellerId, {
                        ...sellerInfo,
                        name,
                        settings: {
                            ...sellerInfo.settings,
                            phoneNumber,
                            emailAddress,
                            internalEmailAddress,
                        },
                    });
                }
            }),
            createSeller: flow(function* (sellerInfo: DraftSeller) {
                const sellerId = yield self.sellersService.createSeller(sellerInfo);
                self.sellers.set(sellerId, {...sellerInfo, sellerId});
                return sellerId;
            }),
            updateSellerLocation: flow(function* (
                sellerId: string,
                locationId: number | string | undefined,
                location: Partial<SellerLocation>
            ) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    const id = yield self.sellersService.updateSellerLocation({id: locationId, sellerId, location});
                    const seller = self.sellers.get(sellerId);
                    if (seller) {
                        const current = seller.locations?.get(String(id));
                        const updated = current ? {...current, ...location} : location;
                        if (!seller.locations) {
                            seller.locations = cast({});
                        }
                        seller.locations!.set(String(id), {...updated, id});
                    }
                }
            }),
            updateSellerIntegration: flow(function* (sellerId: string, integration: string) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    const sellerInfo = getSnapshot(seller);
                    yield self.sellersService.updateSellerInfo(sellerId, {integration});
                    self.sellers.set(sellerId, {
                        ...sellerInfo,
                        integration,
                    });
                }
            }),
            createCustomField: flow(function* ({sellerId, value}: {sellerId: string; value: NewSellerCustomField}) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    yield self.sellersService.createCustomField({
                        sellerId,
                        value,
                    });
                } else {
                    throw Error(`Seller not found: ${sellerId}`);
                }
            }),
            updateCustomField: flow(function* ({
                sellerId,
                key,
                value,
            }: {
                sellerId: string;
                key: string;
                value: Partial<SellerCustomFieldType>;
            }) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    yield self.sellersService.updateCustomField({
                        sellerId,
                        key,
                        value,
                    });
                } else {
                    throw Error(`Seller not found: ${sellerId}`);
                }
            }),
            deleteCustomField: flow(function* ({sellerId, key}: {sellerId: string; key: string}) {
                const seller = self.findSeller(sellerId);
                if (seller) {
                    yield self.sellersService.deleteCustomField({
                        sellerId,
                        key,
                    });
                } else {
                    throw Error(`Seller not found: ${sellerId}`);
                }
            }),
        };
    })
    .actions((self) => ({
        subscribe() {
            this.unsubscribe();
            self.loadingStatus.setInProgress();
            self.disposers.push(
                self.sellersService.subscribeToSellers((sellers) => {
                    self.setSellers(mapFromDto(sellers));
                    self.loadingStatus.setReady();
                })
            );
        },
        unsubscribe() {
            self.disposers.forEach((d) => d());
            self.disposers = [];
        },
        saveSellersForPreapprovedUser: flow(function* (uid: string, sellers: SellerType[] | undefined) {
            try {
                yield self.sellersService.saveSellersForPreapprovedUser(
                    uid,
                    sellers ? sellers.map((s) => s.sellerId) : null
                );
                const preApprSellers = yield self.fetchSellersForPreapprovedUser(uid, true);
                return toJS(preApprSellers);
            } catch (err: any) {
                throw Error(err);
            }
        }),
        beforeDestroy() {
            self.disposers.forEach((d) => d());
        },
    }));

export interface SellerContainerType extends Instance<typeof SellersStore> {}

export interface HasSellersStore {
    sellersStore: SellerContainerType;
}
