import {BaseService} from './BaseService';

export class ClockService extends BaseService {
    subscribe(cb: (value: number) => void) {
        return this.firebase.database.subscribe<number>('.info/serverTimeOffset', (data) => {
            this.logger?.log('ClockService.onValue', data);
            cb(data ?? 0);
        });
    }
}
