import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {promiseWithTimeout} from '@joyrideautos/auction-utils/src/PromiseUtils';
import {ONE_MIN} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {AWSClient, prepareUploadFileData} from '../AWSClient';
import {getCredentialsProvider} from '../AWSCredentialsProvider';
import {waitOnline} from '../connection';
import {ItemImageAndVideoMedia} from '@joyrideautos/auction-core/src/types/ItemMedia';
import {UploadMedia} from '@joyrideautos/ui-services/src/services/mediaService/types';
import {MediaService as Service} from '@joyrideautos/ui-services/src/types';
import {Firebase} from '@joyrideautos/ui-services/src/firebase/types';
import {toMapObject} from '@joyrideautos/ui-services/src/firebase/Firestore';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';

const FULL_HD_SIZE = {
    width: 1920,
    height: 1080,
};

// Max image size in bytes
export const MAX_IMAGE_SIZE = 1_000_000;

function calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {width: srcWidth * ratio, height: srcHeight * ratio};
}

export class MediaService implements Service {
    constructor(private firebase: Firebase, private appConfig: AppConfig, private logger?: Logger) {}

    async fetchMediaForPersistedItem(persistenceKey: string): Promise<ItemImageAndVideoMedia | null> {
        const result = await this.firebase.firestore.fetchOnceDocuments<ItemImageAndVideoMedia>(
            this.firebase.firestore.collectionRef(`items/${persistenceKey}/images`)
        );
        if (!result) {
            return null;
        }
        return toMapObject<ItemImageAndVideoMedia>(result);
    }

    subscribeToMediaForPersistedItem(itemKey: string, listener: (media: ItemImageAndVideoMedia) => void) {
        return this.firebase.firestore.subscribeToCollection<any>(
            this.firebase.firestore.collectionRef(`/items/${itemKey}/images`),
            (result: any) => listener(result)
        );
    }

    async createLoadingMedia(data: UploadMedia): Promise<any> {
        const {itemKey, mediaType, params} = data;
        try {
            return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_CREATE_LOADING_ITEM_MEDIA)({
                itemKey,
                mediaType,
                media: params.map(prepareUploadFileData(this.appConfig, {includeRequest: false})),
            });
        } catch (e: any) {
            await promiseWithTimeout(ONE_MIN, waitOnline, 'internet connection timeout');
            // TODO:(future) fetch current state
            return null;
        }
    }

    async removeItemMedia(itemKey: string, idxs: number[]): Promise<any> {
        try {
            return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_REMOVE_ITEM_MEDIA)({
                itemKey,
                idxs,
            });
        } catch (e: any) {
            await promiseWithTimeout(ONE_MIN, waitOnline, 'internet connection timeout');
            // TODO:(future) fetch current state
            return null;
        }
    }

    async updateItemMediaOrder(itemKey: string, updatedOrder: number[]): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_UPDATE_ITEM_MEDIA_ORDER)({
            itemKey,
            updatedOrder,
        });
    }

    async uploadMediaToS3(data: UploadMedia) {
        await this.createLoadingMedia(data);
        const awsClient = new AWSClient(getCredentialsProvider(this.firebase.auth), this.appConfig);
        await Promise.all(data.params.map((param) => awsClient.uploadToS3(param)));
    }

    /**
     * Resize image if bigger than Full HD (1920x1080)
     */
    resizeImageIfNeeded(binary: Uint8Array): Promise<Uint8Array> {
        if (binary.byteLength <= MAX_IMAGE_SIZE) {
            return Promise.resolve(binary);
        }
        return new Promise<Uint8Array>((res, rej) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const image = new Image();
                image.onload = () => {
                    if (image.width > FULL_HD_SIZE.width || image.height > FULL_HD_SIZE.height) {
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        const calculated = calculateAspectRatioFit(
                            image.width,
                            image.height,
                            FULL_HD_SIZE.width,
                            FULL_HD_SIZE.height
                        );
                        canvas.width = calculated.width;
                        canvas.height = calculated.height;
                        context?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

                        canvas.toBlob((blob) => {
                            if (!blob) {
                                res(binary);
                                return;
                            }
                            blob.arrayBuffer()
                                .then((buffer) => {
                                    // if buffer size after image resize became bigger, just return binary
                                    res(buffer.byteLength > binary.byteLength ? binary : new Uint8Array(buffer));
                                })
                                .catch((e) => this.logger?.log(e));
                        });
                    } else {
                        res(binary);
                    }
                };
                image.onerror = function (error) {
                    rej(error);
                };
                image.src = event?.target?.result as string;
            };
            fileReader.onerror = function (error) {
                rej(error);
            };
            const blob = new Blob([new Uint8Array(binary)]);
            fileReader.readAsDataURL(blob);
        });
    }
}
