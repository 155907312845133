// import {StrictMode} from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import 'core-js/stable';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createRootStoreContextProvider} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';
import {rootStore} from '@models/stores/RootStore';
import {getAppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import '@joyrideautos/web-common-components/src/styles/index.less';

const appConfig = getAppConfig();
const RootStoreProvider = createRootStoreContextProvider(rootStore);

if (appConfig.commonConfig.fullstoryOrgId) {
    rootStore.fullstoryService.init(appConfig.commonConfig.fullstoryOrgId);
}

const rootElement = document.getElementById('root');
if (rootElement) {
    let root;
    if (rootElement.hasChildNodes()) {
        root = hydrateRoot(rootElement);
    } else {
        root = createRoot(rootElement);
    }
    root.render(
        <RootStoreProvider>
            {
                // I disabled StrictMode because there are errors on some pages, e.g. `seller tools`.
                // TODO: (future) enable StrictMode page by page.
                // <StrictMode>
                <App history={rootStore.history} rootStore={rootStore} />
                // </StrictMode>
            }
        </RootStoreProvider>
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
