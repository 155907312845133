import React, {FC} from 'react';
import {Button} from 'antd';
import {observer} from 'mobx-react-lite';
import type {HasViewModel} from '@joyrideautos/ui-models/src/types';
import {Routes} from '../../Routes';
import {useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import Alert from '@joyrideautos/web-common-components/src/components/alert/Alert';

export interface CCHoldFailedProps extends HasViewModel<any> {}

const BuyerProfileIncompleteAlert: FC<CCHoldFailedProps> = observer(({viewModel}) => {
    const {push, location} = useRouter();

    if (location.pathname === Routes.INCOMPLETE_PROFILE()) {
        return null;
    }

    return (
        <Alert
            className="alert-dark"
            message={
                <>
                    <strong>Profile incomplete.</strong>
                    <Button type="text" size="small" onClick={() => push(Routes.INCOMPLETE_PROFILE())}>
                        Complete your profile to start bidding <span>&nbsp;&#8594;</span>
                    </Button>
                </>
            }
            type="warning"
            showIcon
        />
    );
});

export default BuyerProfileIncompleteAlert;
