import fieldModels from './smartFields/models/FieldModels';
import {IAnyType, types} from 'mobx-state-tree';
import {DEFAULT_OPTION} from './smartFields/renderDropdownItems';
import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';

function toSortingLabel(value: SortingEnum) {
    return {
        [SortingEnum.DESC]: 'Most Recent',
        [SortingEnum.ASC]: 'Oldest First',
    }[value];
}

export const createRecentOldestSortingViewModel = (
    onChange?: (self: IAnyType, change: {before: SortingEnum; after: SortingEnum}) => void
) =>
    fieldModels.dropdown(
        types.enumeration([SortingEnum.DESC, SortingEnum.ASC]),
        () => ({
            preloadOptions: true,
            getOptions: () => [SortingEnum.DESC, SortingEnum.ASC],
            toOption: (option) =>
                option
                    ? {
                          key: option,
                          text: toSortingLabel(option),
                          value: option,
                      }
                    : DEFAULT_OPTION,
        }),
        SortingEnum.DESC,
        (self) => ({
            onChange(after, before) {
                onChange && onChange(self, {after, before});
            },
        })
    );
