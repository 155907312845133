import {flow, getRoot, getSnapshot, Instance, types} from 'mobx-state-tree';
import {values} from 'mobx';
import {
    AuctionSeries,
    AuctionSeriesSnapshotType,
    AuctionSeriesType,
    fromAuctionSeriesDto,
    toPartialAuctionSeriesDto,
} from '../types/auction/AuctionSeries';
import {LoadingStatus} from '../utils/LoadingStatus';
import {RegionType} from '../types/Region';
import {AuctionType} from '../types/auction/Auction';
import {makeSubscriber, makeSubscribers} from '@joyrideautos/auction-utils/src/subscriptions';
import type {Subscribe} from '@joyrideautos/auction-utils/src/subscriptions';
import BaseStore from './BaseStore';

export const AuctionSeriesStore = BaseStore.named('AuctionSeriesStore')
    .props({
        auctionSeries: types.map(types.array(AuctionSeries)),
    })
    .volatile(() => ({
        subscribeStatus: new LoadingStatus(),
    }))
    .actions((self) => ({
        setAuctionSeries(regionId: string, auctionSeries: AuctionSeriesSnapshotType[]) {
            self.auctionSeries.set(regionId, auctionSeries);
        },
        addAuctionSeries(auctionSeries: AuctionSeriesType[]) {
            auctionSeries.forEach((newAuctionSeries: AuctionSeriesType) => {
                const regionId = newAuctionSeries.regionId;
                const seriesByRegion =
                    self.auctionSeries.get(regionId) || self.auctionSeries.set(regionId, []).get(regionId)!;
                if (!seriesByRegion.some((series) => series.key === newAuctionSeries.key)) {
                    seriesByRegion.push(newAuctionSeries);
                }
            });
        },
    }))
    .views((self) => ({
        getSeriesForRegion(regionId: string): AuctionSeriesType[] | undefined {
            return self.auctionSeries.get(regionId);
        },
        getSeriesInRegionById(regionId: string, seriesId: number): AuctionSeriesType | undefined {
            const seriesByRegion = self.auctionSeries.get(regionId);
            if (!seriesByRegion) {
                return;
            }
            return seriesByRegion.find((s) => s.id === seriesId);
        },
        getSeriesInRegionByKey(regionId: string, seriesKey: string): AuctionSeriesType | undefined {
            const seriesByRegion = self.auctionSeries.get(regionId);
            if (!seriesByRegion) {
                return;
            }
            return seriesByRegion.find((s) => s.key === seriesKey);
        },
        getSeriesForRegions(regionIds: string[]): AuctionSeriesType[] | undefined {
            const auctionSeries = regionIds
                .map((regionId) => self.auctionSeries.get(regionId))
                .filter((auctionSeries) => !!auctionSeries) as AuctionSeriesType[][];
            if (auctionSeries.length === 0) {
                return undefined;
            }
            return auctionSeries.flat();
        },
        getSeriesWithUpcomingAuctionsForRegions(regionIds: string[]): AuctionSeriesType[] {
            return regionIds.map((regionId) => this.getSeriesWithUpcomingAuctionsForRegion(regionId) || []).flat();
        },
        findSeries(auctionSeriesId: number): AuctionSeriesType | undefined {
            const allSeries = values(self.auctionSeries).flat() as AuctionSeriesType[];
            return allSeries.find((series) => series.id === auctionSeriesId);
        },
        findSeriesForSeriesIdsByRegionIds(
            seriesIdsByRegionIds: {regionId: string; seriesIds: number[]}[]
        ): AuctionSeriesType[] {
            const allSeries = seriesIdsByRegionIds.map(({regionId, seriesIds}) =>
                this.findSeriesForSeriesIdsByRegionId(regionId, seriesIds)
            );
            return allSeries.flat();
        },
        findSeriesForSeriesIdsByRegionId(regionId: string, seriesIds: number[]): AuctionSeriesType[] {
            const allSeries = this.getSeriesForRegion(regionId) || [];
            return allSeries.filter((series) => seriesIds.includes(series.id));
        },
        findSeriesForRegion(regionId: string, auctionSeriesId: number): AuctionSeriesType | undefined {
            const allSeries = this.getSeriesForRegion(regionId) || [];
            return allSeries.find((series) => series.id === auctionSeriesId);
        },
        findSeriesByKey(key: string): AuctionSeriesType | undefined {
            const allSeries = Array.from(self.auctionSeries.values()).flat() as AuctionSeriesType[];
            return allSeries.find((series) => series.key === key);
        },
        findSeriesAssociatedWithSeller(sellerId: string): AuctionSeriesType[] {
            const allSeries = Array.from(self.auctionSeries.values()).flat() as AuctionSeriesType[];
            return allSeries.filter(({sellers}) => sellers && sellers.get(sellerId));
        },
        checkSellerAssociationWithSeries(sellerId: string, regionId: string, seriesKey: string) {
            const series = this.getSeriesInRegionByKey(regionId, seriesKey);
            return series && series.sellers && series.sellers.get(sellerId);
        },
        getAuctionsForSeries(series: AuctionSeriesType): AuctionType[] {
            const root: any = getRoot(self);
            const auctions = root.auctionsStore.auctionsForSeries(series.regionId, series.key);
            return auctions;
        },
        getSeriesWithUpcomingAuctionsForRegion(regionId: string): AuctionSeriesType[] | undefined {
            const root: any = getRoot(self);
            const auctionSeries = this.getSeriesForRegion(regionId);
            if (!auctionSeries || !root.auctionsStore.status.isReady) {
                return undefined;
            }
            return auctionSeries.filter((series) => {
                const upcoming = this.getAuctionsForSeries(series).filter((auction) => !auction.ended);
                return upcoming.length > 0;
            });
        },
        getSeriesWithPastAuctionsForRegion(regionId: string): AuctionSeriesType[] | undefined {
            const root: any = getRoot(self);
            const auctionSeries = this.getSeriesForRegion(regionId);
            if (!auctionSeries || !root.auctionsStore.status.isReady) {
                return undefined;
            }
            return auctionSeries.filter((series) => {
                const past = this.getAuctionsForSeries(series).filter((auction) => auction.ended);
                return past.length > 0;
            });
        },
    }))
    .actions((self) => {
        const load = flow(function* (regionId: string, force = false) {
            let auctionSeries: AuctionSeriesSnapshotType[] | undefined;
            if (force) {
                auctionSeries = yield self.auctionService.getAuctionSeriesList(regionId);
                if (auctionSeries) {
                    self.auctionSeries.set(regionId, auctionSeries);
                    return self.getSeriesForRegion(regionId);
                }
            } else {
                const auctionSeriesForRegion = self.getSeriesForRegion(regionId);
                if (auctionSeriesForRegion) {
                    return auctionSeriesForRegion;
                }
                auctionSeries = yield self.auctionService.getAuctionSeriesList(regionId);
                if (auctionSeries) {
                    self.auctionSeries.set(regionId, auctionSeries);
                    return self.getSeriesForRegion(regionId);
                }
            }
            return undefined;
        });
        const loadForRegions = flow(function* (regionIds: string[]) {
            const auctionSeriesList: AuctionSeriesType[][] = yield Promise.all(
                regionIds.map((regionId) => load(regionId))
            );
            return auctionSeriesList.flat();
        });
        const subscribeForRegion = (regionId: string, cb?: () => void) => () => {
            return self.auctionService.subscribeToAuctionSeries(regionId, (auctionSeries) => {
                self.setAuctionSeries(regionId, auctionSeries.map(fromAuctionSeriesDto));
                cb && cb();
            });
        };

        return {
            load,
            loadForRegions,
            subscribeForRegion(regionId: string) {
                return makeSubscriber(`AuctionSeriesStore-${regionId}`, subscribeForRegion(regionId))();
            },
            subscribe(regions: RegionType[]) {
                return makeSubscribers(
                    `AuctionSeriesStore-${regions.map(({regionId}) => regionId).join('-')}`,
                    regions.reduce<Record<string, Subscribe>>((subscribers, {regionId}, i) => {
                        subscribers[`AuctionSeriesStore-${regionId}`] = subscribeForRegion(regionId, () => {
                            // TODO: find better approach to track loading status
                            if (i === 0) {
                                self.subscribeStatus.setInProgress();
                            }
                            if (i === regions.length - 1) {
                                self.subscribeStatus.setReady();
                            }
                        });
                        return subscribers;
                    }, {})
                )();
            },
            fetchMultipleAuctionSeries: flow(function* (
                auctionSeriesToFetch: {regionId: string; auctionSeriesId: string}[]
            ): Generator<Promise<any>, any, any> {
                const seriesToFetch = auctionSeriesToFetch.filter((auctionSeriesToFetch) => {
                    const seriesByRegion = self.auctionSeries.get(auctionSeriesToFetch.regionId) || [];
                    return !seriesByRegion.some((series) => series.key === auctionSeriesToFetch.auctionSeriesId);
                });
                const newSeries: AuctionSeriesType[] = yield self.auctionService.fetchMultipleAuctionSeries(
                    seriesToFetch
                );
                self.addAuctionSeries(newSeries);
                return newSeries;
            }),
            fetchAuctionSeriesById: flow(function* (regionId: string, seriesId: number) {
                let series = self.getSeriesInRegionById(regionId, seriesId);
                if (!series) {
                    [series] = yield self.auctionService.findAuctionSeriesById(regionId, seriesId);
                    if (series) {
                        self.addAuctionSeries([series]);
                    }
                    return series;
                }
                return getSnapshot<AuctionSeriesType>(series);
            }),
            fetchAuctionSeriesByIds: flow(function* (seriesIdsByRegionIds: {regionId: string; seriesIds: number[]}[]) {
                async function findForRegion(regionId: string, seriesIds: number[]) {
                    const series = self.findSeriesForSeriesIdsByRegionId(regionId, seriesIds);
                    if (series.length === 0) {
                        const series = await Promise.all(
                            seriesIds.map((id) => self.auctionService.findAuctionSeriesById(regionId, id))
                        );
                        if (series) {
                            self.setAuctionSeries(regionId, series.flat().map(fromAuctionSeriesDto));
                        }
                        return series;
                    }
                    return series.map((series) => getSnapshot(series));
                }
                const allSeries = yield Promise.all(
                    seriesIdsByRegionIds.map(({regionId, seriesIds}) => findForRegion(regionId, seriesIds))
                );
                return allSeries.flat();
            }),
            createAuctionSeries: flow(function* (regionId: string, auctionSeriesDto: Partial<AuctionSeriesType>) {
                yield self.auctionService.updateAuctionSeries(
                    regionId,
                    undefined,
                    toPartialAuctionSeriesDto(auctionSeriesDto)
                );
                yield load(regionId, true);
            }),
            saveAuctionSeries: flow(function* (
                regionId: string,
                seriesId: number,
                auctionSeriesDto: Partial<AuctionSeriesType>,
                isPartialUpdate?: boolean
            ) {
                const auctionSeries = self.findSeries(seriesId);
                if (!auctionSeries) {
                    throw new Error(`Can't find an auction series with id: ${seriesId} on ${regionId}`);
                }
                yield self.auctionService.updateAuctionSeries(
                    regionId,
                    auctionSeries.key,
                    toPartialAuctionSeriesDto(auctionSeriesDto),
                    isPartialUpdate
                );
                yield load(regionId, true);
            }),
        };
    });

export interface AuctionSeriesStoreType extends Instance<typeof AuctionSeriesStore> {}

export interface HasAuctionSeriesStore {
    auctionSeriesStore: AuctionSeriesStoreType;
}
