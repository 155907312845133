import {useEffect, useRef} from 'react';
import {destroy, IAnyType} from 'mobx-state-tree';
import {useRootStore} from '../stores/rootStore/useRootStore';
import {action, makeObservable, observable} from 'mobx';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {createMemoize} from '@joyrideautos/auction-utils/src/objectUtils';
import {createSingletonFactory} from '@joyrideautos/auction-utils/src/common';

class ViewModelHolder<T> {
    viewModel: T | null = null;

    constructor(private logger?: Logger) {
        makeObservable(this, {
            viewModel: observable,
            replaceViewModel: action,
        });
    }

    replaceViewModel(vm: T | null) {
        this.logger?.log('set view model', vm);
        const current = this.viewModel;
        this.viewModel = vm;
        requestAnimationFrame(() => {
            current && destroy(current);
        });
    }
}

export const useViewModel = <VMT extends IAnyType>(
    ViewModelType: VMT,
    initialState: VMT['SnapshotType'] | null = null,
    initialEnv = {}
): {viewModel: VMT['Type']} => {
    const viewModelHolderRef = useRef(createSingletonFactory(() => new ViewModelHolder<VMT['Type']>()));
    const memoizeState = useRef(createMemoize()).current;
    const memoizedEnv = useRef(createMemoize()).current;
    const rootStore = useRootStore();
    const state = memoizeState(initialState);
    const env = memoizedEnv(initialEnv);
    useEffect(() => {
        const viewModelHolder = viewModelHolderRef.current();
        const viewModel = ViewModelType.create(state || {}, {rootStore, ...env});
        viewModelHolder.replaceViewModel(viewModel);
        return () => {
            viewModelHolder.replaceViewModel(null);
        };
    }, [state, env]);

    return viewModelHolderRef.current();
};
