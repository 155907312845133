import {createSingletonFactory, isEmulatorDemoProject, isLocalHost} from '@joyrideautos/auction-utils/src/common';
import type {MicroservicesConfig} from '@joyrideautos/auction-core/src/services/FERoutingService';

const LAUNCH_DARKLY_TEST_ENVIRONMENT_CLIENT_ID = '623a440d06ffbb151bf2fd34';

interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
}

interface FirebaseEmulatorsConfig {
    functionsEmulatorHost?: string;
    storageUrl?: string;
    firestoreUrl?: string;
    functionsUrl?: string;
    authenticationUrl?: string;
}

interface CommonConfig {
    stripePublishableKey: string;
    customFunctionsAvailable: boolean;
    launchDarklyEnabled: boolean;
    launchDarklyClientId: string;
    guidedImageFlowEnabled: boolean;
    loggerStatusEnabled: boolean;
    publicAuctionUrl?: string;
    publicAdminUrl?: string;
    red5StreamHost: string;
    publicUrlLanding?: string;
    zendeskChatAppId?: string;
    fullstoryOrgId?: string;
    sentryDsn?: string;
    sentryEnv?: string;
    googleApiClientId?: string;
    gtmId: string;
    migrationToken?: string;
    recaptchaDisabled?: boolean;
}

interface AwsConfig {
    region: string;
    privateBucketName: string;
}

const ENV_PREFIX = 'REACT_APP_';
const APP_CONFIG_FIELD_NAME = 'js-app-config';

export interface AppConfig {
    firebaseConfig: FirebaseConfig;
    emulatorsConfig: FirebaseEmulatorsConfig;
    commonConfig: CommonConfig;
    awsConfig: AwsConfig;
    microservicesConfig: MicroservicesConfig;
    isEmulatedEnv: boolean;
}

class Config implements AppConfig {
    get firebaseConfig(): FirebaseConfig {
        return {
            apiKey: this.getFieldFromEnv('apiKey') || this.getFieldFromGlobalConfig('api_key'),
            authDomain: this.getFieldFromEnv('authDomain') || this.getFieldFromGlobalConfig('auth_domain'),
            databaseURL: this.getFieldFromEnv('databaseURL') || this.getFieldFromGlobalConfig('database_url'),
            projectId: this.getFieldFromEnv('projectId') || this.getFieldFromGlobalConfig('project_id'),
            storageBucket: this.getFieldFromEnv('storageBucket') || this.getFieldFromGlobalConfig('storage_bucket'),
            messagingSenderId:
                this.getFieldFromEnv('messagingSenderId') || this.getFieldFromGlobalConfig('messaging_sender_id'),
            appId: this.getFieldFromEnv('appId') || this.getFieldFromGlobalConfig('app_id'),
            measurementId: this.getFieldFromEnv('measurementId') || this.getFieldFromGlobalConfig('measurement_id'),
        };
    }

    get emulatorsConfig(): FirebaseEmulatorsConfig {
        return {
            functionsEmulatorHost:
                this.getFieldFromEnv('FUNCTIONS_EMULATOR_HOST') ||
                this.getFieldFromGlobalConfig('functions_emulator_host'),
            storageUrl: this.getFieldFromEnv('storageURL') || this.getFieldFromGlobalConfig('storage_url'),
            firestoreUrl: this.getFieldFromEnv('firestoreURL') || this.getFieldFromGlobalConfig('firestore_url'),
            functionsUrl: this.getFieldFromEnv('functionsURL') || this.getFieldFromGlobalConfig('functions_url'),
            authenticationUrl:
                this.getFieldFromEnv('authenticationURL') || this.getFieldFromGlobalConfig('authentication_url'),
        };
    }

    get commonConfig(): CommonConfig {
        return {
            stripePublishableKey:
                this.getFieldFromEnv('STRIPE_PUBLISHABLE_KEY') ||
                this.getFieldFromGlobalConfig('stripe_publishable_key'),
            customFunctionsAvailable:
                this.getFieldFromEnv('CUSTOM_FUNCTIONS_AVAILABLE') === 'true' ||
                this.getFieldFromGlobalConfig('custom_functions_available'),
            launchDarklyEnabled:
                this.getFieldFromEnv('LAUNCH_DARKLY_ENABLED') === 'true' ||
                this.getFieldFromGlobalConfig('launch_darkly_enabled'),
            launchDarklyClientId:
                this.getFieldFromEnv('launch_darkly_client_id') ||
                this.getFieldFromGlobalConfig('launch_darkly_client_id') ||
                LAUNCH_DARKLY_TEST_ENVIRONMENT_CLIENT_ID,
            guidedImageFlowEnabled:
                this.getFieldFromEnv('guided_image_flow_enabled') === 'true' ||
                this.getFieldFromGlobalConfig('guided_image_flow_enabled'),
            loggerStatusEnabled:
                this.getFieldFromEnv('logStatus') === 'ENABLED' || this.getFieldFromGlobalConfig('log_status'),
            publicAuctionUrl:
                this.getFieldFromEnv('PUBLIC_URL_AUCTION') || this.getFieldFromGlobalConfig('public_url_auction'),
            publicAdminUrl:
                this.getFieldFromEnv('PUBLIC_URL_ADMIN') || this.getFieldFromGlobalConfig('public_url_admin'),
            red5StreamHost:
                this.getFieldFromEnv('red5_stream_host') ||
                this.getFieldFromGlobalConfig('red5_stream_host') ||
                'stream.joyrideautos.com',
            publicUrlLanding:
                this.getFieldFromEnv('PUBLIC_URL_LANDING') || this.getFieldFromGlobalConfig('public_url_landing'),
            zendeskChatAppId:
                this.getFieldFromEnv('ZENDESK_CHAT_APP_ID') || this.getFieldFromGlobalConfig('zendesk_chat_app_id'),
            fullstoryOrgId:
                this.getFieldFromEnv('FULLSTORY_ORG_ID') || this.getFieldFromGlobalConfig('fullstory_org_id'),
            sentryDsn: this.getFieldFromEnv('SENTRY_DSN') || this.getFieldFromGlobalConfig('sentry_dsn'),
            sentryEnv: this.getFieldFromEnv('SENDRY_ENV') || this.getFieldFromGlobalConfig('sentry_env'),
            googleApiClientId:
                this.getFieldFromEnv('googleApiClientId') || this.getFieldFromGlobalConfig('google_api_client_id'),
            gtmId: this.getFieldFromEnv('GTM_ID') || this.getFieldFromGlobalConfig('gtm_id'),
            migrationToken:
                this.getFieldFromEnv('DB_MIGRATION_TOKEN', true) || this.getFieldFromGlobalConfig('migration_token'),
            recaptchaDisabled:
                this.getFieldFromEnv('RECAPTCHA_DISABLED', true) || this.getFieldFromGlobalConfig('recaptcha_disabled'),
        };
    }

    get awsConfig(): AwsConfig {
        return {
            region: this.getFieldFromEnv('AWS_REGION') || this.getFieldFromGlobalConfig('aws_region'),
            privateBucketName:
                this.getFieldFromEnv('AWS_PRIVATEBUCKETNAME') || this.getFieldFromGlobalConfig('aws_privatebucketname'),
        };
    }

    get microservicesConfig(): MicroservicesConfig {
        return {
            defaultRequestHandler:
                this.getFieldFromEnv('DEFAULT_REQUEST_HANDLER') ??
                this.getFieldFromGlobalConfig('microservices_default_request_handler'),
            appHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_APP_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_app_http_url'),
            sellersHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_SELLERS_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_sellers_http_url'),
            searchHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_SEARCH_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_search_http_url'),
            auctionsHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_AUCTIONS_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_auctions_http_url'),
            itemsHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_ITEMS_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_items_http_url'),
            mediaHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_MEDIA_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_media_http_url'),
            paymentsHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_PAYMENTS_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_payments_http_url'),
            usersHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_USERS_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_users_http_url'),
            phonesHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_PHONES_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_phones_http_url'),
            bidsHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_BIDS_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_bids_http_url'),
            biddingWsUrl: this.getFieldFromEnv('websocketURL') ?? this.getFieldFromGlobalConfig('websocket_url'),
            migrationsHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_MIGRATIONS_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_migrations_http_url'),
            restApiHttpUrl:
                this.getFieldFromEnv('MICROSERVICES_REST_API_HTTP_URL') ??
                this.getFieldFromGlobalConfig('microservices_rest_api_http_url'),
        };
    }

    toJSON(): AppConfig {
        return {
            firebaseConfig: this.firebaseConfig,
            emulatorsConfig: this.emulatorsConfig,
            commonConfig: this.commonConfig,
            awsConfig: this.awsConfig,
            microservicesConfig: this.microservicesConfig,
            isEmulatedEnv: this.isEmulatedEnv,
        };
    }

    get isEmulatedEnv() {
        return isLocalHost() || isEmulatorDemoProject(this.firebaseConfig.projectId);
    }

    private getFieldFromGlobalConfig(name: string) {
        const config = (globalThis as any)[APP_CONFIG_FIELD_NAME];
        if (!config) {
            return;
        }
        return config[name];
    }

    private getFieldFromEnv(name: string, skipPrefix = false) {
        return process.env[`${skipPrefix ? '' : ENV_PREFIX}${name}`];
    }
}

export const getAppConfig = createSingletonFactory(() => new Config());
