import {ONE_MIN} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {types, getEnv, flow, Instance} from 'mobx-state-tree';
import {LoadingStatus} from '../utils/LoadingStatus';
import {PersistedItem, PersistedItemSnapshotIn, toPartialPersistedItemDto} from '../types/item/PersistedItem';
import type {PersistedItemType} from '../types/item/PersistedItem';
import BaseStore from './BaseStore';
import {UIServicesAwareViewModelType} from '../common/UIServicesAwareViewModel';

export const CachedItemsStore = BaseStore.named('CachedItemsStore')
    .props({
        items: types.map(PersistedItem),
    })
    .volatile((_self) => ({
        order: new Array<string>(),
    }))
    .views((self) => ({
        get tail() {
            // old implementation, without volatile 'order'
            // return self.items.keys().next().value
            return self.order[0];
        },
        get head() {
            // old implementation, without volatile 'order'
            // const count = self.items.size;
            // return Array.from(self.items.keys())[count - 1]
            const count = self.order.length;
            return self.order[count - 1];
        },
        get max(): number {
            return getEnv(self).max;
        },
    }))
    .actions((self) => {
        return {
            deleteItemFromCache(key: string) {
                self.items.delete(key);
                const idx = self.order.indexOf(key);
                if (idx >= 0) {
                    self.order.splice(idx, 1);
                }
            },
            moveToHead(key: string) {
                // old implementation, without volatile 'order'
                // self.items.set(key, detach(self.items.get(key)!));
                const idx = self.order.indexOf(key);
                if (idx >= 0) {
                    const count = self.order.length;
                    const last = self.order[count - 1];
                    self.order[count - 1] = self.order[idx];
                    self.order[idx] = last;
                }
            },
            setItemToCache(key: string, item: PersistedItemType) {
                if (self.items.has(key)) {
                    this.moveToHead(key);
                    return;
                }
                if (self.items.size === self.max) {
                    this.deleteItemFromCache(self.tail);
                }
                self.items.set(key, item);
                self.order.push(key);
            },
        };
    })
    .views((self) => ({
        getItemFromCache(key: string): PersistedItemType | undefined {
            const item = self.items.get(key);
            if (item && self.head !== key) {
                setTimeout(() => self.moveToHead(key));
            }
            return item;
        },
        getAll() {
            // not sorted in any order
            return Array.from(self.items.values());
        },
    }));

export const CachedPersistedItemsStore = CachedItemsStore.named('CachedPersistedItemsStore')
    .volatile(() => ({
        loadingStatuses: {} as Record<string, LoadingStatus>,
        refreshedItems: {} as {[key: string]: number},
    }))
    .views((self) => ({
        getLoadingStatusForItem(persistedKey: string) {
            if (!self.loadingStatuses[persistedKey]) {
                self.loadingStatuses[persistedKey] = new LoadingStatus();
            }
            return self.loadingStatuses[persistedKey];
        },
        get rootStore() {
            return getEnv(self).rootStore;
        },
    }))
    .actions((self) => {
        return {
            refreshItem: flow(function* (persistenceKey: string, force?: boolean) {
                const now = Date.now();
                if (self.getLoadingStatusForItem(persistenceKey).isInProgress) {
                    return;
                }
                const isItemDataOld = now - (self.refreshedItems[persistenceKey] || now) > ONE_MIN;
                if (!force && (self.head === persistenceKey || isItemDataOld)) {
                    return;
                }
                self.getLoadingStatusForItem(persistenceKey).setInProgress();
                const item = yield self.itemsService.fetchItemKey(persistenceKey);
                if (item) {
                    if (force) {
                        self.deleteItemFromCache(persistenceKey);
                    }
                    self.setItemToCache(persistenceKey, item);
                    self.getLoadingStatusForItem(persistenceKey).setReady();
                } else {
                    self.deleteItemFromCache(persistenceKey);
                    self.getLoadingStatusForItem(persistenceKey).setError('Not Found');
                }
                self.refreshedItems[persistenceKey] = Date.now();
            }),
        };
    })
    .actions((self) => ({
        updateItem: flow(function* (persistenceKey: string, item: Partial<PersistedItemType>) {
            yield self.itemsService.updatePersistedItem(persistenceKey, toPartialPersistedItemDto(item));
            yield self.refreshItem(persistenceKey, true);
        }),
    }))
    .views((self) => {
        function refreshIfMissingOrOld(persistenceKey: string) {
            const refreshedAt = self.refreshedItems[persistenceKey];
            if (refreshedAt) {
                const now = Date.now();
                if (now - refreshedAt < 1000) {
                    // already in cache or attempted to refresh
                    return;
                }
            }
            setTimeout(() => self.refreshItem(persistenceKey, true), 0);
        }

        return {
            getAllItems(): PersistedItemType[] {
                return self.getAll();
            },
            getItemByPersistenceKey(persistenceKey: string) {
                if (!persistenceKey) {
                    return;
                }
                const item = self.getItemFromCache(persistenceKey);
                if (!item) {
                    refreshIfMissingOrOld(persistenceKey);
                }
                return item;
            },
            getItemForAuction(regionId: string, auctionId: string, itemId: string) {},
        };
    });

export interface CachedPersistedItemsStoreType extends Instance<typeof CachedPersistedItemsStore> {}

export function createCachedItemsStore(
    props: {items?: Record<string, PersistedItemSnapshotIn>},
    dependencies: {max: number; rootStore: UIServicesAwareViewModelType}
) {
    return CachedPersistedItemsStore.create(props, dependencies);
}
