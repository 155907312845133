import fetch from 'cross-fetch';
import {HttpsCallableOptions} from 'firebase/functions';
import {promiseWithTimeout} from '@joyrideautos/auction-utils/src/PromiseUtils';
import {FeRequestHandlerEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import RoutingService from '@joyrideautos/auction-core/src/services/RoutingService';
import {errorForResponse, HttpsErrorImpl} from './error';
import {encode, decode} from './serializer';
import {HttpResponse, HttpResponseBody} from './types';

// TODO (Future) Clean up the code, remove redundant extra checks and unused features

const DEFAULT_TIMEOUT = 70_000;

/**
 * Does an HTTP POST and returns the completed response.
 * @param url The url to post to.
 * @param body The JSON body of the post.
 * @param headers The HTTP headers to include in the request.
 * @param fetchImpl
 * @return A Promise that will succeed when the request finishes.
 */
async function postJSON(
    url: string,
    body: unknown,
    headers: {[key: string]: string},
    // TODO (Future): use axios instead of fetch
    fetchImpl: typeof fetch
): Promise<HttpResponse> {
    headers['Content-Type'] = 'application/json';

    let response: Response;
    try {
        response = await fetchImpl(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
        });
    } catch (e) {
        // This could be an unhandled error on the backend, or it could be a
        // network error. There's no way to know, since an unhandled error on the
        // backend will fail to set the proper CORS header, and thus will be
        // treated as a network error by fetch.
        return {
            status: 0,
            json: null,
        };
    }
    let json: HttpResponseBody | null = null;
    try {
        json = await response.json();
    } catch (e) {
        // If we fail to parse JSON, it will fail the same as an empty body.
    }
    return {
        status: response.status,
        json,
    };
}

type Fetch = typeof fetch;

interface AuthTokenProvider {
    // eslint-disable-next-line @typescript-eslint/ban-types
    getIdToken: Function;
}

export default class RPCService {
    constructor(
        // TODO (Future): use axios instead of fetch for consistency
        private fetch: Fetch,
        private authTokenProvider: AuthTokenProvider,
        private routingService: RoutingService<FeRequestHandlerEnum>
    ) {}

    async call<ResType>(route: string, data: unknown = {}, options?: HttpsCallableOptions): Promise<ResType> {
        const res = await this.httpsCallable<ResType>(route, options)(data);
        return res?.data as ResType;
    }

    httpsCallable<ResType>(route: string, options?: HttpsCallableOptions) {
        return async (data?: unknown): Promise<{data: ResType}> => {
            const url = this.routingService.getUrlForRoute(route);
            const resData: ResType = await this.callMicroservice(url, data, options);
            return {data: resData};
        };
    }

    private async callMicroservice<ResType>(
        url: string,
        data?: unknown,
        options?: HttpsCallableOptions
    ): Promise<ResType> {
        const headers: Record<string, string> = {};
        const authToken = await this.authTokenProvider.getIdToken();
        if (authToken) {
            headers['Authorization'] = 'Bearer ' + authToken;
        }

        const response = await promiseWithTimeout(
            options?.timeout || DEFAULT_TIMEOUT,
            () => postJSON(url, {data: encode(data)}, headers, this.fetch),
            new HttpsErrorImpl('functions/deadline-exceeded', 'deadline-exceeded')
        );

        // If service was deleted, interrupted response throws an error.
        if (!response) {
            throw new HttpsErrorImpl('functions/cancelled', 'Firebase Functions instance was deleted.');
        }

        // Check for an error status, regardless of http status.
        const error = errorForResponse(response.status, response.json);
        if (error) {
            throw error;
        }

        if (!response.json) {
            throw new HttpsErrorImpl('functions/internal', 'Response is not valid JSON object.');
        }

        let resData = response.json.data;
        // For right now, allow "result" instead of "data", for backwards compatibility.
        if (typeof resData === 'undefined') {
            resData = response.json.result;
        }
        if (typeof resData === 'undefined') {
            // Consider the response malformed.
            throw new HttpsErrorImpl('functions/internal', 'Response is missing data field.');
        }

        return decode(resData) as ResType;
    }
}
