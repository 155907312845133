import BaseViewModel from '../BaseViewModel';
import {cast, flow, isAlive, SnapshotOut, types} from 'mobx-state-tree';
import {PlatformFeeSchedule, PlatformFeeScheduleType} from '../types/auction/PlatformFeeSchedule';
import {LoadingStatus} from '../utils/LoadingStatus';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {PlatformFeeSchedule as PlatformFeeScheduleConfig} from '@joyrideautos/auction-core/src/types/PlatformFeeSchedule';
import {makeSubscriber} from '@joyrideautos/auction-utils/src/subscriptions';

export const PlatformFeeSchedules = BaseViewModel.named('PlatformFeeScheduleStore')
    .props({
        map: types.map(PlatformFeeSchedule),
    })
    .volatile(() => ({
        loadingStatus: new LoadingStatus(),
        savingStatus: new LoadingStatus(),
    }))
    .views((self) => ({
        get all(): WithKey<PlatformFeeScheduleType>[] {
            return Array.from(self.map.entries()).map(([key, config]) => ({
                ...config,
                key,
            }));
        },
        findByKey(key?: string): PlatformFeeScheduleType | undefined {
            if (!key) {
                return undefined;
            }
            return self.map.get(key);
        },
    }))
    .actions((self) => ({
        setPlatformFeeSchedules(feeSchedules: Map<string, PlatformFeeScheduleType>) {
            self.map.replace(feeSchedules);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            const {isReady, isInProgress} = self.loadingStatus;
            if (!isAlive(self) || isReady || isInProgress) {
                return;
            }
            self.loadingStatus.setInProgress();
            const feeSchedules: WithKey<PlatformFeeScheduleConfig>[] | undefined =
                yield self.rootStore.platformFeeScheduleService.fetchPlatformFeeSchedules();
            if (!feeSchedules) {
                self.loadingStatus.setReady();
                return;
            }
            const feeSchedulesMap = feeSchedules.reduce<Map<string, PlatformFeeScheduleType>>(
                (feeSchedules, feeSchedule) => {
                    feeSchedules.set(feeSchedule.key, cast(feeSchedule));
                    return feeSchedules;
                },
                new Map()
            );

            self.setPlatformFeeSchedules(feeSchedulesMap);
            self.loadingStatus.setReady();
        }),
        subscribe() {
            return makeSubscriber('PlatformFeeScheduleStore', () => {
                return self.rootStore.platformFeeScheduleService.subscribeToPlatformFeeSchedules((feeSchedules) => {
                    const feeSchedulesMap = feeSchedules.reduce<Map<string, PlatformFeeScheduleType>>(
                        (feeSchedules, feeSchedule) => {
                            feeSchedules.set(feeSchedule.key, cast(feeSchedule));
                            return feeSchedules;
                        },
                        new Map()
                    );
                    self.setPlatformFeeSchedules(feeSchedulesMap);
                    self.loadingStatus.setReady();
                });
            })();
        },
        create: flow(function* (
            platformFeeSchedule: Omit<
                SnapshotOut<typeof PlatformFeeSchedule>,
                'createdAt' | 'createdBy' | 'editedAt' | 'editedBy'
            >
        ) {
            self.savingStatus.setInProgress();
            const key = yield self.rootStore.platformFeeScheduleService.createPlatformFeeSchedule(platformFeeSchedule);
            if (key) {
                self.map.set(key, platformFeeSchedule);
            }
            self.savingStatus.setReady();
        }),
        update: flow(function* (
            key: string,
            platformFeeSchedule: Omit<
                SnapshotOut<typeof PlatformFeeSchedule>,
                'createdAt' | 'createdBy' | 'editedAt' | 'editedBy'
            >
        ) {
            self.savingStatus.setInProgress();
            yield self.rootStore.platformFeeScheduleService.updatePlatformFeeSchedule(key, platformFeeSchedule);
            self.map.set(key, platformFeeSchedule);
            self.savingStatus.setReady();
        }),
    }));
