import Logger, {LogLevelEnum} from '@joyrideautos/auction-core/src/utils/logger/Logger';

// TODO: (Future) add Sentry type
type SentryService = any;

interface LoggerConfig {
    loggerStatusEnabled: boolean;
}

export enum LoggerStatusEnum {
    ENABLED = 'ENABLED',
    ALWAYS_ENABLED = 'ALWAYS_ENABLED',
    DISABLED = 'DISABLED',
}

export class UILogger implements Logger {
    constructor(
        private context: string,
        private status: LoggerStatusEnum,
        private sentry?: SentryService,
        private config?: LoggerConfig
    ) {}

    // TODO (Future) read from env/config file
    level: LogLevelEnum = LogLevelEnum.DEBUG;

    executor = console;

    get _canShowLogs() {
        return (
            ((process.env.NODE_ENV === 'development' ||
                process.env.NODE_ENV === 'test' ||
                (this.config?.loggerStatusEnabled ?? false)) &&
                this.status === LoggerStatusEnum.ENABLED) ||
            this.status === LoggerStatusEnum.ALWAYS_ENABLED
        );
    }

    get isProduction(): boolean {
        return process.env.NODE_ENV === 'production';
    }

    error(...messages: any[]) {
        const error = `[${this.context}]: ${JSON.stringify(messages, null, 2)}`;
        this.executor.group(`[${this.context}]`);
        const [title, ...rest] = messages;
        this.executor.log(title);
        rest.forEach((e) => this.executor.error(e));
        this.executor.groupEnd();
        if (this.isProduction) {
            this.sentry?.captureMessage(error);
        }
    }

    critical(...messages: any[]) {
        const error = `[${this.context}]: ${JSON.stringify(messages, null, 2)}`;
        this.executor.group(`[${this.context}]`);
        const [title, ...rest] = messages;
        this.executor.log(title);
        rest.forEach((e) => this.executor.error(e));
        this.executor.groupEnd();
        if (this._canShowLogs) {
            throw new Error(title);
        } else {
            this.sentry?.captureMessage(error);
        }
    }

    warn(...message: any[]) {
        if (this._canShowLogs) {
            this.executor.warn(`[${this.context}]`, ...message);
        }
    }

    log(...message: any[]) {
        if (this._canShowLogs) {
            this.executor.log(`[${this.context}]`, ...message);
        }
    }

    info(...message: any[]) {
        if (this._canShowLogs) {
            this.executor.info(`[${this.context}]`, ...message);
        }
    }

    debug(...message: any[]) {
        if (this._canShowLogs) {
            this.executor.debug(`[${this.context}]`, ...message);
        }
    }

    dir(object: any) {
        if (this._canShowLogs) {
            this.executor.log(`[${this.context}]`);
            this.executor.dir(object);
        }
    }

    time(key: string) {
        if (this._canShowLogs) {
            this.executor.time(key);
        }
    }

    timeEnd(key: string) {
        if (this._canShowLogs) {
            this.executor.timeEnd(key);
        }
    }

    // TODO (Future): implement
    child(options: Record<string, unknown>): Logger {
        throw new Error('Method not implemented.');
    }
}

const makeLogger =
    (sentry?: SentryService, config?: LoggerConfig) =>
    (context: string, status = LoggerStatusEnum.DISABLED) => {
        return new UILogger(context, status, sentry, config);
    };
export default makeLogger;
