import {Timestamp} from './common';

export enum FeeTypeEnum {
    KEY = 'key',
    OTHER = 'other',
}

export type FeePrice = {
    amount: number;
    feeType: FeeTypeEnum;
    description?: string;
    feeUpdatedAt?: Timestamp;
    feeUpdatedBy?: string;
};
