import {DocumentSnapshot, Query, WhereFilterOp} from '@joyrideautos/ui-services/src/firebase/Firestore';
import {
    collection,
    Firestore,
    query,
    Query as FirebaseFirestoreQuery,
    where,
    startAt,
    startAfter,
    orderBy,
    limitToLast,
    limit,
    endBefore,
    endAt,
} from 'firebase/firestore';

export class FirestoreQuery<T> implements Query<T> {
    private _query: FirebaseFirestoreQuery<T>;

    constructor(private firestore: Firestore, path: string, ...segments: string[]) {
        this._query = collection(this.firestore, path, ...segments) as FirebaseFirestoreQuery<T>;
    }

    get query() {
        return this._query;
    }

    endAt(key: string): Query<T>;
    endAt(snapshot: DocumentSnapshot<T>): Query<T>;
    endAt(value: T[keyof T]): Query<T>;
    endAt(snapshot: any): Query<T> {
        this._query = query(this._query, endAt(snapshot));
        return this;
    }

    endBefore(value: string): Query<T>;
    endBefore(value: DocumentSnapshot<T>): Query<T>;
    endBefore(value: T[keyof T]): Query<T>;
    endBefore(value: any): Query<T> {
        this._query = query(this._query, endBefore(value));
        return this;
    }

    limit(value: number): Query<T> {
        this._query = query(this._query, limit(value));
        return this;
    }

    limitToLast(limit: number): Query<T> {
        this._query = query(this._query, limitToLast(limit));
        return this;
    }

    orderBy(fieldPath: string, directionStr?: 'asc' | 'desc'): Query<T> {
        this._query = query(this._query, orderBy(fieldPath as string, directionStr || 'asc'));
        return this;
    }

    startAfter(snapshot: DocumentSnapshot<T>): Query<T>;
    startAfter(...fieldValues: any[]): Query<T> {
        this._query = query(this._query, startAfter(...fieldValues));
        return this;
    }

    startAt(snapshot: DocumentSnapshot<T>): Query<T>;
    startAt(...values: any[]): Query<T> {
        this._query = query(this._query, startAt(...values));
        return this;
    }

    where(fieldPath: string, opStr: WhereFilterOp, value: unknown): Query<T> {
        this._query = query(this._query, where(fieldPath as string, opStr, value));
        return this;
    }
}
