import Logger from './logger/Logger';
import {QueueType} from './QueueType';

export default class Queue<T> implements QueueType<T> {
    protected queue: T[] = [];

    constructor(protected logger?: Logger) {}

    enqueue(item: T): void {
        this.queue.push(item);
        this.afterEnqueue(item);
    }

    dequeue(): T | undefined {
        const item = this.queue.shift();
        this.afterDequeue(item);
        return item;
    }

    peek(): T | undefined {
        return this.queue[0];
    }

    get peekAll(): T[] {
        return this.queue.slice();
    }

    clear(): void {
        this.logger?.log('clear queue');
        this.queue = [];
    }

    get size(): number {
        return this.queue.length;
    }

    print(): void {
        this.logger?.log(
            'queue',
            this.queue.map((item) => this.stringify(item))
        );
    }

    protected stringify(item: T | undefined): string {
        if (typeof item === 'undefined') {
            return 'undefined';
        }
        return String(item);
    }

    protected afterEnqueue(item: T): void {
        this.logger?.log('added to queue', this.stringify(item));
    }

    protected afterDequeue(item: T | undefined): void {
        this.logger?.log('removed from queue', this.stringify(item));
    }
}
