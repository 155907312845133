import React, {FC} from 'react';
import {List, Row, Col, Typography} from 'antd';
import {observer} from 'mobx-react-lite';
import type {HasPresenter} from '@joyrideautos/ui-models/src/types';
import {NotificationMenuPresenter} from '@components/notificationsMenu/NotificationsMenu';

// TODO: @alex.dakhno move to separate file
const {Text} = Typography;

interface GDriveImportMenuProps extends HasPresenter<typeof NotificationMenuPresenter> {}

export const GDriveImportMenu: FC<GDriveImportMenuProps> = observer(({presenter}) => {
    const {queueEntries} = presenter;

    return (
        <>
            <Row align="middle" className="notification-item border-top border-bottom">
                <Col span={12}>
                    <Text strong>Google Drive import</Text>
                </Col>
            </Row>
            <Row className="mb-15">
                <Col span={24}>
                    <List className="pl-15 pr-15">
                        {queueEntries.map(({sellerName, auctionName, gDrivePath, timestamp}) => {
                            const title = auctionName
                                ? `Importing '${sellerName}' vehicles to '${auctionName} from ${gDrivePath}`
                                : `Saving '${sellerName}' vehicles from ${gDrivePath}`;

                            return (
                                <List.Item key={timestamp}>
                                    <List.Item.Meta title={title} />
                                </List.Item>
                            );
                        })}
                    </List>
                </Col>
            </Row>
        </>
    );
});
