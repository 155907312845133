export enum MyInventoryTabEnum {
    ALL = 'All',
    FAVORITES = 'Favorites',
    STORED = 'Stored',
    CANDIDATE = 'Candidate',
    SOLD = 'Sold',
    UNSOLD = 'Unsold',
    PUBLISHED = 'Published',
    SCHEDULED_FOR_AUCTION = 'ScheduledForAuction',
    PAYMENT_RECEIVED = 'PaymentReceived',
    CLAIMED = 'Claimed',
    SEARCH_RESULT = 'SearchResult',
    OFFERS = 'Offers',
}
