import {createFERoutingService} from '@joyrideautos/auction-core/src/services/FERoutingService';
import RPCService from './RPCService';
import {getAppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import {initializeApp} from 'firebase/app';
import {createAuth} from '../../firebaseServices/Auth';
import makeLogger, {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';

const appConfig = getAppConfig();

const firebaseApp = initializeApp(appConfig.firebaseConfig);
const _auth = createAuth(firebaseApp, appConfig, makeLogger()('RootStore.Auth', LoggerStatusEnum.DISABLED));

// We moved the factory function to a separate file because firebaseApp depends on windows which is
//  unavailable for db migrations module (we run it with Node.js)
export function createRPCService(auth = _auth, _fetch = fetch) {
    const authTokenProvider = {
        getIdToken: () => auth.currentUser?.getIdToken(),
    };
    return new RPCService(
        _fetch,
        authTokenProvider,
        createFERoutingService({
            projectId: appConfig.firebaseConfig.projectId,
            functionUrl: appConfig.emulatorsConfig.functionsUrl,
            microservices: appConfig.microservicesConfig,
        })
    );
}
