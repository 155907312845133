import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import {Instance} from 'mobx-state-tree';

export const FailedDepositAlertViewModel = RootStoreAwareViewModel.named('FailedDepositAlertViewModel')
    .props({
        isConnected: true,
    })
    .views((self) => {
        return {
            get isVisibleAlert(): boolean {
                return !!self.biddersStatuses?.isGloballyBannedByFailedDeposit;
            },
        };
    });

export interface FailedDepositAlertViewModelType extends Instance<typeof FailedDepositAlertViewModel> {}
