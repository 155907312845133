import {getEnv, getRoot, types} from 'mobx-state-tree';
import type {AWSCredentialsProvider, MediaService, UploadMediaService} from '@joyrideautos/ui-services/src/types';
import type {SentryService} from '@joyrideautos/ui-services/src/services/SentryService';
import type {FullstoryService} from '@joyrideautos/ui-services/src/services/FullstoryService';
import type {SocketWrapper} from '../common/SocketWrapper';
import type {ClockModelType} from '../utils/Clock';
import type {UIServicesAwareViewModelType} from '../common/UIServicesAwareViewModel';
import type {AuthUserService} from '@joyrideautos/ui-services/src/services/AuthUserService';
import type {AnalyticsService} from '@joyrideautos/ui-services/src/services/AnalyticsService';
import type {BidService} from '@joyrideautos/ui-services/src/services/BidService';
import type {AdminRoleService} from '@joyrideautos/ui-services/src/services/AdminRoleService';
import type {AuctionService} from '@joyrideautos/ui-services/src/services/AuctionService';
import type {BiddersService} from '@joyrideautos/ui-services/src/services/BiddersService';
import type {UserBansService} from '@joyrideautos/ui-services/src/services/UserBansService';
import type {EventsService} from '@joyrideautos/ui-services/src/services/EventsService';
import type {FavoritesService} from '@joyrideautos/ui-services/src/services/FavoritesService';
import type {GoogleDriveService} from '@joyrideautos/ui-services/src/services/GoogleDriveService';
import type {ItemPaymentService} from '@joyrideautos/ui-services/src/services/ItemPaymentService';
import type {ClockService} from '@joyrideautos/ui-services/src/services/ClockService';
import type {GlobalConfigService} from '@joyrideautos/ui-services/src/services/GlobalConfigService';
import type {ReleaseVersionService} from '@joyrideautos/ui-services/src/services/ReleaseVersionService';
import type {ManagerRoleService} from '@joyrideautos/ui-services/src/services/ManagerRoleService';
import type {SellerRoleService} from '@joyrideautos/ui-services/src/services/SellerRoleService';
import type {PhoneValidationService} from '@joyrideautos/ui-services/src/services/PhoneValidationService';
import type {PaymentService} from '@joyrideautos/ui-services/src/services/PaymentService';
import type {RecaptchaService} from '@joyrideautos/ui-services/src/services/RecaptchaService';
import type {RefsService} from '@joyrideautos/ui-services/src/services/RefsService';
import type {SearchService} from '@joyrideautos/ui-services/src/services/SearchService';
import type {StorageService} from '@joyrideautos/ui-services/src/services/StorageService';
import type {RegionsService} from '@joyrideautos/ui-services/src/services/RegionsService';
import type {WhiteListService} from '@joyrideautos/ui-services/src/services/WhitelistService';
import type {WonItemsService} from '@joyrideautos/ui-services/src/services/WonItemsService';
import type {FirebaseTraceService} from '@joyrideautos/ui-services/src/services/FirebaseTraceService';
import type {UsersService} from '@joyrideautos/ui-services/src/services/UsersService';
import type {SellersService} from '@joyrideautos/ui-services/src/services/SellersService';
import type {ItemsService} from '@joyrideautos/ui-services/src/services/ItemsService';
import type {FirebaseService} from '@joyrideautos/ui-services/src/services/FirebaseService';
import type {PlatformFeeScheduleService} from '@joyrideautos/ui-services/src/services/PlatformFeeScheduleService';
import type {AuditService} from '@joyrideautos/ui-services/src/services/AuditService';
import type {ClaimSessionService} from '@joyrideautos/ui-services/src/services/ClaimSessionService';

const BaseStore = types.model('BaseStore').views((self) => {
    return {
        get rootStore(): Record<string, any> & UIServicesAwareViewModelType {
            // console.log('from env', getEnv(self).rootStore?.toString());
            // console.log('from root', getRoot(self)?.toString());
            return getEnv(self).rootStore || (getRoot(self) as any);
        },
        get logger() {
            return this.rootStore.getLogger(self.toString());
        },
        get sellerSessionStorage() {
            return this.rootStore.sellerSessionStorage;
        },
        get sessionStore() {
            return this.rootStore.sessionStore;
        },
        get eventsStore() {
            return this.rootStore.eventsStore;
        },
        get localStorageStore() {
            return this.rootStore.localStorageStore;
        },
        get authUserStore() {
            return this.rootStore.authUserStore;
        },
        get bidStore() {
            return this.rootStore.bidsStore;
        },
        get clock(): ClockModelType {
            return this.rootStore.clock;
        },
        findServiceByName<T>(serviceName: string): T {
            return (this.rootStore[serviceName] || getEnv(self).services[serviceName]) as T;
        },
        get itemsService() {
            return this.findServiceByName<ItemsService>('itemsService');
        },
        get bidService() {
            return this.findServiceByName<BidService>('bidsService');
        },
        get wonItemService() {
            return this.findServiceByName<WonItemsService>('wonItemService');
        },
        get whiteListService() {
            return this.findServiceByName<WhiteListService>('whiteListService');
        },
        get userService() {
            return this.findServiceByName<UsersService>('userService');
        },
        get biddersService() {
            return this.findServiceByName<BiddersService>('biddersService');
        },
        get sellersService() {
            return this.findServiceByName<SellersService>('sellersService');
        },
        get sellerRoleService() {
            return this.findServiceByName<SellerRoleService>('sellerRoleService');
        },
        get managerRoleService() {
            return this.findServiceByName<ManagerRoleService>('managerRoleService');
        },
        get regionsService() {
            return this.findServiceByName<RegionsService>('regionsService');
        },
        get refsService() {
            return this.findServiceByName<RefsService>('refsService');
        },
        get itemPaymentService() {
            return this.findServiceByName<ItemPaymentService>('itemPaymentService');
        },
        get paymentService() {
            return this.findServiceByName<PaymentService>('paymentService');
        },
        get globalConfigService() {
            return this.findServiceByName<GlobalConfigService>('globalConfigService');
        },
        get releaseVersionService() {
            return this.findServiceByName<ReleaseVersionService>('releaseVersionService');
        },
        get favoritesService() {
            return this.findServiceByName<FavoritesService>('favoritesService');
        },
        get firebaseTraceService() {
            return this.findServiceByName<FirebaseTraceService>('firebaseTraceService');
        },
        get userBansService() {
            return this.findServiceByName<UserBansService>('userBansService');
        },
        get auctionService() {
            return this.findServiceByName<AuctionService>('auctionService');
        },
        get adminRoleService() {
            return this.findServiceByName<AdminRoleService>('adminRoleService');
        },
        get authUserService() {
            return this.findServiceByName<AuthUserService>('authUserService');
        },
        get sentryService() {
            return this.findServiceByName<SentryService>('sentryService');
        },
        get fullstoryService() {
            return this.findServiceByName<FullstoryService>('fullstoryService');
        },
        get analyticsService() {
            return this.findServiceByName<AnalyticsService>('analyticsService');
        },
        get clockService() {
            return this.findServiceByName<ClockService>('clockService');
        },
        get socketWrapper(): SocketWrapper {
            return this.rootStore.socketWrapper;
        },
        get eventsService() {
            return this.findServiceByName<EventsService>('eventsService');
        },
        get mediaService() {
            return this.findServiceByName<MediaService>('mediaService');
        },
        get uploadMediaService() {
            return this.findServiceByName<UploadMediaService>('uploadMediaService');
        },
        get phoneValidationService() {
            return this.findServiceByName<PhoneValidationService>('phoneValidationService');
        },
        get awsCredentialsProvider() {
            return this.findServiceByName<AWSCredentialsProvider>('awsCredentialsProvider');
        },
        get firebaseService() {
            return this.findServiceByName<FirebaseService>('firebaseService');
        },
        get googleDriveService() {
            return this.findServiceByName<GoogleDriveService>('googleDriveService');
        },
        get recaptchaService() {
            return this.findServiceByName<RecaptchaService>('recaptchaService');
        },
        get searchService() {
            return this.findServiceByName<SearchService>('searchService');
        },
        get storageService() {
            return this.findServiceByName<StorageService>('storageService');
        },
        get platformFeeScheduleService() {
            return this.findServiceByName<PlatformFeeScheduleService>('platformFeeScheduleService');
        },
        get auditService() {
            return this.findServiceByName<AuditService>('auditService');
        },
        get claimSessionService() {
            return this.findServiceByName<ClaimSessionService>('claimSessionService');
        },
    };
});

export default BaseStore;
