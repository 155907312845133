import {types, Instance, getEnv} from 'mobx-state-tree';
import {
    LiveAuctionAlertViewModel,
    LiveAuctionAlertViewModelType,
} from '@joyrideautos/ui-models/src/presentation/auctionView/LiveAuctionAlertViewModel';
import {
    FailedDepositAlertViewModel,
    FailedDepositAlertViewModelType,
} from '@components/alerts/FailedDepositAlertViewModel';
import {CCHoldFailedAlertViewModel, CCHoldFailedAlertViewModelType} from './CCHoldFailedAlertViewModel';
import {
    BuyerProfileIncompleteViewModel,
    BuyerProfileIncompleteViewModelType,
} from '../../pages/home/components/buyerProfileIncomplete/BuyerProfileIncompleteViewModel';
import BaseViewModel from '@joyrideautos/web-common-components/src/models/BaseViewModel';
import {getLiveAuctionAlertOption, LiveAuctionAlertOptionEnum} from '@components/alerts/liveAuctionAlertOptionsConfig';

export enum GlobalAlertsOptionEnum {
    LIVE_AUCTION = 'LIVE_AUCTION',
    FAILED_DEPOSIT = 'FAILED_DEPOSIT',
    CC_HOLD_FAILED = 'CC_HOLD_FAILED',
    SW_NEW_VERSION = 'SW_NEW_VERSION',
    BUYER_PROFILE_INCOMPLETE = 'BUYER_PROFILE_INCOMPLETE',
}

export type VisibleAlertInfo =
    | {
          option: GlobalAlertsOptionEnum.LIVE_AUCTION;
          viewModel: LiveAuctionAlertViewModelType;
      }
    | {
          option: GlobalAlertsOptionEnum.FAILED_DEPOSIT;
          viewModel: FailedDepositAlertViewModelType;
      }
    | {
          option: GlobalAlertsOptionEnum.CC_HOLD_FAILED;
          viewModel: CCHoldFailedAlertViewModelType;
      }
    | {
          option: GlobalAlertsOptionEnum.SW_NEW_VERSION;
          viewModel: null;
      }
    | {
          option: GlobalAlertsOptionEnum.BUYER_PROFILE_INCOMPLETE;
          viewModel: BuyerProfileIncompleteViewModelType;
      }
    | null;

export const GlobalAlertViewModel = BaseViewModel.named('GlobalAlertViewModel')
    .props({
        failedDepositAlertViewModel: types.optional(FailedDepositAlertViewModel, {}),
        ccHoldFailedViewModel: types.optional(CCHoldFailedAlertViewModel, {}),
        buyerProfileIncompleteViewModel: types.optional(BuyerProfileIncompleteViewModel, {}),
    })
    .views((self) => ({
        get auctionsSectionViewModel() {
            const regionId = self.presentationStore.routeModel.getRouteParamByName('regionId');
            const auctionId = self.presentationStore.routeModel.getRouteParamByName('auctionId');
            if (!regionId || !auctionId) {
                return;
            }
            return self.getOrCreateViewModel(
                LiveAuctionAlertViewModel,
                {
                    regionId,
                    auctionId,
                },
                {...getEnv(self), path: self.presentationStore.routeModel.path}
            );
        },
    }))
    .views((self) => {
        return {
            get liveAuctionAlertVisible() {
                if (!self.auctionsSectionViewModel) {
                    return false;
                }

                if (self.auctionsSectionViewModel.auction?.settings?.isListing) {
                    return undefined;
                }
                if (!self.auctionsSectionViewModel.isConnected) {
                    return LiveAuctionAlertOptionEnum.DISCONNECTED;
                }
                // _auctionState may be = null, so we handle by this if
                if (self.auctionsSectionViewModel.auctionState) {
                    const {path} = getEnv<any>(self);
                    return !!getLiveAuctionAlertOption(self.auctionsSectionViewModel.auctionState, path);
                }
                return undefined;
            },
            get visibleAlertInfo(): VisibleAlertInfo {
                if (self.isSWNewVersionAvailable) {
                    return {
                        option: GlobalAlertsOptionEnum.SW_NEW_VERSION,
                        viewModel: null,
                    };
                }
                if (self.ccHoldFailedViewModel.isVisibleAlert) {
                    return {
                        option: GlobalAlertsOptionEnum.CC_HOLD_FAILED,
                        viewModel: self.ccHoldFailedViewModel,
                    };
                }
                if (self.failedDepositAlertViewModel.isVisibleAlert) {
                    return {
                        option: GlobalAlertsOptionEnum.FAILED_DEPOSIT,
                        viewModel: self.failedDepositAlertViewModel,
                    };
                }
                if (this.liveAuctionAlertVisible) {
                    return {
                        option: GlobalAlertsOptionEnum.LIVE_AUCTION,
                        viewModel: self.auctionsSectionViewModel!,
                    };
                }
                if (self.buyerProfileIncompleteViewModel.isVisibleAlert) {
                    return {
                        option: GlobalAlertsOptionEnum.BUYER_PROFILE_INCOMPLETE,
                        viewModel: self.buyerProfileIncompleteViewModel,
                    };
                }
                return null;
            },
        };
    });

export interface GlobalAlertViewModelType extends Instance<typeof GlobalAlertViewModel> {}
