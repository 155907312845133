import {WinningBidFeesInMoney} from './types';
import {FeesBreakdownCalculator} from './FeesBreakdownCalculator';
import {Money} from '../types/Money';

export class BidExcludesFeesBreakdownCalculator extends FeesBreakdownCalculator {
    calculate(bid: Money): WinningBidFeesInMoney {
        this.validate(bid);

        const adminFee = this.adminFeeCalculator.calculate();

        const salePrice = bid;
        const variableFee = this.options.isChargeBuyerFee ? this.buyerFeeCalculator.calculate(salePrice) : Money.zero;
        const buyerFee = this.options.isChargeBuyerFee
            ? Money.sumOf(variableFee, this.perVehicleFee.amount)
            : variableFee;
        const {platformFee} = this.platformFeeCalculator.calculate({
            amount: salePrice,
            bid,
            buyerFee,
            adminFee,
        });
        const salesTax = this.options.isExcludeSellerFeesFromSalesTax
            ? this.salesTaxCalculator.calculate(salePrice)
            : this.salesTaxCalculator.calculate(
                  Money.sumOf(salePrice, variableFee, adminFee, this.perVehicleFee.keeFee, this.perVehicleFee.otherFee)
              );
        const storageTax = this.storageTaxCalculator.calculateStorageTax(salePrice);
        const total = Money.sumOf(salePrice, adminFee, buyerFee, platformFee, salesTax, storageTax);

        return {
            salePrice,
            total,
            bid: salePrice,
            platformFee,
            adminFee,
            buyerFee,
            salesTax,
            storageTax,
            variableFee,
            vehicleKeyFee: this.perVehicleFee.keeFee,
            vehicleOtherFixedFee: this.perVehicleFee.otherFee,
        };
    }
}
