import {AuctionOccurrenceSettings} from '../types/AuctionOccurrence';
import {WinningBidFeesInMoney} from './types';
import * as _ from 'lodash';
import {validateMoney} from './utils';
import {Money} from '../types/Money';

export function calculateDepositAmount(
    totalAmount: Money,
    platformFee: Money,
    isBidIncludesFees: boolean | undefined,
    depositCalc: (amount: Money) => Money
): Money {
    validateMoney(totalAmount, 'total');
    validateMoney(platformFee, 'platformFee');

    const deposit = depositCalc(totalAmount);
    return isBidIncludesFees && totalAmount.isLessThanOrEqual(Money.sumOf(deposit, platformFee))
        ? Money.subtractOf(totalAmount, platformFee)
        : deposit;
}

export const getDepositCalculationStrategy = (
    settings: AuctionOccurrenceSettings,
    preApprovedUser: boolean
): ((fees: Omit<WinningBidFeesInMoney, 'deposit'>) => Money) | null => {
    const isDepositExcludesFees = settings?.isDepositExcludesFees ?? false;
    const bidIncludesFee = settings.bidIncludesFees !== false;

    const getAmount = (fees: Omit<WinningBidFeesInMoney, 'deposit'>) => {
        return bidIncludesFee
            ? isDepositExcludesFees
                ? fees.salePrice
                : fees.bid
            : isDepositExcludesFees
            ? fees.bid
            : fees.total;
    };

    if (preApprovedUser) {
        return () => Money.zero;
    } else if (
        settings.depositPerc != null &&
        settings.depositPerc > 0 &&
        settings.depositFixed != null &&
        settings.depositFixed > 0
    ) {
        return (fees: Omit<WinningBidFeesInMoney, 'deposit'>) => {
            const {depositPerc, depositFixed: depositFixedInCents} = settings;
            const depPerc = _.curryRight(calculateDepositAmount)(fees.platformFee, settings.bidIncludesFees, (amount) =>
                amount.takePercent(depositPerc!, {rounding: 'round'})
            )(getAmount(fees));
            return _.curryRight(calculateDepositAmount)(fees.platformFee, settings.bidIncludesFees, () =>
                Money.fromCents(depositFixedInCents!).add(depPerc)
            )(getAmount(fees));
        };
    } else if (settings.depositPerc != null && settings.depositPerc > 0) {
        const depositPerc = settings.depositPerc;
        return (fees: Omit<WinningBidFeesInMoney, 'deposit'>) =>
            _.curryRight(calculateDepositAmount)(fees.platformFee, settings.bidIncludesFees, (amount) =>
                amount.takePercent(depositPerc, {rounding: 'round'})
            )(getAmount(fees));
    } else if (settings.depositFixed != null && settings.depositFixed > 0) {
        const depositFixedInCents = settings.depositFixed;
        return (fees: Omit<WinningBidFeesInMoney, 'deposit'>) =>
            _.curryRight(calculateDepositAmount)(fees.platformFee, settings.bidIncludesFees, () =>
                Money.fromCents(depositFixedInCents)
            )(getAmount(fees));
    }
    return null;
};
