import fieldModels from '@joyrideautos/ui-models/src/smartFields/models/FieldModels';
import {IAnyType, types} from 'mobx-state-tree';
import {allEventFilters, NotificationFilterEnum, toFilterLabel} from '@models/stores/NotificationFilters';
import {DEFAULT_OPTION} from '@joyrideautos/ui-models/src/smartFields/renderDropdownItems';

export const createFiltersViewModel = (
    onChange?: (
        self: IAnyType,
        change: {
            before: NotificationFilterEnum;
            after: NotificationFilterEnum;
        }
    ) => void
) =>
    fieldModels.dropdown(
        types.enumeration(allEventFilters),
        () => ({
            preloadOptions: true,
            getOptions: () => [NotificationFilterEnum.ALL, NotificationFilterEnum.UNREAD, NotificationFilterEnum.READ],
            toOption: (option) =>
                option
                    ? {
                          key: option,
                          text: toFilterLabel(option),
                          value: option,
                      }
                    : DEFAULT_OPTION,
        }),
        NotificationFilterEnum.UNREAD,
        (self) => ({
            onChange(after, before) {
                onChange && onChange(self, {after, before});
            },
        })
    );
