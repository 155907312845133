import {BaseService} from './BaseService';

const OFFLINE_NOTIFICATION_DELAY = 3000;
export class FirebaseService extends BaseService {
    // we use this approach because of the issue with subscribers in offline mode - https://github.com/firebase/firebase-js-sdk/issues/249
    subscribeToConnectionChanged(listener: (connected: boolean) => void, options = {skipFirst: false}): () => void {
        let unsubscribe: () => void | null;
        let timeout: NodeJS.Timeout | null;

        if (navigator.onLine) {
            unsubscribe = this.subscribeToFirebaseConnectionChanged(listener, options);
        } else {
            listener(false);
        }

        const handleConnectionChanged = () => {
            timeout && clearTimeout(timeout);
            if (navigator.onLine) {
                unsubscribe = this.subscribeToFirebaseConnectionChanged(listener, options);
            } else {
                unsubscribe && unsubscribe();
                listener(true);
                timeout = setTimeout(() => {
                    if (navigator.onLine) {
                        unsubscribe = this.subscribeToFirebaseConnectionChanged(listener, options);
                    } else {
                        listener(false);
                    }
                }, OFFLINE_NOTIFICATION_DELAY);
            }
        };
        window.addEventListener('offline', handleConnectionChanged);
        window.addEventListener('online', handleConnectionChanged);
        return () => {
            unsubscribe && unsubscribe();
            window.removeEventListener('offline', handleConnectionChanged);
            window.removeEventListener('online', handleConnectionChanged);
        };
    }
    private subscribeToFirebaseConnectionChanged(
        listener: (connected: boolean) => void,
        options = {skipFirst: false}
    ): () => void {
        let timeout: NodeJS.Timeout | null;
        let isFirstEvent = true;
        const unsubscribe = this.database.subscribeToSnapshot('.info/connected', (snapshot) => {
            timeout && clearTimeout(timeout);
            if (snapshot.val() === true) {
                const shouldNotify = !isFirstEvent || !options.skipFirst;
                if (shouldNotify) {
                    listener(true);
                }
                if (!isFirstEvent) {
                    isFirstEvent = false;
                }
            } else {
                timeout = setTimeout(() => listener(false), OFFLINE_NOTIFICATION_DELAY);
            }
        });
        return () => {
            unsubscribe();
            timeout && clearTimeout(timeout);
        };
    }

    toFirestoreTimestamp(value: number | string | {seconds: number; nanoseconds: number}) {
        return this.firestore.toFirestoreTimestamp(value);
    }
}
