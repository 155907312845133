import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Link} from '../router/Router';
import {Breadcrumb as AntdBreadcrumb} from 'antd';
import {ItemType} from 'antd/lib/breadcrumb/Breadcrumb';
import {Router, useRouter} from '../router/Router';
import {DownOutlined} from '@ant-design/icons';
import {BreadcrumbSectionType} from '../router/BreadcrumbModel';

const toIcon = (icon: string | React.ReactNode) => {
    if (typeof icon == 'string') {
        return {
            down: <DownOutlined />,
        }[icon];
    }
    return icon;
};

export interface HasBreadcrumbs {
    breadcrumbSections?: BreadcrumbSectionType[];
}

interface BreadcrumbRoute extends ItemType {
    icon?: React.ReactNode;
    onClickItem?: (router: Router) => void;
}

interface BreadcrumbPropsType {
    sections?: BreadcrumbSectionType[];
}

const getRoutes = (sections: BreadcrumbSectionType[]): BreadcrumbRoute[] => {
    return sections.map((sectionInfo) => {
        return {
            breadcrumbName: sectionInfo.label,
            icon: sectionInfo.icon,
            path: sectionInfo.to || '',
            onClickItem: sectionInfo.onClick,
        };
    });
};

const itemRender =
    (router: Router) => (route: BreadcrumbRoute, params: any, routes: BreadcrumbRoute[], paths: Array<string>) => {
        const last = routes.indexOf(route) === routes.length - 1;

        return (
            <>
                <span className="show-md-desktop">
                    {route.path ? (
                        <Link to={route.path}>{route.breadcrumbName}</Link>
                    ) : route.onClickItem ? (
                        <a
                            onClick={() =>
                                route.onClickItem && route.onClickItem(router)
                            }>{`${route.breadcrumbName} `}</a>
                    ) : (
                        <>{route.breadcrumbName}</>
                    )}
                </span>
                <span className="show-md-mobile inline-b">
                    {last ? (
                        <a onClick={() => route.onClickItem && route.onClickItem(router)}>
                            {`${route.breadcrumbName} `}
                            {route.icon ? toIcon(route.icon) : null}
                        </a>
                    ) : (
                        <Link to={route.path!} onClick={() => route.onClickItem && route.onClickItem(router)}>
                            {`${route.breadcrumbName} `}
                            {route.icon ? toIcon(route.icon) : null}
                        </Link>
                    )}
                </span>
            </>
        );
    };

const Breadcrumb: FC<BreadcrumbPropsType> = ({sections}) => {
    const router = useRouter();
    if (!sections) {
        return null;
    }
    const routes = getRoutes(sections);
    const emptyRoute = routes.find((route) => route.breadcrumbName!.length === 0);
    return emptyRoute === undefined ? <AntdBreadcrumb items={routes} itemRender={itemRender(router)} /> : null;
};

export default observer(Breadcrumb);
