import {useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import {useEffect} from 'react';

const useScrollToTop = (needScrollToTop: boolean) => {
    const {location} = useRouter();
    // scroll restoration https://reacttraining.com/react-router/web/guides/scroll-restoration
    useEffect(() => {
        needScrollToTop &&
            requestAnimationFrame(() => {
                window.scrollTo(0, 0);
            });
    }, [needScrollToTop, location.pathname]);
};

export default useScrollToTop;
