import {
    Notification,
    NotificationPayload,
    NotificationState,
    NotificationEnum,
} from '@joyrideautos/auction-core/src/types/events/notifications';

export default class EventFactory {
    static createNewNotificationDto<P extends NotificationPayload>(type: NotificationEnum, params: P): Notification<P> {
        return {
            timestamp: new Date().getTime(),
            type,
            state: NotificationState.NEW,
            params,
        };
    }
}
