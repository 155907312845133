import {BuyerFeeCalculator} from './types';
import {validatePercent} from './utils';
import {Money} from '../types/Money';

export class PercentBuyerFeeCalculator implements BuyerFeeCalculator {
    public prevFee = Money.zero;

    constructor(private buyerFeePerc?: number) {}

    calculate(amount: Money): Money {
        this.validate();
        const fee = amount.takePercent(this.percent);
        this.prevFee = fee;
        return fee;
    }

    checkIfSalePriceIsInRange(salePrice: Money, fee: Money): boolean {
        return true;
    }

    get percent() {
        return this.buyerFeePerc || 0;
    }

    validate() {
        validatePercent(this.buyerFeePerc, 'buyerFeePerc');
    }
}
