import {ItemPlaceholderEnum} from '@joyrideautos/auction-core/src/types/MediaResource';
import type {DBSchema} from 'idb';
import {Progress} from '../../aws/types';

export interface DownloadBinaryParams {
    binary: Uint8Array;
    contentType: string;
    destination: string;
    index: number;
    placeholderType: ItemPlaceholderEnum;
}

export type UploadMedia = {itemKey: string; sellerId: string; mediaType: string; params: DownloadBinaryParams[]};

export interface InputData extends DownloadBinaryParams {
    itemKey: string;
    sellerId: string;
}

export type Meta = {
    index: number;
    itemKey: string;
    sellerId: string;
};

export type ErrorAndData<T = Meta> = {
    error: (Partial<Meta> & {message: string; code?: string}) | undefined;
    data: T | undefined;
};

export type Callback<T = Meta> = (
    error: (Partial<Meta> & {message: string; code?: string}) | undefined,
    data: T | undefined
) => void;

export class MediaQueueError extends Error {
    constructor(
        message: string,
        public params?: {
            dbPrefix: string;
            itemKey: string;
            index: number;
            sellerId?: string;
            code?: string;
        }
    ) {
        super(message);
    }
}

export type CallbackWithAction<T = Meta> = (
    error: MediaQueueError | undefined,
    data: T | undefined,
    postMessage: (message: {type: string; data: any; messageType: 'command' | 'event'}) => void
) => void;

export type UploadMediaSchemaValue = {
    itemKey: string;
    sellerId: string;
    index: number;
    data: InputData;
};

export type InProgressMediaInfo = {
    itemKey: string;
    sellerId: string;
    index: number;
    progress: Progress | undefined;
};

export const DB_NAME = 'UPLOAD_MEDIA_DB';
export const OBJECT_STORE_NAME = 'resources';

export interface UploadMediaSchema extends DBSchema {
    [OBJECT_STORE_NAME]: {
        key: string; // prefix
        value: UploadMediaSchemaValue[];
    };
}

export enum UploadMediaCommandEnum {
    UPLOAD_MEDIA = 'UPLOAD_MEDIA',
    CANCEL_UPLOAD_MEDIA = 'CANCEL_UPLOAD_MEDIA',
    CHECK_UPLOAD_MEDIA = 'CHECK_UPLOAD_MEDIA',
    GET_UNLOADED_MEDIA = 'GET_UNLOADED_MEDIA',
}

export enum SWUploadEventTypeEnum {
    UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
    UPLOADED_ONE_SUCCESS = 'UPLOADED_ONE_SUCCESS',
    UPLOADED_ONE_FAILED = 'UPLOADED_ONE_FAILED',
    RESIZE_IMAGE = 'RESIZE_IMAGE',
}
