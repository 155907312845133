import * as s from 'superstruct';

export const ValidatePhoneNumberReqDataValidation = s.type({
    phoneNumber: s.string(),
    shouldUseUnlockedReceipts: s.optional(s.boolean()),
});

export enum PhoneValidationResponseCodeEnum {
    SUCCESS = 'SUCCESS',
    BUSY = 'BUSY',
    INVALID = 'INVALID',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    PARTNER_QUOTA_EXCEEDED = 'PARTNER_QUOTA_EXCEEDED',
    UNKNOWN = 'UNKNOWN',
    NOT_MOBILE = 'NOT_MOBILE',
    PHONE_BANNED = 'PHONE_BANNED',
}

export type ValidatePhoneNumberReqData = s.Infer<typeof ValidatePhoneNumberReqDataValidation>;

export type ValidatePhoneNumberResData = {
    code: PhoneValidationResponseCodeEnum;
    message?: string;
    result?: boolean | string;
};
