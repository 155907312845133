import {Analytics as Service} from '@joyrideautos/ui-services/src/firebase/types';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {EventParams, getAnalytics, isSupported, logEvent, setUserId} from 'firebase/analytics';
import {promiseTimeout} from '@joyrideautos/auction-utils/src/PromiseUtils';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {Auth} from '@joyrideautos/ui-services/src/firebase/Auth';
import {FirebaseApp} from 'firebase/app';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';

interface AnalyticsActions {
    logEvent(eventName: string, eventParams?: EventParams): void;

    setUserId(uid: string): void;
}

class Analytics implements Service {
    firebaseAnalytics: Promise<AnalyticsActions | undefined>;

    constructor(private firebaseApp: FirebaseApp, private auth: Auth, appConfig: AppConfig, private logger?: Logger) {
        // TODO: (future) consider to extract setting user id to the root store
        this.firebaseAnalytics = new Promise<AnalyticsActions | undefined>((resolve, reject) => {
            if (appConfig.isEmulatedEnv) {
                resolve(undefined);
                return;
            }
            isSupported()
                .then((supported) => {
                    if (supported) {
                        const authPromise = promiseTimeout(
                            new Promise<string>((resolve, reject) => {
                                this.auth.onAuthStateChanged((user) => {
                                    if (user) {
                                        resolve(user.uid);
                                    } else {
                                        reject('no user');
                                    }
                                });
                            }),
                            2000
                        );
                        const analytics = getAnalytics(this.firebaseApp);

                        authPromise
                            .then((uid) => {
                                setUserId(analytics, uid);
                            })
                            .catch((error) => {
                                this.logger?.log(
                                    'No user id in analytics.isSupported(); will set google analytics user later',
                                    error
                                );
                            })
                            .finally(() =>
                                resolve({
                                    logEvent: (eventName, eventParams) => {
                                        logEvent(analytics, eventName, eventParams);
                                    },
                                    setUserId: (uid) => {
                                        setUserId(analytics, uid);
                                    },
                                })
                            );
                    } else {
                        resolve(undefined);
                    }
                })
                .catch(logError('analytics.isSupported() error'));
        });
    }

    async logEvent(eventName: string, eventParams?: any): Promise<void> {
        const analytics = await this.firebaseAnalytics;
        analytics?.logEvent(eventName, eventParams);
    }

    async setUserId(uid: string): Promise<void> {
        const analytics = await this.firebaseAnalytics;
        analytics?.setUserId(uid);
    }
}

export const createAnalytics = (firebaseApp: FirebaseApp, auth: Auth, appConfig: AppConfig, logger?: Logger) =>
    new Analytics(firebaseApp, auth, appConfig, logger);
