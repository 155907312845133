import {action, computed, makeObservable, observable} from 'mobx';
import {destroy, detach} from 'mobx-state-tree';

export class SingletonViewModelCache<T> {
    private viewModels = observable.map<string, T>();

    // left this for debuging purposes
    constructor(private name: string) {
        // console.log('creating SingletonViewModelCache', name)
        makeObservable(this);
    }

    @computed
    get viewModel(): T | undefined {
        return this.viewModels.values().next().value;
    }

    @computed
    get key(): string | undefined {
        return this.viewModels.keys().next().value;
    }

    addOrReplaceViewModel(factory: () => T) {
        if (this.viewModel) {
            this.destroy();
            this.add(factory);
        } else {
            this.add(factory);
        }
    }

    @action
    add(factory: () => T) {
        this.viewModels.set(this.name, factory());
    }

    @action
    delete(key: string) {
        this.viewModels.delete(key);
    }

    destroy() {
        const vm = this.viewModel;
        if (!vm) {
            return;
        }
        this.delete(this.key!);
        detach(vm);
        requestAnimationFrame(() => {
            destroy(vm);
        });
    }
}
