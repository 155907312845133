import {onSnapshot, isAlive, IAnyStateTreeNode, IDisposer} from 'mobx-state-tree';
import {IStateTreeNode, IType} from 'mobx-state-tree/dist/internal';

/**
 * Run callback immediately in case when predicate result is truthy,
 * in opposite case register callback on tree snapshot update
 *
 * @param target MST tree to listen for
 * @param predicate evaluates to decide whether run callback immediately
 * @param callback
 */
export function onSnapshotOrImmediate<S>(
    target: IStateTreeNode<IType<any, S, any>>,
    predicate: () => boolean,
    callback: () => void
) {
    if (predicate()) {
        callback();
    } else {
        onSnapshot(target, callback);
    }
}

export const withAliveStateRequest = <T>(promise: Promise<T>, node: IAnyStateTreeNode): Promise<T> => {
    return new Promise((resolve, _reject) => {
        (async () => {
            const result: T = await promise;
            if (isAlive(node)) {
                return resolve(result);
            }
            console.log('The node is dead');
        })().catch((e) => console.log(e));
    });
};

export const composeDisposers = (disposers: IDisposer[]) => () => disposers.forEach((d) => d());
