import type {PersistedItem} from '@joyrideautos/auction-core/src/types/Item';
import {PersistedItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {WonItem} from '@joyrideautos/auction-core/src/types/WonItem';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {AuctionWonItemType} from '@joyrideautos/auction-core/src/types/UserWonItems';
import {BaseService} from './BaseService';
import {DataSnapshot} from '../firebase/Database';
import {Unsubscribe} from '../types';
import {SoldTypeEnum} from '@joyrideautos/auction-core/src/types/WinningBid';

type UserWonAuctionsListenerType = (wonAuctions: {regionId: string; auctionId: string}[]) => void;
type WonItemsListenerType = (wonItemsByUser: {[key: string]: WonItem[]}) => void;

const getWonItemIdentifier = (auctionId: string, itemId: string) => {
    return `${auctionId}-${itemId}`;
};

function mapToWonItems(regionId: string, auctionId: string, value: any) {
    return Object.keys(value).map((itemId) => {
        return {
            id: getWonItemIdentifier(auctionId, itemId),
            regionId,
            auctionId,
            itemId,
            sellerId: value[itemId].sellerId,
        };
    });
}

export class WonItemsService extends BaseService {
    subscribeToWonItems(regionId: string, auctionId: string, listener: WonItemsListenerType): () => void {
        const onSnapshot = (snapshot: DataSnapshot) => {
            const wonItemsByUser: {
                [userId: string]: WonItem[];
            } = {};

            snapshot.forEach((snapshot) => {
                const uid = snapshot.key;
                if (uid) {
                    let items = wonItemsByUser[uid];
                    if (!items) {
                        items = [];
                        wonItemsByUser[uid] = items;
                    }

                    const wonItemsForUser = snapshot.val();
                    const newUserItems = mapToWonItems(regionId, auctionId, wonItemsForUser);
                    items.push(...(newUserItems as WonItem[]));
                }
            });

            listener(wonItemsByUser);
        };

        return this.firebase.database.subscribeToSnapshot(`/${regionId}/wonItems/${auctionId}`, onSnapshot);
    }

    subscribeToPersistedWonItems(uid: string, cb: (items: WithKey<PersistedItem>[]) => void): Unsubscribe {
        const query = this.firebase.firestore
            .collectionRef<PersistedItem>('/items')
            .where('result.uid', '==', uid)
            .where('result.soldType', '==', SoldTypeEnum.AUCTION)
            .where('status', 'in', [
                PersistedItemStatusEnum.SOLD,
                PersistedItemStatusEnum.PAID,
                PersistedItemStatusEnum.CLAIMED,
            ])
            .orderBy('result.soldType')
            .orderBy('soldAt', 'asc');
        return this.firebase.firestore.subscribeToCollectionSnap(query, (snapshot) => {
            const items = snapshot.docs.map((doc) => {
                const item = doc.data();
                return {...item, key: doc.id};
            });
            cb(items);
        });
    }

    subscribeToUserWonAuctions(userId: string, listener: UserWonAuctionsListenerType): () => void {
        const onSnapshot = (snapshot: DataSnapshot) => {
            let result: {regionId: string; auctionId: string}[] = [];

            if (snapshot.exists()) {
                const val = snapshot.val();
                result = Object.keys(val).reduce((acc, regionId) => {
                    const auctionIds = val[regionId];
                    const wonAuctions = Object.keys(auctionIds).map((auctionId) => ({regionId, auctionId}));

                    return acc.concat(wonAuctions);
                }, result);
            }

            listener(result);
        };

        return this.firebase.database.subscribeToSnapshot(`/users/${userId}/auctionsWithWonItems`, onSnapshot);
    }

    async fetchWonItemsForSeller(sellerId: string, regionId: string): Promise<{[sellerId: string]: WonItem[]}> {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/${regionId}/wonItems`);
        if (!snapshot.exists()) {
            return Promise.resolve({});
        }

        const wonItemsBySellerId: {
            [sellerId: string]: WonItem[];
        } = {};

        snapshot.forEach((child) => {
            const auctionId = child.key!;
            child.forEach((snapshot) => {
                const uid = snapshot.key;
                if (uid) {
                    let items = wonItemsBySellerId[sellerId];
                    if (!items) {
                        items = [];
                        wonItemsBySellerId[sellerId] = items;
                    }
                    const wonItemsForUser = snapshot.val();
                    const newItems = mapToWonItems(regionId, auctionId, wonItemsForUser).filter(
                        (item) => item.sellerId === sellerId
                    );
                    items.push(...(newItems as WonItem[]));
                }
            });
        });
        return Promise.resolve(wonItemsBySellerId);
    }

    async markVehiclesAsPickedUp(persistenceKeys: string[]): Promise<void> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_MARK_VEHICLE_AS_PICKED_UP)({
            persistenceKeys,
        });
    }

    async fetchCurrentUserWonItems(offset?: number, limit?: number): Promise<PersistedItem[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_CURRENT_USER_WON_ITEMS)({offset, limit});
    }

    async fetchCurrentUserPaidItems(offset?: number, limit?: number): Promise<PersistedItem[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_CURRENT_USER_PAID_ITEMS)({offset, limit});
    }

    async fetchCurrentUserClaimedItems(offset?: number, limit?: number): Promise<PersistedItem[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_CURRENT_USER_CLAIMED_ITEMS)({offset, limit});
    }

    subscribeToLoggedInUserWonItemsCount(
        uid: string,
        hasSellerRoleForItem: (item: WithKey<PersistedItem>) => boolean,
        listener: (count: number) => void
    ) {
        const _query = this.firebase.firestore
            .collectionRef<any>('items')
            .where('result.uid', '==', uid)
            .where('result.payInFullAvailable', '==', true)
            .where('status', '==', PersistedItemStatusEnum.SOLD)
            .orderBy('soldAt', 'desc');
        return this.firebase.firestore.subscribeToCollectionSnap(_query, (snapshot) => {
            if (snapshot.empty) {
                listener(0);
            } else {
                let itemsCount = 0;
                snapshot.forEach((snap) => {
                    const item = snap.data() as PersistedItem;
                    if (!hasSellerRoleForItem({...item, key: snap.id})) {
                        itemsCount += 1;
                    }
                });
                listener(itemsCount);
            }
        });
    }

    async fetchUserWonItems(uid: string, monthLimit: number): Promise<AuctionWonItemType[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_USER_WON_ITEMS)({uid, monthLimit});
    }
}
