import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {SentryService as Service} from '../types';

// TODO: fix types
export type SentryScope = any;

export type SentryIntegration = any;

export type SentryClient = {
    init(config: any): void;
    configureScope(cb: (scope: any) => void): void;
    reactRouterV5Instrumentation(history: any): any;
    captureException(e: any): void;

    captureMessage(message: string, cb?: (scope: SentryScope) => SentryScope): string;
};

export interface SentryConfig {
    dsn: string;
    environment?: string;
    release?: string;
    integrations?: any[];
    tracesSampleRate?: number;
    ignoreErrors?: any[];
}

export class SentryService implements Service {
    constructor(private sentry: SentryClient, private logger?: Logger, private config?: SentryConfig) {
        if (this.config) {
            this.sentry.init(this.config);
        } else {
            console.log('Sentry initialization has been skipped');
        }
        this.logger?.log('created SentryService');
    }

    configureScope(cb: (scope: SentryScope) => void) {
        this.logger?.log('configureScope');
        this.sentry.configureScope(cb);
    }

    captureException(e: any) {
        this.logger?.log('captureException', e.message);
        this.sentry.captureException(e);
    }

    captureMessage(message: string, cb?: (scope: SentryScope) => SentryScope) {
        this.logger?.log('captureMessage', message);
        return this.sentry.captureMessage(message, cb);
    }
}

export const createSentryService = (sentry: SentryClient, logger?: Logger, config?: SentryConfig) =>
    new SentryService(sentry, logger, config);
