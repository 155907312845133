export type EnumEntry<T> = {key: keyof T; value: T[keyof T]};

export function enumKeys<T extends Record<string, unknown>>(enumeration: T): Array<keyof T> {
    return Object.keys(enumeration) as Array<keyof T>;
}

export function enumValues<T extends Record<string, unknown>>(enumeration: T): Array<T[keyof T]> {
    return Object.keys(enumeration)
        .filter((k) => isNaN(Number(k)))
        .map((k) => (enumeration as any)[k]);
}

export function enumEntries<T extends Record<string, unknown>>(
    enumeration: T
): Array<{key: keyof T; value: T[keyof T]}> {
    return Object.keys(enumeration)
        .filter((k) => isNaN(Number(k)))
        .map((key: any) => ({key, value: (enumeration as any)[key]}));
}

export function isValidEnumValue<E>(e: E, value: string, type?: string): boolean {
    return typeof e[value as keyof typeof e] === (type || 'string');
}

export function mapStringToEnum<Enum>(stringValue: string | undefined, enumeration: Enum) {
    if (!stringValue) {
        return;
    }
    return enumeration[stringValue as keyof typeof enumeration];
}
