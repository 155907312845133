import {compareStrings} from '@joyrideautos/auction-utils/src/stringUtils';
import {types} from 'mobx-state-tree';

const VehicleModelMstModel = types.model('VehicleModelMstModel', {
    makeId: types.union(types.number, types.string),
    makeCode: types.string,
    modelId: types.union(types.number, types.string),
    modelName: types.string,
});

export default VehicleModelMstModel;

export function getModelWithMakeName(makeName: string, modelName: string) {
    return `${makeName}:${modelName}`;
}

export interface ModelType {
    makeId: number | string;
    makeCode: string;
    modelId: number | string;
    modelName: string;
}

export const sorting = (fieldName: keyof ModelType) => (a: ModelType, b: ModelType) => {
    return {
        makeId: () => +a['makeId'] - +b['makeId'],
        makeCode: () => compareStrings(a['makeCode'], b['makeCode']),
        modelId: () => +a['modelId'] - +b['modelId'],
        modelName: () => compareStrings(a['modelName'], b['modelName']),
    }[fieldName]();
};
