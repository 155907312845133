import {FunctionsErrorCode, FunctionsError} from 'firebase/functions';
import {HttpResponseBody} from './types';
import {decode} from './serializer';

/**
 * Standard error codes for different ways a request can fail, as defined by:
 * https://github.com/googleapis/googleapis/blob/master/google/rpc/code.proto
 *
 * This map is used primarily to convert from a backend error code string to
 * a client SDK error code string, and make sure it's in the supported set.
 */
export const errorCodeMap: {[name: string]: FunctionsErrorCode} = {
    OK: 'functions/ok',
    CANCELLED: 'functions/cancelled',
    UNKNOWN: 'functions/unknown',
    INVALID_ARGUMENT: 'functions/invalid-argument',
    DEADLINE_EXCEEDED: 'functions/deadline-exceeded',
    NOT_FOUND: 'functions/not-found',
    ALREADY_EXISTS: 'functions/already-exists',
    PERMISSION_DENIED: 'functions/permission-denied',
    UNAUTHENTICATED: 'functions/unauthenticated',
    RESOURCE_EXHAUSTED: 'functions/resource-exhausted',
    FAILED_PRECONDITION: 'functions/failed-precondition',
    ABORTED: 'functions/aborted',
    OUT_OF_RANGE: 'functions/out-of-range',
    UNIMPLEMENTED: 'functions/unimplemented',
    INTERNAL: 'functions/internal',
    UNAVAILABLE: 'functions/unavailable',
    DATA_LOSS: 'functions/data-loss',
};

/**
 * An explicit error that can be thrown from a handler to send an error to the
 * client that called the function.
 */
export class HttpsErrorImpl extends Error implements FunctionsError {
    /**
     * A standard error code that will be returned to the client. This also
     * determines the HTTP status code of the response, as defined in code.proto.
     */
    readonly code: FunctionsErrorCode;

    /**
     * Extra data to be converted to JSON and included in the error response.
     */
    readonly details?: unknown;

    constructor(code: FunctionsErrorCode, message?: string, details?: unknown) {
        super(message);

        // This is a workaround for a bug in TypeScript when extending Error:
        // tslint:disable-next-line
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, HttpsErrorImpl.prototype);

        this.code = code;
        this.details = details;
    }
}

/**
 * Takes an HTTP status code and returns the corresponding ErrorCode.
 * This is the standard HTTP status code -> error mapping defined in:
 * https://github.com/googleapis/googleapis/blob/master/google/rpc/code.proto
 *
 * @param status An HTTP status code.
 * @return The corresponding ErrorCode, or ErrorCode.UNKNOWN if none.
 */
function codeForHTTPStatus(status: number): FunctionsErrorCode {
    // Make sure any successful status is OK.
    if (status >= 200 && status < 300) {
        return 'functions/ok';
    }
    switch (status) {
        case 0:
            // This can happen if the server returns 500.
            return 'functions/internal';
        case 400:
            return 'functions/invalid-argument';
        case 401:
            return 'functions/unauthenticated';
        case 403:
            return 'functions/permission-denied';
        case 404:
            return 'functions/not-found';
        case 409:
            return 'functions/aborted';
        case 429:
            return 'functions/resource-exhausted';
        case 499:
            return 'functions/cancelled';
        case 500:
            return 'functions/internal';
        case 501:
            return 'functions/unimplemented';
        case 503:
            return 'functions/unavailable';
        case 504:
            return 'functions/deadline-exceeded';
        default: // ignore
    }
    return 'functions/unknown';
}

/**
 * Takes an HTTP response and returns the corresponding Error, if any.
 */
export function errorForResponse(status: number, bodyJSON: HttpResponseBody | null): Error | null {
    let code = codeForHTTPStatus(status);

    // Start with reasonable defaults from the status code.
    let description: string = code;

    let details: unknown = undefined;

    // Then look through the body for explicit details.
    try {
        const errorJSON = bodyJSON && bodyJSON.error;
        if (errorJSON) {
            const status = errorJSON.status;
            if (typeof status === 'string') {
                if (!errorCodeMap[status]) {
                    // They must've included an unknown error code in the body.
                    return new HttpsErrorImpl('functions/internal', 'internal');
                }
                code = errorCodeMap[status];
                description = status;
            }

            const message = errorJSON.message;
            if (typeof message === 'string') {
                description = message;
            }

            details = errorJSON.details;
            if (details !== undefined) {
                details = decode(details);
            }
        }
    } catch (e) {
        // If we couldn't parse explicit error data, that's fine.
    }

    if (code === 'functions/ok') {
        // Technically, there's an edge case where a developer could explicitly
        // return an error code of OK, and we will treat it as success, but that
        // seems reasonable.
        return null;
    }

    return new HttpsErrorImpl(code, description, details);
}
