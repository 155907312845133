import React, {FC} from 'react';
import {GlobalAlertsOptionEnum, GlobalAlertViewModel} from './GlobalAlertViewModel';
import {withViewModel} from '@joyrideautos/ui-common/src/utils/componentUtils';
import {LiveAuctionAlert} from '@components/alerts/liveAuctionAlert/LiveAuctionAlert';
import FailedDepositAlert from '@components/alerts/FailedDepositAlert';
import CCHoldFailedAlert from '@components/alerts/CCHoldFailedAlert';
import BuyerProfileIncompleteAlert from '@components/alerts/BuyerProfileIncompleteAlert';
import GlobalAlert from '@components/alerts/globalAlert/GlobalAlert';
import {observer} from 'mobx-react-lite';
import SWNewVersionAlert from './SWNewVersionAlert';
import {rootStore} from '@models/stores/RootStore';
import {HasViewModel} from '@joyrideautos/ui-models/src/types';

const alertsByOption = {
    [GlobalAlertsOptionEnum.LIVE_AUCTION]: (props: any) => <LiveAuctionAlert {...props} />,
    [GlobalAlertsOptionEnum.FAILED_DEPOSIT]: (props: any) => <FailedDepositAlert {...props} />,
    [GlobalAlertsOptionEnum.CC_HOLD_FAILED]: (props: any) => <CCHoldFailedAlert {...props} />,
    [GlobalAlertsOptionEnum.SW_NEW_VERSION]: (props: any) => <SWNewVersionAlert {...props} />,
    [GlobalAlertsOptionEnum.BUYER_PROFILE_INCOMPLETE]: (props: any) => <BuyerProfileIncompleteAlert {...props} />,
};

interface GlobalAlertContainerProps extends HasViewModel<typeof GlobalAlertViewModel> {}

const GlobalAlertContainer: FC<GlobalAlertContainerProps> = observer((props) => {
    const {visibleAlertInfo} = props.viewModel;
    return (
        <GlobalAlert>
            {visibleAlertInfo?.option &&
                alertsByOption[visibleAlertInfo.option]({...props, viewModel: visibleAlertInfo.viewModel!})}
        </GlobalAlert>
    );
});

export default withViewModel(GlobalAlertViewModel, rootStore)(GlobalAlertContainer);
