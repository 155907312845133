import {QuestionCircleOutlined} from '@ant-design/icons';
import {Routes} from '../../../Routes';

export const createHelpMenu = () => {
    const helpMenu = {
        label: (
            <a href={Routes.SUPPORT_LINK()} target="_blank" rel="noopener noreferrer" className="help">
                <span>Help</span>
            </a>
        ),
        key: 'help',
        style: {marginInlineStart: 'auto'},
        icon: <QuestionCircleOutlined />,
    };

    return helpMenu;
};
