import * as s from 'superstruct';
import {ACTIVE_OFFER_DURATION_HOURS} from '../constants/Constants';
import {hoursFromNow} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {ItemBidderNumber} from './BiddersNumbers';
import {SortingEnum} from './Sorting';
import {WithKey} from './common';

export enum BidRejectionReasonEnum {
    NO_ACCESS = 'NO_ACCESS',
    NO_MANAGER_BIDS = 'NO_MANAGER_BIDS',
    BIDDING_NOT_STARTED = 'BIDDING_NOT_STARTED',
    BIDDING_ENDED = 'BIDDING_ENDED',
    WRONG_AMOUNT = 'WRONG_AMOUNT',
    AUCTION_SUSPENDED = 'AUCTION_SUSPENDED',
    MANAGER_REJECTED = 'MANAGER_REJECTED',
    OUTBID_BY_AUTOBID = 'OUTBID_BY_AUTOBID',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    FEES_EXCEED_TOTAL_BID = 'FEES_EXCEED_TOTAL_BID',
    ALREADY_WINNING = 'ALREADY_WINNING',
    BIDDING_LIMIT = 'BIDDING_LIMIT',
}

export const allBidRejectionReasons = Object.values(BidRejectionReasonEnum);

export interface AutoBid {
    uid: string;
    authType: AuthType;
    bidInfo?: BidInfo;
    amount: number;
    cancelledAt?: string;
    multibid?: boolean;
    createdAt?: number; // time since the Unix epoch, in milliseconds
}

export interface AutoBidInfo {
    userId?: string;
    timestamp: string; // ctxTimestamp is human readable iso8601 string
    // accepted means it was received by system and valid
    accepted: boolean | null;
    // accepted/rejected(-At/By) means the seller did accept/reject this offer
    acceptedAt?: string;
    rejectedAt?: string;
    acceptedBy?: string;
    rejectedBy?: string;
    reason?: BidRejectionReasonEnum;
}

export interface BidInfo extends AutoBidInfo {
    bidderNumber?: ItemBidderNumber;
}

export enum BidTypeEnum {
    OFFER = 'offer',
    AUCTION = 'auction',
}

export enum BidsTypeEnum {
    BIDS = 'bids',
    AUTOBIDS = 'autobids',
    OFFERS = 'offers',
}

export type AuthType = 'ADMIN' | 'USER' | 'UNAUTHENTICATED';

interface BidCore {
    amount: number;
    uid: string;
    autobidId: string | undefined;
    bidInfo: BidInfo | undefined;
    paddleNumber?: string | undefined;
    type?: BidTypeEnum;
    createdAt?: number; // time since the Unix epoch, in milliseconds
}

export interface Bid extends BidCore {
    authType: AuthType;
}

export type BidPath = {
    regionId: string;
    auctionId: string;
    itemId: string;
    bidId: string;
};

export type OfferPath = {
    regionId: string;
    auctionId: string;
    itemId: string;
    bidId: string;
    uid: string;
};

export type OfferInfo = {
    highestOffer: WithKey<Bid> | null;
};

export const OfferPathValidation = s.type({
    regionId: s.string(),
    auctionId: s.string(),
    itemId: s.string(),
    bidId: s.string(),
    uid: s.string(),
});

export const BidPathValidation = s.type({
    regionId: s.string(),
    auctionId: s.string(),
    itemId: s.string(),
    bidId: s.string(),
});

export enum OfferHandlerActionEnum {
    ACCEPT = 'accept',
    REJECT = 'reject',
    CANCEL_AND_RELIST = 'cancel_and_relist',
}

export function bidsFilter({type, autobidId}: BidCore): boolean {
    return type === BidTypeEnum.AUCTION || !!autobidId;
}

export function offersFilter({type}: BidCore): boolean {
    return type === BidTypeEnum.OFFER;
}

export function activeOffersFilter({bidInfo}: BidCore): boolean {
    return bidInfo == null
        ? false
        : // accepted = it was received by system and valid; acceptedBy/rejectedBy means seller did accept/reject it
          bidInfo.accepted !== false &&
              bidInfo.acceptedBy == null &&
              bidInfo.rejectedBy == null &&
              hoursFromNow(new Date(bidInfo.timestamp).getTime()) <= ACTIVE_OFFER_DURATION_HOURS;
}

export function activeBidsFilter({bidInfo}: BidCore): boolean {
    return bidInfo == null ? false : bidInfo.accepted === true && !bidInfo.reason;
}

export function checkManagerRejectedOffer(offer?: BidCore): boolean {
    if (!offer || !offer.bidInfo) {
        return false;
    }
    return offer.bidInfo.rejectedBy != null;
}

export function acceptedOffersFilter({bidInfo}: BidCore): boolean {
    return bidInfo == null ? false : bidInfo.accepted === true && bidInfo.acceptedBy != null;
}

export const acceptedOffersBySellerUserFilter =
    (sellerUid: string) =>
    ({bidInfo}: BidCore): boolean => {
        return bidInfo == null ? false : !!bidInfo.accepted && bidInfo.acceptedBy === sellerUid;
    };

export const activeOffersForUserFilter =
    (uid: string) =>
    (bid: BidCore): boolean => {
        return activeOffersFilter(bid) && bid.uid === uid;
    };

export const sortBidsByTimestamp =
    (order = SortingEnum.DESC) =>
    (a: BidCore, b: BidCore) => {
        const sign = order === SortingEnum.DESC ? -1 : +1;
        const timestampA = a.bidInfo?.timestamp;
        const timestampB = b.bidInfo?.timestamp;
        return timestampA && timestampB
            ? -sign * new Date(timestampA).getTime() + sign * new Date(timestampB).getTime()
            : 0;
    };

export function getHighestOffer<T extends BidCore>(offers?: T[]): T | null {
    if (!offers) {
        return null;
    }
    return offers.sort(sortBidsByTimestamp(SortingEnum.ASC)).reduce<T | null>((highestOffer, bid) => {
        return (highestOffer?.amount ?? 0) > bid.amount ? highestOffer : bid;
    }, null);
}

export interface UserBidData {
    bidId: string;
    timestamp: string | null; // null valid for auctions with bid type "auction" after migartion (changeAuctionsWithBids)
    type: BidTypeEnum;
}

export function filterBidsForTotalBidsCalculation(bid: WithKey<Bid>): boolean {
    return !!bid.bidInfo?.accepted;
}
