import {Instance} from 'mobx-state-tree';
import {autorun} from 'mobx';
import type {MenuItemKey} from '@joyrideautos/ui-models/src/MainMenus';
import BaseViewModel from '@joyrideautos/web-common-components/src/models/BaseViewModel';
import primitives from '@joyrideautos/ui-models/src/Primitives';
import {mapInventoryItemDtoToModel} from '@joyrideautos/ui-models/src/types/item/PersistedItem';

export const MenuViewModel = BaseViewModel.named('MenuViewModel')
    .props({
        visibleDrawer: primitives.boolean(false),
        currentMenu: primitives.string(),
        pathname: primitives.string('/home', (self) => ({
            onChange(pathname) {
                const pathnameToCurrentMenu: {[key: string]: string} = {
                    '/home': 'home',
                    '/auctions': 'auctions',
                    '/my-vehicles': 'my-vehicles',
                    '/search': 'search',
                    '/my-inventory': 'seller-tools',
                    '/my-auctions': 'seller-tools',
                    '/profile': 'profile',
                };
                self.currentMenu.setValue(pathnameToCurrentMenu[pathname] || '');
            },
        })),
    })
    .volatile(() => ({
        badges: {} as {[key in MenuItemKey]: number},
        unsubscribers: [] as (() => void)[],
        disposers: [] as (() => void)[],
    }))
    .views((self) => {
        return {
            get userInfo() {
                return self.authUserStore.userInfo;
            },
            badgeCountForMenuItem(key: MenuItemKey) {
                return self.badges[key];
            },
            get sellerId() {
                return self.sellerSessionStorage.sellerId;
            },
            get currentSellerName() {
                const currentCompany = this.sellerId ? self.sellersStore.findSeller(this.sellerId) : null;
                return currentCompany?.name;
            },
            get isSeller() {
                return self.sellerRoleStore.getSellerCompanyIds().length > 0;
            },
            get isMultiSeller() {
                return self.sellerRoleStore.getSellerCompanyIds().length > 1;
            },
            get isGateCodesEnabled() {
                return self.globalConfigStore.isGateCodesEnabled.value;
            },
        };
    })
    .actions((self) => ({
        updateMyVehiclesCount(count: number) {
            self.badges = {...self.badges, ...{my_vehicles: count}};
        },
    }))
    .actions((self) => {
        return {
            afterCreate() {
                self.disposers.push(
                    autorun(() => {
                        self.unsubscribers.forEach((unsubscriber) => unsubscriber());
                        const {userInfo} = self.authUserStore;
                        if (userInfo && !userInfo.isAnonymous) {
                            self.unsubscribers.push(
                                self.rootStore.wonItemService.subscribeToLoggedInUserWonItemsCount(
                                    userInfo.uid,
                                    (item) =>
                                        self.sellerRoleStore.hasSellerRoleForItem(mapInventoryItemDtoToModel(item)),
                                    (count) => {
                                        self.updateMyVehiclesCount(count);
                                    }
                                )
                            );
                        }
                    })
                );
            },
            beforeDestroy() {
                self.unsubscribers.forEach((unsubscriber) => unsubscriber());
                self.disposers.forEach((disposer) => disposer());
            },
        };
    });

export interface MenuViewModelType extends Instance<typeof MenuViewModel> {}
