import {
    BaseDatabase,
    Database as Service,
    DatabaseReference,
    DataSnapshot,
    keyOnlySnapshotToArrayTransformer,
    SnapshotTransformer,
} from '@joyrideautos/ui-services/src/firebase/Database';
import {WithKey} from '@joyrideautos/ui-services/src/firebase/types';
import {Unsubscribe} from '@joyrideautos/ui-services/src/types';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {
    ref,
    onValue,
    onChildAdded,
    onChildRemoved,
    onChildMoved,
    onChildChanged,
    runTransaction,
    Query,
    EventType,
    push,
    serverTimestamp,
    set,
    update,
    getDatabase,
    Database as FirebaseDatabase,
} from 'firebase/database';
import {sanitizeObject} from '@joyrideautos/auction-utils/src/objectUtils';
import {DatabaseQuery} from './DatabaseQuery';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import {FirebaseApp} from 'firebase/app';
import {logError} from '@joyrideautos/ui-logger/src/utils';

function makeQueryDescription(query: Query, eventType: EventType) {
    return `${query.toString()}-${eventType}`;
}

const on: Record<
    EventType,
    (query: Query, cb: (snapshot: any) => unknown, cancelCallback?: (error: Error) => unknown) => Unsubscribe
> = {
    value: onValue,
    child_added: onChildAdded,
    child_changed: onChildChanged,
    child_moved: onChildMoved,
    child_removed: onChildRemoved,
};

class Database extends BaseDatabase implements Service {
    constructor(private database: FirebaseDatabase, protected appConfig: AppConfig, protected logger?: Logger) {
        super(appConfig, logger);
    }

    subscriptions: {[key: string]: number | undefined} = {};

    ref(path: string) {
        return new DatabaseQuery(this.database, path);
    }

    async fetchOnce<T>(pathOrQuery: string | DatabaseReference): Promise<WithKey<T> | null> {
        const snapshot = await this.fetchOnceSnapshot(pathOrQuery);
        return snapshot.exists() ? {...snapshot.val(), key: snapshot.key} : null;
    }

    async fetchOnceArray<Element>(pathOrQuery: string | DatabaseReference, transform: SnapshotTransformer<Element>) {
        const snapshot = await this.fetchOnceSnapshot(pathOrQuery);
        return this.snapshotToArray(snapshot, transform);
    }

    async fetchOnceSnapshots(pathOrQuery: string | DatabaseReference): Promise<DataSnapshot[]> {
        const snapshot = await this.fetchOnceSnapshot(pathOrQuery);
        return (
            this.snapshotToArray(snapshot, (childSnapshot) => [{key: childSnapshot.key!, value: childSnapshot}]) ?? []
        );
    }

    subscribeToSnapshot(
        pathOrQuery: string | DatabaseReference,
        cb: (snapshot: DataSnapshot) => void,
        eventType: EventType = 'value'
    ): Unsubscribe {
        const _query = this.toQuery(pathOrQuery);
        const queryDescr = makeQueryDescription(_query, eventType);
        // this.logger?.log('subscribed to ', queryDescr, subscriptions[queryDescr]);
        if (process.env.NODE_ENV === 'development') {
            this.subscriptions[queryDescr] = (this.subscriptions[queryDescr] || 0) + 1;
            if ((this.subscriptions[queryDescr] || 0) > 1) {
                // TODO: (Future) inject logger
                // this.logger?.log('leaked subscription to ', queryDescr, subscriptions[queryDescr]);
            }
        }
        this.logger?.log('subscribeToSnapshot', {pathOrQuery, _query, queryDescr, eventType});
        const unsubscribe = on[eventType](
            _query,
            (snapshot) => {
                this.logger?.log('got a snapshot for', queryDescr);
                cb(snapshot);
            },
            logError(`failed to subscribe to ${queryDescr}`)
        );
        return () => {
            // logger.log('unsubscribed from ', queryDescr, subscriptions[queryDescr]);
            if (process.env.NODE_ENV === 'development') {
                this.subscriptions[queryDescr] = (this.subscriptions[queryDescr] || 0) - 1;
                if ((this.subscriptions[queryDescr] || 0) > 1) {
                    // TODO: (Future) inject logger
                    // this.logger?.log('leaked subscription to ', queryDescr, subscriptions[queryDescr]);
                }
            }
            unsubscribe();
        };
    }

    subscribe<T>(
        pathOrQuery: string | DatabaseReference,
        cb: (value: T | null) => void,
        eventType?: EventType
    ): Unsubscribe {
        return this.subscribeToSnapshot(
            pathOrQuery,
            (snapshot) => {
                cb(snapshot?.exists() ? snapshot.val() : null);
            },
            eventType
        );
    }

    subscribeToNewlyCreated<T>(path: string | DatabaseReference, cb: (snapshot: DataSnapshot) => void): Unsubscribe {
        let unsubscribe: () => void | undefined;
        let unsubscribed = false;
        this.fetchOnceArray(path, keyOnlySnapshotToArrayTransformer)
            .then((keys) => {
                if (unsubscribed) {
                    return;
                }
                unsubscribe = this.subscribeToSnapshot(
                    path,
                    (snapshot) => {
                        if (snapshot.key && !keys.includes(snapshot.key)) {
                            cb(snapshot);
                        }
                    },
                    'child_added'
                );
            })
            .catch((e) => this.logger?.log(e));
        return () => {
            unsubscribed = true;
            unsubscribe && unsubscribe();
        };
    }

    async fetchOnceSnapshot(pathOrQuery: string | DatabaseReference) {
        return new Promise<DataSnapshot>((res, rej) => {
            const unsubscribe = onValue(
                this.toQuery(pathOrQuery),
                (snapshot) => {
                    res(snapshot as any);
                    // there are cases when the callback is called before onValue is finished.
                    // as result 'unsubscribe' variable is undefined;
                    requestAnimationFrame(() => unsubscribe());
                },
                (error) => {
                    rej(error);
                },
                {
                    onlyOnce: true,
                }
            );
        });
    }

    async pushValues<T>(path: string, values: T) {
        try {
            const newObjRef = await push(ref(this.database, path), sanitizeObject(values));
            return new DatabaseQuery(this.database, newObjRef) as any;
        } catch (e: any) {
            this.logger?.log('failed to push values', e);
            throw e;
        }
    }

    async setValues<T>(path: string, values: T): Promise<void> {
        try {
            await set(ref(this.database, path), sanitizeObject(values));
        } catch (e: any) {
            this.logger?.log('failed to set values', e);
            throw e;
        }
    }

    async updateValues<T extends object>(path: string, values: T) {
        try {
            await update(ref(this.database, path), sanitizeObject(values));
        } catch (e: any) {
            this.logger?.log('failed to update values', e);
            throw e;
        }
    }

    onChildAdded(path: string | DatabaseReference, cb: (snapshot: DataSnapshot) => void): Unsubscribe {
        return this.subscribeToSnapshot(path, cb, 'child_added');
    }

    onChildChanged(path: string | DatabaseReference, cb: (snapshot: DataSnapshot) => void): Unsubscribe {
        return this.subscribeToSnapshot(path, cb, 'child_changed');
    }

    onChildRemoved(path: string | DatabaseReference, cb: (snapshot: DataSnapshot) => void): Unsubscribe {
        return this.subscribeToSnapshot(path, cb, 'child_removed');
    }

    runTransaction(path: string, transactionUpdate: (snapshot: DataSnapshot) => any): Promise<any> {
        return runTransaction(ref(this.database, path), transactionUpdate);
    }

    updateValueInTransaction<T>(path: string, cb: (value: T) => T): Promise<any> {
        return runTransaction(ref(this.database, path), (current) => {
            if (current) {
                return sanitizeObject(cb(current));
            }
            return current;
        });
    }

    serverTimestamp(): any {
        return serverTimestamp();
    }

    private toQuery(pathOrQuery: string | DatabaseReference) {
        this.logger?.log('toQuery', pathOrQuery);
        if (typeof pathOrQuery === 'string') {
            return new DatabaseQuery(this.database, pathOrQuery).query;
        }
        if ('query' in pathOrQuery) {
            return pathOrQuery.query;
        }
        return pathOrQuery as any;
    }
}

export function createDatabase(firebaseApp: FirebaseApp, appConfig: AppConfig, logger?: Logger): Service {
    return new Database(getDatabase(firebaseApp), appConfig, logger);
}
