import {WithKey} from './types';
import {Unsubscribe} from '../types';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';

export interface DocumentSnapshot<T> {
    exists(): boolean;

    data(): T | undefined;

    get id(): string;
}

export type WhereFilterOp =
    | '<'
    | '<='
    | '=='
    | '>'
    | '>='
    | '!='
    | 'array-contains'
    | 'array-contains-any'
    | 'in'
    | 'not-in';

export interface Query<T> {
    endAt(key: string): Query<T>;

    endAt(snapshot: DocumentSnapshot<T>): Query<T>;

    endAt(value: T[keyof T]): Query<T>;

    endBefore(key: string): Query<T>;

    endBefore(snapshot: DocumentSnapshot<T>): Query<T>;

    endBefore(value: T[keyof T]): Query<T>;

    limit(limit: number): Query<T>;

    limitToLast(limitToLast: number): Query<T>;

    orderBy(fieldPath: string, directionStr?: 'asc' | 'desc'): Query<T>;

    startAfter(snapshot: DocumentSnapshot<T>): Query<T>;

    startAfter(...fieldValues: any[]): Query<T>;

    startAt(snapshot: DocumentSnapshot<T>): Query<T>;

    startAt(...values: any[]): Query<T>;

    where(fieldPath: string, opStr: WhereFilterOp, value: unknown): Query<T>;
}

export interface QueryDocumentSnapshot<T> extends DocumentSnapshot<T> {
    data(): T;
}

export interface QuerySnapshot<T> {
    get docs(): Array<QueryDocumentSnapshot<T>>;

    get size(): number;

    get empty(): boolean;

    forEach(callback: (result: QueryDocumentSnapshot<T>) => void): void;
}

export interface DocumentReference<T> {
    get id(): string;

    get path(): string;
}

export type CollectionSubscriptionCallback<T> = (results: {[key: string]: T} | null) => any;
export type CollectionCountSubscriptionCallback = (results: number) => void;
export type CollectionSnapSubscriptionCallback<T> = (snapshot: QuerySnapshot<T>) => any;

type Timestamp = any;

export function toArray<R, S = R>(
    snapshot: QuerySnapshot<S>,
    transformer: (key: string, data: S) => R = (_, data) => cast<R>(data)
) {
    const result: R[] = [];
    snapshot.forEach((snap) => result.push(transformer(snap.id, snap.data())));
    return result;
}

export function toMapObject<T>(docs: DocumentSnapshot<T>[], predicate?: (doc: DocumentSnapshot<T>) => boolean) {
    return docs
        .filter((doc) => (doc.exists() && predicate ? predicate(doc) : true))
        .reduce((map, doc) => {
            map[doc.id] = doc.data();
            return map;
        }, {} as any);
}

export interface Firestore {
    fetchOnce<T>(ref: DocumentReference<T>): Promise<T | null>;

    fetchOnceDocument<T>(path: DocumentReference<T>): Promise<DocumentSnapshot<T> | null>;

    subscribeToDocument<T extends Record<string, any>>(
        ref: DocumentReference<T>,
        subscriber: (data: T | undefined, error: Error | undefined) => void
    ): Unsubscribe;

    fetchOnceArray<T, R = T>(
        query: Query<T>,
        transform?: (snapshot: QueryDocumentSnapshot<T>) => R | null
    ): Promise<R[]>;

    fetchOnceDocuments<T>(query: Query<T>): Promise<DocumentSnapshot<T>[] | null>;

    // TODO: (future) use subscribeToCollection instead
    subscribeToCollectionSnap<T>(query: Query<T>, subscriber: CollectionSnapSubscriptionCallback<T>): Unsubscribe;

    subscribeToCollection<T>(query: Query<T>, subscriber: CollectionSubscriptionCallback<T>): Unsubscribe;

    subscribeToCollectionCount<T>(query: Query<T>, subscriber: CollectionCountSubscriptionCallback): Unsubscribe;

    fetchCollectionCount<T>(query: Query<T>): Promise<number>;

    queryIterator<T>(
        query: Query<T>,
        params?: {
            chunkSize?: number;
            startDocumentId?: string;
        }
    ): AsyncIterableIterator<WithKey<T>[]>;

    collectionRef<T>(path: string, ...segments: string[]): Query<T>;

    collectionGroupRef<T>(path: string, ...segments: string[]): Query<T>;

    documentRef<T>(path: string, ...segments: string[]): DocumentReference<T>;

    toFirestoreTimestamp(value: number | string | {seconds: number; nanoseconds: number}): Timestamp;
}
