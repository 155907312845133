import {Progress} from 'antd';
import {ProgressProps} from 'antd/lib/progress';
import React, {FC, useEffect, useState} from 'react';

function useInfiniteProgress(start = 0, step = 20, intervalTimeout = 1000) {
    const [progress, setProgress] = useState(start);
    useEffect(() => {
        let currentProgress = start;
        const interval = setInterval(() => {
            currentProgress += step;
            setProgress(currentProgress % (100 + step));
        }, intervalTimeout);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return progress;
}

const InfiniteProgressBar: FC<ProgressProps> = ({size = 'small', strokeWidth = 2, strokeColor = '#13C2C2'}) => {
    const progress = useInfiniteProgress();
    return (
        <Progress
            size={size}
            percent={progress}
            showInfo={false}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            className="reset-font-size"
        />
    );
};

export default InfiniteProgressBar;
