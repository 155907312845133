import React, {FC, PropsWithChildren, useEffect} from 'react';
import {BreadcrumbModelType} from '../router/BreadcrumbModel';
import {useRootStore} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';
import RootStoreType from '@joyrideautos/ui-models/src/stores/Shared';
import {useRouter} from '../router/Router';

export interface PageContainerProps extends PropsWithChildren {
    path: string;
    getBreadcrumbModel: (rootStore: RootStoreType, params?: any) => BreadcrumbModelType;
}

export const PageContainer: FC<PageContainerProps> = ({path: routePath, getBreadcrumbModel, children}) => {
    const router = useRouter();
    const rootStore = useRootStore<any>();
    const {presentationStore} = rootStore;
    useEffect(() => {
        const match = router.matchPath(router.location.pathname, {path: routePath, exact: true});
        if (!match) {
            return;
        }
        const {path, params, isExact} = match;

        presentationStore.routeModel.update({path, isExact, params});
        presentationStore.routeModel.search.setValue((router.location.search ?? '').replace('?', ''));
        presentationStore.routeModel.hash.setValue(router.getHash());
        presentationStore.routeModel.pathname.setValue(router.location.pathname);
        presentationStore.setBreadcrumbModel(getBreadcrumbModel(rootStore, params));
    }, [router, presentationStore, getBreadcrumbModel, rootStore, routePath]);
    return <>{children}</>;
};
