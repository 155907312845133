import {PaymentTypeEnum} from '@joyrideautos/auction-core/src/types/Payments';
import {PaymentIntentHold} from '@joyrideautos/auction-core/src/types/PaymentIntentHold';
import {
    GenerateReceiptRPCReqData,
    GenerateReceiptRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/generateReceiptReqTypes';
import {
    StoreReceiptsRPCReqData,
    StoreReceiptsRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/storeReceiptsReqTypes';
import {
    GeneratePDFBulkReceiptReqData,
    GeneratePDFBulkReceiptResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/GeneratePDFBulkReceiptReqTypes';
import {
    UpdatePaymentMethodRPCReqData,
    UpdatePaymentMethodRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/UpdatePaymentMethodReqTypes';
import {
    AddPaymentMethodRPCReqData,
    AddPaymentMethodRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/AddPaymentMethodReqTypes';
import {
    RemovePaymentMethodRPCReqData,
    RemovePaymentMethodRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/RemovePaymentMethodReqTypes';
import {CreatePaymentMethodSetupIntentRPCResData} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/CreatePaymentMethodSetupIntentReqTypes';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {
    ConfirmCcHoldAndPlaceCcHoldForBidderRPCReqData,
    ConfirmCcHoldAndPlaceCcHoldForBidderRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/payments/confirmCcHoldAndPlaceCcHoldForBidderReqTypes';
import {BaseService} from './BaseService';

export class PaymentService extends BaseService {
    async addPaymentMethod(data: AddPaymentMethodRPCReqData): Promise<AddPaymentMethodRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_ADD_PAYMENT_METHOD)(data);
    }

    async removePaymentMethod(data: RemovePaymentMethodRPCReqData): Promise<RemovePaymentMethodRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_REMOVE_PAYMENT_METHOD)(data);
    }

    async updatePaymentMethod(data: UpdatePaymentMethodRPCReqData): Promise<UpdatePaymentMethodRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_UPDATE_PAYMENT_METHOD)(data);
    }

    async getFeesBreakdownForAmount(
        amount: number,
        regionId: string,
        auctionId: string,
        itemKey: string
    ): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_GET_FEES_BREAKDOWN_FOR_AMOUNT)({
            amount,
            regionId,
            auctionId,
            itemKey,
        });
    }

    async generateDepositReceipt(data: GenerateReceiptRPCReqData): Promise<GenerateReceiptRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_GENERATE_RECEIPT)(data);
    }

    async generatePayInFullReceipt(data: GenerateReceiptRPCReqData): Promise<GenerateReceiptRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_GENERATE_PAY_IN_FULL_RECEIPT)(data);
    }

    async generateRecentReceipt(data: GenerateReceiptRPCReqData): Promise<GenerateReceiptRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_GENERATE_RECENT_RECEIPT)(data);
    }

    async storePDFReceipts(data: StoreReceiptsRPCReqData): Promise<StoreReceiptsRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_STORE_PDF_RECEIPTS, {
            timeout: 540_000,
        })(data);
    }

    async generatePDFBulkReceipt(data: GeneratePDFBulkReceiptReqData): Promise<GeneratePDFBulkReceiptResData> {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_GENERATE_PDF_BULK_RECEIPT, {
            timeout: 540_000,
        })(data);
    }

    async createPaymentMethodSetupIntent(): Promise<CreatePaymentMethodSetupIntentRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_CREATE_PAYMENT_METHOD_SETUP_INTENT)();
    }

    // Note: this endpoint is not used. At the moment we use checkIfUserIsAbleToPayForItemsLocal instead
    async checkIfUserIsAbleToPayForItems(itemsIds: Array<string | number>, paymentType: PaymentTypeEnum): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_CHECK_IF_USER_IS_ABLE_TO_PAY_FOR_ITEMS)({
            itemsIds,
            paymentType,
        });
    }

    async checkHasFailedDeposits(userId: string, itemIds: Array<string | number>) {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/users/${userId}/failedDeposits`);
        if (!snapshot.exists()) {
            return false;
        }
        let hasFailedDeposits = false;
        snapshot.forEach((child) => {
            if (!child.exists()) {
                return;
            }
            hasFailedDeposits = hasFailedDeposits || itemIds.includes(child.key!);
        });
        return hasFailedDeposits;
    }

    async checkIfUserIsAbleToPayForItemsLocal(
        itemsIds: Array<string | number>,
        paymentType: PaymentTypeEnum
    ): Promise<any> {
        // TODO: refactor this logic. move it to viewModel or store
        const currentUser = this.firebase.auth.currentUser;
        const userId = currentUser && currentUser.uid;
        if (!userId) {
            return false;
        }
        if (paymentType === PaymentTypeEnum.DEPOSIT_OFFER) {
            return;
        }
        if (paymentType === PaymentTypeEnum.PAY_IN_FULL) {
            const hasFailedDeposits = await this.checkHasFailedDeposits(userId, itemsIds);
            if (hasFailedDeposits) {
                throw new Error(
                    "Unable to pay for vehicles. Please make sure you've payed for the outstanding deposit."
                );
            }
        }
    }

    async generateCheckout(itemsIds: string[], paymentType: PaymentTypeEnum): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_GENERATE_CHECKOUT)({
            itemsIds,
            paymentType,
        });
    }

    subscribeToCcHolds(uid: string, listener: (holds: {[key: string]: PaymentIntentHold}) => void) {
        return this.firebase.firestore.subscribeToCollection<any>(
            this.firebase.firestore.collectionRef(`/users/${uid}/holds`),
            (result) => listener(result || {})
        );
    }

    subscribeToCcHoldForAuction(
        {uid, regionId, auctionId}: {uid: string; regionId: string; auctionId: string},
        subscriber: (hold: PaymentIntentHold | undefined) => void
    ) {
        return this.firebase.firestore.subscribeToDocument<PaymentIntentHold>(
            this.firebase.firestore.documentRef(`/users/${uid}/holds/${regionId}-auctions-${auctionId}`),
            (hold: any) => {
                subscriber(hold);
            }
        );
    }

    subscribeToCcHoldPerVehicle(
        {uid, regionId, auctionId, itemId}: {uid: string; regionId: string; auctionId: string; itemId: string},
        subscriber: (hold: PaymentIntentHold | undefined) => void
    ) {
        return this.firebase.firestore.subscribeToDocument<PaymentIntentHold>(
            this.firebase.firestore.documentRef(
                `/users/${uid}/holds/${regionId}-auctions-${auctionId}/items/${itemId}`
            ),
            (hold: any) => {
                subscriber(hold);
            }
        );
    }

    async fetchCcHoldForAuction({
        uid,
        regionId,
        auctionId,
    }: {
        uid: string;
        regionId: string;
        auctionId: string;
    }): Promise<PaymentIntentHold | undefined> {
        const hold = await this.firebase.firestore.fetchOnce<PaymentIntentHold>(
            this.firebase.firestore.documentRef(`/users/${uid}/holds/${regionId}-auctions-${auctionId}`)
        );
        return hold ?? undefined;
    }

    async confirmCcHold({regionId, auctionId, itemId}: {regionId: string; auctionId: string; itemId: string}) {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_CONFIRM_CC_HOLD)({
            regionId,
            auctionId,
            itemId,
        });
    }

    async confirmCcHoldAndPlaceCcHoldForBidder(
        data: ConfirmCcHoldAndPlaceCcHoldForBidderRPCReqData
    ): Promise<ConfirmCcHoldAndPlaceCcHoldForBidderRPCResData> {
        return await this.firebase.rpcService.call(
            FeReqRouteEnum.API_PAYMENT_CONFIRM_CC_HOLD_AND_PLACE_CC_HOLD_FOR_BIDDER
        )(data);
    }

    async placeCcHoldForBidder(data: {regionId: string; auctionId: string}): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_PAYMENT_PLACE_CC_HOLD_FOR_BIDDER)(data);
    }
}
