import {action, makeObservable, observable} from 'mobx';
import {Validator} from '../validators/Validators';
import {InputFieldStatus} from './Statutes';

type InputOnChange = (data?: string) => void;
type InputOnCharChangeAt = (data: string | undefined, position: number) => void;

export interface IModel {
    value?: string;
    chars?: (string | undefined)[];
    message?: string;
    status: InputFieldStatus;
    validate: () => void;
    isValid: boolean;
    setMessage: (value: string) => void;
    resetMessage: () => void;
}

export interface IInputOTPFieldModel extends IModel {
    onChange: InputOnChange;
    onCharChangeAt: InputOnCharChangeAt;
}

export class InputOTPFieldModel {
    status: InputFieldStatus = 'normal';
    focused = false;
    changed = false;
    message: string | undefined = undefined;
    localValue?: string | null = null;
    localChars: (string | undefined | null)[] = [];

    constructor(
        public validator: Validator<(string | undefined | null)[]> = () => undefined,
        public updateOnChange: boolean = false,
        public validateOnChange: boolean = false,
    ) {
        makeObservable(this, {
            status: observable,
            message: observable,
            localValue: observable,
            connect: action,
            setLocalValue: action,
            setMessage: action,
        });
    }

    setLocalValue(value: string | undefined | null, markChanged: boolean) {
        this.localValue = value;
        this.localChars = value ? value.split('') : [];
        this.validate(this.validateOnChange); // Need to validate the value if it was changes somewhere outside
        if (markChanged) {
            this.changed = true;
        }
    }

    setLocalCharAt(value: string | undefined | null, position: number) {
        this.localChars[position] = value;
        this.validate(this.validateOnChange); // Need to validate the value if it was changes somewhere outside
    }

    setMessage(value: string | undefined) {
        this.message = value;
    }

    reset = () => {
        this.status = 'normal';
        this.message = undefined;
    };

    shouldInvokeValidator = () => {
        return this.changed && !this.focused;
    };

    validate = (force = false) => {
        if (this.shouldInvokeValidator() || force) {
            this.setMessage(this.validator(this.localChars));
        }
    };

    connect = (
        x: string | undefined,
        onValueChanged: (value: string | undefined, currentModel: IInputOTPFieldModel) => void,
        onCharChangedAt: (value: string | undefined, position: number, currentModel: IInputOTPFieldModel) => void
    ): IInputOTPFieldModel => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const factory = this;

        factory.setLocalValue(x, false);

        const obj = class implements IInputOTPFieldModel {
            get value() {
                return factory.localValue || '';
            }

            get status() {
                return factory.status;
            }

            get message() {
                return factory.message;
            }

            get isValid() {
                return !factory.message;
            }

            validate() {
                factory.validate(true);
            }

            updateValue = (value: string | undefined, currentModel: IInputOTPFieldModel) => {
                onValueChanged(value, currentModel);
            };

            updateCharValue = (value: string | undefined, position: number, currentModel: IInputOTPFieldModel) => {
                onCharChangedAt(value, position, currentModel);
            };

            onChange: InputOnChange = (data) => {
                factory.setLocalValue(data, true);
                // We need to validate the value first and then pass the model to the change listener so that it can understand if the value is valid
                if (factory.updateOnChange) {
                    this.updateValue(data, this);
                }
            };

            onCharChangeAt: InputOnCharChangeAt = (data, position) => {
                factory.setLocalCharAt(data, position);
                this.updateCharValue(data, position, this);
            }

            setMessage(value = '') {
                factory.message = value;
            }

            resetMessage() {
                factory.message = '';
            }
        };
        return new obj();
    };
}
