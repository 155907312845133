import {Routes} from '../../../Routes';
import AuctionAwareViewModel from '@joyrideautos/ui-models/src/types/auction/AuctionAwareViewModel';

const AuctionRoutesAwareViewModel = AuctionAwareViewModel.named('AuctionRoutesAwareViewModel').views((self) => {
    return {
        get regionRoutePath() {
            const region = self.region;
            return region ? Routes.AUCTION_REGION({pathParams: {regionId: self.regionId}}) : '#';
        },
        get stateRoutePath() {
            const region = self.region;
            return region ? Routes.AUCTIONS_BY_STATE({pathParams: {stateId: region.stateId}}) : '#';
        },
        get seriesRoutePath() {
            return self.auctionSeries
                ? Routes.AUCTION_SERIES({pathParams: {regionId: self.regionId, seriesId: self.auctionSeries.id}})
                : '#';
        },
        get auctionRoutePath() {
            const auction = self.auction;
            return auction
                ? Routes.AUCTION_OCCURRENCE({
                      pathParams: {
                          regionId: auction.regionId,
                          auctionId: auction.auctionId,
                      },
                  })
                : '#';
        },
    };
});

export default AuctionRoutesAwareViewModel;
