import {Region} from '@joyrideautos/auction-core/src/types/Region';
import {BaseService} from './BaseService';
import {DataSnapshot} from '../firebase/Database';

export class RegionsService extends BaseService {
    async fetchRegions(stateId?: string): Promise<Region[]> {
        const _query = this.firebase.database.ref(`/regions`);

        if (stateId) {
            _query.orderByChild('stateId').equalTo(stateId);
        }
        const regionsSnapshot = await this.firebase.database.fetchOnceSnapshot(_query);

        const regions: Region[] = [];
        regionsSnapshot.forEach((snapshot) => {
            regions.push({...snapshot.val(), regionId: snapshot.key});
        });
        return regions;
    }

    subscribeToRegions(listener: (regions: Region[]) => void, stateId?: string): () => void {
        const _query = this.firebase.database.ref(`/regions`);
        if (stateId) {
            _query.orderByChild('stateId').equalTo(stateId);
        }

        function onSnapshot(snapshot: DataSnapshot | null) {
            const regions: Region[] = [];
            snapshot?.forEach((snapshot) => {
                regions.push({...snapshot.val(), regionId: snapshot.key});
            });
            listener(regions);
        }

        return this.firebase.database.subscribeToSnapshot(_query, onSnapshot);
    }

    fetchAllStates = async (): Promise<{[code: string]: string}> => {
        return new Promise((resolve) =>
            resolve({
                AL: 'Alabama',
                AK: 'Alaska',
                AZ: 'Arizona',
                AR: 'Arkansas',
                CA: 'California',
                CO: 'Colorado',
                CT: 'Connecticut',
                DE: 'Delaware',
                FL: 'Florida',
                GA: 'Georgia',
                HI: 'Hawaii',
                ID: 'Idaho',
                IL: 'Illinois',
                IN: 'Indiana',
                IA: 'Iowa',
                KS: 'Kansas',
                KY: 'Kentucky',
                LA: 'Louisiana',
                ME: 'Maine',
                MD: 'Maryland',
                MA: 'Massachusetts',
                MI: 'Michigan',
                MN: 'Minnesota',
                MS: 'Mississippi',
                MO: 'Missouri',
                MT: 'Montana',
                NE: 'Nebraska',
                NV: 'Nevada',
                NH: 'New Hampshire',
                NJ: 'New Jersey',
                NM: 'New Mexico',
                NY: 'New York',
                NC: 'North Carolina',
                ND: 'North Dakota',
                OH: 'Ohio',
                OK: 'Oklahoma',
                OR: 'Oregon',
                PA: 'Pennsylvania',
                RI: 'Rhode Island',
                SC: 'South Carolina',
                SD: 'South Dakota',
                TN: 'Tennessee',
                TX: 'Texas',
                UT: 'Utah',
                VT: 'Vermont',
                VA: 'Virginia',
                WA: 'Washington',
                WV: 'West Virginia',
                WI: 'Wisconsin',
                WY: 'Wyoming',
            })
        );
    };
}
