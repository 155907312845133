import {PerformanceTrace} from '../firebase/Performance';
import {BaseService} from './BaseService';

export const HANDLE_BID_SUBMIT = 'handleBidSubmit';
export const HANDLE_AUTOBID_SUBMIT = 'handleAutoBidSubmit';
export const UPDATE_AUCTION_PAUSE = 'UPDATE_AUCTION_PAUSE';
export const EXTEND_BIDDING_EXPIRATION = 'EXTEND_BIDDING_EXPIRATION';
export const VEHICLES_GRID_VIEW_MODEL = 'VehiclesGridViewModel';
export const VEHICLES_LIST_VIEW_MODEL = 'VehiclesListViewModel';

export enum TracingAttributeNameEnum {
    BID_SUBMIT_BE_HANDLER = 'BID_SUBMIT_BE_HANDLER',
    AUTOBID_SUBMIT_BE_HANDLER = 'AUTOBID_SUBMIT_BE_HANDLER',
    AUCTION_PAUSE_BE_HANDLER = 'AUCTION_PAUSE_BE_HANDLER',
    EXTEND_BIDDING_EXPIRATION_BE_HANDLER = 'EXTEND_BIDDING_EXPIRATION_BE_HANDLER',
}

export type TracingAttributes = Record<TracingAttributeNameEnum, string> | Record<string, unknown>;

export class FirebaseTraceService extends BaseService {
    private traces: Record<string, PerformanceTrace> = {};
    private metrics: Record<string, PerformanceTrace> = {};
    private substates: Record<string, number> = {};

    startTrace(id: string, attributes: TracingAttributes = {}) {
        const tranceId = this.firebase.performance.trace(id);
        if (this.traces[id] || !tranceId) {
            return;
        }
        this.traces[id] = tranceId;
        this.traces[id].start();

        Object.entries(attributes).forEach(([key, value]) => this.traces[id].putAttribute(key, value as string));
    }

    stopTrace(id: string) {
        if (!this.traces[id]) {
            return;
        }

        this.traces[id].stop();
        this.metrics[id] = this.traces[id];
        delete this.traces[id];
    }

    getTraceTime(id: string) {
        return this.metrics[id].getMetric(id);
    }

    removeMetic(id: string) {
        delete this.metrics[id];
    }

    removeAllMetics() {
        this.metrics = {};
    }

    setTraceCountDown(id: string, itemsAffected: number) {
        this.substates[id] = itemsAffected;
    }

    traceCountDown(id: string) {
        if (typeof this.substates[id] !== 'number') {
            return;
        }

        if (this.substates[id] === 0) {
            this.stopTrace(id);
            delete this.substates[id];
            return;
        }
        this.substates[id] = this.substates[id] - 1;
    }

    /**
     * You can set the custom attribute anytime ONLY BETWEEN when the trace STARTS and when the trace STOPS.
     */
    putAttribute(traceId: string, attrKey: string, attrValue: string) {
        this.traces[traceId].putAttribute(attrKey, attrValue);
    }
}
