import {isValidEnumValue} from '@joyrideautos/auction-utils/src/enumUtils';
import {AWSCredentials} from '@joyrideautos/ui-services/src/aws/types';
import {
    CallbackWithAction,
    InProgressMediaInfo,
    SWUploadEventTypeEnum,
    UploadMedia,
    UploadMediaCommandEnum,
} from '@joyrideautos/ui-services/src/services/mediaService/types';
import SWBridge from './SWBridge';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import makeLogger, {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import {createSingletonFactory} from '@joyrideautos/auction-utils/src/common';

class UploadMediaService {
    constructor(private bridge: SWBridge, private appConfig: AppConfig, private logger?: Logger) {
        this.logger?.log('UploadMediaService:constructor');
        this.bridge.start(
            (type) => isValidEnumValue(UploadMediaCommandEnum, type),
            (type) => isValidEnumValue(SWUploadEventTypeEnum, type)
        );
    }

    get isSWSupported() {
        return this.bridge.isSWSupported;
    }

    checkSWisReady() {
        return this.bridge.checkSWisReady();
    }

    uploadMedia(uid: string, data: UploadMedia, credentials: AWSCredentials) {
        this.logger?.log('uploadMedia', {uid, data});
        const {itemKey, sellerId, params} = data;
        return this.bridge.sendMessageSW({
            type: UploadMediaCommandEnum.UPLOAD_MEDIA,
            data: {
                uid,
                credentials,
                itemKey,
                params: params.map((param) => ({...param, itemKey, sellerId})),
                appConfig: this.appConfig,
            },
        });
    }

    checkUpload(uid: string, credentials: AWSCredentials) {
        return this.bridge.sendMessageSW({
            type: UploadMediaCommandEnum.CHECK_UPLOAD_MEDIA,
            data: {credentials, uid, appConfig: this.appConfig},
        });
    }

    getUnloadedMedia(uid: string, credentials: AWSCredentials): Promise<{[itemKey: string]: InProgressMediaInfo[]}> {
        const type = UploadMediaCommandEnum.GET_UNLOADED_MEDIA;
        return this.bridge.sendMessageSW({type, data: {credentials, uid, appConfig: this.appConfig}});
    }

    cancelUpload(uid: string, itemKey: string, index: number) {
        const type = UploadMediaCommandEnum.CANCEL_UPLOAD_MEDIA;
        return this.bridge.sendMessageSW({type, data: {uid, itemKey, index, appConfig: this.appConfig}});
    }

    subscribeToEvents(subscriber: CallbackWithAction<{type: string; [key: string]: any}>) {
        return this.bridge.subscribeToEvents<typeof SWUploadEventTypeEnum, {type: string; [key: string]: any}>(
            SWUploadEventTypeEnum,
            subscriber
        );
    }
}

// TODO: (Future) inject logger
export const createUploadMediaService = createSingletonFactory(
    ({appConfig}: {appConfig: AppConfig}) =>
        new UploadMediaService(new SWBridge(), appConfig, makeLogger()('UploadMediaService', LoggerStatusEnum.DISABLED))
);
