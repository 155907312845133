import {flow, Instance, types} from 'mobx-state-tree';
import {LoadingStatus} from '../../utils/LoadingStatus';
import {Receipt} from '../../types/Receipt';
import BaseViewModel from '../../BaseViewModel';

export const PDFModalViewModel = BaseViewModel.named('PDFModalViewModel')
    .props({
        isModalVisible: false,
        modalTitle: types.maybe(types.string),
        documentDownloadUrl: types.maybe(types.string),
    })
    .volatile(() => ({
        documentLoadingStatus: new LoadingStatus(),
    }))
    .actions((self) => ({
        handleDocument: flow(function* (title: string, path: string) {
            self.isModalVisible = true;
            self.modalTitle = title;
            try {
                self.documentLoadingStatus.setInProgress();
                self.documentDownloadUrl = yield self.rootStore.storageService.getDownloadUrl(path);
                self.logger.log({documentDownloadUrl: self.documentDownloadUrl});
                self.documentLoadingStatus.setReady();
            } catch (e) {
                console.log(e);
            }
        }),
        handleLocalDocument(title: string, path: string) {
            self.isModalVisible = true;
            self.modalTitle = title;
            self.documentDownloadUrl = path;
            self.documentLoadingStatus.setReady();
        },
        handleReceipt: flow(function* (fetchReceipt: () => Promise<Receipt>) {
            self.isModalVisible = true;
            const receipt = yield fetchReceipt();
            self.modalTitle = receipt.fileName;
            self.documentLoadingStatus.setInProgress();
            self.documentDownloadUrl = yield self.rootStore.storageService.getDownloadUrl(receipt.downloadUrl);
            self.documentLoadingStatus.setReady();
        }),
        showModal() {
            self.isModalVisible = true;
        },
        hideModal() {
            self.isModalVisible = false;
        },
        downloadDocument() {
            self.documentDownloadUrl && self.rootStore.storageService.downloadFile('', self.documentDownloadUrl);
        },
    }));

export interface PDFModalViewModelType extends Instance<typeof PDFModalViewModel> {}
