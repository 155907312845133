import {getEnv, getRoot, Instance, types} from 'mobx-state-tree';
import {BidReference} from '../Bid';
import {AuctionType} from '../auction/Auction';
import {AuctionReference, AuctionSeriesReferenceByKey} from '../auction/Refs';
import {PersistedItemReference} from '../item/Refs';
import {SellerReference} from '../Seller';
import {Notification, NotificationKind, NotificationKindEnum} from './Notification';
import {ItemPath} from '@joyrideautos/auction-core/src/types/Item';
import {allBidRejectionReasons, BidPath} from '@joyrideautos/auction-core/src/types/Bid';
import {allNotificationTypes, NotificationEnum} from '@joyrideautos/auction-core/src/types/events/notifications';

export const EventPayload = types.model('EventPayload', {
    type: types.string,
    userId: types.maybe(types.string),
});

export interface EventPayloadType extends Instance<typeof EventPayload> {}

export const AuctionEventPayload = EventPayload.named('AuctionEventPayload').props({
    regionId: types.maybe(types.string),
    auctionId: types.maybe(AuctionReference),
});

export interface AuctionEventPayloadType extends Instance<typeof AuctionEventPayload> {}

export const UploadEventPayload = EventPayload.named('UploadEventPayload').props({
    item: types.maybe(PersistedItemReference()),
    seller: types.maybe(SellerReference()),
});

export interface UploadEventPayloadType extends Instance<typeof UploadEventPayload> {}

export const GDriveEventPayload = EventPayload.named('GDriveEventPayload')
    .props({
        regionId: types.maybe(types.string),
        timestamp: types.maybe(types.number),
        auctionId: types.maybe(types.string),
        isFolderFlow: types.maybe(types.boolean),
        toReplaceExisting: types.maybe(types.boolean),
    })
    .views((self) => {
        return {
            get rootStore() {
                return (getEnv(self).rootStore || getRoot(self)) as any;
            },
            get auctionsStore() {
                return this.rootStore?.auctionsStore;
            },
            get auction(): AuctionType | undefined {
                return self.regionId && this.auctionsStore?.findAuction(self.regionId, self.auctionId);
            },
            get auctionTitle() {
                return this.auction?.title;
            },
        };
    });

export interface GDriveEventPayloadType extends Instance<typeof GDriveEventPayload> {}

export const BidLossEventPayload = EventPayload.named('BidLossEventPayload')
    .props({
        regionId: types.string,
        auctionId: types.string,
        prevBid: types.maybe(BidReference),
        newBid: types.maybe(BidReference),
        // TODO: (Future) remove `item` and make `itemId` as required field
        itemId: types.maybe(types.string),
        item: types.maybe(types.string),
    })
    .views((self) => {
        return {
            get rootStore() {
                return (getEnv(self).rootStore || getRoot(self)) as any;
            },
            get itemPath(): ItemPath {
                return {regionId: self.regionId, auctionId: self.auctionId, itemId: self.item || self.itemId!};
            },
            get itemsStore() {
                return this.rootStore.itemsStoreFactory.getItemsStoreForAuction({
                    regionId: self.regionId,
                    auctionId: self.auctionId,
                });
            },
            get itemDataAsync() {
                return this.itemsStore.fetchItemAsync(this.itemPath);
            },
        };
    });

export interface BidLossEventPayloadType extends Instance<typeof BidLossEventPayload> {}

export const ReserveNotMetEventPayload = EventPayload.named('ReserveNotMetEventPayload')
    .props({
        regionId: types.string,
        auctionId: types.string,
        itemId: types.string,
        bidId: types.string,
        year: types.number,
        make: types.string,
        model: types.string,
    })
    .views((self) => {
        return {
            get bidPath(): BidPath {
                return {regionId: self.regionId, auctionId: self.auctionId, itemId: self.itemId, bidId: self.bidId};
            },
        };
    });

export interface ReserveNotMetEventPayloadType extends Instance<typeof ReserveNotMetEventPayload> {}

export const BidHoldFailedEventPayload = EventPayload.named('BidHoldFailedEventPayload').props({
    regionId: types.string,
    auctionId: types.string,
    auctionSeriesName: types.string,
});

export interface BidHoldFailedEventPayloadType extends Instance<typeof BidHoldFailedEventPayload> {}

export const BidHoldSuccessfulEventPayload = EventPayload.named('BidHoldFailedEventPayload').props({
    regionId: types.string,
    auctionId: types.string,
    auctionSeriesName: types.string,
});

export interface BidHoldSuccessfulEventPayloadType extends Instance<typeof BidHoldSuccessfulEventPayload> {}

export const ItemAwareEventPayload = EventPayload.named('ItemWonEventPayload')
    .props({
        regionId: types.string,
        auctionId: types.string,
        itemId: types.string,
    })
    .views((self) => {
        return {
            get rootStore() {
                return (getEnv(self).rootStore || getRoot(self)) as any;
            },
            get auction(): AuctionType | undefined {
                return self.regionId && this.rootStore.auctionsStore.findAuction(self.regionId, self.auctionId);
            },
            get auctionTitle() {
                return this.auction?.title;
            },
            get itemsStore() {
                return this.rootStore.itemsStoreFactory.getItemsStoreForPastAuction({
                    regionId: self.regionId,
                    auctionId: self.auctionId,
                });
            },
            get itemPath(): ItemPath {
                const {regionId, auctionId, itemId} = self;
                return {
                    regionId,
                    auctionId,
                    itemId,
                };
            },
            get item() {
                const {regionId, auctionId, itemId} = self;
                return this.itemsStore.fetchItemAsync({
                    itemId,
                    regionId,
                    auctionId,
                });
            },
        };
    });

export interface ItemAwareEventPayloadType extends Instance<typeof ItemAwareEventPayload> {}

export const UserEventPayload = EventPayload.named('UserEventPayload ')
    .props({
        auctionSeries: types.maybe(AuctionSeriesReferenceByKey),
        sellerId: types.maybe(types.string),
        sellerName: types.maybe(types.string),
        reason: types.maybe(types.string),
    })
    .views((self) => ({
        get bannedFor() {
            return self.auctionSeries && self.auctionSeries.title ? self.auctionSeries.title : self.sellerName;
        },
    }));

export interface UserEventPayloadType extends Instance<typeof UserEventPayload> {}

export const UserBannedSuccessPayload = EventPayload.named('UserBannedSuccessPayload').props({
    userEmail: types.maybe(types.string),
    bannedUserId: types.string,
});

export const UserBannedFailedPayload = EventPayload.named('UserBannedSuccessPayload').props({
    bannedUserId: types.string,
});

export const PaymentMethodBannedEventPayload = EventPayload.named('PaymentMethodBannedEventPayload').props({
    paymentMethodName: types.maybe(types.string),
    last4: types.maybe(types.string),
});

export const BidRejectEventPayload = EventPayload.named('PaymentMethodBannedEventPayload').props({
    reason: types.enumeration(allBidRejectionReasons),
});

const eventDtoPayloadDispatcher = {
    dispatcher: (snapshot: any) => {
        const payloads: {[eventType in NotificationEnum]?: any} = {
            [NotificationEnum.RESERVE_NOT_MET]: ReserveNotMetEventPayload,
            [NotificationEnum.BID_LOSS]: BidLossEventPayload,
            [NotificationEnum.ITEM_WON]: ItemAwareEventPayload,
            [NotificationEnum.UPLOAD_SUCCESS]: UploadEventPayload,
            [NotificationEnum.UPLOAD_FAILED]: UploadEventPayload,
            [NotificationEnum.G_DRIVE_START_SUCCESS]: GDriveEventPayload,
            [NotificationEnum.G_DRIVE_START_ERROR]: GDriveEventPayload,
            [NotificationEnum.G_DRIVE_FINISH_SUCCESS]: GDriveEventPayload,
            [NotificationEnum.G_DRIVE_FINISH_ERROR]: GDriveEventPayload,
            [NotificationEnum.ITEM_PAYMENT_FAILED]: ItemAwareEventPayload,
            [NotificationEnum.ITEM_PAYMENT_SUCCESSFUL]: ItemAwareEventPayload,
            [NotificationEnum.USER_BANNED]: UserEventPayload,
            [NotificationEnum.USER_BANNED_GLOBALLY]: UserEventPayload,
            [NotificationEnum.USER_BAN_SUCCESS]: UserBannedSuccessPayload,
            [NotificationEnum.USER_BAN_FAIL]: UserBannedFailedPayload,
            [NotificationEnum.PAYMENT_METHOD_BANNED]: PaymentMethodBannedEventPayload,
            [NotificationEnum.BID_REJECT]: BidRejectEventPayload,
            [NotificationEnum.AUCTION_START]: AuctionEventPayload,
            [NotificationEnum.AUCTION_ENDED]: AuctionEventPayload,
            [NotificationEnum.BID_HOLD_FAILED_FOR_BUYER]: BidHoldFailedEventPayload,
            [NotificationEnum.BID_HOLD_SUCCESSFUL_FOR_BUYER]: BidHoldSuccessfulEventPayload,
            [NotificationEnum.UNSCHEDULE_VEHICLE]: ItemAwareEventPayload,
        };
        return payloads[snapshot.type as NotificationEnum] || EventPayload;
    },
};

const EventDtoParams = types.union(
    eventDtoPayloadDispatcher,
    BidLossEventPayload,
    ReserveNotMetEventPayload,
    ItemAwareEventPayload,
    UploadEventPayload,
    GDriveEventPayload,
    UserEventPayload,
    EventPayload,
    BidHoldFailedEventPayload,
    BidHoldSuccessfulEventPayload,
    UserBannedSuccessPayload,
    UserBannedFailedPayload,
    PaymentMethodBannedEventPayload,
    BidRejectEventPayload
);

export type EventDtoParamsType = Instance<typeof EventDtoParams>;

export const EventDto = Notification.named('EventDto').props({
    kind: types.optional(NotificationKind, NotificationKindEnum.PERSISTED),
    params: types.maybe(EventDtoParams),
    state: types.number,
    type: types.enumeration(allNotificationTypes),
    timestamp: types.maybe(types.number),
});
