import {flow, getRoot, Instance, types} from 'mobx-state-tree';
import {Document} from './Document';
import {Region as RegionDto} from '@joyrideautos/auction-core/src/types/Region';
import {LoadingStatus} from '../utils/LoadingStatus';
import type {StorageService} from '@joyrideautos/ui-services/src/services/StorageService';
import {UIServicesAwareViewModelType} from '../common/UIServicesAwareViewModel';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';

export const Location = types.model('Location', {
    lat: types.number,
    lon: types.number,
});

export interface LocationType extends Instance<typeof Location> {}

export const getRegionHeadlineUrl =
    (services: {storageService: StorageService}) =>
    async (regionId: string): Promise<string> => {
        return services.storageService.getDownloadUrl(`${regionId}/region_headline/original.jpg`);
    };

export const Region = types
    .model('Region', {
        regionId: types.identifier,
        displayName: types.string,
        location: types.maybe(Location),
        zipCodes: types.map(types.boolean),
        timezone: types.maybe(types.string),
        state: types.string,
        stateId: types.string,
        policy_docs: types.maybe(types.array(Document)),
        payment_info_docs: types.maybe(types.array(Document)),
        hidden: types.maybe(types.boolean),
    })
    .volatile(() => ({
        status: new LoadingStatus(),
        _imageUrl: null as string | null,
    }))
    .views((self) => ({
        get rootStore() {
            return getRoot(self) as UIServicesAwareViewModelType;
        },
    }))
    .actions((self) => ({
        fetchImageUrl: flow(function* () {
            try {
                self._imageUrl = yield getRegionHeadlineUrl({storageService: self.rootStore.storageService})(
                    self.regionId
                );
            } catch (err: any) {
                console.log(err.message);
            }
        }),
    }))
    .views((self) => {
        return {
            get imageUrl() {
                if (!self._imageUrl) {
                    setTimeout(() => self.fetchImageUrl());
                }
                return self._imageUrl;
            },
            get renderedDisplayName() {
                const hiddenText = self.hidden ? '(hidden)' : '';
                return `${self.displayName} ${hiddenText}`;
            },
            get renderedRegionId() {
                const hiddenText = self.hidden ? '(hidden)' : '';
                return `${self.regionId} ${hiddenText}`;
            },
        };
    });

export interface RegionType extends Instance<typeof Region> {}

export function fromDto(region: RegionDto): RegionType {
    return cast(region);
}
