import {
    CompletedPart,
    CompleteMultipartUploadCommand,
    CreateMultipartUploadCommand,
    AbortMultipartUploadCommand,
    PutObjectCommand,
    PutObjectCommandInput,
    S3Client,
    UploadPartCommand,
    UploadPartCommandOutput,
} from '@aws-sdk/client-s3';
import {waitOnline} from './connection';
import {promiseWithTimeout} from '@joyrideautos/auction-utils/src/PromiseUtils';
import {ONE_MIN} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {Progress} from '@joyrideautos/ui-services/src/aws/types';
import {Buffer} from 'buffer/';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';

type Options = {
    queueSize: number; // TODO: (Future) implement concurrent upload
    partSize: number;
};

// TODO: (Future) implement progress
// upload.on('httpUploadProgress', (progress) => {
//     logger.log('progress', progress);
// });

export class Upload {
    private uploadId?: string;
    private uploadedParts: CompletedPart[] = [];
    private bytesUploadedSoFar = 0;
    private totalSize = 0;

    constructor(private client: S3Client, private options: Options, private logger?: Logger) {}

    async start(
        params: PutObjectCommandInput,
        onProgress: (progress: Progress) => void,
        cancelable: (onCancel: () => Promise<void>) => void
    ) {
        // if data is less than part size -> send it via put
        // else do multipart upload

        // send initiate multipart upload
        // start upload parts (can be send concurrently)
        // 	if success -> save ETag
        // 	if any part failed to upload retry N times
        // 		if after N times the failed part is still failing -> send stop multipart upload
        // send complete when all parts were uploaded

        this.bytesUploadedSoFar = 0;
        this.totalSize = (params.Body as Uint8Array).length;
        for await (const chunk of getChunks(Buffer.from(params.Body as Uint8Array), this.options.partSize)) {
            this.logger?.log('partNumber', chunk.partNumber);
            this.logger?.log('lastPart', chunk.lastPart);
            if (chunk.partNumber === 1 && chunk.lastPart) {
                await promiseWithTimeout(
                    3 * ONE_MIN,
                    () => this.uploadUsingPutWithRetry(params, 3, onProgress),
                    'internet connection timeout'
                );
                this.logger?.log('uploaded via put');
                return;
            }

            await this.createMultipartUploadIfNeeded(params);
            cancelable(async () => {
                try {
                    await this.cancelMultipartUpload({...params, Body: chunk.data});
                } catch (e: any) {
                    this.logger?.log('error of cancel multipart upload', e.message);
                }
            });

            try {
                await this.uploadChunk({...params, Body: chunk.data}, chunk.partNumber, onProgress);
            } catch (e: any) {
                this.logger?.log(`error of uploading part: ${chunk.partNumber}`, e.message);
                await this.retryUploadChunk({...params, Body: chunk.data}, chunk.partNumber, onProgress);
            }
        }

        if (this.uploadId) {
            await this.completeMultipartUpload(params);
        }
    }

    async uploadUsingPut(params: PutObjectCommandInput) {
        this.logger?.log('upload using put');
        await this.client.send(new PutObjectCommand(params), {});
    }

    async uploadUsingPutWithRetry(
        params: PutObjectCommandInput,
        maxRetryCount: number,
        onProgress: (progress: Progress) => void
    ) {
        this.logger?.log(`upload using put with retry ${maxRetryCount} times`);
        let retryCount = 1;
        const uploadUsingPut = async (params: PutObjectCommandInput): Promise<void> => {
            try {
                await this.client.send(new PutObjectCommand(params));
                // TODO: PutObjectCommand doesn't support progress
                onProgress({loaded: this.totalSize, total: this.totalSize});
            } catch (e: any) {
                this.logger?.log(`error of uploading part: ${retryCount}`, e.message);
                if (retryCount++ < maxRetryCount) {
                    this.logger?.log('wait for internet connection');
                    await promiseWithTimeout(ONE_MIN, waitOnline, 'internet connection timeout');
                    this.logger?.log(`retry uploading part. ${retryCount} time`);
                    await uploadUsingPut(params);
                } else {
                    throw e;
                }
            }
        };
        await uploadUsingPut(params);
    }

    async uploadChunk(params: PutObjectCommandInput, partNumber: number, onProgress: (progress: Progress) => void) {
        this.logger?.log('upload by chunks. chunk', partNumber);
        const result: UploadPartCommandOutput &
            Partial<{
                ChecksumCRC32: string;
                ChecksumCRC32C: string;
                ChecksumSHA1: string;
                ChecksumSHA256: string;
            }> = await this.client.send(
            new UploadPartCommand({
                ...params,
                UploadId: this.uploadId,
                Body: params.Body,
                PartNumber: partNumber,
            })
        );

        this.uploadedParts.push({
            PartNumber: partNumber,
            ETag: result.ETag,
            ...(result.ChecksumCRC32 && {ChecksumCRC32: result.ChecksumCRC32}),
            ...(result.ChecksumCRC32C && {ChecksumCRC32C: result.ChecksumCRC32C}),
            ...(result.ChecksumSHA1 && {ChecksumSHA1: result.ChecksumSHA1}),
            ...(result.ChecksumSHA256 && {ChecksumSHA256: result.ChecksumSHA256}),
        });

        this.bytesUploadedSoFar += (params.Body as Uint8Array).length;
        onProgress({loaded: this.bytesUploadedSoFar, total: this.totalSize});
    }

    async retryUploadChunk(
        params: PutObjectCommandInput,
        partNumber: number,
        onProgress: (progress: Progress) => void
    ) {
        try {
            await this.uploadChunk(params, partNumber, onProgress);
        } catch (e: any) {
            this.logger?.log(`error of retry uploading part: ${partNumber}`, e.message);
            await this.cancelMultipartUpload(params);
            throw new Error('General error');
        }
    }

    async createMultipartUploadIfNeeded(params: PutObjectCommandInput) {
        if (!this.uploadId) {
            const input = {...params, Body: undefined};
            const result = await this.client.send(new CreateMultipartUploadCommand(input));
            this.uploadId = result.UploadId;
        }
    }

    async completeMultipartUpload(params: PutObjectCommandInput) {
        this.uploadedParts.sort((a, b) => a.PartNumber! - b.PartNumber!);

        const input = {
            ...params,
            Body: undefined,
            UploadId: this.uploadId,
            MultipartUpload: {
                Parts: this.uploadedParts,
            },
        };
        const result = await this.client.send(new CompleteMultipartUploadCommand(input));
        this.uploadId = undefined;
        this.uploadedParts = [];
        return result;
    }

    async cancelMultipartUpload(params: PutObjectCommandInput) {
        const input = {...params, Body: undefined, UploadId: this.uploadId};
        await this.client.send(new AbortMultipartUploadCommand(input));
    }
}

interface RawDataPart {
    partNumber: number;
    data: Uint8Array;
    lastPart?: boolean;
}

async function* getChunks(data: Buffer, partSize: number): AsyncGenerator<RawDataPart, void, undefined> {
    let partNumber = 1;
    let startByte = 0;
    let endByte = partSize;

    while (endByte < data.byteLength) {
        yield {
            partNumber,
            data: data.slice(startByte, endByte),
        };
        partNumber += 1;
        startByte = endByte;
        endByte = startByte + partSize;
    }

    yield {
        partNumber,
        data: data.slice(startByte),
        lastPart: true,
    };
}
