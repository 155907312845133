import {castToSnapshot, flow, getParent, getRoot, Instance, SnapshotOut, types} from 'mobx-state-tree';
import {ItemPath} from '@joyrideautos/auction-core/src/types/Item';
import type {ItemType} from '../types/item/Item';
import {
    FavoriteAuction,
    FavoriteAuctionSeries,
    FavoriteAuctionSeriesType,
    FavoriteAuctionType,
    FavoriteItem,
    FavoriteItemType,
    FavoriteRegion,
    FavoriteRegionType,
    toFavoriteItemDto,
    mapFromFavoriteItemDto,
} from '../types/Favorite';
import {RegionType} from '../types/Region';
import {AuctionType} from '../types/auction/Auction';
import {UserInfo} from '@joyrideautos/auction-core/src/types/User';
import {AuctionSeriesType} from '../types/auction/AuctionSeries';
import {LoadingStatus, LoadingStatusEnum} from '../utils/LoadingStatus';
import RootStoreType from './Shared';
import {FavoriteItem as FavoriteItemDto} from '@joyrideautos/auction-core/src/types/Favorite';
import {HasItemsStoreFactoryType} from './types';
import BaseStore from './BaseStore';

export const FavoritesStore = BaseStore.named('FavoriteItemsStore')
    .props({
        regions: types.map(types.map(FavoriteRegion)),
        auctionSeries: types.map(types.map(FavoriteAuctionSeries)),
        auctions: types.map(types.map(FavoriteAuction)),
        items: types.map(types.map(FavoriteItem)),
    })
    .volatile(() => ({
        itemsLoadingStatus: new LoadingStatus(LoadingStatusEnum.NEW, 'favorites items'),
        auctionsLoadingStatus: new LoadingStatus(LoadingStatusEnum.NEW, 'favorites auctions'),
        auctionsSeriesLoadingStatus: new LoadingStatus(LoadingStatusEnum.NEW, 'favorites auction Series'),
        regionsLoadingStatus: new LoadingStatus(LoadingStatusEnum.NEW, 'favorites regions'),
        disposers: {} as {[key: string]: () => void},
    }))
    .views((self) => {
        return {
            getCurrentUserInfo() {
                const {authUserStore} = getRoot(self) as RootStoreType;
                const {userInfo} = authUserStore;
                return userInfo;
            },
            getCurrentUserId() {
                const userInfo = this.getCurrentUserInfo();
                return userInfo && !userInfo.isAnonymous ? userInfo.uid : null;
            },
            canToggleFavorite() {
                return this.getCurrentUserId() != null;
            },
            get auctionSeriesStore() {
                return (getParent(self) as RootStoreType).auctionSeriesStore;
            },
            get auctionsStore() {
                return (getParent(self) as RootStoreType).auctionsStore;
            },
            get itemsStoreFactory() {
                return (getParent(self) as HasItemsStoreFactoryType).itemsStoreFactory;
            },
        };
    })
    .views((self) => {
        return {
            getFavoriteItems(): FavoriteItemType[] {
                const uid = self.getCurrentUserId();
                const itemsMap = uid && self.items.get(uid);
                if (itemsMap) {
                    return [...itemsMap.values()].sort((a: FavoriteItemType, b: FavoriteItemType) =>
                        a.sortableKey.localeCompare(b.sortableKey)
                    );
                } else {
                    return [];
                }
            },
            getItemPaths(): ItemPath[] {
                return this.getFavoriteItems().map((f) => {
                    return {
                        regionId: f.regionId,
                        auctionId: f.auctionId,
                        itemId: f.itemKey,
                    } as ItemPath;
                });
            },
            findFavoriteItem(itemKey: string) {
                const uid = self.getCurrentUserId();
                const favoriteItems = uid && self.items.get(uid);
                return favoriteItems && favoriteItems.get(itemKey);
            },
            isFavoriteItem(itemKey: string) {
                return Boolean(this.findFavoriteItem(itemKey));
            },
        };
    })
    .views((self) => {
        return {
            getFavoriteRegions(): FavoriteRegionType[] {
                const uid = self.getCurrentUserId();
                const regionsMap = uid && self.regions.get(uid);
                if (regionsMap) {
                    return [...regionsMap.values()].sort((a: FavoriteRegionType, b: FavoriteRegionType) =>
                        a.sortableKey.localeCompare(b.sortableKey)
                    );
                } else {
                    return [];
                }
            },
            isFavoriteRegion(regionId: string) {
                const uid = self.getCurrentUserId();
                const favoriteRegions = uid && self.regions.get(uid);
                return Boolean(favoriteRegions && favoriteRegions.get(regionId));
            },
        };
    })
    .views((self) => {
        return {
            getFavoriteAuctionSeries(): FavoriteAuctionSeriesType[] {
                const uid = self.getCurrentUserId();
                const auctionSeriesMap = uid && self.auctionSeries.get(uid);
                if (auctionSeriesMap) {
                    return [...auctionSeriesMap.values()].sort(
                        (a: FavoriteAuctionSeriesType, b: FavoriteAuctionSeriesType) =>
                            a.sortableKey.localeCompare(b.sortableKey)
                    );
                } else {
                    return [];
                }
            },
            isFavoriteAuctionSeries(auctionSeriesId: string) {
                const uid = self.getCurrentUserId();
                const favoriteAuctionSeries = uid && self.auctionSeries.get(uid);
                return Boolean(favoriteAuctionSeries && favoriteAuctionSeries.get(auctionSeriesId));
            },
        };
    })
    .views((self) => {
        return {
            getFavoriteAuctions(): FavoriteAuctionType[] {
                const uid = self.getCurrentUserId();
                const auctionsMap = uid && self.auctions.get(uid);
                if (auctionsMap) {
                    return [...auctionsMap.values()].sort((a: FavoriteAuctionType, b: FavoriteAuctionType) =>
                        a.sortableKey.localeCompare(b.sortableKey)
                    );
                } else {
                    return [];
                }
            },
            isFavoriteAuction(auctionId: string) {
                const uid = self.getCurrentUserId();
                const favoriteAuctions = uid && self.auctions.get(uid);
                return Boolean(favoriteAuctions && favoriteAuctions.get(auctionId));
            },
        };
    })
    .actions((self) => {
        return {
            setRegions(userId: string, favorites: {[regionId: string]: FavoriteRegionType}) {
                self.regions.set(userId, favorites);
            },
            setAuctionSeries(userId: string, favorites: {[auctionSeriesId: string]: FavoriteAuctionSeriesType}) {
                self.auctionSeries.set(userId, favorites);
            },
            setAuctions(userId: string, favorites: {[auctionId: string]: FavoriteAuctionType}) {
                self.auctions.set(userId, favorites);
            },
            setItems(userId: string, favorites: {[itemKey: string]: FavoriteItemType}) {
                self.items.set(userId, castToSnapshot(favorites));
            },
            toggleFavoriteRegion(region: RegionType) {
                const uid = self.getCurrentUserId();
                const regionId = region.regionId;

                if (uid) {
                    const sortableKey = `${region.state}:${regionId}`;

                    const favorite = {
                        regionId,
                        sortableKey,
                    } as FavoriteRegionType;

                    // optimistic update
                    if (self.isFavoriteRegion(regionId)) {
                        self.regions.get(uid)!.delete(regionId);
                    } else {
                        const regionsByUser = self.regions.get(uid) || self.regions.set(uid, {}).get(uid)!;
                        regionsByUser.set(regionId, favorite);
                    }

                    return self.favoritesService.toggleFavoriteRegion(uid, favorite);
                } else {
                    return Promise.resolve();
                }
            },
            toggleFavoriteAuctionSeries(auctionSeries: AuctionSeriesType) {
                const uid = self.getCurrentUserId();

                if (uid) {
                    const auctionSeriesId = auctionSeries.key;
                    const sortableKey = `${auctionSeries.regionId}:${auctionSeriesId}`;

                    const favorite = {
                        regionId: auctionSeries.regionId,
                        auctionSeriesId: auctionSeriesId,
                        sortableKey,
                    } as FavoriteAuctionSeriesType;

                    // optimistic update
                    if (self.isFavoriteAuctionSeries(auctionSeriesId)) {
                        self.auctionSeries.get(uid)!.delete(auctionSeriesId);
                    } else {
                        const auctionSeriesByUser =
                            self.auctionSeries.get(uid) || self.auctionSeries.set(uid, {}).get(uid)!;
                        auctionSeriesByUser.set(auctionSeriesId, favorite);
                    }

                    return self.favoritesService.toggleFavoriteAuctionSeries(uid, favorite);
                } else {
                    return Promise.resolve();
                }
            },
            toggleFavoriteAuction(auction: AuctionType) {
                const uid = self.getCurrentUserId();

                if (uid) {
                    const auctionId = auction.auctionId;
                    const auctionStart = auction.settings.startByAuctionTypeAsDate;
                    const sortableKey = (auctionStart && String(new Date(auctionStart).getTime())) || '';

                    const favorite: FavoriteAuctionType = {
                        regionId: auction.regionId,
                        auctionId: auctionId,
                        sortableKey,
                    };

                    // optimistic update
                    if (self.isFavoriteAuction(auctionId)) {
                        self.auctions.get(uid)!.delete(auctionId);
                    } else {
                        const auctionsByUser = self.auctions.get(uid) || self.auctions.set(uid, {}).get(uid)!;
                        auctionsByUser.set(auctionId, favorite);
                    }

                    return self.favoritesService.toggleFavoriteAuction(uid, favorite);
                } else {
                    return Promise.resolve();
                }
            },
            async toggleFavoriteItem(item: ItemType): Promise<boolean> {
                const {auctionsStore, sellerRoleStore} = getRoot(self) as RootStoreType;
                const auction = auctionsStore.findAuction(item.regionId!, item.auctionId!);
                const uid = self.getCurrentUserId();
                const isSeller = sellerRoleStore.hasSellerRoleForItem(item);

                if (uid && auction) {
                    const auctionStart = auction.settings.startByAuctionTypeAsDate;
                    const sortableKey = (auctionStart && `${new Date(auctionStart).getTime()}:${item.idx || 0}`) || '';

                    const favorite: SnapshotOut<FavoriteItemType> = {
                        itemKey: String(item.itemId),
                        regionId: item.regionId,
                        isSeller,
                        auctionId: item.auctionId,
                        sortableKey: sortableKey,
                    };

                    // optimistic update
                    if (self.isFavoriteItem(item.key)) {
                        self.items.get(uid)!.delete(item.key);
                    } else {
                        const itemsByUser = self.items.get(uid) || self.items.set(uid, {}).get(uid)!;
                        itemsByUser.set(item.key, favorite);
                    }

                    return await self.favoritesService.toggleFavoriteItem(uid, toFavoriteItemDto(favorite));
                }
                return false;
            },
        };
    })
    .actions((self) => {
        const generateKey = (userInfo: UserInfo, suffix: string) => {
            return `${userInfo.uid}_${suffix}`;
        };
        return {
            beforeDestroy: function () {
                this.unsubscribe();
                const uid = self.getCurrentUserId();
                if (uid) {
                    self.itemsStoreFactory.destroyFavoriteItemsStore(uid);
                }
            },
            unsubscribe() {
                Object.values(self.disposers).forEach((d) => d());
                self.disposers = {};
            },
            // eslint-disable-next-line require-yield
            load: flow(function* (): Generator<Promise<any>, any, any> {
                const {authUserStore} = getRoot(self) as RootStoreType;
                const {userInfo} = authUserStore;

                if (userInfo && !userInfo.isAnonymous) {
                    const disposerItemsKey = generateKey(userInfo, 'items');
                    if (!self.disposers[disposerItemsKey]) {
                        self.itemsLoadingStatus.setInProgress();
                        self.disposers[disposerItemsKey] = self.favoritesService.subscribeToFavoriteItems(
                            userInfo.uid,
                            async (favorites: {[itemKey: string]: FavoriteItemDto}) => {
                                self.setItems(userInfo.uid, mapFromFavoriteItemDto(favorites));
                                await self.itemsStoreFactory
                                    .getFavoriteItemsStore(userInfo.uid)
                                    .fetchItems(self.getItemPaths());
                                self.itemsLoadingStatus.setReady();
                            }
                        );
                    }

                    const disposerRegionsKey = generateKey(userInfo, 'regions');
                    if (!self.disposers[disposerRegionsKey]) {
                        self.regionsLoadingStatus.setInProgress();
                        self.disposers[disposerRegionsKey] = self.favoritesService.subscribeToFavoriteRegions(
                            userInfo.uid,
                            (favorites: {[regionId: string]: FavoriteRegionType}) => {
                                self.setRegions(userInfo.uid, favorites);
                                self.regionsLoadingStatus.setReady();
                            }
                        );
                    }

                    const disposerAuctionSeriesKey = generateKey(userInfo, 'auctionSeries');
                    if (!self.disposers[disposerAuctionSeriesKey]) {
                        self.auctionsSeriesLoadingStatus.setInProgress();
                        self.disposers[disposerAuctionSeriesKey] =
                            self.favoritesService.subscribeToFavoriteAuctionSeries(
                                userInfo.uid,
                                async (favorites: {[auctionSeriesId: string]: FavoriteAuctionSeriesType}) => {
                                    self.setAuctionSeries(userInfo.uid, favorites);
                                    await self.auctionSeriesStore.fetchMultipleAuctionSeries(Object.values(favorites));
                                    self.auctionsSeriesLoadingStatus.setReady();
                                }
                            );
                    }

                    const disposerAuctionsKey = generateKey(userInfo, 'auctions');
                    if (!self.disposers[disposerAuctionsKey]) {
                        self.auctionsLoadingStatus.setInProgress();
                        self.disposers[disposerAuctionsKey] = self.favoritesService.subscribeToFavoriteAuctions(
                            userInfo.uid,
                            async (favorites: {[auctionSeriesId: string]: FavoriteAuctionType}) => {
                                self.setAuctions(userInfo.uid, favorites);
                                await self.auctionsStore.fetchMultipleAuctions(Object.values(favorites));
                                self.auctionsLoadingStatus.setReady();
                            }
                        );
                    }
                }
            }),
        };
    });

export interface FavoriteItemsStoreType extends Instance<typeof FavoritesStore> {}

export interface HasFavoritesStore {
    favoritesStore: FavoriteItemsStoreType;
}
