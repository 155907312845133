import {v4 as uuidv4} from 'uuid';

enum ExecutionStatus {
    NEW,
    SCHEDULED,
    IN_PROGRESS,
    DONE,
    FAILURE,
}

interface Job {
    id?: string;
    startTime: number | Date;
    executor(): void;
}

interface JobInternal extends Job {
    id: string;
    startTime: number;
    status: ExecutionStatus;
    disposer?: any;
}

export class Scheduler {
    private jobs: Map<string, JobInternal> = new Map<string, JobInternal>();

    constructor(jobs: Job[] = []) {
        this.registerJobs(jobs);
    }

    registerJobs(jobs: Job[]) {
        this.cancelAll();
        this.jobs = new Map([...this.jobs, ...jobs.map(toJobInternalEntry)]);
        scheduleExecution(Array.from(this.jobs.values()));
    }

    registerJob(startTime: number | Date, executor: () => void, id: string = uuidv4()) {
        const job: JobInternal = {id, executor, startTime: dateToNumber(startTime), status: ExecutionStatus.NEW};
        this.cancelAll();
        this.jobs.set(id, job);
        scheduleExecution(Array.from(this.jobs.values()));
        return job.id;
    }

    jobStatus(jobId: string) {
        const job = this.jobs.get(jobId);
        return job ? job.status : undefined;
    }

    cancelAll(ids?: string[]) {
        (ids ? ids : [...this.jobs.keys()]).forEach((id) => {
            if (this.jobs.has(id)) {
                this.cancelJob(id);
            }
        });
    }

    cancelJob(jobId: string) {
        const job = this.jobs.get(jobId);
        job && job.disposer && clearTimeout(job.disposer);
    }
}

function toJobInternalEntry(job: Job): [string, JobInternal] {
    const {id = uuidv4(), startTime, ...rest} = job;
    return [id, {...rest, id, startTime: dateToNumber(startTime), status: ExecutionStatus.NEW}];
}

function scheduleExecution(jobs: JobInternal[]) {
    const now = Date.now();
    jobs.filter((j) => j.status === ExecutionStatus.NEW).forEach((job) => {
        if (now > job.startTime) {
            job.status = job.status === ExecutionStatus.NEW ? ExecutionStatus.DONE : job.status;
            return;
        }
        if (typeof job.executor !== 'function') {
            job.status = ExecutionStatus.FAILURE;
            return;
        }
        job.status = ExecutionStatus.SCHEDULED;
        const timeout = job.startTime - now;
        job.disposer = setTimeout(() => {
            job.status = ExecutionStatus.IN_PROGRESS;
            try {
                job.executor();
                job.status = ExecutionStatus.DONE;
            } catch (e) {
                job.status = ExecutionStatus.FAILURE;
                throw e;
            }
        }, timeout);
    });
}

function dateToNumber(time: number | Date): number {
    return typeof time === 'number' ? time : time.valueOf();
}
