import * as React from 'react';
import {Validator} from '../validators/Validators';
import {action, computed, observable, isObservable, toJS, makeObservable} from 'mobx';
import {isAlive, isStateTreeNode} from 'mobx-state-tree';
import {DropdownStatus} from './Statutes';
import {logError} from '@joyrideautos/ui-logger/src/utils';

export type DropdownItemProps = {
    value: string | number | undefined;
    text: React.ReactNode | undefined;
    disabled?: boolean;
    selected?: boolean;
    [key: string]: any;
};

export type DropdownOnSearchChangeData = {
    searchQuery: string;
};

export type DropdownProps = {
    value?: number | string | (number | string)[];
    options?: DropdownItemProps[];
};

export type PrimitiveOrUndefined = string | number | boolean | undefined;
export type ValueType = string | number | undefined;
export type ValuesType = ValueType[];

/**
 * bridge between DropdownModel status handling and services
 *
 * @param callback
 * @param emptyValue value to add at the top of the available values list
 */
export function withDropdownStatus<T>(
    callback: (() => Promise<T[]> | T[]) | Promise<T[]>,
    emptyValue?: T | null
): (value?: string) => Promise<[T[], DropdownStatus]> {
    return () => {
        return new Promise<[T[], DropdownStatus]>((resolve, reject) => {
            const result = callback instanceof Promise ? callback : callback();
            const onResult = (res: T[]) =>
                resolve([
                    emptyValue !== undefined ? [emptyValue as T].concat(isStateTreeNode(res) ? res.slice() : res) : res,
                    'loaded' as DropdownStatus,
                ]);
            result instanceof Promise
                ? result.then(onResult).catch(() => reject([[], 'error' as DropdownStatus]))
                : onResult(result);
        });
    };
}

/*
export function renderCodeAndNameItem(textSource: (v: CodeAndName) => string | undefined = v => typeof v.code === 'number' ? v.code.toString() : v.code): (obj?: CodeAndName) => DropdownItemProps {
    return obj => {
        return obj ? {value: obj.code, text: textSource(obj) || ""} : {value: undefined}
    }
}

export function renderCodeItem(textSource: (v: CodeAndName) => string | undefined = v => typeof v.code === 'number' ? v.code.toString() : v.code): (obj?: CodeAndName) => DropdownItemProps {
    return obj => {
        return obj ? {value: obj.code, text: textSource(obj) || ""} : {value: undefined}
    }
}
*/

export function renderToText(value: DropdownItemProps | undefined) {
    return value && value.text ? value.text.toString() : '';
}

export function renderToValue(value: DropdownItemProps | undefined) {
    return value && value.value;
}

export function noSearchFilter(options: DropdownItemProps[], query: string): DropdownItemProps[] {
    return options;
}

export interface IDropdownModel<T, ST = T> {
    value: T | undefined | null;

    options: (DropdownItemProps & {_obj: ST | null})[];

    message?: string;

    errorValue?: ST;

    status: DropdownStatus;

    renderItem: (t?: ST) => DropdownItemProps;

    onChange: (value: ValueType | ValuesType) => void;
    onBlur: () => void;
    onFocus: (e?: React.SyntheticEvent<HTMLElement>) => void;
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    onSearchChange?: (evt: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => void;
    searchFilter?: (options: DropdownItemProps[], query: string) => DropdownItemProps[];
    validate: () => void;
}

export interface IDropdownMultiModel<T> extends IDropdownModel<T[], T> {
    value: T[];
    onAdd?: (evt: React.SyntheticEvent<HTMLElement>, dropdown: DropdownItemProps) => void;
    onRemove?: (evt: React.SyntheticEvent<HTMLElement>, dropdown: DropdownItemProps) => void;
    isActiveOption: (value: string | number | undefined) => boolean;
    findOption: (value: string | number | undefined) => DropdownItemProps | undefined;
}

export interface IDropdownModelFactory<T, ST = T> {
    status: DropdownStatus;
    options: (DropdownItemProps & {_obj: ST | null})[];
    errorValue?: ST;
    onSearchChange?: (evt: React.SyntheticEvent<HTMLElement>, dropdown: DropdownOnSearchChangeData) => void;
    searchFilter?: (options: DropdownItemProps[], query: string) => DropdownItemProps[];
    renderItem: (value?: ST) => DropdownItemProps;
    validator: Validator<T>;
    onDropdownOpen: () => void;
    values: ST[];
    focused: boolean;
    changed: boolean;
    message: string | undefined;
    validate: (value: T | null | undefined, force?: boolean) => void;
    reset: () => void;
}

function toOptions<T>(values: T[], renderItem: (value?: T) => DropdownItemProps): (DropdownItemProps & {_obj: T})[] {
    return values
        .filter((obj) => !isStateTreeNode(obj) || isAlive(obj))
        .map((obj) => {
            const v = renderItem(obj);

            return {...v, _obj: isObservable(obj) ? toJS(obj) : obj};
        });
}

export class DropdownModelFactoryBase<T, ST = T> {
    status: DropdownStatus;
    values: ST[];
    errorValue?: ST;
    onSearchChange?: (evt: React.SyntheticEvent<HTMLElement>, dropdown: DropdownOnSearchChangeData) => void;
    searchFilter?: (options: DropdownItemProps[], query: string) => DropdownItemProps[];
    focused = false;
    changed = false;
    message: string | undefined = undefined;

    getOptions(): (DropdownItemProps & {_obj: ST | null})[] {
        if (this.status === 'loading_error') {
            return [{text: this.errorLoadingMessage, value: undefined, _obj: null}];
        }

        return toOptions(this.values, this.renderItem);
    }

    constructor(
        private load: (valueKey?: string) => Promise<[ST[], DropdownStatus]> | undefined,
        private renderItemFunction: (value?: ST) => DropdownItemProps,
        private errorLoadingMessage: string = 'Error loading values',
        public validator: Validator<T> = () => undefined,
        private preloadOptions: boolean = false
    ) {
        this.values = [];
        this.status = 'initial';
        this.renderItem = renderItemFunction.bind(this);
        this.updateOptions = this.updateOptions.bind(this);
        this.updateOptionValuesAndStatus = this.updateOptionValuesAndStatus.bind(this);
        this.onDropdownOpen = this.onDropdownOpen.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.searchUsingPromise = this.searchUsingPromise.bind(this);
        if (this.preloadOptions && this.getOptions().length === 0) {
            this.loadOptions()?.catch((e) => console.log(e));
        }
    }

    shouldInvokeValidator = () => {
        return this.changed && !this.focused;
    };

    reset = () => {
        this.status = 'initial';
        this.message = undefined;
    };

    setMessage(value: string | undefined) {
        this.message = value;
    }

    validate = (value: T | null | undefined, force = false) => {
        if (this.shouldInvokeValidator() || force) {
            this.setMessage(this.validator(value));
        }
    };

    renderItem(value?: ST): DropdownItemProps {
        return this.renderItemFunction(value);
    }

    updateOptions(value: any) {
        if (value === false) {
            this.updateOptionValuesAndStatus([], 'loaded');
        } else {
            const [items, loadingStatus] = value;
            this.updateOptionValuesAndStatus(items, loadingStatus);
        }
    }

    private updateOptionValuesAndStatus(items: ST[], loadingStatus: DropdownStatus) {
        switch (loadingStatus) {
            case 'loaded':
                this.values = items;
                this.status = 'loaded';
                break;
            case 'blocked':
                this.values = [];
                this.status = 'blocked';
                break;
            case 'error':
                this.values = [];
                this.status = 'error';
        }
    }

    onDropdownOpen() {
        this.loadOptions()?.catch((e) => console.log(e));
    }

    loadOptions(valueKey?: string): Promise<[ST[], DropdownStatus]> | undefined {
        this.status = 'loading';
        const mayBePromise = this.load(valueKey);
        const values = this.values;
        const promise: Promise<[ST[], DropdownStatus]> = mayBePromise
            ? mayBePromise
            : values
            ? Promise.resolve([values, 'loaded'] as [ST[], DropdownStatus])
            : Promise.resolve([[], 'blocked'] as [ST[], DropdownStatus]);
        return promise.then(this.updateOptions).catch((error) => {
            this.status = 'error';
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                logError(error);
            }
            return error;
        });
    }

    searchUsingPromise(promise: (searchQuery: string) => Promise<ST[]> | undefined) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return (evt: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => {
            if (data.searchQuery) {
                self.status = 'loading';
                const p = promise(data.searchQuery);
                if (p) {
                    p.then((values) => {
                        self.values = values;
                        self.status = 'loaded';
                    }).catch(() => {
                        self.status = 'error';
                    });
                } else {
                    self.values = [];
                    self.status = 'loaded';
                }
            } else {
                self.values = [];
                self.status = 'loaded';
            }
        };
    }
}

export class DropdownModel<T> implements IDropdownModel<T> {
    constructor(
        private factory: IDropdownModelFactory<T>,
        private x: T | undefined | null = null,
        private onChanged: (value: T | undefined) => void = () => {}
    ) {}

    get value() {
        return this.x;
    }

    get options() {
        return this.factory.options;
    }

    get status() {
        return this.factory.status;
    }

    get errorValue() {
        return this.factory.errorValue;
    }

    get message() {
        return this.factory.message;
    }

    validate() {
        this.factory.validate(this.x, true);
    }

    renderItem(value: T | undefined) {
        return this.factory.renderItem(value);
    }

    onChange = (value: ValueType | ValuesType) => {
        const option = this.options.find((v) => v.value === value);
        const updatedValue = option?._obj ?? undefined;
        this.onChanged(updatedValue);
        this.factory.changed = true;
        this.x = updatedValue;
        this.factory.validate(this.x);
    };

    onDropdownOpen = () => {
        this.factory.onDropdownOpen();
    };

    onDropdownClose = () => {
        const value = this.value;
        this.factory.values = value ? [value] : [];
    };

    onSearchChange = (evt: React.SyntheticEvent<HTMLElement>, dropdown: DropdownOnSearchChangeData) => {
        this.factory.onSearchChange && this.factory.onSearchChange(evt, dropdown);
    };

    searchFilter = (options: DropdownItemProps[], query: string) => {
        return (this.factory.searchFilter && this.factory.searchFilter(options, query)) || [];
    };

    onBlur = () => {
        this.factory.focused = false;
        this.factory.validate(this.x);
    };

    onFocus = () => {
        this.factory.focused = true;
        this.factory.validate(this.x);
    };
}

export interface DropdownModelConstructor<T> {
    new (
        factory: IDropdownModelFactory<T>,
        x: T | undefined | null,
        onValueChanged: (value: T | undefined, dropdown: DropdownProps) => void
    ): DropdownModel<T>;
}

export class DropdownModelFactory<T> extends DropdownModelFactoryBase<T, T> implements IDropdownModelFactory<T, T> {
    constructor(
        load: (valueKey?: string) => Promise<[T[], DropdownStatus]> | undefined,
        renderItemFunction: (value?: T) => DropdownItemProps,
        errorLoadingMessage = 'Error loading values',
        validator: Validator<T> = () => undefined,
        preloadOptions = false
    ) {
        super(load, renderItemFunction, errorLoadingMessage, validator, preloadOptions);
        makeObservable(this, {
            status: observable,
            options: computed,
            message: observable,
            connect: action,
            validate: action,
            loadOptions: action,
            updateOptions: action,
            reset: action,
            setMessage: action,
        });
    }

    connect = (x: T | undefined | null, onValueChanged: (value?: T) => void): IDropdownModel<T, T> => {
        return new DropdownModel(this, x, onValueChanged);
    };

    get options() {
        return super.getOptions();
    }
}

function groupBy<V, K>(list: V[], keyGetter: (v: V) => K): Map<K, V> {
    const map = new Map<K, V>();
    list.forEach((item) => {
        const key = keyGetter(item);
        if (key) {
            map.set(key, item);
        }
    });
    return map;
}

class DropdownMultiModel<T> implements IDropdownMultiModel<T> {
    private domainToDropdownItems = new Map<T, PrimitiveOrUndefined>();

    constructor(
        private factory: IDropdownModelFactory<T[], T>,
        private x: T[] = [],
        private onAddItems?: (value: T[]) => void,
        private onRemoveItems?: (value: T[]) => void,
        private findIndex?: (searchValue: string | number | undefined) => (value: T) => boolean
    ) {
        this.renderItem = this.renderItem.bind(this);
    }

    get value() {
        return this.x;
    }

    get options() {
        return this.status === 'loaded'
            ? this.factory.options
            : // if options were not loaded use current value as available options... other options will be loaded onOpen
              toOptions(this.value, this.renderItem);
    }

    get status() {
        return this.factory.status;
    }

    get errorValue() {
        return this.factory.errorValue;
    }

    get message() {
        return this.factory.message;
    }

    validate() {
        this.factory.validate(this.x, true);
    }

    renderItem(value?: T) {
        const dropdownItemProps = this.factory.renderItem(value);
        const dropdownItemValue = dropdownItemProps.value;
        if (value) {
            this.domainToDropdownItems.set(value, dropdownItemValue);
        }
        return dropdownItemProps;
    }

    onChange = (value: ValueType | ValuesType) => {
        const newValues = value as ValuesType;
        if (this.onAddItems || this.onRemoveItems) {
            const optionsByValue: Map<ValueType, DropdownItemProps> = groupBy<DropdownItemProps, ValueType>(
                this.options,
                (option) => option.value
            );

            if (this.onAddItems) {
                const stringValueSet: Set<PrimitiveOrUndefined> = new Set<PrimitiveOrUndefined>();
                this.value.forEach((x) => {
                    if (this.domainToDropdownItems.has(x)) {
                        stringValueSet.add(this.domainToDropdownItems.get(x));
                    }
                });
                const addedValues = newValues.filter((value) => !stringValueSet.has(value as string));
                const valueArray = addedValues
                    .map((value) => optionsByValue.get(value))
                    .map((val) => (val ? val._obj : undefined));
                this.onAddItems(valueArray);
            }
            if (this.onRemoveItems) {
                const removedValues: ValuesType = [];
                optionsByValue.forEach((option, value) => {
                    if (!newValues.includes(value)) removedValues.push(value);
                });

                const valueArray = removedValues
                    .map((value) => optionsByValue.get(value))
                    .map((val) => (val ? val._obj : undefined));
                this.onRemoveItems(valueArray);
                valueArray.forEach((value) => {
                    this.domainToDropdownItems.delete(value);
                });
            }
        }

        this.factory.changed = true;
        this.factory.validate(this.x);
    };

    onAdd = (evt: React.SyntheticEvent<HTMLElement>, option: DropdownItemProps) => {
        if (option) {
            this.onAddItems && this.onAddItems([option._obj]);
            this.factory.changed = true;
            this.factory.validate(this.x);
        }
    };

    onRemove = (evt: React.SyntheticEvent<HTMLElement>, option: DropdownItemProps) => {
        if (option) {
            this.onRemoveItems && this.onRemoveItems([option._obj]);
            this.factory.changed = true;
            this.factory.validate(this.x);
        }
    };

    onDropdownOpen = () => {
        this.factory.onDropdownOpen();
    };

    onDropdownClose = () => {
        if (this.value) {
            this.factory.values = this.value;
        }
    };

    onSearchChange = (evt: React.SyntheticEvent<HTMLElement>, dropdown: DropdownOnSearchChangeData) => {
        this.factory.onSearchChange && this.factory.onSearchChange(evt, dropdown);
    };

    searchFilter = (options: DropdownItemProps[], query: string) => {
        return (this.factory.searchFilter && this.factory.searchFilter(options, query)) || [];
    };

    onBlur = () => {
        this.factory.focused = false;
        this.factory.validate(this.x);
    };

    onFocus = () => {
        this.factory.focused = true;
        this.factory.validate(this.x);
    };

    isActiveOption(value: string | number | undefined) {
        if (!this.findIndex) {
            return false;
        }
        return this.value.findIndex(this.findIndex(value)) !== -1;
    }

    findOption(v: string | number | undefined) {
        return this.options.find(({value}) => value === v);
    }
}

export interface DropdownMultiModelConstructor<T> {
    new (
        factory: IDropdownModelFactory<T[], T>,
        x: T[],
        onAdd?: (value: T[], dropdown: DropdownProps) => void,
        onRemove?: (value: T[], dropdown: DropdownProps) => void
    ): DropdownMultiModel<T>;
}

export class DropdownMultiModelFactory<T>
    extends DropdownModelFactoryBase<T[], T>
    implements IDropdownModelFactory<T[], T>
{
    constructor(
        load: (valueKey?: string) => Promise<[T[], DropdownStatus]> | undefined,
        renderItemFunction: (value?: T) => DropdownItemProps,
        errorLoadingMessage = 'Error loading values',
        validator: Validator<T[]> = () => undefined,
        preloadOptions = false
    ) {
        super(load, renderItemFunction, errorLoadingMessage, validator, preloadOptions);
        makeObservable(this, {
            options: computed,
            status: observable,
            message: observable,
            connect: action,
            validate: action,
            loadOptions: action,
            updateOptions: action,
            reset: action,
            setMessage: action,
        });
    }

    connect = (
        x: T[] | undefined,
        onAddItems?: (value: T[]) => void,
        onRemoveItems?: (value: T[]) => void,
        findIndex?: (searchValue: string | number | undefined) => (value: T) => boolean
    ): IDropdownMultiModel<T> => {
        return new DropdownMultiModel(this, x, onAddItems, onRemoveItems, findIndex);
    };

    get options() {
        return super.getOptions();
    }
}
