import React, {FC, ReactNode} from 'react';
import {
    NotificationMenuPresenter,
    NotificationMenuPresenterType,
    NotificationsMenu,
} from '@components/notificationsMenu/NotificationsMenu';
import {BellOutlined} from '@ant-design/icons';
import {Dropdown, Button, Badge, List, Row, Col, Typography} from 'antd';
import {observer, Observer} from 'mobx-react-lite';
import InfiniteProgressBar from '@joyrideautos/web-common-components/src/components/InfiniteProgressBar';
import {Only} from '@joyrideautos/ui-common/src/components/Only';
import type {HasPresenter} from '@joyrideautos/ui-models/src/types';
import {useViewModel} from '@joyrideautos/ui-models/src/hooks/useViewModel';
import {GDriveImportMenu} from './GDriveImportMenu';
import type {MenuProps} from 'antd';
import {isMobile} from 'react-device-detect';

// TODO: @alex.dakhno move to separate file
const {Text} = Typography;

interface UploadsProps extends HasPresenter<typeof NotificationMenuPresenter> {}

const Uploads: FC<UploadsProps> = observer(({presenter}) => {
    return (
        <>
            <Row align="middle" className="notification-item border-top border-bottom">
                <Col span={12}>
                    <Text strong>Uploads</Text>
                </Col>
            </Row>
            <Row className="mb-15">
                <Col span={24}>
                    {/* FIXME: there is an exception 'validateDOMNesting(...): <li> cannot appear as a descendant of <li>' */}
                    <List.Item>
                        <List.Item.Meta
                            title={`Uploading photos for ${presenter.vehiclesLeft} vehicles`}
                            description={`${presenter.mediaLeft} photos left to upload`}
                        />
                    </List.Item>
                </Col>
            </Row>
        </>
    );
});

const NotificationItemLayout: FC<{top: ReactNode; bottom: ReactNode}> = ({top, bottom}) => {
    return (
        <>
            <div>{top}</div>
            <div className="notification-progress">{bottom}</div>
        </>
    );
};

export const NotificationItem = observer(() => {
    const {viewModel: notificationsPresenter} = useViewModel(NotificationMenuPresenter);

    if (!notificationsPresenter) {
        return null;
    }

    const getItems = (presenter: NotificationMenuPresenterType): MenuProps['items'] => {
        const items = [{label: <NotificationsMenu presenter={presenter} />, key: 'notification'}];
        if (presenter.vehiclesLeft > 0) {
            items.push({label: <Uploads presenter={presenter} />, key: 'uploads'});
        }
        if (presenter.queueEntries.length > 0) {
            items.push({label: <GDriveImportMenu presenter={presenter} />, key: 'GDriveImport'});
        }
        return items;
    };

    return (
        <Dropdown
            menu={{items: getItems(notificationsPresenter)}}
            dropdownRender={(menu) => <div className="drop-notifications-container">{menu}</div>}
            placement={isMobile ? 'bottom' : 'bottomRight'}
            trigger={['click']}>
            <div className="drop-notifications-item">
                <NotificationItemLayout
                    top={
                        <Observer>
                            {() => (
                                // The badge can show only up to '99+'.
                                // The title value will be equal to 'eventsNumber' if we don't pass any value.
                                // We want to hide the title because we subscribe only up to 100 notitications count.
                                <Badge
                                    className="notification-badge"
                                    title={''}
                                    count={notificationsPresenter.eventsNumber}>
                                    <Button type="link">
                                        <div className="btn-item">
                                            <BellOutlined />
                                        </div>
                                    </Button>
                                </Badge>
                            )}
                        </Observer>
                    }
                    bottom={<Only when={notificationsPresenter.progressVisible}>{() => <InfiniteProgressBar />}</Only>}
                />
            </div>
        </Dropdown>
    );
});
