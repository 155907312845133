import {getAppConfig} from '@joyrideautos/ui-services/src/AppConfig';

const appConfig = getAppConfig();

class JRDivHTMLElement extends HTMLElement {
    connectedCallback() {
        this.style.display = appConfig.commonConfig.customFunctionsAvailable ? 'inherit' : 'none';
    }

    inject(fnName: string, fn: (...args: any) => void) {
        if (appConfig.commonConfig.customFunctionsAvailable) {
            // @ts-ignore
            this[fnName] = fn;
        }
    }
}

export default JRDivHTMLElement;
