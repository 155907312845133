import {getSnapshot, Instance, onPatch, onSnapshot} from 'mobx-state-tree';
import {AuctionType} from '../types/auction/Auction';
import {WinningBidType} from '../types/WinningBid';
import {ONE_MIN, ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {
    ListingAuctionSMPresenterType,
    ListingAuctionStateEnum,
    ListingAuctionStateMachineContext,
    ListingAuctionStateMachineEvent,
    ListingAuctionStateMachineSchema,
} from './statemachines/types';
import {BaseLiveAuctionStateModel} from './BaseLiveAuctionStateModel';
import {interpret} from 'xstate';
import {IAnyStateTreeNode, IJsonPatch} from 'mobx-state-tree/dist/internal';
import {listingAuctionStateMachine} from './statemachines/ListingAuctionStateMachine';
import {Timer} from './statemachines/Timer';
import {AuctionPauseEnum, AuctionPauseType} from '@joyrideautos/auction-core/src/types/AuctionTypes';

export const START_AUCTION_REMINDER = ONE_MIN;

export const LIVE_STATES: ListingAuctionStateEnum[] = [ListingAuctionStateEnum.BIDDING];

export const LiveListingAuctionStateModel = BaseLiveAuctionStateModel.named('LiveListingAuctionStateModel')
    .volatile(() => ({
        checkListingAuctionEndedTimeout: null as number | null,
        checkListingAuctionEndedTime: null as number | null,
        listingAuctionExpiration: undefined as string | undefined,
        earliestItemExpiration: undefined as string | undefined,
        earliestItemKey: undefined as string | undefined,
        latestItemKey: undefined as string | undefined,
        itemTimers: {} as {[itemKey: string]: Timer},
    }))
    .actions((self) => ({
        setCheckListingAuctionEndedTimeout(timeout: number) {
            self.checkListingAuctionEndedTimeout = timeout;
        },

        setCheckListingAuctionEndedTime(timeInMillis: number) {
            self.checkListingAuctionEndedTime = timeInMillis;
        },
        setListingAuctionExpiration: (expiration?: string) => {
            self.listingAuctionExpiration = expiration;
        },
        setEarliestItemExpiration: (expiration?: string) => {
            self.earliestItemExpiration = expiration;
        },
        setEarliestItemKey(itemKey: string) {
            self.earliestItemKey = itemKey;
        },
        setLatestItemKey(itemKey: string) {
            self.latestItemKey = itemKey;
        },
    }))
    .views((self) => ({
        get isLive() {
            const state = self._auctionState as ListingAuctionStateEnum;
            return Boolean(state && LIVE_STATES.indexOf(state) >= 0);
        },
        get endAuctionTime() {
            return self.listingAuctionExpiration ? self.clock.parseDate(self.listingAuctionExpiration) : 0;
        },
        get earliestItemEndTime() {
            return self.earliestItemExpiration ? self.clock.parseDate(self.earliestItemExpiration) : 0;
        },
        get isBidding() {
            return Boolean(self._auctionState === ListingAuctionStateEnum.BIDDING);
        },
        isAcceptBids(itemKey: string) {
            const winningBidContainer = self.winningBidContainer;
            const winningBid = winningBidContainer && winningBidContainer.getValue(itemKey);

            return winningBid && !winningBid.ended && this.isLive;
        },
        getItemCountdown(itemKey?: string) {
            if (!itemKey) {
                throw new Error('itemKey is not provided');
            }
            if (self.countDownTimer.get() < 0) {
                return 0;
            }
            const winningBid = self.getWinningBid(itemKey);
            if (!winningBid) {
                return -1;
            }
            return Math.floor((winningBid.expiration - Date.now()) / ONE_SEC);
        },
        get earliestWinningBid() {
            return self.earliestItemKey && self.getWinningBid(self.earliestItemKey);
        },
        get areAllResultsEnded() {
            return !!self.winningBidContainer?.allWinningBids.every((bid) => bid.ended === true);
        },
    }))
    .actions((self) => {
        const getEarliestVehicleExpirationTimeForListingAuction = (snapshot: {[key: string]: WinningBidType}) => {
            return Object.values(snapshot).reduce(
                (acc: {dateTimeAsString: string | null; itemKey: string | null}, winningBid: WinningBidType) => {
                    if (!winningBid.ended) {
                        const expirationTimestamp = self.clock.parseDate(winningBid.expiration);
                        const dateTimeInMs = acc.dateTimeAsString && self.clock.parseDate(acc.dateTimeAsString);
                        if (dateTimeInMs) {
                            if (expirationTimestamp < dateTimeInMs) {
                                return {
                                    dateTimeAsString: winningBid.expiration,
                                    itemKey: winningBid.itemId,
                                };
                            } else {
                                return acc;
                            }
                        } else {
                            return {
                                dateTimeAsString: winningBid.expiration,
                                itemKey: winningBid.itemId,
                            };
                        }
                    }
                    return acc;
                },
                {dateTimeAsString: null as string | null, itemKey: null as string | null}
            );
        };

        const getLatestVehicleExpirationTimeForListingAuction = (snapshot: {[key: string]: WinningBidType}) => {
            return Object.values(snapshot).reduce(
                (acc: {dateTimeAsString: string | null; itemKey: string | null}, winningBid: WinningBidType) => {
                    const expirationTimestamp = self.clock.parseDate(winningBid.expiration);
                    const dateTimeInMs = acc.dateTimeAsString && self.clock.parseDate(acc.dateTimeAsString);
                    if (dateTimeInMs) {
                        if (expirationTimestamp > dateTimeInMs) {
                            return {
                                dateTimeAsString: winningBid.expiration,
                                itemKey: winningBid.itemId,
                            };
                        } else {
                            return acc;
                        }
                    } else {
                        return {
                            dateTimeAsString: winningBid.expiration,
                            itemKey: winningBid.itemId,
                        };
                    }
                },
                {dateTimeAsString: null as string | null, itemKey: null as string | null}
            );
        };

        const setupListingAuctionExpiration = (auction: AuctionType, snapshot: {[key: string]: WinningBidType}) => {
            const {dateTimeAsString: latestExpirationTime, itemKey: latestItemKey} =
                getLatestVehicleExpirationTimeForListingAuction(snapshot);
            const {dateTimeAsString: earliestExpirationTime, itemKey: earliestItemKey} =
                getEarliestVehicleExpirationTimeForListingAuction(snapshot);
            if (latestExpirationTime && latestItemKey) {
                self.setListingAuctionExpiration(latestExpirationTime);
                self.setLatestItemKey(latestItemKey);
            }
            if (earliestExpirationTime && earliestItemKey) {
                self.setEarliestItemExpiration(earliestExpirationTime);
                self.setEarliestItemKey(earliestItemKey);
            }
            if (!self._smService) {
                return;
            }
        };

        const setupStateMachine = () => {
            const sasMachine = listingAuctionStateMachine.withContext({
                presenter: self as ListingAuctionSMPresenterType,
                services: {bidService: self.rootStore.bidsService},
            });

            const service = interpret<
                ListingAuctionStateMachineContext,
                ListingAuctionStateMachineSchema,
                ListingAuctionStateMachineEvent
            >(sasMachine).onTransition((state) => {
                self.setState(state.value as ListingAuctionStateEnum);

                const newState = self._auctionState as ListingAuctionStateEnum;
                console.log('state', newState);
            });

            const auction = self.auction! as IAnyStateTreeNode;
            self._disposers.push(
                onPatch(auction, (patch: IJsonPatch) => {
                    if (patch.path.endsWith('paused')) {
                        const value = patch.value as AuctionPauseType;
                        if (value === AuctionPauseEnum.SUSPENDED) {
                            service.send('SUSPEND');
                        }
                    }
                })
            );

            self.setSmService(service);
        };

        return {
            setupAuction() {
                if (!self.auction) {
                    throw new Error('Missing auction');
                }
                if (!self.winningBidContainer) {
                    return;
                }

                setupStateMachine();

                self._disposers.push(
                    onSnapshot(self.winningBidContainer.values, (snapshot: {[key: string]: WinningBidType}) => {
                        setupListingAuctionExpiration(self.auction!, snapshot);
                    })
                );
                const initialSnapshot = getSnapshot<{[key: string]: WinningBidType}>(self.winningBidContainer.values);
                setupListingAuctionExpiration(self.auction, initialSnapshot);

                self._smService && self._smService.start();
            },
            beforeDestroy() {
                if (self.checkListingAuctionEndedTimeout) {
                    clearTimeout(self.checkListingAuctionEndedTimeout);
                }
            },
        };
    });

export interface LiveListingAuctionStateModelType extends Instance<typeof LiveListingAuctionStateModel> {}
