import {cast, castToSnapshot, flow, getRoot, Instance, types} from 'mobx-state-tree';
import {AuctionType} from '../types/auction/Auction';
import {ActiveUser, BannedUser, GlobalBan, GlobalBanType, PreApprovedUser} from '../types/PreApprovedUserType';
import {when} from 'mobx';
import {BanReasonEnum} from '@joyrideautos/auction-core/src/types/User';
import {makeSubscribers} from '@joyrideautos/auction-utils/src/subscriptions';
import type {Subscribe} from '@joyrideautos/auction-utils/src/subscriptions';
import {isPublicAuction, isRestrictedAuction} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import BaseStore from './BaseStore';
import {RestrictedAuctionSeriesAccess} from '@joyrideautos/auction-core/src/types/RestrictedAuctionSeriesAccess';

export const BidderStatusStore = BaseStore.named('BiddersStatusStore')
    .props({
        bannedStatus: types.optional(types.map(BannedUser), {}),
        activeStatus: types.optional(types.map(ActiveUser), {}),
        preApprovedStatus: types.map(PreApprovedUser),
        globalBan: types.maybe(GlobalBan),
    })
    .volatile(() => ({
        uid: null as string | null,
        disposers: [] as (() => void)[],
    }))
    .views((self) => ({
        isActive: function (auctionSeriesKey: string) {
            return self.activeStatus.get(auctionSeriesKey);
        },
        isBanned: function (sellerId: string) {
            return self.bannedStatus.get(sellerId);
        },
        isPreApprovedUser(uid: string, sellerId: string): boolean {
            const preApprovedStatusForUser = self.preApprovedStatus.get(uid);
            if (!preApprovedStatusForUser) {
                return false;
            }
            const isForAllSellers = preApprovedStatusForUser.forAllSellers;
            if (isForAllSellers) {
                return true;
            }
            const sellers = preApprovedStatusForUser.sellers;
            return !!sellers && (sellers.length === 0 || !!sellers.find((s) => s.sellerId === sellerId));
        },
        get isGloballyBanned() {
            return Boolean(self.globalBan);
        },
        get isGloballyBannedByFailedDeposit() {
            return Boolean(self.globalBan?.banReasons?.get(BanReasonEnum.FAILED_DEPOSIT));
        },
        preApprovedStatusForSeller(
            uid: string,
            sellerId: string
        ): {preApprovedBy: string; preApprovedAt: string} | null {
            const preApprovedStatusForUser = self.preApprovedStatus.get(uid);
            if (!preApprovedStatusForUser) {
                return null;
            }
            const forAllSellers = preApprovedStatusForUser.forAllSellers;
            if (forAllSellers) {
                return forAllSellers;
            }

            const forSeller =
                preApprovedStatusForUser.sellers &&
                preApprovedStatusForUser.sellers.find((s) => s.sellerId === sellerId);
            if (!forSeller) {
                return null;
            }
            return {preApprovedAt: forSeller.preApprovedAt, preApprovedBy: forSeller.preApprovedBy};
        },
        bannedStatusForSeller(uid: string, sellerId: string): {bannedBy: string; bannedSince: string} | null {
            const sellerBan = self.bannedStatus.get(sellerId);
            if (!sellerBan) {
                return null;
            }

            return {bannedBy: sellerBan.bannedBy, bannedSince: sellerBan.bannedSince};
        },
    }))
    .views((self) => ({
        hasAccessForSeries(auctionSeriesKey: string): boolean {
            if (self.isGloballyBanned) {
                return false;
            }
            // activeStatus is a map of auctionSeriesKey -> ActiveUser
            const seriesWithActiveAccess = Array.from(self.activeStatus.keys());

            return seriesWithActiveAccess.includes(auctionSeriesKey);
        },
        hasAccessForSeller(sellerId: string, auctionSeriesKey: string): boolean {
            if (self.isGloballyBanned) {
                return false;
            }

            const isUserBanned = self.isBanned(sellerId);
            const isUserActive = self.isActive(auctionSeriesKey);
            return Boolean(isUserActive && !isUserBanned);
        },
        hasAccessForSellerAndAuction(sellerId: string, auction: AuctionType) {
            const isUserBanned = self.isBanned(sellerId);
            const isUserActive = self.isActive(auction.auctionSeries);

            if (self.isGloballyBanned) {
                return false;
            }
            if (isPublicAuction(auction.access) && isUserBanned) {
                return false;
            } else if (isRestrictedAuction(auction.access) && (isUserBanned || !isUserActive)) {
                return false;
            }

            return true;
        },
    }))
    .actions((self) => ({
        setBlacklistedUser(globalBan: GlobalBanType | null) {
            self.globalBan = globalBan ?? undefined;
        },
        setBannedStatusForSeller(sellerId: string, status: Instance<typeof BannedUser> | null) {
            if (status) {
                self.bannedStatus.set(sellerId, status);
            } else {
                self.bannedStatus.delete(sellerId);
            }
        },
        setActiveStatusForSeries(auctionSeriesKey: string, status: Instance<typeof ActiveUser>) {
            self.activeStatus.set(auctionSeriesKey, status);
        },
        clearActiveStatusForSeries(auctionSeriesKey: string) {
            self.activeStatus.delete(auctionSeriesKey);
        },
        setPreApprovedStatusForUser(uid: string, status: Instance<typeof PreApprovedUser> | null) {
            if (status) {
                self.preApprovedStatus.set(uid, status);
            } else {
                self.preApprovedStatus.delete(uid);
            }
        },
    }))
    .actions((self) => {
        const {auctionSeriesStore} = getRoot(self) as any;

        const activeBiddersSubscribe = (auctionSeriesKey: string) => () => {
            return self.biddersService.subscribeToCheckIfCurrentUserHasRestrictedAuctionSeriesAccess(
                self.uid,
                auctionSeriesKey,
                (result) => {
                    if (result) {
                        // TODO: fix
                        if (!result.approvedSince) {
                            self.setActiveStatusForSeries(auctionSeriesKey, result);
                        } else {
                            self.setActiveStatusForSeries(auctionSeriesKey, {
                                approvedSince: '',
                                approvedBy: 'ADMIN',
                            });
                        }
                    } else {
                        self.clearActiveStatusForSeries(auctionSeriesKey);
                    }
                }
            );
        };

        const bannedBiddersSubscribe = (sellerId: string) => () => {
            return self.userBansService.subscribeToCheckIfCurrentUserIsBannedForSeller(self.uid, sellerId, (result) => {
                if (result) {
                    self.setBannedStatusForSeller(sellerId, result);
                } else {
                    self.setBannedStatusForSeller(sellerId, null);
                }
            });
        };

        return {
            subscribe(auctionSeriesKey: string, sellerIds: string[]) {
                return makeSubscribers(
                    `BiddersStatusStore-${auctionSeriesKey}-${sellerIds.join('-')}`,
                    Object.assign(
                        {},
                        sellerIds.reduce<Record<string, Subscribe>>((subscribers, sellerId) => {
                            subscribers[`${sellerId}-banend`] = bannedBiddersSubscribe(sellerId);
                            return subscribers;
                        }, {}),
                        {
                            [`${auctionSeriesKey}-active`]: activeBiddersSubscribe(auctionSeriesKey),
                        }
                    )
                )();
            },
            unsubscribe() {
                self.disposers.forEach((d) => d());
                self.disposers = [];
            },
            load: flow(function* (uid: string) {
                self.uid = uid;
                self.disposers.push(
                    self.userBansService.subscribeToCheckIfCurrentUserIsGloballyBanned(self.uid, (result) => {
                        self.setBlacklistedUser(castToSnapshot(result));
                    })
                );
                self.disposers.push(
                    self.biddersService.subscribeToCheckIfUserIsPreApproved(uid, (result) => {
                        self.setPreApprovedStatusForUser(uid, result && cast(result));
                    })
                );

                const seriesWithRestrictedAccess: RestrictedAuctionSeriesAccess[] =
                    yield self.biddersService.getUserActiveRestrictedAccessAuctionSeries({userId: uid});

                self.disposers.push(
                    when(
                        () => auctionSeriesStore.subscribeStatus.isReady,
                        () => {
                            seriesWithRestrictedAccess.forEach((restrictedAccess) => {
                                const approvedBy = restrictedAccess.approvedBy || 'ADMIN';
                                const approvedSince = restrictedAccess.approvedSince || '';

                                self.setActiveStatusForSeries(restrictedAccess.auctionSeriesKey, {
                                    approvedBy,
                                    approvedSince,
                                });
                            });
                        }
                    )
                );
            }),
            banBidders: flow(function* (uids: string[], sellerId: string): Generator<Promise<any>, any, any> {
                return yield self.userBansService.banUsers(uids, sellerId);
            }),
            beforeDestroy() {
                self.disposers.forEach((d) => d());
            },
        };
    });

export interface BidderStatusStoreType extends Instance<typeof BidderStatusStore> {}

export interface HasBidderStatusStore {
    biddersStatuses: BidderStatusStoreType;
}
