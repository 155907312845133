import {getEnv, getRoot, Instance, types} from 'mobx-state-tree';
import {CodeAndName} from '../CodeAndName';
import {FirestoreTimestamp} from './FirestoreTimestamp';
import {capitalize, toUpper} from '@joyrideautos/auction-utils/src/stringUtils';
import {toFormattedYearValue, UNKNOWN_ITEM_VALUE} from '@joyrideautos/auction-core/src/types/Item';

export const ItemInfo = types
    .model('ItemInfo', {
        vin: types.maybe(types.string),
        isNonStandardVin: types.maybe(types.boolean),
        licensePlateState: types.maybe(CodeAndName),
        licensePlate: types.maybe(types.string),
        year: types.maybeNull(types.number),
        make: types.string,
        model: types.maybeNull(types.string),
        body: types.maybeNull(types.string),
        exteriorColor: types.maybeNull(types.string),
        odometer: types.maybeNull(types.number),
        transmission: types.maybeNull(CodeAndName),
        engineType: types.maybeNull(CodeAndName),
        drivetrain: types.maybeNull(CodeAndName),
        startCode: types.maybeNull(types.string),
        keyStatus: types.maybeNull(types.string),
        sellerSequence: types.maybeNull(types.string),
        updatedAt: types.maybe(FirestoreTimestamp),
        updatedBy: types.maybe(types.string),
        documentation: types.maybeNull(types.string),
        documentationState: types.maybeNull(types.string), // For now it's filled only for vehicles from Copart
        documentationType: types.maybeNull(types.string),
        auctionConditions: types.maybeNull(types.string),
        licenseExpiration: types.maybeNull(types.string),
        catalyticConverter: types.maybeNull(types.string),
        fuelType: types.maybeNull(types.string),
    })
    .views((self) => ({
        get rootStore() {
            // Sometimes its root is CachedPersistedItemsStore which is child of plain class, not in the direct hierarchy
            // from RootStore, so to get access there we use getRoot(self).rootStore which is set from dependencies
            const root = getRoot(self) as any;
            // When we do ItemInfo.create() it will be its own root, we make sure to pass rootStore via env
            return getEnv(self).rootStore || self !== root ? root.rootStore : (undefined as any);
        },
        get documentationStateDisplayValue(): string {
            // documentationState is stored as abbreviation like "CA", we want to display the full name like "California"
            return (
                this.rootStore?.refsStore?.licensePlateStates.byCode.get(self.documentationState!)?.name ||
                self.documentationState ||
                ''
            );
        },
        get keyStatusDisplayValue() {
            return self.keyStatus ? capitalize(self.keyStatus.replace(/_/g, ' ')) : 'Not specified';
        },
        get catalyticConverterDisplayValue() {
            const catalyticConverters = this.rootStore?.refsStore?.catalyticConverters;
            return self.catalyticConverter
                ? catalyticConverters.byCode.get(self.catalyticConverter)?.name || self.catalyticConverter
                : 'Not specified';
        },
        get engineTypeDisplayValue() {
            if (!self.engineType || !self.engineType.name) {
                return 'Not specified';
            }
            const components = self.engineType.name.split(';');
            return `${components[0]} ${components[1] || ''}`;
        },
        get startStatusDisplayValue() {
            if (!self.startCode) {
                return 'Not specified';
            }
            return capitalize(self.startCode.toLowerCase());
        },
        get yearMakeModel() {
            const year = toFormattedYearValue(self.year);
            const make = toUpper(self.make, UNKNOWN_ITEM_VALUE);
            const model = toUpper(self.model, UNKNOWN_ITEM_VALUE);
            return `${year} ${make} ${model}`;
        },
    }));

export interface ItemInfoType extends Instance<typeof ItemInfo> {}
