import React, {FC, PropsWithChildren, useContext} from 'react';
import {UIServicesAwareViewModelType} from '../../common/UIServicesAwareViewModel';
import RootStoreType from '../Shared';

interface RootStore extends RootStoreType, UIServicesAwareViewModelType {}

export const RootStoreContext = React.createContext<RootStore | null>(null);
RootStoreContext.displayName = 'RootStoreContext';

export function createRootStoreContextProvider<T extends RootStore = RootStore>(rootStore: T) {
    const RootStoreContextProvider: FC<PropsWithChildren<any>> = ({children}) => {
        return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>;
    };
    return RootStoreContextProvider;
}

export const useRootStore = <T extends RootStore = RootStore>() => {
    const rootStore = useContext(RootStoreContext);
    if (!rootStore) {
        throw new Error('useRootStore should be used withing an RootStoreContextProvider');
    }

    return rootStore as T;
};
