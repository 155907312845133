import {compareStrings} from '@joyrideautos/auction-utils/src/stringUtils';
import {Instance, types} from 'mobx-state-tree';

export const SellerIntegration = types.model('SellerIntegration', {
    id: types.string,
    name: types.string,
});

export interface SellerIntegrationType extends Instance<typeof SellerIntegration> {}

export const sorting =
    (fieldName: keyof {id: string; name: string}) => (a: SellerIntegrationType, b: SellerIntegrationType) => {
        return {
            id: () => compareStrings(a['id'], b['id']),
            name: () => compareStrings(a['name'], b['name']),
        }[fieldName]();
    };
