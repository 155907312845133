import {Instance, SnapshotOut, types} from 'mobx-state-tree';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
//import {Timestamp} from 'firebase/firestore';
// We have to implement this ourselves because we can't import it from 'firebase/firestore'.
// This file could be used in the browser or in RN.
const MIN_SECONDS = -62135596800;
const MS_TO_NANOS = 1e6;
class Timestamp {
    static now(): Timestamp {
        return Timestamp.fromMillis(Date.now());
    }

    static fromDate(date: Date): Timestamp {
        return Timestamp.fromMillis(date.getTime());
    }

    static fromMillis(milliseconds: number): Timestamp {
        const seconds = Math.floor(milliseconds / 1000);
        const nanos = Math.floor((milliseconds - seconds * 1000) * MS_TO_NANOS);
        return new Timestamp(seconds, nanos);
    }

    constructor(readonly seconds: number, readonly nanoseconds: number) {
        if (nanoseconds < 0) {
            throw new Error('Timestamp nanoseconds out of range: ' + nanoseconds);
        }
        if (nanoseconds >= 1e9) {
            throw new Error('Timestamp nanoseconds out of range: ' + nanoseconds);
        }
        if (seconds < MIN_SECONDS) {
            throw new Error('Timestamp seconds out of range: ' + seconds);
        }
        if (seconds >= 253402300800) {
            throw new Error('Timestamp seconds out of range: ' + seconds);
        }
    }

    toDate(): Date {
        return new Date(this.toMillis());
    }

    toMillis(): number {
        return this.seconds * 1000 + this.nanoseconds / MS_TO_NANOS;
    }

    valueOf(): string {
        return String(this.seconds - MIN_SECONDS).padStart(12, '0') + '.' + String(this.nanoseconds).padStart(9, '0');
    }
}

export function toTimestamp(seconds: number, nanoseconds: number): any {
    return new Timestamp(seconds, nanoseconds).toMillis();
}

export function clientTimestamp(): FirestoreTimestampType {
    return cast<FirestoreTimestampType>(Timestamp.now());
}

export const FirestoreTimestampDto = types
    .model('FirestoreTimestamp', {
        // We use BE schema to minimize possible changes in the FE code base.
        // Because validation scheme in the core module uses BE scheme.
        // TODO: (future) replace Firestore specific timestamp scheme with a string or a number value in DTO, validation and FE/BE Models.
        _nanoseconds: types.number,
        _seconds: types.number,
    })
    .views((self) => ({
        get timestamp() {
            return toTimestamp(self._seconds, self._nanoseconds);
        },
        get date() {
            return new Timestamp(self._seconds, self._nanoseconds).toDate();
        },
    }));

function isFirestoreTimestamp(ts: Timestamp | {_nanoseconds: number; _seconds: number}): ts is Timestamp {
    // eslint-disable-next-line no-prototype-builtins
    return ts instanceof Timestamp || ts.hasOwnProperty('nanoseconds') || ts.hasOwnProperty('seconds');
}

export const FirestoreTimestamp = types.snapshotProcessor(types.maybe(FirestoreTimestampDto), {
    preProcessor(ts: any | {_nanoseconds: number; _seconds: number}) {
        if (!ts) {
            return;
        }
        let timestamp: {_nanoseconds: number; _seconds: number};
        if (isFirestoreTimestamp(ts)) {
            timestamp = {_nanoseconds: ts.nanoseconds, _seconds: ts.seconds};
        } else {
            timestamp = ts;
        }

        return timestamp;
    },
    postProcessor(sn: SnapshotOut<typeof FirestoreTimestampDto>) {
        return sn;
    },
});

export interface FirestoreTimestampType extends Instance<typeof FirestoreTimestampDto> {}
