import {compareStrings} from '@joyrideautos/auction-utils/src/stringUtils';
import {types} from 'mobx-state-tree';

const Make = types.model('Make', {
    makeId: types.union(types.number, types.string),
    makeCode: types.string,
    makeName: types.string,
    isPopular: types.boolean,
});

export default Make;
export interface MakeType {
    makeId: number | string;
    makeCode: string;
    makeName: string;
    isPopular: boolean;
}

export const sorting = (fieldName: keyof MakeType) => (a: MakeType, b: MakeType) => {
    return {
        makeId: () => +a['makeId'] - +b['makeId'],
        makeName: () => compareStrings(a['makeName'], b['makeName']),
        makeCode: () => compareStrings(a['makeCode'], b['makeCode']),
        isPopular: () => (a['isPopular'] < b['isPopular'] ? -1 : a['isPopular'] > b['isPopular'] ? 1 : 0),
    }[fieldName]();
};
