import {Unsubscribe} from '../types';
import {WithKey} from './types';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {AppConfig} from '../AppConfig';

export const keyOnlySnapToArrayFactory = (childSnapshot: DataSnapshot): {key: string; value: string | null}[] => [
    {key: childSnapshot.key!, value: childSnapshot.key},
];

export interface DataSnapshot {
    key: string | null;

    val(): any;

    exists(): boolean;

    forEach(action: (child: DataSnapshot) => true | void): boolean;

    ref: DatabaseReference;

    toJSON(): Record<string, unknown> | null;

    get size(): number;
}

export interface DatabaseQuery {
    orderByKey(): DatabaseQuery;

    orderByChild(path: string): DatabaseQuery;

    orderByValue(): DatabaseQuery;

    limitToFirst(limit: number): DatabaseQuery;

    limitToLast(limit: number): DatabaseQuery;

    equalTo(value: string | number | boolean | null, key?: string): DatabaseQuery;

    /**
     * @param value - The value to start at. The argument type depends on which
     * `orderBy*()` function was used in this query. Specify a value that matches
     * the `orderBy*()` type. When used in combination with `orderByKey()`, the
     * value must be a string.
     * @param key - The child key to start at. This argument is only allowed if
     * ordering by child, value, or priority.
     */

    startAt(value?: number | string | boolean | null, key?: string): DatabaseQuery;

    /**
     * @param value - The value to start after. The argument type depends on which
     * `orderBy*()` function was used in this query. Specify a value that matches
     * the `orderBy*()` type. When used in combination with `orderByKey()`, the
     * value must be a string.
     * @param key - The child key to start after. This argument is only allowed if
     * ordering by child, value, or priority.
     */
    startAfter(value: number | string | boolean | null, key?: string): DatabaseQuery;

    /**
     * The ending point is exclusive. If only a value is provided, children
     * with a value less than the specified value will be included in the query.
     * If a key is specified, then children must have a value less than or equal
     * to the specified value and a key name less than the specified key.
     *
     * @param value - The value to end at. The argument type depends on which
     * `orderBy*()` function was used in this query. Specify a value that matches
     * the `orderBy*()` type. When used in combination with `orderByKey()`, the
     * value must be a string.
     * @param key - The child key to end at, among the children with the previously
     * specified priority. This argument is only allowed if ordering by child,
     * value, or priority.
     */
    endAt(value: number | string | boolean | null, key?: string): DatabaseQuery;

    /**
     * The ending point is exclusive. If only a value is provided, children
     * with a value less than the specified value will be included in the query.
     * If a key is specified, then children must have a value less than or equal
     * to the specified value and a key name less than the specified key.
     *
     * @param value - The value to end before. The argument type depends on which
     * `orderBy*()` function was used in this query. Specify a value that matches
     * the `orderBy*()` type. When used in combination with `orderByKey()`, the
     * value must be a string.
     * @param key - The child key to end before, among the children with the
     * previously specified priority. This argument is only allowed if ordering by
     * child, value, or priority.
     */
    endBefore(value: number | string | boolean | null, key?: string): DatabaseQuery;
}

export interface DatabaseReference extends DatabaseQuery {
    ref?: any;
    key?: string | null;
}

type EventType = 'value' | 'child_added' | 'child_changed' | 'child_removed' | 'child_moved';

// It's possible to iterate over DataSnapshot with child snapshots so transformer returns an array of values.
export type SnapshotTransformer<TransformValue> = (snapshot: DataSnapshot) =>
    | WithKey<{
          value: TransformValue;
      }>[]
    | null;

export interface Database {
    ref(path: string): DatabaseQuery;

    fetchOnce<T>(path: string | DatabaseReference): Promise<T | null>;

    fetchOnceSnapshot(path: string | DatabaseReference): Promise<DataSnapshot>;

    fetchOnceSnapshots(pathOrQuery: string | DatabaseReference): Promise<DataSnapshot[]>;

    fetchOnceArray<Element>(
        path: string | DatabaseReference,
        transform?: SnapshotTransformer<Element>
    ): Promise<Element[]>;

    subscribe<T>(
        path: string | DatabaseReference,
        onValue: (value: T | null) => void,
        eventType?: EventType
    ): Unsubscribe;

    subscribeToSnapshot(
        path: string | DatabaseReference,
        onSnapshot: (snapshot: DataSnapshot) => void,
        eventType?: EventType
    ): Unsubscribe;

    subscribeToNewlyCreated<T>(
        path: string | DatabaseReference,
        onValue: (snapshot: DataSnapshot) => void
    ): Unsubscribe;

    onChildAdded(path: string | DatabaseReference, onSnapshot: (snapshot: DataSnapshot) => void): Unsubscribe;

    onChildChanged(path: string | DatabaseReference, onSnapshot: (snapshot: DataSnapshot) => void): Unsubscribe;

    onChildRemoved(path: string | DatabaseReference, onSnapshot: (snapshot: DataSnapshot) => void): Unsubscribe;

    pushValues<T>(path: string, values: T): Promise<DatabaseReference | undefined>;

    setValues<T>(path: string, values: T): Promise<void>;

    updateValues<T extends object>(path: string, values: T): Promise<void>;

    snapshotToArray<Element>(snapshot: DataSnapshot, transform?: SnapshotTransformer<Element>): Element[];

    snapshotsToArray<Element>(snapshots: DataSnapshot[], transform?: SnapshotTransformer<Element>): WithKey<Element>[];

    snapshotToMap<Element>(snapshot: DataSnapshot, transform: SnapshotTransformer<Element>): Map<string, Element>;

    runTransaction(path: string, transactionUpdate: (snapshot: DataSnapshot) => any): Promise<any>;

    updateValueInTransaction<T>(path: string, cb: (value: T) => T): Promise<any>;

    serverTimestamp(): any;
}

export const defaultSnapshotToArrayTransformer = (childSnapshot: DataSnapshot) => [
    {
        key: childSnapshot.key!,
        value: {
            key: childSnapshot.key,
            ...childSnapshot.val(),
        },
    },
];

export const keyOnlySnapshotToArrayTransformer: SnapshotTransformer<string> = (childSnapshot: DataSnapshot) => [
    {
        key: childSnapshot.key!,
        value: childSnapshot.key!,
    },
];

export abstract class BaseDatabase {
    constructor(protected appConfig: AppConfig, protected logger?: Logger) {}

    snapshotToArray<Element>(
        snapshot: DataSnapshot,
        transform: SnapshotTransformer<Element> = defaultSnapshotToArrayTransformer
    ): Element[] {
        const array: Element[] = [];
        snapshot.forEach((childSnapshot) => {
            Array.prototype.push.apply(
                array,
                (transform(childSnapshot) || []).map(({value}) => value)
            );
            return undefined;
        });
        return array;
    }

    snapshotsToArray<Element>(
        snapshots: DataSnapshot[],
        transform: SnapshotTransformer<Element> = defaultSnapshotToArrayTransformer
    ): WithKey<Element>[] {
        return snapshots.map((childSnapshot) => {
            const result = (transform(childSnapshot) ?? [])[0];
            return {key: result.key, ...result.value};
        });
    }

    snapshotToMap<Element>(snapshot: DataSnapshot, transform: SnapshotTransformer<Element>): Map<string, Element> {
        const map: Map<string, Element> = new Map();
        snapshot.forEach((childSnapshot) => {
            const values = transform(childSnapshot);
            if (!values) {
                return undefined;
            }
            for (const {key, value} of values) {
                map.set(key, value);
            }
            return undefined;
        });
        return map;
    }
}
