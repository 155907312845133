import {isValidPositiveNumberOrZero, validateMoney, validatePercent} from '../utils';
import {AuctionOccurrenceSettings} from '../../types/AuctionOccurrence';
import {Money} from '../../types/Money';
import {PlatformFeeCalculator} from './types';
import {PlatformFeeTypeEnum} from '../../types/PlatformFeeSchedule';

export type PlatformFeeType = {
    platformFeePerc: number;
    minimumPlatformFee?: Money;
    maximumPlatformFee?: Money;
};

export class PercentPlatformFeeCalculator implements PlatformFeeCalculator {
    type = PlatformFeeTypeEnum.PERCENTAGE;
    constructor(
        private platformFeeData: PlatformFeeType,
        private options?: {
            isPlatformFeeTakesOfSellerFee: boolean;
        }
    ) {
        this.validate();
    }

    static getInstanceWithAuctionSettings(
        auctionSettings: AuctionOccurrenceSettings,
        options: {isManagerForSeller: boolean}
    ) {
        const isPlatformFeeTakesOfSellerFee = auctionSettings?.isPlatformFeeTakesOfSellerFee ?? true;
        const platformFeeData: PlatformFeeType = {
            platformFeePerc: auctionSettings.platformFee || 0,
            minimumPlatformFee: Money.fromDollars(auctionSettings.minimumPlatformFee || 0),
            maximumPlatformFee: Money.fromDollars(auctionSettings.maximumPlatformFee || 0),
        };
        if (options.isManagerForSeller && auctionSettings.hybrid && !auctionSettings.hybridAuctionChargePlatformFee) {
            platformFeeData.platformFeePerc = 0;
        }
        return new PercentPlatformFeeCalculator(platformFeeData, {isPlatformFeeTakesOfSellerFee});
    }

    /**
     * isPlatformFeeTakesOfSellerFee=true
     * platformFee = (X + af + bf) * pfPerc
     *
     * isPlatformFeeTakesOfSellerFee=false
     * platformFee = X * pfPerc
     *
     * X - sales price
     * af - admin fee
     * pf - platform fee
     * fp - fee price
     * bf - buyer fee (bpf or bsf(X))
     * bpf - buyer percent fee
     * bsf - buyer step function fee
     *
     */

    calculate({amount, adminFee, buyerFee}: {amount?: Money; adminFee?: Money; buyerFee?: Money; bid?: Money}): {
        platformFee: Money;
        rawPlatformFee: Money;
    } {
        const {platformFeePerc, minimumPlatformFee, maximumPlatformFee} = this.platformFeeData;

        this.validate(amount, adminFee, buyerFee);

        const calculatedPlatformFee = Money.sumOf(
            amount!,
            this.isPlatformFeeTakesOfSellerFee ? Money.sumOf(adminFee!, buyerFee!) : Money.zero
        ).takePercent(platformFeePerc);
        if (minimumPlatformFee?.isGreaterThan(Money.zero) && calculatedPlatformFee.isLessThan(minimumPlatformFee)) {
            return {platformFee: minimumPlatformFee, rawPlatformFee: calculatedPlatformFee};
        }
        if (maximumPlatformFee?.isGreaterThan(Money.zero) && calculatedPlatformFee.isGreaterThan(maximumPlatformFee)) {
            return {platformFee: maximumPlatformFee, rawPlatformFee: calculatedPlatformFee};
        }
        return {platformFee: calculatedPlatformFee, rawPlatformFee: calculatedPlatformFee};
    }

    get percent() {
        return this.platformFeeData.platformFeePerc;
    }

    get isPlatformFeeTakesOfSellerFee() {
        return this.options?.isPlatformFeeTakesOfSellerFee ?? false;
    }

    validate(amount?: Money, adminFee?: Money, buyerFee?: Money) {
        const {platformFeePerc, minimumPlatformFee, maximumPlatformFee} = this.platformFeeData;
        validatePercent(platformFeePerc, 'platformFeePerc');
        validateMoney(amount, 'salePrice');
        validatePercent(platformFeePerc, 'platformFeePerc');
        validateMoney(minimumPlatformFee, 'minimumPlatformFee');
        validateMoney(maximumPlatformFee, 'maximumPlatformFee');
        validateMoney(adminFee, 'adminFeeAmount');
        validateMoney(buyerFee, 'buyerFee');

        if (
            !isValidPositiveNumberOrZero(minimumPlatformFee?.cents ?? 0) ||
            !isValidPositiveNumberOrZero(maximumPlatformFee?.cents ?? 0)
        ) {
            throw Error(
                `Platform Fee illegal argument: ${platformFeePerc}, ${minimumPlatformFee}, ${maximumPlatformFee}`
            );
        }
    }
}
