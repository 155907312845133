export type AWSCredentials = {
    identityId: string;
    token: string;
    tokenExpiresAt: string;
};

export interface AWSCredentialsProvider {
    getCredentials(): Promise<AWSCredentials>;

    isCredentialsValid: boolean;
}

export enum AwsErrorCodeEnum {
    WRONG_TYPE = 'WRONG_TYPE',
    NETWORK_ERROR = 'NETWORK_ERROR',
}

export interface Progress {
    loaded: number;
    total: number;
}
