import {flow, getRoot, Instance, types} from 'mobx-state-tree';
import {Region, RegionType, fromDto} from '../types/Region';
import {LoadingStatus} from '../utils/LoadingStatus';
import {makeSubscriber} from '@joyrideautos/auction-utils/src/subscriptions';
import type RootStoreType from './Shared';
import BaseStore from './BaseStore';

export const RegionsStore = BaseStore.named('RegionsStore')
    .props({
        regionsById: types.optional(types.map(Region), {}),
        statesById: types.optional(types.map(types.string), {}),
    })
    .volatile(() => ({
        status: new LoadingStatus(),
        allStates: undefined as {[code: string]: string} | undefined,
    }))
    .views((self) => ({
        get regions(): RegionType[] {
            return [...self.regionsById.values()];
        },
        get states() {
            return [...self.statesById.entries()];
        },
        getStateById(stateId: string) {
            return self.statesById.get(stateId);
        },
        getRegionsByState(stateId: string) {
            return this.regions.filter((r) => r.stateId === stateId);
        },
        findRegion(regionId: string) {
            return self.regionsById.get(regionId);
        },
        isRegionVisibleToCurrentUser(regionId: string): boolean {
            const region = this.findRegion(regionId);
            const rootStore = getRoot(self) as RootStoreType;
            const authUserStore = rootStore.authUserStore;
            return !region?.hidden || (region.hidden && authUserStore.isCurrentUserAdmin);
        },
    }))
    .actions((self) => ({
        setRegions(regions: RegionType[]) {
            self.regionsById.clear();
            regions.forEach((region) => self.regionsById.put(region));
        },
        setStates(regions: RegionType[]) {
            self.statesById.clear();
            regions
                .filter((region) => !region.hidden)
                .forEach((region) => self.statesById.set(region.stateId, region.state));
        },
    }))
    .actions((self) => {
        const _subscribeToRegions = (stateId?: string) => () => {
            self.status.setInProgress();
            return self.regionsService.subscribeToRegions((regions) => {
                self.setRegions(regions.map(fromDto));
                self.setStates(regions.map(fromDto));
                self.status.setReady();
            }, stateId);
        };

        return {
            load: flow(function* (stateId?: string): Generator<Promise<any>, any, any> {
                self.status.setInProgress();
                const regions: RegionType[] = yield self.regionsService.fetchRegions(stateId);
                self.setRegions(regions);
                self.setStates(regions);
                self.status.setReady();
                return self.regions;
            }),
            subscribe(stateId?: string) {
                return makeSubscriber(`RegionsStore-${stateId || 'all-states'}`, _subscribeToRegions(stateId))();
            },
            fetchAllStates: flow(function* () {
                self.allStates = yield self.regionsService.fetchAllStates();
            }),
            afterCreate() {
                this.fetchAllStates().catch((e) => console.log(e));
            },
        };
    });

export interface RegionsStoreType extends Instance<typeof RegionsStore> {}

export interface HasRegionsStore {
    regionsStore: RegionsStoreType;
}
