import {delay} from '@joyrideautos/auction-utils/src/PromiseUtils';
import soldSound from '../../resources/sounds/sold.mp3';
import newBidSound from '../../resources/sounds/new_bid.mp3';
import newBiddderSound from '../../resources/sounds/new_bidder.mp3';
import auctionEndedSound from '../../resources/sounds/auction_ended.mp3';
import auctionLiveSound from '../../resources/sounds/auction_live.mp3';
import auctionStartingSound from '../../resources/sounds/auction_starting.mp3';
import nextSound from '../../resources/sounds/next.mp3';
import youWonSound from '../../resources/sounds/you_won.mp3';
import {logError} from '@joyrideautos/ui-logger/src/utils';

const MAX_DELAY_BETWEEN_SOUNDS = 20; // ms

export enum AudioNotificationEnum {
    AUCTION_ENDED = 'Auction Ended',
    AUCTION_LIVE = 'Auction Live',
    AUCTION_STARTING = 'Auction Starting',
    NEXT = 'Next',
    YOU_WON = 'You Won',
    NEW_BIDDER = 'New Bidder',
    NEW_BID = 'New Bid',
    SOLD = 'Sold',
}
const soundMap = {
    [AudioNotificationEnum.AUCTION_ENDED]: auctionEndedSound,
    [AudioNotificationEnum.AUCTION_LIVE]: auctionLiveSound,
    [AudioNotificationEnum.AUCTION_STARTING]: auctionStartingSound,
    [AudioNotificationEnum.NEXT]: nextSound,
    [AudioNotificationEnum.YOU_WON]: youWonSound,
    [AudioNotificationEnum.NEW_BIDDER]: newBiddderSound,
    [AudioNotificationEnum.NEW_BID]: newBidSound,
    [AudioNotificationEnum.SOLD]: soldSound,
};

export class AudioQueue {
    private context: AudioContext | null = null;
    private soundsQueue: AudioNotificationEnum[] = [];

    createContext() {
        if (!this.context) {
            const AudioContext = this.setupAudioContext(window.AudioContext || (window as any).webkitAudioContext);
            this.context = new AudioContext();
        }
    }
    playSound(sound: AudioNotificationEnum) {
        this.soundsQueue.push(sound);
        if (this.soundsQueue.length === 1) {
            this.triggerPlay().catch(logError());
        }
    }
    hasContext() {
        return !!this.context;
    }
    async testAudio(): Promise<boolean> {
        return new Promise((res, rej) => {
            (async () => {
                try {
                    const testAudio = new Audio(soldSound);
                    testAudio.muted = true;
                    await testAudio.play();
                    res(true);
                } catch (err: any) {
                    // NotAllowedError because user didn't interact with browser
                    if (err.name !== 'NotAllowedError') {
                        // broswer can't play
                        res(false);
                    }
                }
            })().catch(logError());
        });
    }

    private setupAudioContext = (context: any) => {
        const audioContextList: AudioContext[] = [];
        const userInputEventNames = [
            'click',
            'contextmenu',
            'auxclick',
            'dblclick',
            'mousedown',
            'mouseup',
            'pointerup',
            'touchend',
            'keydown',
            'keyup',
        ];

        const AudioContext = new Proxy(context, {
            construct(target, args) {
                const result = new target(...args);
                audioContextList.push(result);
                return result;
            },
        });

        function resumeAllContexts() {
            let count = 0;
            audioContextList.forEach((context) => {
                if (context.state !== 'running') {
                    context.resume().catch(logError());
                } else {
                    count++;
                }
            });

            if (count === audioContextList.length) {
                userInputEventNames.forEach((eventName) => {
                    document.removeEventListener(eventName, resumeAllContexts);
                });
            }
        }

        userInputEventNames.forEach((eventName) => {
            document.addEventListener(eventName, resumeAllContexts);
        });
        return AudioContext;
    };
    private playAudioContext = (soundFromQueue: AudioNotificationEnum) => {
        const sound = soundMap[soundFromQueue];
        if (!this.context) {
            return;
        }

        const context = this.context;
        return fetch(sound)
            .then((response: Response) => response.arrayBuffer())
            .then((bufferSound) => {
                return new Promise<void>((resolve, reject) => {
                    const source = context.createBufferSource();
                    context
                        .decodeAudioData(bufferSound, (buffer) => {
                            source.buffer = buffer;
                            source.connect(context.destination);
                            source.onended = () => {
                                resolve();
                            };

                            context.resume().catch((err) => {
                                console.error('context.resume() err', err);
                                reject();
                            });
                            source.start();
                        })
                        .catch(logError());
                });
            });
    };

    private triggerPlay = async () => {
        if (!this.soundsQueue.length) {
            return;
        }
        const firstSound = this.soundsQueue[0];
        await this.playAudioContext(firstSound!);
        await delay(MAX_DELAY_BETWEEN_SOUNDS);
        this.soundsQueue.shift();
        await this.triggerPlay();
    };
}
