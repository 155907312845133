import {getParent, Instance, types} from 'mobx-state-tree';
import {SnapshotIn} from 'mobx-state-tree/dist/internal';
import {isAuctionItemStatus, ItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {Item as ItemCoreDto, ItemPath, PersistedItem} from '@joyrideautos/auction-core/src/types/Item';
import {BaseItem} from './BaseItem';
import type {BaseItemType} from './BaseItem';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';

export const ItemStatus = types.enumeration('status', [
    ItemStatusEnum.PUBLISHED,
    ItemStatusEnum.SOLD,
    ItemStatusEnum.UNSOLD,
    ItemStatusEnum.PAID,
    ItemStatusEnum.CLAIMED,
]);

export const ItemDto = BaseItem.named('ItemDto').props({
    idx: types.number,
    next: types.maybeNull(types.string),
    prev: types.maybeNull(types.string),
});

export const Item = ItemDto.named('Item')
    .props({
        key: types.identifier, // realtime db
        persistenceKey: types.maybe(types.string), // firestore
        regionId: types.string,
        auctionId: types.string,
        status: types.maybe(
            types.enumeration('status', [
                ItemStatusEnum.PUBLISHED,
                ItemStatusEnum.SOLD,
                ItemStatusEnum.UNSOLD,
                ItemStatusEnum.CLAIMED,
                ItemStatusEnum.PAID,
            ])
        ),
    })
    .views((self) => {
        return {
            get itemContainer(): any {
                return getParent(self, 2);
            },
            get itemPath(): ItemPath {
                const {regionId, auctionId, key: itemId} = self;
                return {
                    regionId,
                    auctionId,
                    itemId,
                };
            },
        };
    })
    .actions((self) => ({
        setPrev(prev: string) {
            self.prev = prev;
        },
    }));

export interface ItemType extends Instance<typeof Item> {}
export interface ItemSnapshotIn extends SnapshotIn<typeof Item> {}

export type ItemHolder<T extends BaseItemType> = {
    item: T;
};

export function fromDto(item: ItemCoreDto): ItemSnapshotIn {
    return cast(item);
}

export function mapAuctionItemDtoToModel(item: ItemCoreDto): ItemType {
    return cast(item);
}

export function mapInventoryItemDtoToAuctionItemModel(item: WithKey<PersistedItem>): ItemType | undefined {
    const {
        key,
        idx,
        regionId,
        auctionId,
        itemId,
        publishedAt,
        storedAt,
        candidateAt,
        soldAt,
        unsoldAt,
        paidAt,
        claimedAt,
        archivedAt,
        status,
        statusMeta,
        result,
        currentResult,
        totalBids,
        buyerInfo,
        markedAsUnsoldBy,
        forbiddenAuctionsIds,
        ...rest
    } = item;
    if (!isAuctionItemStatus(status)) {
        return;
    }
    return cast({
        ...rest,
        key: itemId,
        idx: -1, // unknown
        prev: undefined, // unknown
        next: undefined, // unknown
        persistenceKey: key,
        auctionId: auctionId!,
        regionId: regionId!,
        itemId,
        status: status as any,
    });
}
