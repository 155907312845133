import {delay} from './PromiseUtils';

const logger = {
    log(...args: any) {
        // console.log(args);
    },
};

export class CancelableError extends Error {}
export type Cancelable<T> = (
    callback: () => Promise<T | null | undefined | void>,
    params?: {abort?: () => boolean; rejectCanceled?: boolean}
) => Promise<T>;

export function makeCancelable<T>(name = '', delayInMs = 100): Cancelable<T> {
    const count = {value: 0};
    return (
        callback: () => Promise<T | null | undefined | void>,
        params?: {abort?: () => boolean; rejectCanceled?: boolean}
    ) => {
        const rejectCanceled = params?.rejectCanceled || false;
        const current = {value: count.value};

        const cancel = () => (params?.abort ? params.abort() : false) || count.value - 1 !== current.value;
        return new Promise<T>((res, rej) => {
            (async () => {
                count.value++;

                await delay(delayInMs);
                if (cancel()) {
                    logger.log(`dont run the request for '${name}', index: ${current.value}`);
                    rejectCanceled && rej(new CancelableError('canceled prev request data (skip run)'));
                } else {
                    try {
                        const data = await callback();
                        if (!cancel()) {
                            logger.log(`return the request data for '${name}', index: ${current.value}`);
                            res(data as T);
                        } else {
                            logger.log(`skip the request data for '${name}', index: ${current.value}`);
                            rejectCanceled && rej(new CancelableError('canceled prev request data (skip result)'));
                        }
                    } catch (error) {
                        if (!cancel()) {
                            rej(error);
                        } else {
                            logger.log(`skip the request error for '${name}', index: ${current.value}`);
                            rejectCanceled && rej(new CancelableError('canceled the request error'));
                        }
                    }
                }
            })().catch((e) => console.log(e));
        });
    };
}
