export const BLUE_TARGET = 20;
export const ORANGE_TARGET = 10;
export const VOLCANO_TARGET = 6;
export const RED_TARGET = 1;

export function colorForCountdown(countdown: number) {
    let color;
    if (countdown >= BLUE_TARGET) {
        color = 'blue';
    } else if (countdown >= ORANGE_TARGET && countdown < BLUE_TARGET) {
        color = 'orange';
    } else if (countdown >= VOLCANO_TARGET && countdown < ORANGE_TARGET) {
        color = 'volcano';
    } else if (countdown >= RED_TARGET && countdown < VOLCANO_TARGET) {
        color = 'red';
    } else {
        color = 'blue';
    }
    return color;
}
