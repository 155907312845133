import {BaseService} from './BaseService';

export enum AnalyticsFavoritesEventEnum {
    ADD_TO_WISHLIST = 'add_to_wishlist',
    REMOVE_FROM_WISHLIST = 'remove_from_wishlist',
}

export enum AnalyticsPlaceBidFromPageEventEnum {
    VEHICLES_LIST_PAGE_BID = 'vehicles_list_page_bid',
    VEHICLE_DETAILS_PAGE_BID = 'vehicle_details_page_bid',
    OTHER_PAGE_BID = 'other_page_bid',
}

// TODO (Future): move out the event types definitions to a separate file
export type AnalyticsEvent = AnalyticsFavoritesEventEnum | AnalyticsPlaceBidFromPageEventEnum;

export class AnalyticsService extends BaseService {
    logFavoritesEvent(
        eventName: AnalyticsFavoritesEventEnum,
        itemCategory: 'auction' | 'item' | 'auction series' | 'region',
        id: string | number,
        regionId?: string | undefined,
        auctionId?: string | undefined
    ): Promise<void> {
        let eventItem = {
            item_id: `${id}`,
            item_category: itemCategory,
        } as any;
        if (itemCategory === 'item' || itemCategory === 'auction') {
            eventItem = {...eventItem, item_list_id: `${regionId}/${auctionId}`};
        }
        return this.firebase.analytics.logEvent(eventName, {
            items: [eventItem],
        });
    }

    // TODO (Future): eventName should be AnalyticsEvent instead of string
    logEvent(eventName: string, eventObj: any): Promise<void> {
        console.debug('called AnalyticsService.logEvent:', eventName, eventObj);
        return this.firebase.analytics.logEvent(eventName, eventObj);
    }

    setUserId(uid: string): Promise<void> {
        return this.firebase.analytics.setUserId(uid);
    }
}
