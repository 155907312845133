import numeral from 'numeral';

export const CURRENCY_NUMBER_FORMAT = '$0,0.00';

/**
 * convert percent like 10% into corresponding number 0.1
 */
export function percentToDouble(percent?: number) {
    if (percent && isFinite(percent)) {
        return (percent * 100) / 10000;
    }
    return percent;
}

/**
 * convert decimal number 0.1 to percent - 10%
 */
export function doubleToPercent(n?: number) {
    if (n && isFinite(n)) {
        return n * 100;
    }
    return n;
}

export function formatNumber(value: number | null | undefined, fractionDigits = 2): string {
    if (value == null) {
        value = 0;
    }
    const result = Math.round((+value + Number.EPSILON) * Math.pow(10, fractionDigits)) / Math.pow(10, fractionDigits);
    return result.toFixed(fractionDigits);
}

/**
 * Extract numbers from a string
 */
export function extractNumber(value: string): number {
    return Number(value.replace(/[^0-9]/g, ''));
}

export function roundNDecimalPlaces(value: number, n: number) {
    return Math.round((value + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, 2);
}

export function fromDollarAmount(value: any): number {
    return numeral(value).value();
}

export function toFloat(value: string | number | null | undefined): number | undefined {
    if (typeof value === 'number') {
        return value;
    }
    if (!value) {
        return undefined;
    }
    const parsedValue = Number.parseFloat(value);
    return Number.isNaN(parsedValue) ? undefined : parsedValue;
}

export function toNumber(value: string | number | null | undefined): number | null {
    if (typeof value === 'number') {
        return value;
    }
    if (!value) {
        return null;
    }
    const parsedValue = Number.parseInt(value);
    return Number.isNaN(parsedValue) ? null : parsedValue;
}

export function toFiniteNumberOrUndefined(value: string | number | null | undefined) {
    if (typeof value === 'number') {
        return value;
    }
    if (value) {
        const converted = Number.parseInt(value.toString());
        if (Number.isFinite(converted)) {
            return converted;
        }
    }
    return undefined;
}

export const formatCurrencyInDollars = (value?: number, nullValue = '') => {
    if (value == null) {
        return nullValue;
    }
    return numeral(value).format(CURRENCY_NUMBER_FORMAT);
};

export function isValidPositiveNumberOrZero(amount: number) {
    return amount >= 0 && !isNaN(amount) && isFinite(amount) && amount <= Number.MAX_SAFE_INTEGER;
}

export function parseDecimalFloat(n: string | null | undefined, defaultNumber: number) {
    return n ? parseFloat(n) : defaultNumber;
}

export function parseDecimalInt(n: string | null | undefined, defaultNumber?: number): number | undefined {
    try {
        if (n) {
            return parseInt(n, 10);
        }
        if (typeof defaultNumber === 'number') {
            return defaultNumber;
        }
    } catch (e) {
        console.error('unable to parse', n);
        if (typeof defaultNumber === 'number') {
            return defaultNumber;
        }
        throw e;
    }
    throw Error(`Parse error: Default number is not provided when parsing ${n}`);
}

export function dollarsToCents(value: number) {
    if (value && isFinite(value)) {
        return value * 100;
    }
    return value;
}

export function centsToDollars(value: number) {
    if (value && isFinite(value)) {
        return value / 100;
    }
    return value;
}

export function dollarsWithCommas(value: number | undefined): string {
    const safeValue = value || 0;
    return safeValue.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
}

export function isNumber(
    str: string | undefined | null,
    undefinedValue: boolean | undefined = undefined
): boolean | undefined {
    if (str == null || str === '') {
        return undefinedValue;
    }
    const value = Number(str);
    return !isNaN(value);
}

/**
 * nulls last sorting
 */
export function compareNumbers(n1?: number | null, n2?: number | null, reverse = false) {
    if (n1 != null && n2 != null) {
        return reverse ? n2 - n1 : n1 - n2;
    }
    return reverse ? (n1 != null ? 1 : n2 != null ? -1 : 0) : n1 != null ? -1 : n2 != null ? 1 : 0;
}
