import {destroy, getEnv, IDisposer, Instance, types} from 'mobx-state-tree';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import type {ClockService} from '@joyrideautos/ui-services/src/services/ClockService';

export const ClockModel = types
    .model('ClockModel', {
        offset: 0,
    })
    .volatile(() => ({
        disposers: [] as IDisposer[],
    }))
    .views((self) => ({
        parseDate(date: string) {
            return Date.parse(date) + self.offset;
        },
        get clockService(): ClockService {
            return getEnv(self).services['clockService'];
        },
        get logger() {
            return getEnv(self).services['logger'] as Logger;
        },
    }))
    .actions((self) => {
        const hiddenSecret = Date.now();
        return {
            setOffset: function (value: number, secret: number) {
                self.logger.log(`updating server time offset to ${value}ms`);
                if (secret === hiddenSecret) {
                    self.offset = -value;
                }
            },
            afterCreate: function () {
                self.disposers.push(
                    self.clockService.subscribe((value) => {
                        this.setOffset(value, hiddenSecret);
                    })
                );
            },
            beforeDestroy: function () {
                self.disposers.forEach((d) => d());
            },
        };
    });

export interface ClockModelType extends Instance<typeof ClockModel> {}

let clock: ClockModelType | undefined = undefined;

export function createClock(services: {clockService: ClockService; logger?: Logger}) {
    if (clock) {
        requestAnimationFrame(() => {
            destroy(clock);
        });
    }
    clock = ClockModel.create({}, {services});
    return clock;
}
