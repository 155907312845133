export const ERROR_CODE_PARAM = 'errorCode';

export const UIErrors: {[code: string]: string} = {
    'auth/user-not-found': 'There is no user record corresponding to the provided action code.',
    'auth/invalid-action-code':
        'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
    'auth/expired-action-code': 'The action code expired. Pleas, resend verification mail again',
    'auth/user-disabled': 'User was disabled. Please register again',
    'auth/weak-password': 'User Password is too weak',
    'auth/wrong-password': 'The password is invalid or the user does not have a password.',
    'auth/requires-recent-login': 'Please re-login and try once again.',
};

export interface UIError {
    code: string;
    message: any;
}

export function toError(code: string | null | undefined): UIError | undefined {
    if (!code) {
        return;
    }
    const message = UIErrors[code];
    if (message) {
        return {
            code,
            message,
        };
    }
    return;
}
