import {Instance, types} from 'mobx-state-tree';

const PreApprovedUserForAllSellers = types.model('PreApprovedUserForAllSellers', {
    preApprovedBy: types.string,
    preApprovedAt: types.string,
});

const PreApprovedUserForSeller = types.model('PreApprovedUserForSeller', {
    sellerId: types.string,
    preApprovedBy: types.string,
    preApprovedAt: types.string,
});

export const PreApprovedUser = types.model('PreApprovedUser', {
    forAllSellers: types.maybe(PreApprovedUserForAllSellers),
    sellers: types.maybe(types.array(PreApprovedUserForSeller)),
});

export const BannedUser = types.model('BannedUser', {
    bannedSince: types.string,
    bannedBy: types.string,
});

export const GlobalBan = types.model('GlobalBan', {
    bannedSince: types.maybe(types.string),
    bannedBy: types.maybe(types.string),
    banReasons: types.maybe(types.map(types.boolean)),
});

export type GlobalBanType = Instance<typeof GlobalBan>;

export const ActiveUser = types.model('ActiveUser', {
    approvedSince: types.string,
    approvedBy: types.string,
});
