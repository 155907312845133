import {BaseService} from './BaseService';
import {
    SearchVehiclesByTextRPCReqData,
    SearchVehiclesByTextRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/search/searchVehiclesByTextReqTypes';
import {
    GetVehiclesSearchSuggestionsRPCReqData,
    GetVehiclesSearchSuggestionsRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/search/getVehiclesSearchSuggestionsReqTypes';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {
    SearchInventoryVehiclesRPCReqData,
    SearchInventoryVehiclesRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/search/searchInventoryVehiclesReqTypes';
import {
    GetPublishedVehiclesForFiltersRPCResData,
    GetPublishedVehiclesForFiltersRPCReqData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/search/getPublishedVehiclesForFiltersReqTypes';
import {SuggestedVehicleInfo} from '@joyrideautos/auction-core/src/types/SuggestedVehicleInfo';

const cachedParsedResults = new Map<string, SuggestedVehicleInfo>();

export class SearchService extends BaseService {
    async parseVehicleInfoInSearchText(text: string): Promise<SuggestedVehicleInfo | undefined> {
        if (cachedParsedResults.get(text)) {
            return cachedParsedResults.get(text);
        }

        const reqData: SearchVehiclesByTextRPCReqData = {text, size: 1};
        const [result]: SearchVehiclesByTextRPCResData = await this.firebase.rpcService.call(
            FeReqRouteEnum.API_SEARCH_VEHICLES_BY_TEXT
        )(reqData);
        if (!result) {
            return undefined;
        }
        const make = result?.source?.make;
        const model = result.highlight?.model ? result?.source?.model : undefined;
        const year = result.highlight?.year ? result?.source?.year?.toString() : undefined;
        const payload = {
            make,
            model,
            year,
        };

        cachedParsedResults.set(text, payload);
        return payload;
    }

    async initiateInventorySearch(data: SearchInventoryVehiclesRPCReqData): Promise<SearchInventoryVehiclesRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_SEARCH_INVENTORY_VEHICLES)(data);
    }

    async getVehiclesSearchSuggestions(
        text: string,
        isSeller: boolean
    ): Promise<GetVehiclesSearchSuggestionsRPCResData> {
        const data: GetVehiclesSearchSuggestionsRPCReqData = {text, isSeller};
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_SEARCH_GET_VEHICLES_SUGGESTIONS)(data);
    }

    async getPublishedVehiclesForFilters(
        data: GetPublishedVehiclesForFiltersRPCReqData
    ): Promise<GetPublishedVehiclesForFiltersRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_SEARCH_GET_PUBLISHED_VEHICLES_FOR_FILTERS)(data);
    }
}
