export enum PaymentIntentHoldStatusEnum {
    INITIAL = 'initial',
    PENDING_PLACEMENT = 'pending_placement',
    PENDING_CAPTURE = 'pending_capture',
    FAILED = 'failed', // failed to put a hold
    SUCCESS = 'success', // requires_capture
    CAPTURED = 'captured',
    CANCELLED = 'cancelled',
    NO_DEFAULT_PAYMENT_METHOD = 'no_default_payment_method',
}
