import {types} from 'mobx-state-tree';
import {AuctionFormat} from './types';
import {DEFAULT_BID_INCREMENT_TYPE} from '@joyrideautos/auction-core/src/types/BidIncrement';
import {PlatformFeeTypeEnum} from '@joyrideautos/auction-core/src/types/PlatformFeeSchedule';

export const PlatformFeeType = types.enumeration('PlatformFeeType', Object.values(PlatformFeeTypeEnum));

export const AuctionTemplate = types.model('AuctionTemplate', {
    durationInSeconds: types.maybe(types.number),
    durationBetweenSellersInSeconds: types.maybe(types.number),
    vehicleTransitionIntervalInSeconds: types.maybe(types.number),
    extendExpirationBySeconds: types.maybe(types.number),
    hybrid: types.maybe(types.boolean),
    hybridAuctionChargePlatformFee: types.maybe(types.boolean),
    auctionType: AuctionFormat,
    minimumBid: types.maybe(types.number),
    paymentMethodRequired: types.maybe(types.boolean),
    paddleNumberRequired: types.maybe(types.boolean),
    bidIncludesFees: true,
    salesTax: types.maybe(types.number),
    isExcludeSellerFeesFromSalesTax: types.maybe(types.boolean),
    platformFee: types.maybe(types.number),
    platformFeeType: types.maybe(PlatformFeeType), // PERCENTAGE is default
    platformFeeSchedule: types.maybe(types.string), // key in RTDB
    minimumPlatformFee: types.maybe(types.number),
    maximumPlatformFee: types.maybe(types.number),
    depositFixed: types.maybe(types.number),
    depositPerc: types.maybe(types.number),
    mapCode: types.maybe(types.string),
    ccHoldRequired: false,
    isCCHoldPerVehicle: false,
    bidIncrementConfiguration: types.optional(types.maybe(types.string), DEFAULT_BID_INCREMENT_TYPE),
    isReservePriceAsStartingBid: true,
    isSmsNotificationsEnabled: false,
    isChargeBuyerFee: true,
    isBidderInitials: false,
    lineItemMessage: types.maybe(types.string),
    auctionNameForSms: types.maybe(types.string),
    bidCardMessage: types.maybe(types.string),
    smsLeadTime: types.maybe(types.number),
    isPlatformFeeTakesOfSellerFee: true,
    requireIdentityVerifiedToBid: types.maybe(types.boolean),
    isSelfPublishEnabled: false,
    isBuyNowEnabled: false,
    isDepositExcludesFees: false,
    isContinuousAuctionEnabled: false,
    shouldShowReservePrice: false,
    shouldHideAuctionDate: false,
    shouldHidePastAuctions: false,
    isTestAuction: false,
    restrictBiddingCount: types.maybe(types.number),
    isStorageTaxEnabled: types.maybe(types.boolean),
});
