import {autorun} from 'mobx';
import {Instance, onPatch} from 'mobx-state-tree';
import {interpret} from 'xstate';
import {sequenceAuctionStateMachine} from './statemachines/SequenceAuctionStateMachine';
import {IAnyStateTreeNode, IJsonPatch} from 'mobx-state-tree/dist/internal';
import type {ItemType} from '../types/item/Item';
import {ONE_MIN} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {AuctionPauseEnum, AuctionPauseType} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import {
    SequenceAuctionSMPresenterType,
    SequenceAuctionStateEnum,
    SequenceAuctionStateMachineContext,
    SequenceAuctionStateMachineEvent,
    SequenceAuctionStateMachineSchema,
} from './statemachines/types';
import {BaseLiveAuctionStateModel} from './BaseLiveAuctionStateModel';
import {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';

export const START_AUCTION_REMINDER = ONE_MIN;

export const LIVE_STATES: SequenceAuctionStateEnum[] = [
    SequenceAuctionStateEnum.BIDDING,
    SequenceAuctionStateEnum.AFTER_BIDDING,
    SequenceAuctionStateEnum.BIDDING_RESULT,
    SequenceAuctionStateEnum.BEFORE_BIDDING,
];

function makeVehiclesSequence(items: ItemType[]) {
    let sequence = '';
    items.forEach((item) => {
        if (!item.prev) {
            sequence += `${item.key}`;
        }
        if (item.next) {
            sequence += `|${item.next}`;
        }
    });
    return sequence;
}

export const LiveSequenceAuctionStateModel = BaseLiveAuctionStateModel.named('LiveSequenceAuctionStateModel')
    .volatile(() => ({
        currentVehiclesSequence: '',
    }))
    .actions((self) => ({
        setCurrentItem(itemKey: string | null) {
            if (itemKey) {
                const item = self.itemsStore.getItem(self.regionId, self.auctionId, itemKey);
                if (!item) {
                    throw Error(
                        `Illegal Presenter state: unable to find model for item with key ${itemKey}, auction: ${self.regionId}/${self.auctionId}`
                    );
                }
                // TODO: do we need this?
                // if (!item.prev && self._currentItem) {
                //     item.setPrev(self._currentItem.key);
                // }
                self._currentItem = item;
            } else {
                self._currentItem = undefined;
            }
        },
        setCurrentVehiclesSequence(sequence: string) {
            self.currentVehiclesSequence = sequence;
        },
    }))
    .views((self) => ({
        get isAuctionInProgress() {
            return self._auctionState === 'beforeBidding' || this.isLive;
        },
        isAcceptBids(itemKey: string) {
            const winningBidContainer = self.winningBidContainer;
            const winningBid = winningBidContainer && winningBidContainer.getValue(itemKey);

            const state = self._auctionState as SequenceAuctionStateEnum;
            const isLive = this.isLive;

            return (
                winningBid &&
                !winningBid.ended &&
                (isLive ||
                    (state &&
                        [
                            SequenceAuctionStateEnum.PRE_BID,
                            SequenceAuctionStateEnum.BEFORE_START,
                            SequenceAuctionStateEnum.PAUSED,
                        ].includes(state)))
            );
        },
        get biddingItem() {
            const currentState = self._auctionState;
            let currentItem: ItemType = self._currentItem!;
            if (currentItem && currentState === 'biddingResult' && currentItem.prev) {
                currentItem = self.itemsStore.getItem(self.regionId, self.auctionId, currentItem.prev)!;
            }

            return currentItem;
        },
        get nextBiddingItem() {
            const currentItem = self._currentItem;
            if (currentItem && currentItem.next) {
                return self.itemsStore.getItem(self.regionId, self.auctionId, currentItem.next);
            }
            return null;
        },
        get startItemKey() {
            return self.auction ? self.auction.startItem : undefined;
        },
        makeFirstItem() {
            const itemKey = this.startItemKey;
            self.setCurrentItem(itemKey || null);
            return self._currentItem;
        },
        get numberOfItemsLeftInAuction() {
            const itemsLength = (self.winningBidContainer || []).length;
            const prevItem =
                self._currentItem && self._currentItem.prev
                    ? self.itemsStore.getItem(self.regionId, self.auctionId, self._currentItem.prev)
                    : null;
            return prevItem ? itemsLength - prevItem.idx : itemsLength;
        },
        get currentWinningBid() {
            const item = self._currentItem;
            return item && self.getWinningBid(item.key);
        },
        get previousWinningBid() {
            let prevItemKey: string | null = null;

            if (self._currentItem) {
                prevItemKey = self._currentItem.prev;
            }

            return prevItemKey ? self.getWinningBid(prevItemKey) : undefined;
        },
        get lastWinningBid() {
            const items = self.itemsStore.getAllItems();
            const lastItem = items[items.length - 1];
            return lastItem && self.getWinningBid(lastItem.key);
        },

        get startPreBidTime() {
            if (!self.auction) {
                return NaN;
            }

            return self.clock.parseDate(self.auction.settings.start);
        },

        get isLive() {
            const state = self._auctionState as SequenceAuctionStateEnum;
            const isLive = Boolean(state && LIVE_STATES.indexOf(state) >= 0);
            return isLive;
        },

        get isBeforePreBid() {
            return self._auctionState === SequenceAuctionStateEnum.BEFORE_PRE_BID;
        },

        get acceptsPreBids() {
            const state = self._auctionState as SequenceAuctionStateEnum;

            return state && [SequenceAuctionStateEnum.PRE_BID, SequenceAuctionStateEnum.BEFORE_START].includes(state);
        },

        get isLiveBidding() {
            const state = self._auctionState as SequenceAuctionStateEnum;

            return (
                state &&
                [
                    SequenceAuctionStateEnum.BIDDING,
                    SequenceAuctionStateEnum.AFTER_BIDDING,
                    SequenceAuctionStateEnum.BIDDING_RESULT,
                    SequenceAuctionStateEnum.BEFORE_BIDDING,
                    SequenceAuctionStateEnum.PAUSED,
                    SequenceAuctionStateEnum.SUSPENDED,
                ].includes(state)
            );
        },

        get isPaused() {
            return self._auctionState === SequenceAuctionStateEnum.PAUSED;
        },

        get isSuspended() {
            return Boolean(self._auctionState === SequenceAuctionStateEnum.SUSPENDED);
        },

        get isEnded() {
            return self._auctionState === SequenceAuctionStateEnum.ENDED;
        },

        get isBeforeStart() {
            return self._auctionState === SequenceAuctionStateEnum.BEFORE_START;
        },

        get isBeforeBidding() {
            return self._auctionState === SequenceAuctionStateEnum.BEFORE_BIDDING;
        },

        get isBidding() {
            return Boolean(self._auctionState === SequenceAuctionStateEnum.BIDDING);
        },

        get isAfterBidding() {
            return self._auctionState === SequenceAuctionStateEnum.AFTER_BIDDING;
        },

        get biddingProgress() {
            const countdown = self.countDownTimer.get();
            if (countdown === undefined || !self.auction?.settings?.durationInSeconds) {
                return undefined;
            }
            const fullDuration = self.auction.settings.durationInSeconds;
            const progress = (fullDuration - countdown) / fullDuration;
            return progress * 100;
        },
        get alertVisibilityDependsOnState() {
            const visibility = {
                [SequenceAuctionStateEnum.BEFORE_PRE_BID]: true,
                [SequenceAuctionStateEnum.PRE_BID]: true,
                [SequenceAuctionStateEnum.BEFORE_START]: true,
                [SequenceAuctionStateEnum.BIDDING]: true,
                [SequenceAuctionStateEnum.AFTER_BIDDING]: false,
                [SequenceAuctionStateEnum.BIDDING_RESULT]: false,
                [SequenceAuctionStateEnum.BEFORE_BIDDING]: false,
                [SequenceAuctionStateEnum.ENDED_RECENTLY]: true,
                [SequenceAuctionStateEnum.ENDED]: true,
                [SequenceAuctionStateEnum.PAUSED]: false,
                [SequenceAuctionStateEnum.SUSPENDED]: false,
                [SequenceAuctionStateEnum.ERROR]: false,
                [SequenceAuctionStateEnum.EMPTY]: false,
            };
            return self._auctionState ? visibility[self._auctionState] : false;
        },

        isCurrentItem(itemKey: string) {
            return Boolean(self._currentItem && itemKey === self._currentItem.key);
        },

        getItemCountdown(_itemKey?: string) {
            return self.countDownTimer.get();
        },

        get vehiclesSequenceChanged() {
            const vehiclesSequence = makeVehiclesSequence(self.itemsStore.getAllItems());
            return vehiclesSequence !== self.currentVehiclesSequence;
        },
        get startItemChanged() {
            const allItems = self.itemsStore.getAllItems();
            if (allItems.length === 0) {
                return false;
            }
            const firstItem = allItems[0];
            // if start item is not changed -> false
            // if start item is changed ->
            //      when an auction is not updated (in store) -> true
            //      when an auction is updated (in store) -> false
            return self.auction && self.auction.startItem !== firstItem.key;
        },
    }))
    .actions((self) => ({
        makeNextItem() {
            if (!self._currentItem) {
                return null;
            }
            self.setCurrentItem(self._currentItem.next);
            return self._currentItem;
        },
        setupAuction() {
            if (self._smService) {
                self._smService.stop();
            }

            if (!self.auction) {
                throw new Error('Missing auction');
            }
            if (self.auction.ended) {
                self.setState(SequenceAuctionStateEnum.ENDED);
                return;
            }
            self.setCurrentVehiclesSequence(makeVehiclesSequence(self.itemsStore.getAllItems()));
            self.setNeedsResetupAuction(false);
            self.makeFirstItem();

            if (process.env.NODE_ENV === 'test') {
                return;
            }

            const sasMachine = sequenceAuctionStateMachine.withContext({
                presenter: self as SequenceAuctionSMPresenterType,
                services: {bidService: self.rootStore.bidsService},
                logger: self.rootStore.getLogger('SequenceAuctionStateMachine', LoggerStatusEnum.DISABLED),
            });

            const service = interpret<
                SequenceAuctionStateMachineContext,
                SequenceAuctionStateMachineSchema,
                SequenceAuctionStateMachineEvent
            >(sasMachine).onTransition((state) => {
                self.setState(state.value as SequenceAuctionStateEnum);

                const newState = self._auctionState as SequenceAuctionStateEnum;
                self.logger.log({auctionId: self.auctionId, state: newState});
            });

            const auction = self.auction! as IAnyStateTreeNode;
            self._disposers.push(
                onPatch(auction, (patch: IJsonPatch) => {
                    if (patch.path.endsWith('paused')) {
                        const value = patch.value as AuctionPauseType;

                        if (value === AuctionPauseEnum.PAUSED) {
                            service.send('PAUSE');
                        } else if (value === AuctionPauseEnum.SUSPENDED) {
                            service.send('SUSPEND');
                        }
                    }
                })
            );

            service.start();

            self.setSmService(service);
        },
    }))
    .actions((self) => {
        return {
            afterCreate() {
                self._disposers.push(
                    autorun(() => {
                        if (self.auction?.startItem && self.vehiclesSequenceChanged && !self.startItemChanged) {
                            self.setNeedsResetupAuction(true);
                        }
                    })
                );
            },
        };
    });

export interface LiveSequenceAuctionStateModelType extends Instance<typeof LiveSequenceAuctionStateModel> {}
