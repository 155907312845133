import {getEnv, getRoot, IAnyStateTreeNode, IReferenceType, types} from 'mobx-state-tree';
import {Item} from './Item';
import {PersistedItem} from './PersistedItem';
import {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';

export const PersistedItemReferenceForAuction = function (rootStore?: any, shouldKeepId = false) {
    function getItemsStore(parent: any) {
        const root = rootStore || getRoot(parent);
        return root.itemsStoreFactory.persistentItemsStoreForAuctions;
    }

    return types.reference(Item, {
        get(itemId: string, parent: IAnyStateTreeNode & {regionId: string; auctionId: string}): any {
            const {regionId, auctionId} = parent;
            if (regionId && auctionId) {
                // since sometimes auctionId can be a reference we should get plain ID from it.
                const auctionIdString: string = isAuction(auctionId) ? auctionId.auctionId : (auctionId as string);
                return getItemsStore(parent).getItemForAuction({
                    regionId,
                    auctionId: auctionIdString,
                    itemId,
                });
            }
            // Sometimes items may not be found since the auction is already deleted (archived)
            console.warn(`Unable to find persisted item ${itemId}`, parent);
            return shouldKeepId ? itemId : undefined;
        },
        set(value) {
            return value.key;
        },
    });
};

export const ItemReferenceForAuction = function (rootStore?: any, shouldKeepId = false) {
    function getItemsStore(parent: any, auctionIdString: string) {
        const {regionId} = parent;
        const root = rootStore || getRoot(parent);
        return root.itemsStoreFactory.getItemsStoreForAuction({regionId, auctionId: auctionIdString});
    }

    return types.reference(Item, {
        get(itemId: string, parent: IAnyStateTreeNode & {regionId: string; auctionId: string}): any {
            const {regionId, auctionId} = parent;
            if (regionId && auctionId) {
                // since sometimes auctionId can be a reference we should get plain ID from it.
                const auctionIdString: string = isAuction(auctionId) ? auctionId.auctionId : (auctionId as string);
                return getItemsStore(parent, auctionIdString).fetchItemAsync({
                    itemId,
                    regionId,
                    auctionId: auctionIdString,
                });
            }
            // Sometimes items may not be found since the auction is already deleted (archived)
            console.warn(`Unable to find item ${itemId}`, parent);
            return shouldKeepId ? itemId : undefined;
        },
        set(value) {
            return value.key;
        },
    });
};

function isAuction(auction: any): auction is {auctionId: string} {
    return typeof auction === 'object' && !!auction.auctionId;
}

export const PersistedItemReference = function (rootStore?: any): IReferenceType<typeof PersistedItem> {
    function getItemsStore(parent: any) {
        const root = rootStore || getEnv(parent).rootStore || getRoot(parent);
        root?.getLogger('PersistedItemReference', LoggerStatusEnum.DISABLED)?.log({root, parent: parent.toString()});
        return root.itemsStoreFactory.persistentItemsStore;
    }

    return types.reference(PersistedItem, {
        get(itemId: string, parent: IAnyStateTreeNode): any {
            return getItemsStore(parent).getItemByPersistenceKey(itemId);
        },
        set(value) {
            return value.key;
        },
    });
};
