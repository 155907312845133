import {loadCustomScriptWithPromise} from '@joyrideautos/web-common-components/src/utils/Utils';
import {FC, memo, useEffect, useRef} from 'react';
import {getAppConfig} from '@joyrideautos/ui-services/src/AppConfig';

const appConfig = getAppConfig();

declare global {
    interface Window {
        $zopim: any;
    }
}

type LiveChat = {button: {hide: () => void; show: () => void}};

class ZendeskWidgetWrapper {
    private zopim: {livechat: LiveChat} | undefined;
    private loading = false;

    hide() {
        if (!this.liveChat) {
            return;
        }
        this.liveChat.button.hide();
    }

    show() {
        if (!this.liveChat) {
            return;
        }
        this.liveChat.button.show();
    }

    async loadIfNeeded(onLoad?: (status: {isReady: boolean; error?: string}) => void) {
        if (!this.needToLoad || this.loading) {
            return;
        }
        this.loading = true;
        try {
            await loadCustomScriptWithPromise(this.url!, {id: 'ze-snippet', charset: 'utf-8'});
            await this.waitForLivechat();
            this.zopim = window.$zopim;
            onLoad && onLoad({isReady: true});
            this.loading = false;
        } catch (e: any) {
            onLoad && onLoad({isReady: false, error: e.message});
        }
    }

    private get liveChat() {
        return this.zopim?.livechat;
    }

    private get needToLoad() {
        return this.url && typeof this.zopim === 'undefined';
    }

    private get url(): string | undefined {
        if (appConfig.commonConfig.zendeskChatAppId) {
            return `https://static.zdassets.com/ekr/snippet.js?key=${appConfig.commonConfig.zendeskChatAppId}`;
        }
        return undefined;
    }

    private waitForLivechat() {
        const name = '$zopim';
        return new Promise<void>((res, rej) => {
            // TODO: (future) handle timeout
            const wait = () => {
                setTimeout(() => {
                    if (typeof window[name] === 'undefined') {
                        wait();
                    } else {
                        res();
                    }
                }, 1000);
            };
            if (typeof window[name] === 'undefined') {
                wait();
            } else {
                res();
            }
        });
    }
}

const zendeskWrapper = new ZendeskWidgetWrapper();

export const ZendeskWidgetView: FC<{
    visible?: boolean;
    onLoad?: (status: {isReady: boolean; error?: string}) => void;
}> = memo(({visible, onLoad}) => {
    const zopim = useRef<ZendeskWidgetWrapper>(zendeskWrapper);
    useEffect(() => {
        zopim.current
            .loadIfNeeded(onLoad)
            .then(() => {
                if (visible ?? true) {
                    zopim.current.show();
                } else {
                    zopim.current.hide();
                }
            })
            .catch((e) => console.log(e));
    }, [visible, onLoad]);
    return null;
});
