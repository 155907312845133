import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {FirestoreTimestamp} from '../item/FirestoreTimestamp';

export const BidIncrementStep = types.model('BidIncrementStep', {
    from: types.number,
    to: types.maybe(types.number),
    increment: types.number,
});

export interface BidIncrementStepType extends Instance<typeof BidIncrementStep> {}

export interface BidIncrementStepSnapshotIn extends SnapshotIn<typeof BidIncrementStep> {}

export const BidIncrementConfig = types.model('BidIncrementConfig', {
    name: types.string,
    steps: types.maybe(types.array(BidIncrementStep)),
    editedBy: types.maybe(types.string),
    editedAt: types.maybe(FirestoreTimestamp),
    createdBy: types.maybe(types.string),
    createdAt: types.maybe(FirestoreTimestamp),
});

export interface BidIncrementConfigType extends Instance<typeof BidIncrementConfig> {}
