import BaseBridge from '../BaseBridge';
import {CallbackWithAction} from '@joyrideautos/ui-services/src/services/mediaService/types';

class SWBridge extends BaseBridge {
    private subscribers = new Map<string, CallbackWithAction<Omit<any, 'data'> & {type: string}>>();

    start(isCommandType: (type: string) => boolean, isEventType: (type: string) => boolean) {
        if (this.isSWSupported) {
            this.logger?.log('subscribe to messages from sw');
            navigator.serviceWorker.addEventListener('message', async ({data}) => {
                const {data: eventData, type, messageType} = data;
                this.logger?.log('data', messageType, type);
                if (messageType === 'command' && isCommandType(type)) {
                    this.handleCommand(type, eventData);
                    return;
                }
                if (messageType === 'event' && isEventType(type)) {
                    this.handleEvent(type, eventData);
                    return;
                }
            });
        }
    }

    get isSWSupported() {
        return 'serviceWorker' in navigator;
    }

    async checkSWisReady() {
        if (!this.isSWSupported) {
            return false;
        }
        const registration = await navigator.serviceWorker.ready;
        this.logger?.log('SW state is:', registration.active?.state);
        return registration.active?.state === 'activated';
    }

    async sendMessageSW(message: {type: string; data: any}) {
        if (this.isSWSupported) {
            this.logger?.log('sendMessageSW', message.type);
            const isReady = await this.checkSWisReady();
            if (isReady) {
                navigator.serviceWorker.controller?.postMessage(message);
                return this.makeResultFor(message.type);
            } else {
                this.logger?.log('SW is not ready');
                return Promise.reject('SW is not ready');
            }
        } else {
            this.logger?.log('sw is not supported');
        }
    }

    subscribeToEvents<Event extends Record<string, any>, SubscriberType>(
        eventType: Event,
        subscriber: CallbackWithAction<SubscriberType>
    ) {
        const unsubscribers = Object.values(eventType).map((type) => this.subscribeToEvent(type, subscriber));
        return () => {
            unsubscribers.forEach((unsubscriber) => unsubscriber && unsubscriber());
        };
    }

    subscribeToEvent(type: string, subscriber: CallbackWithAction<Omit<any, 'data'> & {type: string}>) {
        if (this.subscribers.get(type)) {
            return;
        }
        this.subscribers.set(type, subscriber);
        return () => {
            this.subscribers.delete(type);
        };
    }

    private postMessage(message: {type: string; data: any; messageType: string}) {
        this.logger?.log('post message', message);
        navigator.serviceWorker.controller?.postMessage(message);
    }

    private handleEvent(type: string, messageData: any) {
        this.logger?.log('handleEvent, data', type, messageData);
        const subscriber = this.subscribers.get(type);
        if (subscriber) {
            const {data, error} = messageData;
            subscriber(error, {...data, type}, this.postMessage.bind(this));
        }
    }
}

export default SWBridge;
