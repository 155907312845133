import {Instance, SnapshotIn, types} from 'mobx-state-tree';

export const PlatformFeeScheduleStep = types.model('PlatformFeeScheduleStep', {
    from: types.number,
    to: types.maybe(types.number),
    amountInCents: types.number,
});

export interface PlatformFeeScheduleStepType extends Instance<typeof PlatformFeeScheduleStep> {}

export interface PlatformFeeScheduleStepSnapshotIn extends SnapshotIn<typeof PlatformFeeScheduleStep> {}

export const PlatformFeeSchedule = types.model('PlatformFeeSchedule', {
    name: types.string,
    steps: types.array(PlatformFeeScheduleStep),
    editedBy: types.maybe(types.string),
    editedAt: types.maybe(types.string),
    createdBy: types.maybe(types.string),
    createdAt: types.maybe(types.string),
});

export interface PlatformFeeScheduleType extends Instance<typeof PlatformFeeSchedule> {}
