import {types} from 'mobx-state-tree';
import primitives from '@joyrideautos/ui-models/src/Primitives';

export const RouteModel = types
    .model('RouteModel', {
        _path: primitives.string(),
        _isExact: primitives.boolean(),
        params: primitives.map(),
        search: primitives.string(),
        hash: primitives.string(),
        pathname: primitives.string(),
    })
    .views((self) => {
        return {
            get path() {
                return self._path.value;
            },
            get fullPath() {
                return (
                    self.pathname.value +
                    (self.search.isEmpty ? '' : `?${self.search.value}`) +
                    (self.hash.isEmpty ? '' : `#${self.hash.value}`)
                );
            },
            get isExact() {
                return self._isExact.value;
            },
            getRouteParamByName<R = string>(name: string): R | undefined {
                return self.params.getValue(name);
            },
        };
    })
    .actions((self) => ({
        update({path, isExact, params}: {path: string; isExact: boolean; params: Record<string, any>}) {
            self._path.setValue(path);
            self._isExact.setValue(isExact);
            self.params.replaceFromObject(params);
        },
    }));
