import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import * as H from 'history';
import {RootStoreType} from '@models/stores/RootStore';
import {logError} from '@joyrideautos/ui-logger/src/utils';

interface ReactRouterGAProps extends RouteComponentProps<any> {
    children?: React.ReactNode;
    trackPathnameOnly: boolean;
    rootStore: RootStoreType;
}

class ReactRouterGA extends React.Component<ReactRouterGAProps, any> {
    lastPathname: string | undefined;

    constructor(props: ReactRouterGAProps) {
        super(props);

        this.sendPageView = this.sendPageView.bind(this);
    }

    componentDidMount() {
        if (this.props.location) {
            this.sendPageView(this.props.location);
        }
        this.props.history.listen(this.sendPageView);
    }

    sendPageView(location: H.Location) {
        if (this.props.trackPathnameOnly && location.pathname === this.lastPathname) {
            return;
        }

        const pagePath = H.createPath(location);
        this.lastPathname = location.pathname;
        // before firebase 9:
        // analytics.setCurrentScreen(location.pathname);
        this.props.rootStore.analyticsService
            .logEvent('screen_view', {
                firebase_screen: location.pathname,
                // firebase_screen_class: screenClass, //TODO: (future) pass this parameter as well.
            })
            .catch(logError());

        this.props.rootStore.analyticsService
            .logEvent('pageview', {
                page_path: pagePath,
            })
            .catch(logError());
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ReactRouterGA);
