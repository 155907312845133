import moment from 'moment';
import numeral from 'numeral';
import {CodeAndName} from '@joyrideautos/auction-core/src/types/CodeAndName';

export const DateFormat = 'YYYY-MM-DD';
export const TimeFormat = 'hh:mm A';
export const DateTimeFormat = `${DateFormat} ${TimeFormat}`;

export const CURRENCY_NUMBER_FORMAT = '0,0.00';

function isNullOrUndefined(value: any) {
    return value === null || typeof value === 'undefined';
}

export function valueOrEmptyString(value: any) {
    return !isNullOrUndefined(value) ? value : '';
}

export function valueOrFalse(value: any) {
    return !isNullOrUndefined(value) ? value : false;
}

export function valueOrEmptyArray(value: any) {
    return !isNullOrUndefined(value) ? value : [];
}

export function valueOrNull(value: any) {
    return value || null;
}

export function stringToNumber(value: any): any {
    return value ? parseInt(value, 10) : null;
}

export function numberToString(value: number | null | undefined, defaultValue = ''): string {
    return value != null ? String(value) : defaultValue;
}

export const stringToISODate = (value: string, format: string = DateFormat) => {
    const date = moment(value, format, true);
    return date.isValid() ? date.toISOString(true) : value;
};

export const stringToGLocalDateTimeOrNull = (value?: string, format: string = DateFormat) => {
    if (!value) return null;

    return {
        dateTimeString: stringToISODate(value, format),
    };
};

export const formatISOToDateTime = (value: string | null | undefined, format: string) => {
    if (!value) return '';
    const date = moment(value, moment.ISO_8601);
    return date.isValid() ? date.format(format) : value;
};

export const formatISOToLocalizedDate = (value: string) => {
    return formatISOToDateTime(value, 'L');
};

export const formatISOToLocalizedTime = (value: string) => {
    return formatISOToDateTime(value, 'LT');
};

export const formatISOToLocalizedDateTime = (value: string) => {
    return formatISOToDateTime(value, 'L LT');
};

export const formatLocalizedMonthLong = (month: number) => {
    return moment({month}).format('MMMM');
};

export function formatCurrency(amount: number | null | undefined) {
    return typeof amount === 'number' ? `$${amount}` : '-';
}

export const formatCurrencyInDollars = (value?: number) => {
    return value !== undefined ? `$${numeral(value).format(CURRENCY_NUMBER_FORMAT)}` : '-';
};

export const toString = (val: any) => {
    return val != null ? String(val) : '';
};

export function nbspString(str: string) {
    return str.replace(/\s/g, '\xa0');
}

export function compose(formatA: (val: any) => any, formatB: (val: any) => any) {
    return (value: any) => {
        return formatA(formatB(value));
    };
}

export const formatGLocalDateTimeToDateString = (value: {dateTimeString: string} | null | undefined) => {
    return formatISOToDateTime(value && value.dateTimeString, DateFormat);
};

export function formatCodeAndNameToString(codeAndName: Partial<CodeAndName> | null | undefined) {
    return codeAndName ? codeAndName.name || (codeAndName.code && codeAndName.code.toString()) : undefined;
}
