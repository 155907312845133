import {Instance, types} from 'mobx-state-tree';
import {BidsContainer} from './BidsContainer';
import type {BidsContainerType} from './BidsContainer';
import {observable} from 'mobx';
import {ItemPath} from '@joyrideautos/auction-core/src/types/Item';

function makeKey(item: ItemPath) {
    const {regionId, auctionId, itemId} = item;
    // handle case for persistedItems, which have unique keys
    if (!regionId && !auctionId) {
        return itemId;
    }
    return `${regionId}-${auctionId}-${itemId}`;
}

export const BidsStore = types
    .model('BidsStore', {
        containers: types.map(BidsContainer),
    })
    .volatile(() => ({
        revertBidStatuses: observable.array<string>([]),
    }))
    .actions((self) => ({
        addContainers(containers: {[key: string]: {regionId: string; auctionId: string}}) {
            Object.keys(containers).forEach((key) => {
                const container = containers[key];
                if (!self.containers.has(key)) {
                    self.containers.set(key, container);
                }
            });
            // It doesnt merge as we expected, it cleans previous values
            // self.containers.merge(containers)
        },
        addContainersForItems(items: ItemPath[]) {
            const bidContainers = items.reduce((bidContainers, item) => {
                const {regionId, auctionId, itemId} = item;
                bidContainers[makeKey({regionId, auctionId, itemId})] = {
                    regionId: item.regionId,
                    auctionId: item.auctionId,
                };
                return bidContainers;
            }, {} as {[key: string]: {regionId: string; auctionId: string}});
            this.addContainers(bidContainers);
        },
        clear() {
            self.containers.clear();
        },
        addRevertInProgress(bidKey: string) {
            self.revertBidStatuses.push(bidKey);
        },
        removeRevertInProgress(bidKey: string) {
            self.revertBidStatuses.replace(self.revertBidStatuses.filter((key) => key !== bidKey));
        },
    }))
    .views((self) => ({
        containerFor(itemPath: ItemPath): BidsContainerType | undefined {
            return self.containers.get(makeKey(itemPath));
        },
        containerForAsync(itemPath: ItemPath): BidsContainerType | undefined {
            const container = self.containers.get(makeKey(itemPath));
            if (!container) {
                requestAnimationFrame(() => {
                    self.addContainersForItems([itemPath]);
                });
            }
            return container;
        },
        get revertBidInProgress() {
            return self.revertBidStatuses.length > 0;
        },
    }));

export interface BidsStoreType extends Instance<typeof BidsStore> {}

export interface HasBidsStore {
    bidsStore: BidsStoreType;
}
