export class Money {
    private constructor(private amountInCents: number) {}

    /**
     * Clones a Money instance.
     * @param {Money} money - The Money instance to clone.
     * @returns {Money} - A new Money instance with the same amount.
     */
    static clone(money: Money) {
        return new Money(money.amountInCents);
    }

    /**
     * Creates a Money instance from a cents amount.
     * @param {number} amount - The amount in cents.
     * @returns {Money} - A new Money instance.
     */
    static fromCents(amount: number) {
        return new Money(amount);
    }

    /**
     * Creates a Money instance from a dollars amount.
     * @param {number} amount - The amount in dollars.
     * @returns {Money} - A new Money instance.
     */
    static fromDollars(amount: number) {
        return new Money(amount * 100);
    }

    /**
     * Creates a Money instance with zero amount.
     * @returns {Money} - A new Money instance with zero amount.
     */
    static get zero() {
        return new Money(0);
    }

    /**
     * Returns a sorting function for Money instances.
     * @param {boolean} reverse - Whether to sort in reverse order.
     * @returns {function} - A sorting function.
     */
    static getSorting =
        (reverse = false) =>
        (a: Money, b: Money) => {
            if (a.amountInCents < b.amountInCents) {
                return reverse ? 1 : -1;
            }
            if (a.amountInCents > b.amountInCents) {
                return reverse ? -1 : 1;
            }
            return 0;
        };

    /**
     * Returns the sum of multiple Money instances.
     * @param {...Money[]} money - The Money instances to sum.
     * @returns {Money} - A new Money instance representing the sum.
     */
    static sumOf(...money: Money[]) {
        return money.reduce((acc, curr) => acc.add(curr));
    }

    /**
     * Returns the difference of multiple Money instances.
     * @param {...Money[]} money - The Money instances to subtract.
     * @returns {Money} - A new Money instance representing the difference.
     */
    static subtractOf(...money: Money[]) {
        return money.reduce((acc, curr) => acc.subtract(curr));
    }

    /**
     * Returns the minimum of multiple Money instances.
     * @param values
     */
    static min(...values: Money[]) {
        return Money.fromCents(Math.min(...values.map((m) => m.amountInCents)));
    }

    /**
     * Returns the maximum of multiple Money instances.
     * @param values
     */
    static max(...values: Money[]) {
        return Money.fromCents(Math.max(...values.map((m) => m.amountInCents)));
    }

    /**
     * Adds a Money instance to the current one.
     * @param {Money} money - The Money instance to add.
     * @returns {Money} - A new Money instance representing the sum.
     */
    add(money: Money) {
        return Money.fromCents(Math.round(this.amountInCents + money.amountInCents));
    }

    /**
     * Adds a Money instance to the current one.
     * @param {Money} money - The Money instance to add.
     * @returns {Money} - A new Money instance representing the sum.
     */
    plus(money: Money) {
        return Money.fromCents(Math.round(this.amountInCents + money.amountInCents));
    }

    /**
     * Subtracts a Money instance from the current one.
     * @param {Money} money - The Money instance to subtract.
     * @returns {Money} - A new Money instance representing the difference.
     */
    subtract(money: Money) {
        return Money.fromCents(Math.round(this.amountInCents - money.amountInCents));
    }

    /**
     * Subtracts a Money instance from the current one.
     * @param {Money} money - The Money instance to subtract.
     * @returns {Money} - A new Money instance representing the difference.
     */
    minus(money: Money) {
        return Money.fromCents(Math.round(this.amountInCents - money.amountInCents));
    }

    /**
     * Multiplies the current Money instance by another one.
     * @param {Money} money - The Money instance to multiply by.
     * @returns {Money} - A new Money instance representing the product.
     */
    multiply(money: Money) {
        return Money.fromCents(Math.round(this.amountInCents * money.amountInCents));
    }

    /**
     * Divides the current Money instance by another one.
     * @param {Money} money - The Money instance to divide by.
     * @param {Object} options - Optional parameters.
     * @param {('floor'|'ceil'|'round')} options.rounding - The rounding method to use. Defaults to 'round'.
     * @returns {Money} - A new Money instance representing the quotient.
     */
    divide(money: Money, options?: {rounding: 'floor' | 'ceil' | 'round'}) {
        const rounding = options?.rounding ?? 'round';
        if (rounding === 'ceil') {
            return Money.fromCents(Math.ceil(this.amountInCents / money.amountInCents));
        }
        if (rounding === 'round') {
            return Money.fromCents(Math.round(this.amountInCents / money.amountInCents));
        }
        return Money.fromCents(Math.floor(this.amountInCents / money.amountInCents));
    }

    /**
     * Takes a percentage of the current money amount.
     *
     * @param {number} percent - The percentage to take from the current money amount as a fractional number (0-1).
     * @param {Object} [options] - Optional parameters.
     * @param {('floor'|'ceil'|'round')} [options.rounding='round'] - The rounding method to use. Defaults to 'round'.
     * @returns {Money} A new Money instance representing the specified percentage of the current money amount.
     *
     * @example
     * Returns a Money instance representing 50% of the current money amount, rounded to the nearest cent.
     * moneyInstance.takePercent(0.5);
     *
     * @example
     * Returns a Money instance representing 25% of the current money amount, rounded up to the nearest cent.
     * moneyInstance.takePercent(0.25, { rounding: 'ceil' });
     *
     * @example
     * Returns a Money instance representing 75% of the current money amount, rounded down to the nearest cent.
     * moneyInstance.takePercent(0.75, { rounding: 'floor' });
     */
    takePercent(percent: number, options?: {rounding: 'floor' | 'ceil' | 'round'}) {
        const rounding = options?.rounding ?? 'round';
        if (rounding === 'ceil') {
            return Money.fromCents(Math.ceil(this.amountInCents * percent));
        }
        if (rounding === 'round') {
            return Money.fromCents(Math.round(this.amountInCents * percent));
        }
        return Money.fromCents(Math.floor(this.amountInCents * percent));
    }

    /**
     * Add a percentage to the current money amount.
     *
     * @param {number} percent - The percentage to take from the current money amount as a fractional number (0-1).
     * @param {Object} [options] - Optional parameters.
     * @param {('floor'|'ceil'|'round')} [options.rounding='round'] - The rounding method to use. Defaults to 'round'.
     * @returns {Money} A new Money instance representing the specified percentage of the current money amount.
     *
     * @example
     * Returns a Money instance representing 50% of the current money amount plus the current money amount, rounded to the nearest cent.
     * //
     * 100.addPercent(0.5) = 150;
     *
     * @example
     * Returns a Money instance representing 25% of the current money amount plus the current money amount, rounded up to the nearest cent.
     * 100.addPercent(0.25, { rounding: 'ceil' }) = 125;
     *
     * @example
     * Returns a Money instance representing 75% of the current money amount plus the current money amount, rounded down to the nearest cent.
     * 100.addPercent(0.75, { rounding: 'floor' }) = 175;
     */
    addPercent(percent: number, options?: {rounding: 'floor' | 'ceil' | 'round'}) {
        const rounding = options?.rounding ?? 'round';
        let result: Money;
        if (rounding === 'ceil') {
            result = Money.fromCents(Math.ceil(this.amountInCents * percent));
        } else if (rounding === 'round') {
            result = Money.fromCents(Math.round(this.amountInCents * percent));
        } else {
            result = Money.fromCents(Math.floor(this.amountInCents * percent));
        }
        return this.add(result);
    }

    /**
     * Checks if the current Money instance is less than another one.
     * @param {Money} money - The Money instance to compare with.
     * @returns {boolean} - True if the current Money instance is less than the other one, false otherwise.
     */
    isLessThan(money: Money) {
        return this.amountInCents < money.amountInCents;
    }

    /**
     * Checks if the current Money instance is less than or equal to another one.
     * @param {Money} money - The Money instance to compare with.
     * @returns {boolean} - True if the current Money instance is less than or equal to the other one, false otherwise.
     */
    isLessThanOrEqual(money: Money) {
        return this.amountInCents <= money.amountInCents;
    }

    /**
     * Checks if the current Money instance is greater than another one.
     * @param {Money} money - The Money instance to compare with.
     * @returns {boolean} - True if the current Money instance is greater than the other one, false otherwise.
     */
    isGreaterThan(money: Money) {
        return this.amountInCents > money.amountInCents;
    }

    /**
     * Checks if the current Money instance is greater than or equal to another one.
     * @param {Money} money - The Money instance to compare with.
     * @returns {boolean} - True if the current Money instance is greater than or equal to the other one, false otherwise.
     */
    isGreaterThanOrEqual(money: Money) {
        return this.amountInCents >= money.amountInCents;
    }

    /**
     * Checks if the current Money instance is equal to another one.
     * @param {Money} money - The Money instance to compare with.
     * @returns {boolean} - True if the current Money instance is equal to the other one, false otherwise.
     */
    isEqual(money: Money) {
        return this.amountInCents === money.amountInCents;
    }

    /**
     * Checks if the current Money instance is zero.
     * @returns {boolean} - True if the current Money instance is zero, false otherwise.
     */
    isZero() {
        return this.amountInCents === 0;
    }

    /**
     * Gets the amount in cents.
     * @returns {number} - The amount in cents.
     */
    get cents() {
        return this.amountInCents;
    }

    /**
     * Gets the amount in dollars.
     * @returns {number} - The amount in dollars.
     */
    get dollars() {
        return this.amountInCents / 100;
    }

    /**
     * Converts the current Money instance to a string.
     * @returns {string} - A string representation of the current Money instance.
     */
    toString() {
        return JSON.stringify({amount: this.amountInCents.toString()});
    }
}
