import {ItemPlaceholderEnum} from './MediaResource';

export const DEFAULT_REQUIRED_NUMBER_OF_PHOTOS_FOR_CANDIDATE = 4;
export enum ImageSizeEnum {
    ORIGINAL = 'original',
    THUMB_200 = 'thumb_200',
    FULL_4X3 = 'full_4x3',
    THUMBNAIL_4X3 = 'thumbnail_4x3',
}

export enum VideoSizeEnum {
    VIDEO = 'video',
    VIDEO_4X3 = 'video_4x3',
}

export type ImageOrVideoSize = ImageSizeEnum | VideoSizeEnum;

export enum ItemMediaStatusEnum {
    LOADING = 'loading',
    LOADED = 'loaded',
    ERROR = 'error',
}

export type ItemImageIndexType = string | number;

export type ItemImages = {
    [imageSize in ImageSizeEnum]: {[key: string]: ItemImage};
};

export interface ItemImageInfo {
    image?: ItemImages;
    imagesCount?: number;
}

export interface ItemMedia {
    bucket: string;
    contentType: string;
    name: string;
    url: string;
    metadata: {
        sourceIdx: string;
        sourceURL?: string;
        sourceGdriveFileId?: string;
    };
    idx: ItemImageIndexType | null;
    uploadedAt?: number;
    placeholderType?: ItemPlaceholderEnum;
    a?: string;
    status?: ItemMediaStatusEnum;
}

export interface ItemVideo extends ItemMedia {}

export interface ItemVideoWithId extends ItemVideo {
    id: string;
}

export interface ItemImage extends ItemMedia {
    height: number;
    width: number;
}

export interface ItemImageWithId extends ItemImage {
    id: string;
}

export interface ItemImageMap {
    original?: Map<string, ItemImage>;
    thumb_200?: Map<string, ItemImage>;
    full_4x3?: Map<string, ItemImage>;
    thumbnail_4x3?: Map<string, ItemImage>;
}

export interface ItemVideoMap {
    video?: Map<string, ItemVideo>;
    video_4x3?: Map<string, ItemVideo>;
}

export interface ItemImageAndVideoMedia extends ItemImageMap, ItemVideoMap {}

export interface MediaInfo {
    idx: number;
    image: {[imageSize in ImageSizeEnum]: ItemImage};
    video?: {[videoSize in VideoSizeEnum]: ItemImage};
}

export function isVideo(imageOrVideoSize: string) {
    return Object.values(VideoSizeEnum).includes(imageOrVideoSize as VideoSizeEnum);
}

export function isImage(imageOrVideoSize: string) {
    return Object.values(ImageSizeEnum).includes(imageOrVideoSize as ImageSizeEnum);
}
