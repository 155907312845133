import React from 'react';

const PDF_VIEWER_PATH = '/pdf/web/viewer.html';

export const PDF_VIEWER_DEFAULT_HEIGHT = '80vh';

export const PDFViewer = (props: {link: string; height?: string}) => {
    const {link, height = PDF_VIEWER_DEFAULT_HEIGHT} = props;

    return (
        <iframe
            title="PDF Viewer"
            style={{width: '100%', height}}
            src={`${PDF_VIEWER_PATH}?file=${encodeURIComponent(link)}`}
        />
    );
};
