import makeLogger, {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import {RequestsService, Analytics, Firebase} from './firebase/types';
import {SentryService, Services} from './types';
import type {Database} from './firebase/Database';
import type {Firestore} from './firebase/Firestore';
import type {Auth} from './firebase/Auth';
import type {Storage} from './firebase/Storage';
import type {Performance} from './firebase/Performance';
import {UIServicesFactory as Factory} from './types';
import {PhoneValidationService} from './services/PhoneValidationService';
import {UsersService} from './services/UsersService';
import {ManagerRoleService} from './services/ManagerRoleService';
import {SellerRoleService} from './services/SellerRoleService';
import {SellersService} from './services/SellersService';
import {BidService} from './services/BidService';
import {AuthUserService} from './services/AuthUserService';
import {AnalyticsService} from './services/AnalyticsService';
import {AdminRoleService} from './services/AdminRoleService';
import {AuctionService} from './services/AuctionService';
import {BiddersService} from './services/BiddersService';
import {UserBansService} from './services/UserBansService';
import {EventsService} from './services/EventsService';
import {FavoritesService} from './services/FavoritesService';
import {GoogleDriveService} from './services/GoogleDriveService';
import {ItemPaymentService} from './services/ItemPaymentService';
import {ClockService} from './services/ClockService';
import {GlobalConfigService} from './services/GlobalConfigService';
import {PaymentService} from './services/PaymentService';
import {RecaptchaService} from './services/RecaptchaService';
import {RefsService} from './services/RefsService';
import {SearchService} from './services/SearchService';
import {StorageService} from './services/StorageService';
import {RegionsService} from './services/RegionsService';
import {WhiteListService} from './services/WhitelistService';
import {WonItemsService} from './services/WonItemsService';
import {FirebaseTraceService} from './services/FirebaseTraceService';
import {ItemsService} from './services/ItemsService';
import {FirebaseService} from './services/FirebaseService';
import {AppConfig} from './AppConfig';
import {PlatformFeeScheduleService} from './services/PlatformFeeScheduleService';
import {ReleaseVersionService} from './services/ReleaseVersionService';
import {AuditService} from './services/AuditService';
import {ClaimSessionService} from './services/ClaimSessionService';

export class UIServicesFactory implements Factory {
    constructor(protected firebase: Firebase, protected config: AppConfig, public sentryService?: SentryService) {}

    createAuthUserService() {
        return new AuthUserService(
            this.firebase,
            makeLogger(this.sentryService)('AuthUserService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createPhoneValidationService(): PhoneValidationService {
        return new PhoneValidationService(
            this.firebase,
            makeLogger(this.sentryService)('PhoneValidationService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createUsersService() {
        return new UsersService(
            this.firebase,
            makeLogger(this.sentryService)('UsersService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createManagerRoleService(): ManagerRoleService {
        return new ManagerRoleService(
            this.firebase,
            makeLogger(this.sentryService)('ManagerRoleService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createSellerRoleService(): SellerRoleService {
        return new SellerRoleService(
            this.firebase,
            makeLogger(this.sentryService)('SellerRoleService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createSellersService() {
        return new SellersService(
            this.firebase,
            makeLogger(this.sentryService)('SellersService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createAdminRoleService() {
        return new AdminRoleService(
            this.firebase,
            makeLogger(this.sentryService)('AdminRoleService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createAnalyticsService() {
        return new AnalyticsService(
            this.firebase,
            makeLogger(this.sentryService)('AnalyticsService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createAuctionService() {
        return new AuctionService(
            this.firebase,
            makeLogger(this.sentryService)('AuctionService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createBidService() {
        return new BidService(
            this.firebase,
            makeLogger(this.sentryService)('BidService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createBiddersService(): BiddersService {
        return new BiddersService(
            this.firebase,
            makeLogger(this.sentryService)('BiddersService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createUserBansService(): UserBansService {
        return new UserBansService(
            this.firebase,
            makeLogger(this.sentryService)('UserBansService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createClockService(): ClockService {
        return new ClockService(
            this.firebase,
            makeLogger(this.sentryService)('ClockService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createEventSubscriptionService(): any {
        return {} as any;
    }

    createEventsService(): EventsService {
        return new EventsService(
            this.firebase,
            makeLogger(this.sentryService)('EventsService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createFavoritesService(): FavoritesService {
        return new FavoritesService(
            this.firebase,
            makeLogger(this.sentryService)('FavoritesService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    // TODO: (future) remove singleton. probably we don't need singleton here because rootStore will be created only once
    private static instance: FirebaseTraceService;

    createFirebaseTraceService() {
        if (!UIServicesFactory.instance) {
            UIServicesFactory.instance = new FirebaseTraceService(
                this.firebase,
                makeLogger(this.sentryService)('FirebaseTraceService', LoggerStatusEnum.ENABLED),
                this.config
            );
        }
        return UIServicesFactory.instance;
    }

    createGlobalConfigService(): GlobalConfigService {
        return new GlobalConfigService(
            this.firebase,
            makeLogger(this.sentryService)('GlobalConfigService'),
            this.config
        );
    }

    createReleaseVersionService(): ReleaseVersionService {
        return new ReleaseVersionService(
            this.firebase,
            makeLogger(this.sentryService)('ReleaseVersionService'),
            this.config
        );
    }

    createGoogleDriveService() {
        return new GoogleDriveService(this.firebase, makeLogger(this.sentryService)('GoogleDriveService'), this.config);
    }

    createItemPaymentService(): ItemPaymentService {
        return new ItemPaymentService(
            this.firebase,
            makeLogger(this.sentryService)('ItemPaymentService', LoggerStatusEnum.DISABLED),
            this.config
        );
    }

    createItemsService() {
        return new ItemsService(
            this.firebase,
            makeLogger(this.sentryService)('ItemsService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createPaymentService(): PaymentService {
        return new PaymentService(this.firebase, makeLogger(this.sentryService)('PaymentService'), this.config);
    }

    createRecaptchaService() {
        return new RecaptchaService(this.firebase, makeLogger(this.sentryService)('RecaptchaService'), this.config);
    }

    createRefsService(): RefsService {
        return new RefsService(this.firebase, makeLogger(this.sentryService)('RefsService'), this.config);
    }

    createRegionsService(): RegionsService {
        return new RegionsService(this.firebase, makeLogger(this.sentryService)('RegionsService'), this.config);
    }

    createSearchService() {
        return new SearchService(this.firebase, makeLogger(this.sentryService)('SearchService'), this.config);
    }

    createWhiteListService() {
        return new WhiteListService(this.firebase, makeLogger(this.sentryService)('WhiteListService'), this.config);
    }

    createWonItemsService() {
        return new WonItemsService(
            this.firebase,
            makeLogger(this.sentryService)('WonItemsService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createStorageService() {
        return new StorageService(this.firebase, makeLogger(this.sentryService)('StorageService'), this.config);
    }

    createFirebaseService() {
        return new FirebaseService(this.firebase, makeLogger(this.sentryService)('FirebaseService'), this.config);
    }

    createPlatformFeeScheduleService() {
        return new PlatformFeeScheduleService(
            this.firebase,
            makeLogger(this.sentryService)('PlatformFeeScheduleService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createAuditService() {
        return new AuditService(
            this.firebase,
            makeLogger(this.sentryService)('AuditService', LoggerStatusEnum.DISABLED),
            this.config
        );
    }

    createClaimSessionService() {
        return new ClaimSessionService(
            this.firebase,
            makeLogger(this.sentryService)('ClaimSessionService', LoggerStatusEnum.ENABLED),
            this.config
        );
    }

    createAllServices(): Services {
        return {
            authUserService: this.createAuthUserService(),
            phoneValidationService: this.createPhoneValidationService(),
            userService: this.createUsersService(),
            managerRoleService: this.createManagerRoleService(),
            sellerRoleService: this.createSellerRoleService(),
            sellersService: this.createSellersService(),
            adminRoleService: this.createAdminRoleService(),
            analyticsService: this.createAnalyticsService(),
            auctionService: this.createAuctionService(),
            bidsService: this.createBidService(),
            biddersService: this.createBiddersService(),
            userBansService: this.createUserBansService(),
            clockService: this.createClockService(),
            eventsService: this.createEventsService(),
            favoritesService: this.createFavoritesService(),
            firebaseTraceService: this.createFirebaseTraceService(),
            globalConfigService: this.createGlobalConfigService(),
            releaseVersionService: this.createReleaseVersionService(),
            googleDriveService: this.createGoogleDriveService(),
            itemPaymentService: this.createItemPaymentService(),
            itemsService: this.createItemsService(),
            paymentService: this.createPaymentService(),
            recaptchaService: this.createRecaptchaService(),
            refsService: this.createRefsService(),
            regionsService: this.createRegionsService(),
            searchService: this.createSearchService(),
            whiteListService: this.createWhiteListService(),
            wonItemService: this.createWonItemsService(),
            storageService: this.createStorageService(),
            firebaseService: this.createFirebaseService(),
            platformFeeScheduleService: this.createPlatformFeeScheduleService(),
            auditService: this.createAuditService(),
            claimSessionService: this.createClaimSessionService(),
        };
    }
}

export const createUIServicesFactory = ({
    auth,
    database,
    firestore,
    storage,
    rpcService,
    analytics,
    sentryService,
    performance,
    config,
}: {
    auth: Auth;
    database: Database;
    firestore: Firestore;
    storage: Storage;
    rpcService: RequestsService;
    analytics: Analytics;
    sentryService: SentryService;
    performance: Performance;
    config: AppConfig;
}): Factory => {
    return new UIServicesFactory(
        {auth, database, firestore, rpcService, analytics, storage, performance},
        config,
        sentryService
    );
};
