import {Instance, types} from 'mobx-state-tree';
import {compareStrings} from '@joyrideautos/auction-utils/src/stringUtils';

export const CodeAndName = types.model('CodeAndName', {
    code: types.maybe(types.union(types.string, types.number)),
    name: types.maybe(types.string),
});

export interface CodeAndNameType extends Instance<typeof CodeAndName> {}

type CodeAndNameSorting = {
    code: string | number;
    name: string;
};

export const sorting =
    (fieldName: keyof Partial<CodeAndNameSorting>) =>
    (a: Partial<CodeAndNameSorting>, b: Partial<CodeAndNameSorting>) => {
        return {
            code: () => {
                if (typeof a['code'] === 'string' && typeof b['code'] === 'string') {
                    return compareStrings(a['code'], b['code']);
                }
                if (typeof a['code'] === 'number' && typeof b['code'] === 'number') {
                    return a['code'] - b['code'];
                }
                return 0;
            },
            name: () => compareStrings(a['name'], b['name']),
        }[fieldName]();
    };
